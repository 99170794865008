import { Legend, Line, Tooltip, XAxis, YAxis } from "recharts";

import moment from "moment";

const getStyleAttributes = () => {
  return {
    fontSize: getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-font-size")
      .trim(),
    borderColor: getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-charts-lines")
      .trim(),
    backgroundColor: getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-editor-background")
      .trim(),
    fontColor: getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-charts-foreground")
      .trim(),
  };
};

const YAxisWrap = (props) => {
  const { label } = props;

  const cs = getStyleAttributes();

  label.fill = cs.fontColor;
  label.stroke = cs.fontColor;
  label.fontSize = cs.fontSize;
  label.position = "center";
  label.strokeWidth = 0;

  return (
    <YAxis
      style={{ fontSize: cs.fontSize }}
      tick={{ fill: cs.fontColor, stroke: cs.fontColor }}
      scale="auto"
      strokeWidth={0}
      tickLine={{ stroke: cs.fontColor, strokeWidth: 1 }}
      label={label}
      {...props}
    />
  );
};

const LineWrap = (props) => {
  const { name, id, color, dataKey, strokeWidth = 2 } = props;
  return (
    <Line
      key={name}
      name={name}
      yAxisId={id}
      type="monotone"
      dataKey={dataKey || id}
      stroke={color}
      strokeWidth={strokeWidth}
      isAnimationActive={false}
      {...props}
    />
  );
};

const XAxisWrap = (props) => {
  const { dataKey, dy } = props;

  const cs = getStyleAttributes();

  return (
    <XAxis
      tick={{ fill: cs.fontColor, stroke: cs.fontColor }}
      dataKey={dataKey}
      strokeWidth={0}
      tickFormatter={(date) => {
        return moment(date, "YYYY-MM-DD").format("MM/DD");
      }}
      style={{ fontSize: cs.fontSize }}
      dy={dy}
    />
  );
};

const LegendWrapper = (props) => {
  const cs = getStyleAttributes();

  return (
    <Legend
      align="center"
      wrapperStyle={{
        fontSize: cs.fontSize,
        paddingTop: "12px",
        color: cs.fontColor,
      }}
      layout="horizontal"
      verticalAlign="bottom"
    />
  );
};

const TooltipWrap = () => {
  const cs = getStyleAttributes();

  return (
    <Tooltip
      contentStyle={{
        fontSize: cs.fontSize,
        color: cs.fontColor,
        borderRadius: "4px",
        backgroundColor: cs.backgroundColor,
        borderColor: cs.borderColor,
      }}
      wrapperStyle={{
        backgroundColor: cs.backgroundColor,
      }}
      formatter={(value) => {
        return value.toFixed(0);
      }}
    />
  );
};

const GraphReTooltip = ({ children }) => {
  return <div className="p-2 text-sm border rounded shadow bg-background">{children}</div>;
};

export { YAxisWrap, LineWrap, XAxisWrap, LegendWrapper, GraphReTooltip, TooltipWrap };
