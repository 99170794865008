import Title from "@/components/Title";
import { VSCodeButton } from "@vscode/webview-ui-toolkit/react";

const NotFoundPage = () => {
  return (
    <>
      <Title>404</Title>
      <div className="flex items-center justify-center object-center text-center">
        <center>
          <img alt="404" className="w-1/4 h-1/4" src="/assets/robot.png" />
          <br />
          <h2 className="text-3xl leading-8 tracking-tight text-gray-900">404 - Page Not Found</h2>

          <div className="flex flex-col items-center justify-center pt-6 space-y-2">
            <a className="link" href="/">
              Goto /
            </a>

            <VSCodeButton>
              <a href="https://marketplace.visualstudio.com/items?itemName=stateful.stable">
                Sign up for Stateful
              </a>
            </VSCodeButton>
          </div>
        </center>
      </div>
    </>
  );
};

export default NotFoundPage;
