import { createContext, useCallback, useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import { isWorking } from "@/utils/personalize";

const SettingsContext = createContext();

const USER_DAYS_SETTINGS = gql`
  query UserDaysSettings {
    user {
      id
      timezone
      settings {
        days {
          data {
            workStartTime
            workEndTime
            targetRestDurationSeconds
            targetRestCount
            targetCodingDurationSeconds
            standupTime
            standupTimezone
            ratingPromptTime
            dayOfWeek
          }
        }
      }
    }
  }
`;

const USER_DAY_SETTINGS = gql`
  query UserDaySettings {
    user {
      id
      timezone
      settings {
        day {
          workStartTime
          workEndTime
          targetRestDurationSeconds
          targetRestCount
          targetCodingDurationSeconds
          standupTime
          standupTimezone
          ratingPromptTime
          dayOfWeek
          penalizeGitBranchUsage
        }
      }
    }
  }
`;

const SET_DAY_SETTINGS = gql`
  mutation SetDaySettings(
    $dayOfWeeks: [DayOfWeek!]!
    $standupTime: Time
    $standupTimezone: String
    $workStartTime: Time
    $ratingPromptTime: Time
    $targetCodingDurationSeconds: Int
    $targetRestCount: Int
    $targetRestDurationSeconds: Int
    $workEndTime: Time
    $penalizeGitBranchUsage: [String!]
  ) {
    setUserDaySettings(
      input: {
        dayOfWeeks: $dayOfWeeks
        standupTime: $standupTime
        standupTimezone: $standupTimezone
        workStartTime: $workStartTime
        ratingPromptTime: $ratingPromptTime
        targetCodingDurationSeconds: $targetCodingDurationSeconds
        targetRestCount: $targetRestCount
        targetRestDurationSeconds: $targetRestDurationSeconds
        workEndTime: $workEndTime
        penalizeGitBranchUsage: $penalizeGitBranchUsage
      }
    ) {
      errors {
        field
        message
      }
    }
  }
`;

const SettingsProvider = ({ children }) => {
  const {
    data: daysData,
    error: daysError,
    loading: daysLoading,
    refetch: daysRefetch,
  } = useQuery(USER_DAYS_SETTINGS);

  const {
    data: dayData,
    error: dayError,
    loading: dayLoading,
    refetch: dayRefetch,
  } = useQuery(USER_DAY_SETTINGS);

  const [updateUser, { loading: updateSettingsLoading, error: updateSettingsError }] =
    useMutation(SET_DAY_SETTINGS);

  const daysSettings = useMemo(() => {
    if (daysData) {
      return daysData?.user?.settings?.days?.data;
    }
  }, [daysData]);

  const daySettings = useMemo(() => {
    if (dayData) {
      return dayData?.user?.settings?.day;
    }
  }, [dayData]);

  const plannedDays = useMemo(() => {
    if (daysSettings) {
      const daysArr = daysSettings.map((day) => [day.dayOfWeek, isWorking(day)]);
      return daysArr.reduce((accumulator, value) => {
        return { ...accumulator, [value[0]]: value[1] };
      }, {});
    }
  }, [daysSettings]);

  const saveUserDayWorkingSettings = useCallback(
    (props, refetch = true) => {
      const days = daysSettings.filter((day) => isWorking(day)).map((day) => day.dayOfWeek);

      const variables = { dayOfWeeks: days, ...props };

      updateUser({ variables: variables }).then((resp) => {
        if (refetch) {
          daysRefetch();
        }
      });
    },
    [daysSettings, updateUser, daysRefetch]
  );

  const saveUserDaySettings = useCallback(
    (props, cb = null) => {
      const days = daysSettings.map((day) => day.dayOfWeek);

      const variables = { dayOfWeeks: days, ...props };
      updateUser({ variables: variables }).then((resp) => {
        daysRefetch();
        if (cb) {
          cb();
        }
      });
    },
    [daysSettings, updateUser, daysRefetch]
  );

  return (
    <SettingsContext.Provider
      value={{
        updateSettingsLoading,
        updateSettingsError,
        dayLoading,
        dayError,
        dayData,
        dayRefetch,
        daysLoading,
        daysError,
        daysData,
        daysRefetch,
        daySettings,
        daysSettings,
        saveUserDaySettings,
        saveUserDayWorkingSettings,
        plannedDays,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
export { SettingsProvider };
