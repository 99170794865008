import { VSCodeButton, VSCodeCheckbox, VSCodeTextField } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation } from "@apollo/client";
import { memo, useCallback, useContext, useEffect, useState } from "react";

import { HookContext } from "@/contexts/HookContext";
import { MilestoneContext } from "@/contexts/MilestoneContext";
import ModalContent from "@/components/modals/ModalContent";
import { TrashIcon } from "@heroicons/react/outline";
import parse from "url-parse";
import { uid } from "uid";
import useDynamicRefs from "use-dynamic-refs";
import { useNotification } from "@/contexts/NotificationContext";

const CREATE_WEB_HOOK = gql`
  mutation CreateWebHook($input: CreateWebHookInput!) {
    createWebHook(input: $input) {
      errors {
        message
      }
    }
  }
`;

const sharingDisplay = {
  activity: "Activity",
  file: "File",
  gitBranch: "Branch",
  language: "Language",
  project: "Project",
};

const sharingArr = ["activity", "file", "gitBranch", "language", "project"];

const WebhookAddContent = ({
  headers,
  setHeaders,
  url,
  setUrl,
  name,
  setName,
  permissions,
  setPermissions,
}) => {
  const [getRef, setRef] = useDynamicRefs();
  const [focused, setFocused] = useState(null);

  const addHeader = useCallback(() => {
    var array = [...headers];
    const id = uid(16);
    array.unshift({ id: id, key: "", value: "" });
    setHeaders(array);
  }, [headers, setHeaders]);

  const updateKey = useCallback(
    (id, val) => {
      var array = [...headers];
      var index = array.findIndex((el) => {
        return el.id === id;
      });
      if (index !== -1) {
        array[index]["key"] = val;
        setHeaders(array);
      }
    },
    [headers, setHeaders]
  );

  const updateValue = useCallback(
    (id, val) => {
      var array = [...headers];
      var index = array.findIndex((el) => {
        return el.id === id;
      });
      if (index !== -1) {
        array[index]["value"] = val;
        setHeaders(array);
      }
    },
    [headers, setHeaders]
  );

  //get the most recently added key field and give it focus
  useEffect(() => {
    if (headers.length > 0) {
      const id = headers[0].id;
      if (id && id !== focused) {
        const input = getRef(`${id}_key`).current;

        //don't focus the initial header placeholder ui
        if (headers.length > 1) {
          input.focus();
        }
        setFocused(id);
      }
    }
  }, [headers, getRef, focused]);

  const removeHeader = useCallback(
    (id) => {
      var array = [...headers];
      var index = array.findIndex((el) => {
        return el.id === id;
      });
      if (index !== -1) {
        array.splice(index, 1);
        setHeaders(array);
      }
    },
    [headers, setHeaders]
  );

  const onChangeCheckbox = useCallback(
    (field, value) => {
      if (value) {
        setPermissions([...permissions, field]);
      } else {
        setPermissions(permissions.filter((name) => name !== field));
      }
    },
    [setPermissions, permissions]
  );

  return (
    <div className="flex flex-col p-4 space-y-4">
      <div>
        <label htmlFor="name" className="block font-medium">
          Name
        </label>
        <div className="mt-1">
          <VSCodeTextField
            value={name}
            placeholder="Custom webhook..."
            id="name"
            name="name"
            type="text"
            autoComplete="name"
            className={"w-full"}
            onInput={(e) => {
              setName(e.target.value);
            }}
            autoFocus
          />
        </div>
      </div>
      <div>
        <label htmlFor="url" className="block font-medium">
          Endpoint URL
        </label>
        <div className="mt-1">
          <VSCodeTextField
            value={url}
            id="url"
            name="url"
            type="text"
            autoComplete="off"
            className={"w-full"}
            placeholder="https://"
            onInput={(e) => {
              setUrl(e.target.value);
            }}
          />
        </div>
      </div>
      <div>
        <label htmlFor="sharing" className="block font-medium">
          Sharing
        </label>
        <div className="pl-4 mt-2">
          {sharingArr.map((name) => {
            const checked = permissions.includes(name);
            return (
              <VSCodeCheckbox
                key={name}
                checked={checked}
                onChange={(e) => {
                  onChangeCheckbox(name, e.target.checked);
                }}
              >
                {sharingDisplay[name]}
              </VSCodeCheckbox>
            );
          })}
        </div>
      </div>

      <div>
        <div className="justify-between flex-row-centered">
          <div>
            <label htmlFor="headers" className="block font-medium">
              Headers
            </label>
          </div>
          <div>
            <VSCodeButton
              appearance="secondary"
              onClick={() => {
                addHeader();
              }}
            >
              <div className="flex-row-centered">
                <div>Add header</div>
              </div>
            </VSCodeButton>
          </div>
        </div>
        <div className="p-2 mt-1">
          <div className="flex flex-col space-y-2">
            {headers.length === 0 && (
              <div className="w-full">
                <div className="flex items-center justify-center w-full h-full">
                  No headers defined.
                </div>
              </div>
            )}
            {headers.map((entry) => {
              return (
                <div className="space-x-2 flex-row-centered" key={entry.id}>
                  <VSCodeTextField
                    type="text"
                    placeholder="key"
                    ref={setRef(entry.id + "_key")}
                    className={"w-1/2"}
                    value={entry.key}
                    onInput={(e) => {
                      updateKey(entry.id, e.target.value);
                    }}
                  />
                  <div>:</div>
                  <VSCodeTextField
                    type="text"
                    placeholder="value"
                    ref={setRef(entry.id + "_value")}
                    className={"w-1/2"}
                    value={entry.value}
                    onInput={(e) => {
                      updateValue(entry.id, e.target.value);
                    }}
                  />
                  <div className="px-2 cursor-pointer">
                    <TrashIcon
                      className="h-5 icon"
                      onClick={() => {
                        removeHeader(entry.id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const WebhookAddModal = ({ _close }) => {
  const { hooksRefetch } = useContext(HookContext);
  const { completeMilestones } = useContext(MilestoneContext);

  const [headers, setHeaders] = useState([]);
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const notification = useNotification();

  const [saveEndpoint] = useMutation(CREATE_WEB_HOOK);

  const addEndpoint = () => {
    const status = {
      default: false,
    };
    permissions.map((entry) => {
      status[entry] = true;
      return entry;
    });

    var urlObj = parse(url, {});
    if (urlObj.protocol === "") {
      urlObj.set("protocol", "https");
    }

    const headersFiltered = headers
      .filter((header) => {
        return header.key && header.value;
      })
      .map(({ key, value }) => ({ name: key, value }));

    saveEndpoint({
      variables: {
        input: {
          name: name,
          url: urlObj.toString(),
          statusChange: {
            default: status.default,
            activity: permissions.includes("activity"),
            project: permissions.includes("project_name"),
            language: permissions.includes("language_id"),
            file: permissions.includes("file"),
            gitBranch: permissions.includes("git_branch"),
          },
          headers: headersFiltered,
          disabled: false,
        },
      },
    }).then(({ data }) => {
      if (data.createWebHook.errors.length > 0) {
        notification.error(data.createWebHook.errors[0].message);
      } else {
        hooksRefetch();
        _close();
        notification.info("Added.");
      }
    });
  };

  useEffect(() => {
    setHeaders([{ id: uid(16), key: "", value: "" }]);
  }, []);

  useEffect(() => {
    completeMilestones("configure_webhook");
  }, [completeMilestones]);

  return (
    <ModalContent
      title={`Add a webhook`}
      close={_close}
      buttons={<VSCodeButton onClick={addEndpoint}>Add</VSCodeButton>}
    >
      <WebhookAddContent
        headers={headers}
        setHeaders={setHeaders}
        url={url}
        setUrl={setUrl}
        name={name}
        setName={setName}
        permissions={permissions}
        setPermissions={setPermissions}
      />
    </ModalContent>
  );
};

export default memo(WebhookAddModal);
