import { useContext, useEffect } from "react";

import AppContext from "@/contexts/AppContext";
import Loading from "@/components/lib/Loading";
import RouteTabs from "@/components/RouteTabs";
import SettingsConnectionsTab from "@/components/settings/SettingsConnectionsTab";
import SettingsGeneralTab from "@/components/settings/SettingsGeneralTab";
import SettingsScheduleTab from "@/components/settings/SettingsScheduleTab";
import SettingsScoreTab from "@/components/settings/SettingsScoreTab";
import SettingsSharingTab from "@/components/settings/SettingsSharingTab";
import UserContext from "@/contexts/UserContext";
import { useLocation } from "react-router-dom";
import { useNotification } from "@/contexts/NotificationContext";

const TabWrapper = ({ children }) => {
  return <div className="p-6">{children}</div>;
};

const TABS = [
  {
    title: "General",
    value: "general",
    url: "general",
    Component: (
      <TabWrapper>
        <SettingsGeneralTab />
      </TabWrapper>
    ),
  },
  {
    title: "Schedule",
    value: "schedule",
    url: "schedule",
    Component: (
      <TabWrapper>
        <SettingsScheduleTab />
      </TabWrapper>
    ),
  },
  {
    title: "Sharing",
    value: "sharing",
    url: "sharing",
    Component: (
      <TabWrapper>
        <SettingsSharingTab />
      </TabWrapper>
    ),
  },
  {
    title: "Score",
    value: "score",
    url: "score",
    Component: (
      <TabWrapper>
        <SettingsScoreTab />
      </TabWrapper>
    ),
  },
  {
    title: "Connections",
    value: "connections",
    url: "connections",
    Component: (
      <TabWrapper>
        <SettingsConnectionsTab />
      </TabWrapper>
    ),
  },
];

const DEFAULT_TAB = "general";

const SettingsContainer = () => {
  const { userIsLoading } = useContext(UserContext);

  const notification = useNotification(4000);
  const location = useLocation();
  const { browser } = useContext(AppContext);

  useEffect(() => {
    if (browser) {
      const params = new URLSearchParams(location.search);
      const status = params.get("status");
      if (status && status !== "ok") {
        notification.error(`There was an error setting up Slack.`);
      }
    }
  }, [location.search, notification, browser]);

  if (userIsLoading) {
    return <Loading />;
  }
  return <RouteTabs TABS={TABS} DEFAULT_TAB={DEFAULT_TAB} />;
};

export default SettingsContainer;
