import {
  VSCodeButton,
  VSCodeDropdown,
  VSCodeOption,
  VSCodeTextArea,
} from "@vscode/webview-ui-toolkit/react";
import { memo, useCallback, useContext, useState } from "react";

import AppContext from "@/contexts/AppContext";
import Dots from "@/components/lib/Dots";
import ModalContent from "@/components/modals/ModalContent";
import NoteContext from "@/contexts/NoteContext";
import clsx from "clsx";
import { useNotification } from "@/contexts/NotificationContext";

const NoteAddModal = ({ typeDefault = "NOTE", _close, ...props }) => {
  const { projectEnv } = useContext(AppContext);
  const { createNote, createNoteLoading } = useContext(NoteContext);
  const { title, filePath, gitBranchName, projectId, date, refetchData } = props;

  const [content, setContent] = useState("");
  const [type, setType] = useState(typeDefault);
  const [valid, setValid] = useState(true);
  const notification = useNotification();

  const addNote = useCallback(() => {
    if (content !== "") {
      const noteInput = {
        date: date,
        content: content,
        type: type,
      };

      //note is being created from vscode
      if (projectId) {
        Object.assign(noteInput, {
          projectId: projectId,
          gitBranchName: gitBranchName,
          filePath: filePath,
        });
      }
      //note is being created by the webview, using the vscode environment
      //data coming through tangle
      else if (projectEnv !== null) {
        Object.assign(noteInput, {
          gitUrl: projectEnv?.gitUrl,
          gitBranchName: projectEnv?.gitBranchName,
          gitCommit: projectEnv?.gitCommit,
        });
      }

      createNote({
        variables: {
          input: noteInput,
        },
      }).then(() => {
        if (refetchData) {
          refetchData();
        }
        notification.info("Note Added.");

        _close();
      });
    } else {
      setValid(false);
    }
  }, [
    content,
    filePath,
    gitBranchName,
    projectId,
    createNote,
    _close,
    date,
    refetchData,
    type,
    projectEnv,
    notification,
  ]);

  return (
    <ModalContent
      title={
        <div className="flex-row-centered">
          <div className="text-lg font-medium">Add note {title && <>for {title}</>}</div>
          {createNoteLoading && <Dots height={6} />}
        </div>
      }
      close={_close}
      buttons={
        <div className="justify-end w-full space-x-2 flex-row-centered">
          <VSCodeButton
            appearance="secondary"
            onClick={() => {
              _close();
            }}
          >
            Cancel
          </VSCodeButton>
          <VSCodeButton
            onClick={() => {
              addNote();
            }}
          >
            Add
          </VSCodeButton>
        </div>
      }
    >
      <VSCodeTextArea
        className={clsx("w-full", {
          "input-error-border": !valid,
        })}
        autoFocus
        placeholder={"Enter your note here..."}
        id={"note_text"}
        name={"note_text"}
        rows={4}
        value={content}
        onInput={(e) => {
          if (e.target.value !== "") {
            setValid(true);
          } else {
            setValid(false);
          }
          setContent(e.target.value);
        }}
      />
      <VSCodeDropdown
        value={type}
        name="total"
        onChange={(e) => {
          setType(e.target.value);
        }}
      >
        <VSCodeOption key="note" value="NOTE">
          Note
        </VSCodeOption>
        <VSCodeOption key="todo" value="TODO">
          Todo
        </VSCodeOption>
      </VSCodeDropdown>
    </ModalContent>
  );
};

export default memo(NoteAddModal);
