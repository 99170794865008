import { Arrow, useLayer } from "react-laag";
import { gql, useMutation } from "@apollo/client";
import { memo, useState } from "react";

import { DotsHorizontalIcon } from "@heroicons/react/outline";
import { useCallback } from "react";
import { useNotification } from "@/contexts/NotificationContext";

const SET_CARD_VISIBILITY = gql`
  mutation updateCardVis($input: SetFeedItemsInput!) {
    setFeedItems(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

// const SET_TYPE_VISIBILITY = gql`
//   mutation updateTypeVis($input: SetFeedItemTypeSettingsInput!) {
//     setFeedItemTypeSettings(input: $input) {
//       errors {
//         field
//         message
//       }
//     }
//   }
// `;

const CardPopOpt = ({ label, fn }) => {
  return (
    <div
      className="px-2 py-1 cursor-pointer hover:bg-sideBarBackground"
      onClick={() => {
        fn();
      }}
    >
      {label}
    </div>
  );
};

const CardPopContent = (props) => {
  const { item, feedRefetch, dismissable = true, snooze = true } = props;
  // const { item, feedRefetch, dismissable = true, snooze = true, typeDismissable = false } = props;
  const [updateCardVis] = useMutation(SET_CARD_VISIBILITY);
  // const [updateTypeVis] = useMutation(SET_TYPE_VISIBILITY);

  const notification = useNotification();

  const doUpdateCardVis = useCallback(
    (input) => {
      updateCardVis({
        variables: input,
      }).then((resp) => {
        feedRefetch();
        notification.info("Done!");
      });
    },
    [updateCardVis, feedRefetch, notification]
  );

  // const doUpdateTypeVis = useCallback(
  //   (input) => {
  //     updateTypeVis({
  //       variables: input,
  //     }).then((resp) => {
  //       feedRefetch();
  //       notification.info("Done!");
  //     });
  //   },
  //   [updateTypeVis, feedRefetch, notification]
  // );

  return (
    <div className="flex flex-col w-full h-full py-1 divide-y">
      {dismissable && (
        <CardPopOpt
          label="Hide this card"
          fn={() => {
            doUpdateCardVis({
              input: {
                dismissed: true,
                feedItemIds: [item?.id],
              },
            });
          }}
        />
      )}

      {/* {typeDismissable && (
        <CardPopOpt
          label="Disable this card type"
          fn={() => {
            doUpdateTypeVis({
              input: {
                dismissed: true,
                feedItemTypeIds: [item?.type?.id],
              },
            });
          }}
        />
      )} */}

      {!item.shareable && snooze && (
        <>
          <CardPopOpt
            label="Snooze one day"
            fn={() => {
              doUpdateCardVis({
                input: {
                  snoozeDays: 1,
                  feedItemIds: [item.id],
                },
              });
            }}
          />
          <CardPopOpt
            label="Snooze one week"
            fn={() => {
              doUpdateCardVis({
                input: {
                  snoozeDays: 7,
                  feedItemIds: [item.id],
                },
              });
            }}
          />
          <CardPopOpt
            label="Snooze one month"
            fn={() => {
              doUpdateCardVis({
                input: {
                  snoozeDays: 30,
                  feedItemIds: [item.id],
                },
              });
            }}
          />
        </>
      )}

      {/* <CardPopOpt label="Delete forever" /> */}
    </div>
  );
};

const CardPop = (props) => {
  const [isOpen, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    placement: "bottom",
    isOpen,
    auto: true,
    triggerOffset: 8,
    onOutsideClick: () => setOpen(false),
  });

  return (
    <>
      <div
        {...triggerProps}
        className="cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DotsHorizontalIcon className="w-4 icon" />
      </div>

      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{ ...layerProps.style, zIndex: 100 }}
            className="text-sm bg-opacity-0 border rounded-md shadow-lg bg-background text-foreground"
          >
            <CardPopContent {...props} />
            <Arrow
              {...arrowProps}
              size={10}
              roundness={0}
              borderWidth={1}
              borderColor="var(--vscode-panel-border)"
              backgroundColor="var(--vscode-editor-background)"
            />
          </div>
        )}
    </>
  );
};

export default memo(CardPop);
