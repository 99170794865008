import ModalTitle from "@/components/modals/ModalTitle";
import { memo } from "react";

const ModalContent = ({ title, description, buttons, children, close }) => {
  return (
    <div className="flex flex-col w-full h-full focus:outline-none text-foreground bg-background">
      <ModalTitle close={close} title={title} description={description} />
      <div className="h-full px-6 py-4 overflow-auto">{children}</div>
      <div className="h-16">
        <div className="justify-end h-full px-2 space-x-2 flex-row-centered">{buttons}</div>
      </div>
    </div>
  );
};

export default memo(ModalContent);
