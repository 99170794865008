import { forwardRef, memo, useMemo, useState, useEffect } from "react";

import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import groupBy from "lodash/groupBy";
import { minToHuman } from "@/utils/convert";
import moment from "moment";

const getStyleAttributes = () => {
  return {
    fontSize: getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-font-size")
      .trim(),
    borderColor: getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-charts-lines")
      .trim(),
    backgroundColor: getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-editor-background")
      .trim(),
    fontColor: getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-charts-foreground")
      .trim(),
  };
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const WeekdayGraph = forwardRef(({ events, full = false }, ref) => {
  const { height, width: rawWidth } = useWindowDimensions();
  const width = Math.max(550, rawWidth);
  const reverseAspectRatio = height / width; // height matters more

  const factor = useMemo(
    () => Math.max(0.5, Math.min(1, reverseAspectRatio)),
    [reverseAspectRatio]
  );

  const cs = getStyleAttributes();
  const numericSort = (a, b) => parseInt(a) - parseInt(b);

  const byHourStarted = Object.keys(
    groupBy(events, (o) => {
      return parseInt(moment.utc(o.started_at).local().format("H"));
    })
  ).sort(numericSort);

  const byHourEnded = Object.keys(
    groupBy(events, (o) => {
      return parseInt(moment.utc(o.ended_at).local().format("H"));
    })
  ).sort(numericSort);

  const minHour = byHourStarted.length > 0 ? parseInt(byHourStarted[0]) - 1 : 0;
  const maxHour = byHourEnded.length > 0 ? parseInt(byHourEnded[byHourEnded.length - 1]) + 1 : 0;

  const groups = useMemo(() => {
    const dayArr = [];

    for (var i = 0; i < 7; i++) {
      dayArr.push(moment().subtract(i, "days").format("ddd"));
    }

    return dayArr.reverse();
  }, []);

  return (
    <div className="w-full h-full overflow-hidden" ref={ref}>
      <ResponsiveSwarmPlot
        data={events}
        groups={groups}
        value="time"
        valueScale={{
          type: "linear",
          min: minHour,
          max: maxHour,
          reverse: true,
        }}
        size={{
          key: "mins",
          values: [0, 120],
          sizes: [6, 35 * factor],
        }}
        forceStrength={6}
        simulationIterations={200}
        borderColor={{
          from: "color",
          modifiers: [
            ["darker", 0.6],
            ["opacity", 0.5],
          ],
        }}
        margin={{ top: 20, right: 30, bottom: 65, left: 70 }}
        colors={{ scheme: "set2" }}
        theme={{
          fontFamily: "",
          textColor: cs.fontColor,
          fontSize: cs.fontSize,
          grid: {
            line: {
              stroke: "var(--vscode-charts-lines)",
              strokeWidth: 1,
            },
          },
        }}
        enableGridY={false}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: (d) => {
            let today = moment().format("ddd");
            if (today === d) {
              return d + " 🎈";
            }
            return d;
          },
          orient: "bottom",
          tickSize: 11,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Day of the week",
          legendPosition: "middle",
          legendOffset: 46,
          fontSize: cs.fontSize,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 11,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Hour of the day",
          legendPosition: "middle",
          legendOffset: -46,
          fontSize: cs.fontSize,
        }}
        motionStiffness={50}
        motionDamping={10}
        tooltip={(props) => {
          const { data } = props;

          const isLeft = groups.slice(0, 3).includes(data.group);
          const isBottom = data.time > 12;

          return (
            <div
              style={{
                position: "absolute",
                right: !isLeft ? 105 : -10,
                top: isBottom ? -50 : 10,
              }}
              className="border"
            >
              <div className="absolute p-2 text-xs border rounded bg-background ">
                <div className="flex flex-col w-full font-semibold whitespace-nowrap">
                  <div>Started: {moment(data.started_at).local().format("M/D LT")}</div>
                  <div>Activity: {minToHuman(data.mins)}</div>
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
});

export default memo(WeekdayGraph);
