import InstallExtensionButton from "@/components/status/InstallExtensionButton";
import Loading from "@/components/lib/Loading";
import Title from "@/components/Title";
import UserContext from "@/contexts/UserContext";
import { useContext } from "react";

const InstallLandingPage = () => {
  const { user, userIsLoading } = useContext(UserContext);

  if (userIsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Title>Install the VS Code extension</Title>

      <div className="w-full h-full">
        <div className="flex flex-col justify-center w-full h-full max-w-3xl py-6 mx-auto space-y-6 text-center ">
          <img src="/assets/stateful_icon.svg" className="h-16" alt="Stateful" />
          <div className="text-xl">
            Almost there, {user?.user?.github?.username || "friend"}... 👍
          </div>
          <div className="font-medium text-l text-description">
            Next, install the VS Code extension.
          </div>
          <div className="flex items-center justify-center">
            <InstallExtensionButton />
          </div>
          <div>
            Or, search for <span className="font-medium">`stateful`</span> in the VS Code extensions
            pane.
          </div>
        </div>
      </div>
    </>
  );
};

export default InstallLandingPage;
