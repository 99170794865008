import ExpandSection from "@/components/settings/ExpandSection";
import SlackContainer from "@/components/settings/SlackContainer";
import WebhookContainer from "@/components/settings/WebhookContainer";

const SettingsConnectionsTab = () => {
  return (
    <div className="flex flex-col space-y-2">
      <ExpandSection title="Slack" defaultOpen={true}>
        <div className="w-full">
          <SlackContainer />
        </div>
      </ExpandSection>
      <ExpandSection title="Webhooks" defaultOpen={true}>
        <div className="w-full">
          <WebhookContainer />
        </div>
      </ExpandSection>
    </div>
  );
};

export default SettingsConnectionsTab;
