import { useContext, useEffect } from "react";

import DisplaySettingValues from "@/components/settings/DisplaySettingValues";
import ExpandSection from "@/components/settings/ExpandSection";
import MilestoneContext from "@/contexts/MilestoneContext";
import PlannedBreaks from "@/components/settings/PlannedBreaks";
import PlannedDailyHoursCoding from "@/components/settings/PlannedDailyHoursCoding";
import PlannedWorkDays from "@/components/settings/PlannedWorkDays";
import RateYourDaySchedule from "@/components/settings/RateYourDaySchedule";
import TeamStandUpTime from "@/components/settings/TeamStandUpTime";
import WorkingHours from "@/components/settings/WorkingHours";

const SettingsScheduleTab = () => {
  const { completeMilestones, milestonesRefetch } = useContext(MilestoneContext);

  useEffect(() => {
    completeMilestones("view_personalization", () => {
      milestonesRefetch();
    });
  }, [completeMilestones, milestonesRefetch]);

  return (
    <div className="flex flex-col space-y-2">
      <div className="py-2">
        <DisplaySettingValues />
      </div>
      <div className="py-2">
        <PlannedWorkDays />
      </div>
      <ExpandSection title="Coding goal" overflow={false}>
        <PlannedDailyHoursCoding />
      </ExpandSection>
      <ExpandSection title="Active hours" overflow={false}>
        <WorkingHours />
      </ExpandSection>
      <ExpandSection title="Standup">
        <TeamStandUpTime />
      </ExpandSection>
      <ExpandSection title="Day rating" overflow={false}>
        <RateYourDaySchedule />
      </ExpandSection>

      <ExpandSection title="Breaks" overflow={false}>
        <PlannedBreaks />
      </ExpandSection>
    </div>
  );
};

export default SettingsScheduleTab;
