import {
  BeakerIcon,
  CalendarIcon,
  ClockIcon,
  CollectionIcon,
  DocumentTextIcon,
  FireIcon,
  FolderIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { cloneElement, memo, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import DaysDetailedGraph from "@/components/graphs/DaysDetailedGraph";
import ErrorGrow from "@/components/lib/ErrorGrow";
import LanguagePie from "@/components/graphs/LanguagePie";
import Loading from "@/components/lib/Loading";
import ModalTitle from "@/components/modals/ModalTitle";
import { StarIcon } from "@heroicons/react/solid";
import SummaryDateRangeSelector from "@/components/SummaryDateRangeSelector";
import clsx from "clsx";
import flatten from "lodash/flatten";
import moment from "moment";
import { secondsToHuman } from "@/utils/convert";
import { useMemo } from "react";

const GET_DAY_DURATIONS = gql`
  query getDayDurations($startDate: Date!, $endDate: Date!) {
    days(startDate: $startDate, endDate: $endDate, pageSize: 30) {
      data {
        codingDuration {
          totalSeconds
        }
        date
      }
    }
  }
`;

const GET_DAY_RANGE_SUMMARY = gql`
  query dayRangeSummary($startDate: Date!, $endDate: Date!) {
    dayRangeSummary(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      codingDuration {
        totalSeconds
      }
      dayCount
      languages {
        data {
          codingDuration {
            totalSeconds
          }
          displayName
          id
        }
      }
      projects {
        data {
          codingDuration {
            totalSeconds
          }
          displayName
          gitBranches {
            data {
              codingDuration {
                totalSeconds
              }
              name
              files {
                data {
                  codingDuration {
                    totalSeconds
                  }
                  name
                  path
                }
              }
            }
          }
          files {
            data {
              codingDuration {
                totalSeconds
              }
              name
              path
            }
          }
          name
          shortName
          id
        }
      }
      rating {
        mean
      }
      score {
        mean
      }
    }
  }
`;

const ExpandSection = ({ children, title, count, icon, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className="border rounded-sm">
      <div className="flex flex-col">
        <div
          className={clsx(
            { "border-b": open },
            "flex-row-centered justify-between p-2 cursor-pointer"
          )}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="space-x-2 flex-row-centered">
            {icon && <div>{cloneElement(icon, { className: "w-5 text-description" })}</div>}
            <div className="font-medium">{title}</div>
            <div>({count})</div>
          </div>
          <div>
            {open ? (
              <ChevronUpIcon className="h-5 icon" />
            ) : (
              <ChevronDownIcon className="h-5 icon" />
            )}
          </div>
        </div>
        <div
          className={clsx({
            hidden: !open,
            block: open,
          })}
        >
          <div className="w-full p-2 pt-3 space-x-2 flex-row-centered">{children}</div>
        </div>
      </div>
    </div>
  );
};

const ReportEntry = ({ title, description, children }) => {
  return (
    <div className="flex flex-col px-2 py-1 my-1 space-y-1 border rounded max-w-1/2 bg-background min-w-1/3">
      <div className="flex flex-row justify-between space-x-1 text-xs">
        <div className="font-medium truncate">{title}</div>
        {description && <div className="text-descriptions">{description}</div>}
      </div>
      {children}
    </div>
  );
};

const FileTree = ({ files }) => {
  return (
    <div className="flex flex-col pl-4">
      {files &&
        files.map((file) => {
          return (
            <div className="flex flex-col" key={file.path + "-tree"}>
              <div className="flex flex-row justify-between w-full space-x-2">
                <div className="hidden overflow-x-auto vmd:block">{file.path}</div>
                <div className="block overflow-x-auto vmd:hidden">{file.name}</div>
                <div>{secondsToHuman(file.codingDuration.totalSeconds)}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const BranchTree = ({ gitBranches }) => {
  return (
    <div className="flex flex-col pl-4">
      {gitBranches &&
        gitBranches.map((branch) => {
          return (
            <div className="flex flex-col py-2" key={branch.name + "-tree"}>
              <div className="flex flex-row justify-between space-x-2 font-medium border-b">
                <div className="overflow-x-auto">{branch.name}</div>
                <div>{secondsToHuman(branch.codingDuration.totalSeconds)}</div>
              </div>
              <div>
                <FileTree files={branch.files.data} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

const ProjectTree = ({ projects }) => {
  return (
    <div className="flex flex-col w-full">
      {projects &&
        projects.map((project) => {
          return (
            <div className="flex flex-col py-2" key={project.name + "-tree"}>
              <div className="flex flex-row justify-between space-x-2 font-semibold">
                <div className="overflow-x-auto">{project.name}</div>
                <div>{secondsToHuman(project.codingDuration.totalSeconds)}</div>
              </div>
              <div>
                <BranchTree gitBranches={project.gitBranches.data} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

const SummaryContent = ({ startDate, endDate }) => {
  const {
    loading: durationsLoading,
    error: durationsError,
    data: durationsData,
  } = useQuery(GET_DAY_DURATIONS, {
    skip: !startDate || !endDate,
    variables: { startDate: startDate, endDate: endDate },
  });

  const {
    loading: rangeLoading,
    error: rangeError,
    data: rangeData,
  } = useQuery(GET_DAY_RANGE_SUMMARY, {
    skip: !startDate || !endDate,
    variables: { startDate: startDate, endDate: endDate },
  });

  const dayRangeSummary = rangeData?.dayRangeSummary;
  const activity = dayRangeSummary?.codingDuration?.totalSeconds || null;
  const score = dayRangeSummary?.score?.mean || null;
  const rating = dayRangeSummary?.rating?.mean || null;
  const projectTree = dayRangeSummary?.projects?.data;

  const languages = useMemo(() => {
    const langData = dayRangeSummary?.languages?.data;
    if (langData) {
      const langArr = langData.map((lang) => {
        return [lang?.displayName, lang?.codingDuration?.totalSeconds];
      });

      return langArr.sort((a, b) => b[1] - a[1]);
    }
  }, [dayRangeSummary]);

  const languageData = useMemo(() => {
    const arr = dayRangeSummary?.languages?.data;

    if (arr) {
      return arr.map((lang) => {
        return {
          ...lang,
          totalSeconds: lang?.codingDuration?.totalSeconds,
        };
      });
    }
  }, [dayRangeSummary]);

  const projects = useMemo(() => {
    const projectData = dayRangeSummary?.projects?.data;
    if (projectData) {
      const projectArr = projectData.map((project) => {
        return [project?.displayName, project?.codingDuration?.totalSeconds];
      });

      return projectArr.sort((a, b) => b[1] - a[1]);
    }
  }, [dayRangeSummary]);

  const branches = useMemo(() => {
    const arr = dayRangeSummary?.projects?.data;
    if (arr) {
      const branchData = flatten(
        arr.map((project) => {
          return project?.gitBranches?.data;
        })
      );
      const branchArr = branchData.map((branch) => {
        return [branch?.name, branch?.codingDuration?.totalSeconds];
      });
      return branchArr.sort((a, b) => b[1] - a[1]);
    }
  }, [dayRangeSummary]);

  const files = useMemo(() => {
    const arr = dayRangeSummary?.projects?.data;
    if (arr) {
      const fileData = flatten(
        arr.map((project) => {
          return project.files.data;
        })
      );
      const filesArr = fileData.map((file) => {
        return [file?.name, file?.codingDuration?.totalSeconds];
      });
      return filesArr.sort((a, b) => b[1] - a[1]);
    }
  }, [dayRangeSummary]);

  const durations = useMemo(() => {
    const arr = durationsData?.days?.data;
    if (arr) {
      const dayArr = arr.map((day) => {
        return [day.date, day?.codingDuration?.totalSeconds || 0];
      });
      return dayArr.sort((a, b) => b[1] - a[1]);
    }
  }, [durationsData]);

  const durationsObj = useMemo(() => {
    if (durations) {
      return durations
        .map((entry) => {
          return {
            day: entry[0],
            duration: entry[1],
          };
        })
        .sort(function (a, b) {
          return new Date(b.day) - new Date(a.day);
        })
        .reverse();
    }
  }, [durations]);

  if (rangeLoading) return <Loading />;
  if (rangeError) return <ErrorGrow message={rangeError.message} />;
  if (durationsLoading) return <Loading />;
  if (durationsError) return <ErrorGrow message={durationsError.message} />;

  return (
    <div className="flex flex-col pb-2 space-y-1">
      <div className="flex flex-row flex-wrap space-x-1 overflow-auto">
        {activity && (
          <ReportEntry title="Activity">
            <div className="flex-row-centered">
              <div>{secondsToHuman(activity)}</div>
              <div>
                <ClockIcon className="w-4" />
              </div>
            </div>
          </ReportEntry>
        )}
        {score && (
          <ReportEntry title="Score">
            <div className="flex-row-centered">
              <div>{score}</div>
              <div>
                <FireIcon className="w-4 " />
              </div>
            </div>
          </ReportEntry>
        )}
        <ReportEntry title="Rating">
          <div>
            <div className="flex-row-centered">
              <div>{rating}/5</div>
              <div>
                <StarIcon className="w-4 text-yellow-500" />
              </div>
            </div>
          </div>
        </ReportEntry>
      </div>
      <ExpandSection
        title="Active days"
        defaultOpen={true}
        count={durations.length}
        icon={<CalendarIcon />}
      >
        {durations && durationsObj && (
          <div className="w-full h-48">
            <DaysDetailedGraph data={durationsObj} />
          </div>
        )}
      </ExpandSection>
      <div className="flex flex-col w-full space-y-2">
        {languageData && languages && (
          <ExpandSection title="Languages" count={languages.length} icon={<BeakerIcon />}>
            <div className="flex flex-row w-full h-full">
              <div className="w-1/2 h-32">
                <LanguagePie data={languageData} />
              </div>
              <div className="w-1/2 flex flex-col space-y-0.5 justify-center">
                {languages.map((language) => {
                  return (
                    <div key={language[0]}>
                      {language[0]} ({secondsToHuman(language[1])})
                    </div>
                  );
                })}
              </div>
            </div>
          </ExpandSection>
        )}

        <ExpandSection title="Projects" count={projects.length} icon={<FolderIcon />}>
          <div className="flex flex-col w-full space-y-1">
            {projects.map((project) => {
              return (
                <div className="flex flex-row justify-between w-full" key={project[0]}>
                  <div>{project[0]}</div> <div>{secondsToHuman(project[1])}</div>
                </div>
              );
            })}
          </div>
        </ExpandSection>

        <ExpandSection title="Branches" count={branches.length} icon={<CollectionIcon />}>
          <div className="flex flex-col w-full space-y-1">
            {branches.map((branch) => {
              return (
                <div
                  className="flex flex-row justify-between w-full"
                  key={branch[0] + "-" + branch[1]}
                >
                  <div>{branch[0]}</div> <div>{secondsToHuman(branch[1])}</div>
                </div>
              );
            })}
          </div>
        </ExpandSection>

        <ExpandSection
          title="File tree"
          count={`${projects.length}p, ${branches.length}b, ${files.length}f`}
          icon={<DocumentTextIcon />}
        >
          <ProjectTree projects={projectTree} />
        </ExpandSection>
      </div>
    </div>
  );
};

const ModalContent = ({ title, description, buttons, children, close }) => {
  return (
    <div className="flex flex-col w-full h-full focus:outline-none text-foreground bg-background">
      <ModalTitle close={close} title={title} description={description} />
      <div className="h-full px-6 pt-4 overflow-auto">{children}</div>
    </div>
  );
};

const SummaryReportDayModal = ({ _close, startDate, endDate }) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: moment(startDate).subtract(6, "days").startOf("day").toDate(),
    endDate: moment(endDate).endOf("day").toDate(),
    key: "selection",
  });

  return (
    <ModalContent
      title={
        <div className="flex-row-centered">
          <div className="text-lg">Summary report</div>
        </div>
      }
      description={
        <div className="flex">
          <SummaryDateRangeSelector
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
          />
        </div>
      }
      close={_close}
    >
      <SummaryContent
        startDate={moment(selectionRange.startDate).format("YYYY-MM-DD")}
        endDate={moment(selectionRange.endDate).format("YYYY-MM-DD")}
      />
    </ModalContent>
  );
};

export default memo(SummaryReportDayModal);
