import {
  CardContent,
  CardTitle,
  CardTitleIcon,
  CardWrapper,
} from "@/components/cards/CardWrappers";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import { UserAddIcon } from "@heroicons/react/solid";
import UserAvatarStatus from "@/components/UserAvatarStatus";
import { VSCodeButton } from "@vscode/webview-ui-toolkit/react";
import uniq from "lodash/uniq";
import { useNotification } from "@/contexts/NotificationContext";

/*
  profile: null filter out the request
  profile?.projects?.data?.length have projects in common
  profile?.follower is following you
  otherwise: generic recommendation user or filter out.
*/

/*
  Recommendations expire after 7 days.
*/

const FOLLOW_USER = gql`
  mutation Follow($input: FollowUserInput!) {
    followUser(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const SET_CARD_VISIBILITY = gql`
  mutation updateCardVis($input: SetFeedItemsInput!) {
    setFeedItems(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const UserRecommendationCard = (props) => {
  const { item, feedRefetch } = props;
  const [followUser] = useMutation(FOLLOW_USER);
  const [updateCardVis] = useMutation(SET_CARD_VISIBILITY);

  const notification = useNotification();

  const profile = useMemo(() => {
    return item?.profile;
  }, [item]);

  const commonProjects = useMemo(() => {
    const shortNamesArr = profile?.projects?.data.map((project) => project.shortName);
    return uniq(shortNamesArr);
  }, [profile]);

  const isFollowing = useMemo(() => {
    return profile?.follower;
  }, [profile]);

  // Follow the user, update the card visibility
  // to hidden, then notify and refetch the card feed.
  const doFollowAndHide = useCallback(() => {
    followUser({
      variables: {
        input: {
          userId: profile.id,
        },
      },
    }).then(() => {
      updateCardVis({
        variables: {
          input: {
            dismissed: true,
            feedItemIds: [item.id],
          },
        },
      }).then(() => {
        notification.info(`Followed ${profile.github.username}`);
        feedRefetch();
      });
    });
  }, [profile, feedRefetch, notification, followUser, updateCardVis, item]);

  // profile info has become unavailable, so ignore this card
  // probably due to a permissions change of the target user
  if (profile === null) {
    return <></>;
  }

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        title={
          <div className="space-x-2 flex-row-centered">
            <CardTitleIcon {...props}>
              <UserAddIcon />
            </CardTitleIcon>
            <div>Follow recommendation</div>
          </div>
        }
      />
      <CardContent>
        <div className="flex flex-col space-y-3">
          <div className="justify-between space-x-2 flex-row-centered">
            <div className="space-x-2 flex-row-centered">
              <UserAvatarStatus face={item.profile} classes="w-10" />
              <div className="flex flex-col">
                <div className="font-medium text-covey-500">{profile?.github?.username}</div>
                {profile?.bio && <div className="text-description">{profile?.bio}</div>}
              </div>
            </div>
            <div className="space-x-2 flex-row-centered">
              {isFollowing && <div className="text-xs">They follow you!</div>}
              <VSCodeButton
                className=" whitespace-nowrap"
                onClick={() => {
                  doFollowAndHide();
                }}
              >
                Follow
              </VSCodeButton>
            </div>
          </div>

          {commonProjects && commonProjects.length > 0 && (
            <div className="flex flex-col space-y-2">
              <div className="font-medium">You have {commonProjects.length} common projects:</div>
              <div>
                {commonProjects.slice(0, 5).join(", ")}
                {commonProjects.length > 5 && <>, and {commonProjects.length - 5} more...</>}
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </CardWrapper>
  );
};

export default UserRecommendationCard;
