import CalendarContainer from "@/components/CalendarContainer";
import RatingsContainer from "@/components/RatingsContainer";
import RouteTabs from "@/components/RouteTabs";
import ScoreContainer from "@/components/ScoreContainer";
import TimeContainer from "@/components/TimeContainer";
import WeekContainer from "@/components/WeekContainer";

const TABS = [
  { title: "Week", value: "week", url: "week", Component: <WeekContainer /> },
  { title: "Time", value: "time", url: "time", Component: <TimeContainer /> },
  { title: "Score", value: "score", url: "score", Component: <ScoreContainer /> },
  { title: "Ratings", value: "ratings", url: "ratings", Component: <RatingsContainer /> },
  {
    title: "Forecast",
    value: "forecast",
    url: "forecast",
    Component: <CalendarContainer view="day" customToolbar={true} />,
  },
];

const DEFAULT_TAB = "week";

const MetricsContainer = () => {
  return <RouteTabs TABS={TABS} DEFAULT_TAB={DEFAULT_TAB} />;
};

// const MetricsContainer = ({ defaultTab = "week" }) => {
//   return <RouteTabs TABS={TABS} DEFAULT_TAB={defaultTab} />;
// };

export default MetricsContainer;
