import * as appConfig from "@/config/appConfig";

import { DialogProvider } from "@/contexts/DialogContext";
import { IntercomProvider } from "react-use-intercom";
import { NotificationProvider } from "@/contexts/NotificationContext";
import { SWRConfig } from "swr";
import fetcher from "@/utils/fetcher";
import { useNotification } from "@/contexts/NotificationContext";

const INTERCOM_APP_ID =
  appConfig.API_ENDPOINT === "https://api.stateful.com" ? "i3cov2gj" : "x3hvygb6";

const UnauthedProviderWrapped = ({ children }) => {
  const notification = useNotification();

  return (
    <SWRConfig
      value={{
        refreshInterval: 12 * 1000,
        dedupingInterval: 12 * 1000,
        revalidateOnMount: true,
        // initialData: { results: [] },
        suspense: true,
        fetcher: fetcher,
        onError: (error, key) => {
          if (error.status !== 403 && error.status !== 404) {
            notification.info("Error.");
          }
        },
      }}
    >
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <DialogProvider>{children}</DialogProvider>
      </IntercomProvider>
    </SWRConfig>
  );
};

let UnauthedProviders = ({ children }) => {
  return (
    <NotificationProvider>
      <UnauthedProviderWrapped>{children}</UnauthedProviderWrapped>
    </NotificationProvider>
  );
};

export default UnauthedProviders;
