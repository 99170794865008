import { useContext, useEffect, useMemo } from "react";

import InstallLandingPage from "@/components/status/InstallLandingPage";
import Loading from "@/components/lib/Loading";
import MilestoneContext from "@/contexts/MilestoneContext";
import ShadowWrapper from "@/components/status/ShadowWrapper";
import SharingContainer from "@/components/SharingContainer";
import StyleWrapper from "@/components/StyleWrapper";

const Sharing = () => {
  const { milestones, milestonesLoading, startPollingMilestones, stopPollingMilestones } =
    useContext(MilestoneContext);

  const milestoneMap = useMemo(() => {
    if (milestones) {
      return milestones.reduce(function (accum, currentVal) {
        accum[currentVal.name] = currentVal;
        return accum;
      }, {});
    }
  }, [milestones]);

  const extensionInstalled = useMemo(() => {
    return milestoneMap && milestoneMap["setup_vscode_extension"]?.status === "COMPLETED";
  }, [milestoneMap]);

  //poll until the milestone is set
  useEffect(() => {
    if (!extensionInstalled) {
      startPollingMilestones(10000);
    } else {
      stopPollingMilestones();
    }
  }, [extensionInstalled, startPollingMilestones, stopPollingMilestones]);

  if (milestonesLoading) {
    return <Loading />;
  }

  if (extensionInstalled) {
    return (
      <StyleWrapper>
        <ShadowWrapper>
          <SharingContainer />
        </ShadowWrapper>
      </StyleWrapper>
    );
  }
  return (
    <StyleWrapper>
      <InstallLandingPage />
    </StyleWrapper>
  );
};

export default Sharing;
