import { memo, useState } from "react";

import PopWrapper from "@/components/pops/PopWrapper";

const StatusPreviewVs = ({ children, contentChildren }) => {
  const [open, setOpen] = useState(false);

  return (
    <PopWrapper open={open} setOpen={setOpen} contentChildren={contentChildren}>
      <div
        className="w-full truncate cursor-pointer whitespace-nowrap"
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </div>
    </PopWrapper>
  );
};

export default memo(StatusPreviewVs);
