import { gql, useQuery } from "@apollo/client";

import CalendarLinks from "@/components/CalendarLinks";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import { useMemo } from "react";

const GET_CALENDARS = gql`
  query getCalendars {
    user {
      id
      calendars {
        data {
          createTime
          id
          public
          secret
          sinceWeeks
          type
          updateTime
          origin
          name
          description
          calendarUrl
        }
      }
    }
  }
`;

const CalendarSettings = () => {
  const {
    data: calData,
    loading: calLoading,
    error: calError,
  } = useQuery(GET_CALENDARS, { pollInterval: 60000 });

  const calendars = useMemo(() => {
    return calData?.user?.calendars?.data;
  }, [calData]);

  if (calLoading)
    return (
      <div className="pt-8">
        <Loading />
      </div>
    );
  if (calError) return <ErrorGrow message={calError.message} />;

  if (calendars && calendars.length > 0) {
    return (
      <div className="flex flex-col space-y-3">
        <div>
          We generated a custom calendar to predict when you're going to be most productive. It's
          sharable, and can be easily added to Google Calendar.
        </div>
        <CalendarLinks calendar={calendars[0]} />
      </div>
    );
  }

  return <div>We don't have enough data to generate your calendar yet, but check back!</div>;
};

export default CalendarSettings;
