import { WORKING_HOURS_END, WORKING_HOURS_START } from "@/const/personalize";
import { getAvailableHoursCoding, parseDayTime } from "@/utils/personalize";
import { useContext, useEffect, useMemo, useState } from "react";

import SettingsContext from "@/contexts/SettingsContext";

const DisplaySettingValues = () => {
  const { daySettings } = useContext(SettingsContext);
  const [workingHours, setWorkingHours] = useState({
    start: { label: parseDayTime(WORKING_HOURS_START), data: WORKING_HOURS_START },
    end: { label: parseDayTime(WORKING_HOURS_END), data: WORKING_HOURS_END },
  });

  const dailyAvailableCodingTime = useMemo(() => {
    if (daySettings) {
      return getAvailableHoursCoding(daySettings);
    }
  }, [daySettings]);

  useEffect(() => {
    if (daySettings) {
      setWorkingHours({
        start: {
          label: parseDayTime(daySettings.workStartTime),
          data: daySettings.workStartTime,
        },
        end: { label: parseDayTime(daySettings.workEndTime), data: daySettings.workEndTime },
      });
    }
  }, [daySettings]);

  return (
    <div className="p-5 border-2 rounded background bg-sideBarBackground">
      {dailyAvailableCodingTime && daySettings?.workStartTime ? (
        <>
          <div className="pb-2 font-medium text-description">Available code time</div>
          <div className="text-xl">{dailyAvailableCodingTime}</div>
          <div className="text-sm">
            Monday - Friday, {workingHours?.start?.label} ➔ {workingHours?.end?.label}.
          </div>
        </>
      ) : (
        <div className="text-lg font-medium">Today is not configured for coding. 🌴</div>
      )}
    </div>
  );
};

export default DisplaySettingValues;
