import {
  CardContent,
  CardTitle,
  CardTitleIcon,
  CardWrapper,
} from "@/components/cards/CardWrappers";
import { gql, useQuery } from "@apollo/client";
import { memo, useContext, useEffect, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import SimpleTooltip from "@/components/pops/SimpleTooltip";
import clsx from "clsx";
import getStatus from "@/utils/getStatus";
import useWindowFocus from "@/components/hooks/useWindowFocus";

//(includeUser: true)
const GET_PROFILES = gql`
  query GetProfiles {
    profiles(includeUser: true) {
      data {
        id
        displayName
        github {
          username
        }
        name
        photoUrl
        publicUrl
        status {
          project {
            file {
              name
            }
            displayName
            gitBranch {
              name
            }
            language {
              displayName
              id
            }
            name
            shortName
          }
          latestActivityTime
          activity {
            displayName
          }
        }
        dnd {
          active
          startTime
          expireTime
        }
        workDay {
          endTime
          startTime
        }
      }
    }
  }
`;

const Face = memo(({ face, setUserSelected, selected }) => {
  const isSelected = useMemo(() => {
    return selected?.id === face?.id;
  }, [face, selected]);

  const isDnd = useMemo(() => {
    return face?.dnd?.active;
  }, [face]);

  const status = useMemo(() => {
    const workDay = face?.workDay;
    return getStatus(face.status, face?.dnd, workDay);
  }, [face]);

  return (
    <div className="relative py-0.5 max-w-min">
      <SimpleTooltip
        opts={{
          interactive: true,
          delayHide: 100,
          placement: "bottom",
          offset: [10, 10],
          closeOnOutsideClick: true,
        }}
        trigger={
          <img
            onMouseOver={() => {
              setUserSelected(face);
            }}
            src={face.photoUrl || "/assets/empty-avatar.png"}
            className={clsx(
              "w-8 h-8 max-w-min rounded cursor-pointer overflow-hidden  border-gray-500 hover:border-blue-400 border",
              { "border-blue-400": isSelected },
              { "border-covey-400": isDnd }
            )}
            alt="face"
          />
        }
      >
        <div
          className="flex flex-col flex-grow space-y-1 text-center rounded-xl"
          style={{ minWidth: "200px" }}
        >
          <div className="font-medium">{face.github.username}</div>
          <div className="">{status}</div>
        </div>
      </SimpleTooltip>
    </div>
  );
});

const FacePile = ({ faces }) => {
  const [selected, setSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);

  useEffect(() => {
    if (userSelected) {
      setSelected(userSelected);
    }
  }, [userSelected]);

  if (faces.length === 0) {
    return <div>Looks like nobody's online, check back later. 🪐</div>;
  }

  return (
    <div
      className="flex flex-col"
      onMouseLeave={() => {
        setSelected(null);
      }}
      onMouseEnter={() => {
        if (faces.length > 1 && !userSelected) {
          const index = faces.findIndex((face) => face.id === selected?.id);
          if (index < faces.length - 1) {
            setSelected(faces[index + 1]);
          } else if (faces.length > 3) {
            const middle = faces.length / 2;
            setSelected(faces[Math.floor(middle)]);
          } else {
            setSelected(faces[0]);
          }
        }
      }}
    >
      <div className="flex flex-row flex-wrap items-start justify-start space-x-1">
        {faces.map((face) => {
          return (
            <Face key={face.id} face={face} setUserSelected={setUserSelected} selected={selected} />
          );
        })}
      </div>
    </div>
  );
};

const FacepileFeedItem = (props) => {
  const { pollInterval } = useContext(AppContext);

  const { animate = false } = props;
  const { data, loading, error, refetch } = useQuery(GET_PROFILES, { pollInterval: pollInterval });

  useWindowFocus(0, () => {
    refetch();
  });

  const faces = useMemo(() => {
    return data?.profiles?.data;
  }, [data]);

  if (loading) {
    return <Loading />;
  }
  if (error) return <ErrorGrow message={error.message} />;

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        title={
          <div className="flex-row-centered space-x-2">
            <CardTitleIcon {...props}>
              <img
                src="/assets/multiple-users-silhouette.svg"
                className="w-full h-full"
                alt="community"
              />
            </CardTitleIcon>
            <div>Online and sharing</div>
          </div>
        }
      />
      <CardContent>
        <FacePile faces={faces} animate={animate} />
      </CardContent>
    </CardWrapper>
  );
};

export default FacepileFeedItem;
