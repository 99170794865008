import { useContext } from "react";

import AppContext from "@/contexts/AppContext";
import { GithubIcon } from "@/components/lib/icons";
import { use100vh } from "react-div-100vh";

const Login = () => {
  const { authGithub } = useContext(AppContext);
  const height = use100vh();

  return (
    <div className="flex bg-background text-foreground" style={{ height: height }}>
      <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="w-full max-w-sm mx-auto lg:w-96">
          <div>
            <img className="w-auto h-12" src="/assets/stateful_icon.svg" alt="Stateful Inc" />
            <h2 className="mt-6 text-3xl font-semibold ">Sign in</h2>
            <p className="mt-1 text-sm text-description">Coding metrics for devs.</p>
          </div>

          <div className="mt-8">
            <div>
              <div>
                <div className="mt-1">
                  <div
                    className="inline-flex justify-center w-full px-4 py-2 space-x-2 text-sm font-medium border rounded-md shadow-sm cursor-pointer bg-background hover:bg-selection"
                    onClick={() => {
                      authGithub();
                    }}
                  >
                    <div>Sign in with GitHub</div>
                    <GithubIcon className="h-5" />
                  </div>
                </div>
              </div>

              <div className="relative pt-6 pb-2 mt-6 border-b ">
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-description ">Privacy</span>
                </div>
              </div>
            </div>

            <div className="px-4 mt-4 text-xs">
              <ul className="space-y-2 list-disc">
                <li>
                  Your data is for you, and you alone. We are <b>not</b> a management tool. What you
                  share, and with whom, is your decision.
                </li>
                <li>
                  Your privacy is our top priority, and we take our{" "}
                  <a
                    className="font-medium link"
                    href="https://stateful.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>{" "}
                  very seriously.
                </li>
                <li>
                  This is the web dashboard - to start sending data, you also need to install the{" "}
                  <a
                    className="font-medium link"
                    href="https://marketplace.visualstudio.com/items?itemName=stateful.stable"
                    target="_blank"
                    rel="noreferrer"
                  >
                    VS Code extension.
                  </a>
                </li>
                <li>
                  Once the extension is installed, we collect metadata about how you code, but not
                  the code you work on.
                </li>
                <li>
                  If you have further security concerns, we are happy (and interested){" "}
                  <a
                    className="font-medium link"
                    href="mailto:hello@stateful.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    in discussing
                  </a>{" "}
                  ways to make you comfortable.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex-1 hidden w-0 lg:block">
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src="/assets/bg3.jpg"
          alt="Welcome to Stateful"
        />
      </div>
    </div>
  );
};

export default Login;
