import { useEffect } from "react";
import throttle from "lodash.throttle";

/**
 * Calls callback when window is focused
 * @param {number} threshold - miliseconds since the last focus before triggering callback again.
 * @param {function} callback - called when window is being focused.
 */
const useWindowFocus = (threshold, callback) => {
  useEffect(() => {
    const throttledCallback = throttle(callback, threshold);
    window.addEventListener("focus", throttledCallback);
    return () => window.removeEventListener("focus", throttledCallback);
  }, [callback, threshold]);
};

export default useWindowFocus;
