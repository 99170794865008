//this globally disables all user sharing
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import MediumSwitch from "@/components/MediumSwitch";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const PROFILE_SHARING_SETTING = gql`
  query getProfileSharingSetting {
    user {
      settings {
        sharing {
          schedule
        }
      }
    }
  }
`;

const UPDATE_SHARING_SETTING = gql`
  mutation UpdateSetting($input: SetUserSharingSettingsInput!) {
    setUserSharingSettings(input: $input) {
      errors {
        message
      }
    }
  }
`;

const ScheduleSwitch = () => {
  const { data, error, loading, refetch } = useQuery(PROFILE_SHARING_SETTING);
  const [UpdateSetting] = useMutation(UPDATE_SHARING_SETTING, {
    refetchQueries: [PROFILE_SHARING_SETTING, "getProfileSharingSetting"],
  });

  useWindowFocus(
    0,
    () => {
      refetch();
    },
    [refetch]
  );

  const value = data?.user?.settings?.sharing?.schedule;

  const enabled = useMemo(() => {
    return value === "EVERYONE" ? true : false;
  }, [value]);

  const update = useCallback(
    (value) => {
      UpdateSetting({
        variables: {
          input: {
            schedule: value ? "EVERYONE" : "ONLY_ME",
          },
        },
      });
    },
    [UpdateSetting]
  );

  if (loading) return <Loading />;
  if (error) return <ErrorGrow message={error.message} />;

  return <MediumSwitch enabled={enabled} setEnabled={update} />;
};

export default ScheduleSwitch;
