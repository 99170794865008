import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import App from "@/pages/App";
import AppLogin from "@/pages/AppLogin";
import Calendar from "@/pages/Calendar";
import Dash from "@/pages/Dash";
import IntercomAuthedWrap from "@/components/IntercomAuthedWrap";
import IntercomUnauthedWrap from "@/components/IntercomUnauthedWrap";
import Layout from "@/components/Layout";
import Login from "@/pages/Login";
import NotFoundPage from "@/pages/NotFoundPage";
import Providers from "@/providers/Providers";
import Settings from "@/pages/Settings";
import SettingsManager from "@/pages/SettingsManager";
import Sharing from "@/pages/Sharing";
import StatusAuthed from "@/pages/StatusAuthed";
import StatusUnauthed from "@/pages/StatusUnauthed";
import UnauthedProviders from "@/providers/UnauthedProviders";

export const BrowserUnAuthedRoutes = () => {
  return (
    <UnauthedProviders>
      <IntercomUnauthedWrap>
        <BrowserRouter>
          <Routes>
            <Route path="@:username" element={<StatusUnauthed />} />
            <Route path="status/@:username" element={<StatusUnauthed />} />
            <Route path="status/:username" element={<StatusUnauthed />} />
            <Route path="login" element={<AppLogin />} />
            <Route path="app" element={<AppLogin />} />
            <Route path="manage/settings/*" element={<Login />} />
            <Route path="calendar" element={<Login />} />
            <Route path="status" element={<StatusUnauthed />} />
            <Route path="share" element={<Login />} />

            <Route index element={<Login />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </IntercomUnauthedWrap>
    </UnauthedProviders>
  );
};

export const BrowserAuthedRoutes = () => {
  return (
    <Providers browser={true}>
      <IntercomAuthedWrap>
        <BrowserRouter>
          <Routes>
            <Route path="calendar" element={<Calendar />} />
            <Route path="@:username" element={<StatusAuthed />} />
            <Route path="status/@:username" element={<StatusAuthed />} />
            <Route path="status/:username" element={<StatusAuthed />} />

            <Route exact path="manage/settings/*" element={<SettingsManager />} />
            <Route path="share/*" element={<Sharing />} />
            <Route path="dash" element={<Dash />} />

            <Route
              path="settings/*"
              element={
                <Layout>
                  <Settings />
                </Layout>
              }
            />
            <Route
              path="app/*"
              element={
                <Layout>
                  <App />
                </Layout>
              }
            />
            <Route path="status" element={<Navigate to="/share" />} />

            <Route index element={<Navigate to="share" />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </IntercomAuthedWrap>
    </Providers>
  );
};
