import {
  VSCodeButton,
  VSCodeDropdown,
  VSCodeOption,
  VSCodeTextArea,
} from "@vscode/webview-ui-toolkit/react";
import { memo, useCallback, useContext, useEffect, useRef, useState } from "react";

import Dots from "@/components/lib/Dots";
import ModalContent from "@/components/modals/ModalContent";
import NoteContext from "@/contexts/NoteContext";
import clsx from "clsx";
import { useNotification } from "@/contexts/NotificationContext";

const NoteEditModal = ({ _close, ...props }) => {
  const { updateNote, updateNoteLoading } = useContext(NoteContext);
  const { note, refetchData } = props;
  const [content, setContent] = useState(note.content);
  const [type, setType] = useState(note.type);
  const [valid, setValid] = useState(true);
  const notification = useNotification();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.selectionStart = inputRef.current.value.length;
    inputRef.current.selectionEnd = inputRef.current.value.length;
  }, []);

  const doUpdateNote = useCallback(() => {
    if (content !== "") {
      updateNote({
        variables: { input: { id: note.id, content: content, type: type } },
      }).then(() => {
        if (refetchData) {
          refetchData();
        }
        notification.info("Note Updated.");

        _close();
      });
    } else {
      setValid(false);
    }
  }, [content, refetchData, note, _close, updateNote, type, notification]);

  return (
    <ModalContent
      title={
        <div className="flex-row-centered">
          <div className="text-lg font-medium">Update note</div>
          {updateNoteLoading && <Dots height={6} />}
        </div>
      }
      close={_close}
      buttons={
        <div className="justify-end w-full space-x-2 flex-row-centered">
          <VSCodeButton
            appearance="secondary"
            onClick={() => {
              _close();
            }}
          >
            Cancel
          </VSCodeButton>
          <VSCodeButton
            onClick={() => {
              doUpdateNote();
            }}
          >
            Save
          </VSCodeButton>
        </div>
      }
    >
      <VSCodeTextArea
        ref={inputRef}
        className={clsx("w-full outline-none", {
          "outline-input-error-border": !valid,
        })}
        autoFocus
        placeholder={"Note content..."}
        id={"note_text"}
        name={"note_text"}
        rows={4}
        value={content}
        onInput={(e) => {
          if (e.target.value !== "") {
            setValid(true);
          } else {
            setValid(false);
          }
          setContent(e.target.value);
        }}
      />
      <VSCodeDropdown
        value={type}
        name="total"
        onChange={(e) => {
          setType(e.target.value);
        }}
      >
        <VSCodeOption key="note" value="NOTE">
          Note
        </VSCodeOption>
        <VSCodeOption key="todo" value="TODO">
          Todo
        </VSCodeOption>
      </VSCodeDropdown>
    </ModalContent>
  );
};

export default memo(NoteEditModal);
