import { Arrow, useLayer } from "react-laag";
import { memo, useState } from "react";

const SimplePop = ({ children, trigger }) => {
  const [isOpen, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    auto: true,
    triggerOffset: 10,
    onOutsideClick: () => setOpen(false),
  });

  return (
    <>
      <div
        {...triggerProps}
        className="pl-1 cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        {trigger}
      </div>

      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{ ...layerProps.style, zIndex: 100 }}
            className="max-w-sm text-sm bg-opacity-0 border rounded-md shadow-lg bg-background text-foreground"
          >
            {children}
            <Arrow
              {...arrowProps}
              size={10}
              roundness={0}
              borderWidth={1}
              borderColor="var(--vscode-panel-border)"
              backgroundColor="var(--vscode-editor-background)"
            />
          </div>
        )}
    </>
  );
};

export default memo(SimplePop);
