import "firebase/auth";
import "firebase/analytics";

import * as appConfig from "../config/appConfig";

import { createContext, useEffect, useMemo, useState } from "react";

import { TelemetryReporter } from "vscode-telemetry/webview";
import Channel from "tangle/webviews";
import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, inMemoryPersistence, setPersistence } from "firebase/auth";
import { getFirebaseConfig } from "../config/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

const baseUrl = appConfig.API_ENDPOINT;
const pollInterval = 60000;

let vscode = null;
if (typeof acquireVsCodeApi === "function") {
  // eslint-disable-next-line no-undef
  vscode = acquireVsCodeApi();
}
const ch = new Channel("app-tangle");
const tangle = vscode ? ch.attach(vscode) : null;
const reporter = vscode ? TelemetryReporter.configure(vscode) : null;

firebase.initializeApp(getFirebaseConfig());
const analytics = getAnalytics();

const auth = getAuth();

const AppContext = createContext();

// eslint-disable-next-line no-undef
const initialStatusDefault = `Chillin 🤗`;

const AppProvider = ({ children, browser }) => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const [projectEnv, setProjectEnv] = useState(null);

  const vscode = window.APP_STATE?.syncPayload?.appConfig?.vscode;

  const vscodeVersion = useMemo(() => vscode?.vscodeversion, [vscode]);

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    tangle?.broadcast({ timezone });
  });

  useEffect(() => {
    if (tangle && tangle.on) {
      tangle.on("noteContext", (noteContext) => {
        setProjectEnv(noteContext);
      });
    }
  }, [setProjectEnv]);

  const authGithub = () => {
    var provider = new firebase.auth.GithubAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  };

  const authCustomToken = (token) => {
    return setPersistence(firebase.auth(), inMemoryPersistence).then(() => {
      return firebase.auth().signInWithCustomToken(token);
    });
  };

  const signOut = () => {
    auth.signOut();
  };

  return (
    <AppContext.Provider
      value={{
        browser,
        vscodeVersion,
        user,
        loading,
        error,
        authGithub,
        authCustomToken,
        analytics,
        currentUser: auth.currentUser,
        signOut,
        initialStatusDefault,
        tangle,
        reporter,
        projectEnv,
        baseUrl,
        pollInterval,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const withAppContext = (Component) => {
  return function WrapperComponent(props) {
    return (
      <AppProvider>
        <Component {...props} />
      </AppProvider>
    );
  };
};

export default AppContext;
export { AppProvider, withAppContext };
