import { gql, useQuery } from "@apollo/client";
import { memo, useMemo, useRef } from "react";

import CoolDateRangeSelector from "@/components/CoolDateRangeSelector";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import WeekdayGraph from "@/components/graphs/WeekdayGraph";
import moment from "moment";
import useRangeSelection from "@/components/hooks/useRangeSelection";

const GET_HOURS = gql`
  query GetHours($startTime: DateTime!, $endTime: DateTime!) {
    sessionSpans(
      startTime: $startTime
      endTime: $endTime
      sort: { field: TIME, order: DESC }
      pageSize: 500
    ) {
      data {
        durationMs
        startTime
        endTime
      }
    }
  }
`;

const WeekdayGraphWrapper = memo(({ selectionRange }) => {
  const [start, end] = useMemo(() => {
    return [
      moment(selectionRange.startDate).startOf("day").toDate(),
      moment(selectionRange.endDate).endOf("day").toDate(),
    ];
  }, [selectionRange]);

  const { loading, error, data } = useQuery(GET_HOURS, {
    variables: { startTime: start, endTime: end },
  });

  const sessions = useMemo(() => {
    if (data?.sessionSpans?.data) {
      return data.sessionSpans.data.map((o) => {
        const entry = {
          mins: moment.duration(o.durationMs).asMinutes(),
          time: moment.utc(o.startTime).local().format("HH"),
          group: moment.utc(o.endTime).local().format("ddd"),
          started_at: moment.utc(o.startTime).local(),
          ended_at: moment.utc(o.endTime).local(),
          date: moment.utc(o.endTime).local().format("YYYY-MM-DD"),
          duration: o.durationMs,
        };
        return entry;
      });
    }
  }, [data]);

  const graphRef = useRef();
  const Graph = WeekdayGraph;

  if (loading) return <Loading />;
  if (error) {
    return <ErrorGrow message={error.message} />;
  }

  return <Graph events={sessions} ref={graphRef} />;
});

const WeekContainer = () => {
  const [selectionRange, setSelectionRange] = useRangeSelection();

  return (
    <div className="flex flex-col w-full h-full py-1">
      <CoolDateRangeSelector
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
      <WeekdayGraphWrapper selectionRange={selectionRange} />
    </div>
  );
};

export default memo(WeekContainer);
