import { useContext, useState } from "react";

import DateContext from "@/contexts/DateContext";
import moment from "moment";

const useRangeSelection = (local = false) => {
  const { selectionRange } = useContext(DateContext);
  const [useLocal, setUseLocal] = useState(local);
  const [localRange, setLocalRange] = useState({
    startDate: moment().subtract(6, "days").startOf("day").toDate(),
    endDate: moment().endOf("day").toDate(),
    key: "selection",
  });

  const setLocal = (range) => {
    if (range === null) {
      setUseLocal(false);
    } else {
      setUseLocal(true);
      setLocalRange(range);
    }
  };

  return [useLocal ? localRange : selectionRange, setLocal];
};

export default useRangeSelection;
