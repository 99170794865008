import { createContext, useContext, useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import AppContext from "@/contexts/AppContext";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_STATUS = gql`
  query getStatus {
    user {
      id
      status {
        project {
          file {
            name
          }
          displayName
          gitBranch {
            name
          }
          language {
            displayName
            id
          }
          name
          shortName
        }
        latestActivityTime
        activity {
          displayName
        }
      }
    }
  }
`;

const GET_USER = gql`
  query getUser {
    user {
      timezone
      id
      name
      email
      displayName
      photoUrl
      github {
        username
      }
      settings {
        day {
          targetCodingDurationSeconds
          dayOfWeek
          workEndTime
          workStartTime
        }
      }
      dnd {
        startTime
        expireTime
        active
      }
    }
  }
`;

const GET_SHARING_SETTINGS = gql`
  query GetSharingSettings {
    user {
      id
      publicUrl
      settings {
        sharing {
          profile
          status
          activity
          file
          gitBranch
          language
          project
          dnd
          schedule
          feed
        }
      }
    }
  }
`;

const SET_SHARING_SETTINGS = gql`
  mutation SetSharingSettings($input: SetUserSharingSettingsInput!) {
    setUserSharingSettings(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { pollInterval } = useContext(AppContext);

  const {
    data: user,
    loading: userIsLoading,
    error: userError,
    refetch: refetchUser,
  } = useQuery(GET_USER);

  const {
    loading: statusLoading,
    error: statusError,
    data: statusData,
    refetch: refetchStatus,
  } = useQuery(GET_STATUS, { pollInterval: pollInterval });

  const {
    loading: sharingSettingsLoading,
    error: sharingSettingsError,
    data: sharingSettingsData,
    refetch: sharingSettingsRefetch,
  } = useQuery(GET_SHARING_SETTINGS, { pollInterval: pollInterval });

  useWindowFocus(0, () => {
    sharingSettingsRefetch();
    refetchStatus();
  });

  const [setSharingSettings] = useMutation(SET_SHARING_SETTINGS);

  const status = useMemo(() => {
    return statusData?.user?.status;
  }, [statusData]);

  const hasStatus = useMemo(() => {
    if (!statusLoading && status === null) {
      return false;
    }
    return true;
  }, [status, statusLoading]);

  const day = useMemo(() => {
    return user?.user?.settings?.day;
  }, [user]);

  const sharingSettings = useMemo(() => {
    return sharingSettingsData?.user?.settings?.sharing;
  }, [sharingSettingsData]);

  const sharingSettingsArray = useMemo(() => {
    return sharingSettings ? Object.keys(sharingSettings) : [];
  }, [sharingSettings]);

  const sharingSettingsMap = useMemo(() => {
    return sharingSettingsArray.reduce(function (accum, currentVal) {
      if (currentVal !== "__typename") {
        accum[currentVal] = sharingSettings[currentVal] === "EVERYONE";
      }
      return accum;
    }, {});
  }, [sharingSettingsArray, sharingSettings]);

  return (
    <UserContext.Provider
      value={{
        user,
        userIsLoading,
        userError,
        refetchUser,
        statusLoading,
        statusError,
        statusData,
        refetchStatus,
        hasStatus,
        sharingSettingsLoading,
        sharingSettingsError,
        sharingSettingsData,
        sharingSettingsRefetch,
        setSharingSettings,
        status,
        day,
        sharingSettings,
        sharingSettingsArray,
        sharingSettingsMap,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const withUserContext = (Component) => {
  return function WrapperComponent(props) {
    return (
      <UserProvider>
        <Component {...props} />
      </UserProvider>
    );
  };
};

export default UserContext;
export { UserProvider, withUserContext };
