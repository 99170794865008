import { createContext, useCallback, useContext } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import AppContext from "@/contexts/AppContext";
import omit from "lodash.omit";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_MILESTONES = gql`
  query GetMilestones {
    user {
      milestones(statuses: [UNCOMPLETED, COMPLETED, SKIPPED, SNOOZED]) {
        name
        status
      }
      id
    }
  }
`;

const SNOOZE_MILESTONES = gql`
  mutation SnoozeMilestones($names: [String!]!, $days: Int!) {
    snoozeUserMilestones(input: { names: $names, days: $days }) {
      errors {
        field
        message
      }
    }
  }
`;

const UNSNOOZE_MILESTONES = gql`
  mutation UnsnoozeMilestones($names: [String!]) {
    unsnoozeUserMilestones(input: { names: $names }) {
      errors {
        field
        message
      }
    }
  }
`;

const COMPLETE_MILESTONES = gql`
  mutation CompleteMilestones($input: CompleteUserMilestonesInput!) {
    completeUserMilestones(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const SKIP_MILESTONES = gql`
  mutation SkipMilestones($input: SkipUserMilestonesInput!) {
    skipUserMilestones(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const MILESTONE_SNOOZE_DAYS = 14;

export const MilestoneContext = createContext();

const MilestoneProvider = ({ children }) => {
  const { pollInterval } = useContext(AppContext);

  const {
    data: milestoneData,
    loading: milestonesLoading,
    error: milestonesError,
    refetch: milestonesRefetch,
    startPolling: startPollingMilestones,
    stopPolling: stopPollingMilestones,
  } = useQuery(GET_MILESTONES, { pollInterval: pollInterval });

  const milestones = milestoneData?.user?.milestones;

  const milestonesCompleted = milestones
    ?.map((milestone) => {
      return omit(milestone, "__typename");
    })
    .filter((milestone) => milestone.status === "COMPLETED");

  const [snoozeUserMilestones] = useMutation(SNOOZE_MILESTONES);
  const [unsnoozeUserMilestones] = useMutation(UNSNOOZE_MILESTONES);
  const [completeUserMilestones] = useMutation(COMPLETE_MILESTONES);
  const [skipUserMilestones] = useMutation(SKIP_MILESTONES);

  useWindowFocus(0, () => {
    milestonesRefetch();
  });

  const completeMilestones = useCallback(
    (milestone, fn) => {
      if (milestone) {
        completeUserMilestones({ variables: { input: { names: [milestone] } } }).then((resp) => {
          if (fn) {
            fn(resp);
          }
        });
      }
    },
    [completeUserMilestones]
  );

  const skipMilestones = (milestone, fn) => {
    skipUserMilestones({ variables: { input: { names: [milestone] } } }).then((resp) => {
      if (fn) {
        fn(resp);
      }
    });
  };

  const snoozeMilestones = (milestone, fn) => {
    snoozeUserMilestones({ variables: { names: [milestone], days: MILESTONE_SNOOZE_DAYS } }).then(
      (resp) => {
        if (fn) {
          fn(resp);
        }
      }
    );
  };

  const unsnoozeMilestones = (milestone, fn) => {
    unsnoozeUserMilestones({ variables: { names: [milestone] } }).then((resp) => {
      if (fn) {
        fn(resp);
      }
    });
  };

  return (
    <MilestoneContext.Provider
      value={{
        milestoneData,
        milestonesLoading,
        milestonesError,
        milestonesRefetch,
        milestones,
        snoozeMilestones,
        unsnoozeMilestones,
        completeMilestones,
        skipMilestones,
        milestonesCompleted,
        startPollingMilestones,
        stopPollingMilestones,
      }}
    >
      {children}
    </MilestoneContext.Provider>
  );
};

export default MilestoneContext;
export { MilestoneProvider };
