import { useIntercom } from "react-use-intercom";
import { useContext, useEffect } from "react";
import AppContext from "@/contexts/AppContext";

const IntercomAuthedWrap = ({ children }) => {
  const { currentUser } = useContext(AppContext);
  const { boot } = useIntercom();

  useEffect(() => {
    boot({
      userId: currentUser.uid,
      name: currentUser.displayName,
      email: currentUser.email,
    });
  }, [currentUser, boot]);

  return <>{children}</>;
};

export default IntercomAuthedWrap;
