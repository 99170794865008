import { getScheduleMessage, getScheduleObject } from "@/utils/getSchedule";

export const getStatusTuples = (status, userDnD) => {
  const dndInactive = userDnD?.active === false;
  const activity = status?.activity?.displayName || "";
  const language = status?.project?.language?.displayName || "";
  const project = status?.project?.shortName || "";
  const gitBranch = status?.project?.gitBranch?.name || "";
  const file = status?.project?.file?.name || "";

  const statusArr = [];

  statusArr.push(["dnd", dndInactive ? "🎧" : "🔥"]);

  if (activity) {
    statusArr.push(["activity", activity]);

    if (language === "") {
      if (project || gitBranch) {
        statusArr.push([null, "on"]);
      }
    }
  }

  if (language) {
    statusArr.push(["language", language]);
    if (project || gitBranch) {
      statusArr.push([null, "on"]);
    }
  }

  if (project && gitBranch) {
    statusArr.push(["project", project]);
    statusArr.push([null, "#"]);
    statusArr.push(["gitBranch", gitBranch]);
  } else {
    if (project) {
      statusArr.push(["project", project]);
    }
    if (gitBranch) {
      statusArr.push(["gitBranch", gitBranch]);
    }
  }

  if (file) {
    statusArr.push([null, "in"]);
    statusArr.push(["file", file]);
  }

  return statusArr;
};

const getStatus = (status, userDnD, workDay) => {
  const statusArr = getStatusTuples(status, userDnD).map(([_, txt]) => txt);
  const statusMsg = statusArr.join(" ").replaceAll(" # ", "#");

  const workDayObj = getScheduleObject(workDay);

  if (statusMsg.trim().length <= 2) {
    if (workDayObj) {
      const scheduleMessage = getScheduleMessage(workDay);
      if (scheduleMessage) {
        return scheduleMessage;
      }
      return "Chillin (no status available) 🤗";
    }
    return "Chillin (no status available) 🤗";
  }

  return `${statusMsg}`;
};

export default getStatus;
