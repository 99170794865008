/* eslint-disable no-unused-vars */
import {
  MemoryRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { memo, useContext, useEffect, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import Cards from "@/components/Cards";
import DndCard from "@/components/cards/DndCard";
import IntercomAuthedWrap from "@/components/IntercomAuthedWrap";
import IntercomUnauthedWrap from "@/components/IntercomUnauthedWrap";
import MetricsContainer from "@/components/MetricsContainer";
import NotesContainer from "@/components/NotesContainer";
import PanelLink from "@/components/PanelLink";
import Providers from "@/providers/Providers";
import ScoreBox from "@/components/ScoreBox";
import StandupContainer from "@/components/StandupContainer";
import { StandupProvider } from "@/contexts/StandupContext";
import StyleWrapper from "@/components/StyleWrapper";
import SummaryCard from "@/components/cards/SummaryCard";
import SuspenseContainer from "@/components/SuspenseContainer";
import Title from "@/components/Title";
import Tos from "@/components/Tos";
import UnauthedProviders from "@/providers/UnauthedProviders";
import VSAuthView from "@/components/vs/VSAuthView";
import VSHomeView from "@/components/vs/VSHomeView";
import VSLayout from "@/components/vs/VSLayout";
import VSWaitingView from "@/components/vs/VSWaitingView";

function createPageView(name, uri, refUri, isLoggedIn, duration, panelIdentifier) {
  return { name, uri, refUri, isLoggedIn, duration, panelIdentifier };
}

const ScoreContainer = () => {
  return (
    <div className="h-full overflow-auto">
      <div>
        <Cards panel={true} />
      </div>
    </div>
  );
};

const StatusContainer = () => {
  return (
    <div className="flex flex-col h-full py-2 space-y-2 overflow-auto">
      <div>
        <SuspenseContainer>
          <ScoreBox />
        </SuspenseContainer>
      </div>
      <div>
        <DndCard />
      </div>
      <div>
        <SuspenseContainer>
          <SummaryCard panel={true} />
        </SuspenseContainer>
      </div>
    </div>
  );
};

const WaitingMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center w-3/4 h-full mx-auto space-y-2">
      <Title>Waiting</Title>
      <div>Waiting for data...</div>
      <div>
        <PanelLink
          linkText="Check the feed panel for details."
          uriState={{ onCommand: "open" }}
          browserUrl="/app/feed"
        />
      </div>
    </div>
  );
};

const WaitingRoutes = () => {
  return (
    <Routes>
      <Route
        path="view"
        element={
          <StyleWrapper>
            <VSWaitingView />
          </StyleWrapper>
        }
      />
      <Route
        path="message"
        element={
          <StyleWrapper>
            <WaitingMessage />
          </StyleWrapper>
        }
      />
      <Route index element={<Navigate to="message" />} />
    </Routes>
  );
};

const VSRoutes = () => {
  const navigate = useNavigate();
  const pathObj = useResolvedPath(":page/*");
  const match = useMatch({ path: pathObj.pathname, end: true });
  const page = match?.params?.page;
  const currentLocation = useLocation();
  const { tangle, reporter } = useContext(AppContext);
  const [dataSummary, setDataSummary] = useState(null);
  const [lastLocation, setLastLocation] = useState([undefined, Date.now()]);

  let { panelIdentifier, defaultUx } = window.APP_STATE;
  //  let { panelIdentifier, defaultUx, uriState } = window.APP_STATE;

  if (!panelIdentifier && defaultUx) {
    panelIdentifier = defaultUx;
  }

  useEffect(() => {
    const newTs = Date.now();
    const { pathname } = currentLocation;
    const [lastPathname, lastTs] = lastLocation;
    if (lastPathname !== pathname && pathname) {
      const duration = newTs - lastTs;
      const pageView = createPageView(
        document.title,
        pathname,
        lastPathname,
        true,
        duration,
        panelIdentifier
      );
      reporter?.sendRawTelemetryEvent("PageView", pageView);
      setLastLocation([pathname, newTs]);
    }
  }, [lastLocation, currentLocation, reporter, panelIdentifier]);

  useEffect(() => {
    // display deprecation notice immediately
    // tangle?.on("status", (status) => {
    //   setDataSummary(status?.userDataSummary);
    // });
    tangle?.on("onView", (view) => {
      if (view?.panel === panelIdentifier) {
        if (view.name && panelIdentifier !== "stateful-standup") {
          navigate(`${panelIdentifier}/${view.name}`);
        }
      }
    });
  }, [navigate, panelIdentifier, tangle]);

  // routing exception logic goes here
  const nextRoute = useMemo(() => {
    if (dataSummary?.status === "LOW_DATA") {
      switch (panelIdentifier) {
        case "stateful-feed":
          return "waiting/view";
        case "stateful-main":
          return "waiting/view";
        case "insights": // compat with ext < 0.8.x
          return "waiting/view";
        default:
          return "waiting";
      }
    }
    return panelIdentifier;
  }, [dataSummary?.status, panelIdentifier]);

  useEffect(() => {
    // avoid infinite redirect for default routes
    if (page !== nextRoute) {
      navigate(nextRoute);
    }
  }, [nextRoute, navigate, page]);

  return (
    <Routes>
      <Route
        path="stateful-metrics/*"
        element={
          <StyleWrapper>
            <MetricsContainer />
          </StyleWrapper>
        }
      />

      <Route
        path="stateful-standup"
        element={
          <StyleWrapper>
            <StandupProvider>
              <Title>Standup</Title>
              <StandupContainer />
            </StandupProvider>
          </StyleWrapper>
        }
      />
      <Route
        path="stateful-notes1"
        element={
          <StyleWrapper>
            <Title>Notes</Title>
            <NotesContainer />
          </StyleWrapper>
        }
      />
      <Route
        path="stateful-feed"
        element={
          <StyleWrapper>
            <Tos />
            <Title>Score</Title>
            <ScoreContainer />
          </StyleWrapper>
        }
      />
      <Route
        path="stateful-network/*"
        element={
          <StyleWrapper>
            {/* <PeopleContainer /> */}
            <ScoreContainer />
          </StyleWrapper>
        }
      />
      <Route
        path="stateful-status"
        element={
          <StyleWrapper>
            <Title>Status</Title>
            {/* <StatusContainer /> */}
            <ScoreContainer />
          </StyleWrapper>
        }
      />
      <Route
        path="stateful-main/*"
        element={
          <VSLayout>
            <VSHomeView />
          </VSLayout>
        }
      />
      <Route
        path="insights/*"
        element={
          <VSLayout>
            <VSHomeView />
          </VSLayout>
        }
      />

      <Route path="waiting/*" element={<WaitingRoutes />} />
    </Routes>
  );
};

export const VSUnauthedRouter = () => {
  return (
    <UnauthedProviders>
      <IntercomUnauthedWrap>
        <MemoryRouter initialEntries={["/auth"]}>
          <Routes>
            <Route path="auth" element={<VSAuthView />} />
          </Routes>
        </MemoryRouter>
      </IntercomUnauthedWrap>
    </UnauthedProviders>
  );
};

export const VSAuthedRouter = () => {
  let { panelIdentifier, defaultUx } = window.APP_STATE;

  if (!panelIdentifier && defaultUx) {
    panelIdentifier = defaultUx;
  }

  return (
    <Providers>
      <IntercomAuthedWrap>
        <MemoryRouter initialEntries={[`/${panelIdentifier}`]}>
          <VSRoutes />
        </MemoryRouter>
      </IntercomAuthedWrap>
    </Providers>
  );
};

export default memo(VSAuthedRouter);
