import { StatusOfflineIcon, StatusOnlineIcon } from "@heroicons/react/solid";
import { gql, useMutation } from "@apollo/client";
import { useContext, useMemo } from "react";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import { HookContext } from "@/contexts/HookContext";
import Loading from "@/components/lib/Loading";
import MilestoneContext from "@/contexts/MilestoneContext";
import ModalSmall from "@/components/modals/ModalSmall";
import SlackEditModal from "@/components/modals/SlackEditModal";
import SuspenseContainer from "@/components/SuspenseContainer";
import { VSCodeButton } from "@vscode/webview-ui-toolkit/react";
import moment from "moment";
import omit from "lodash.omit";
import { useDialog } from "@/contexts/DialogContext";
import { useNotification } from "@/contexts/NotificationContext";

const CREATE_SLACK_CONNECTION = gql`
  mutation CreateSlackConnection($input: CreateSlackConnectionInput!) {
    createSlackConnection(input: $input) {
      redirectUrl
      errors {
        message
      }
    }
  }
`;

const SlackAddButton = () => {
  const notification = useNotification(4000);
  const [createSlackConnection] = useMutation(CREATE_SLACK_CONNECTION);
  const { completeMilestones } = useContext(MilestoneContext);

  const addSlackConnection = () => {
    completeMilestones("configure_slack");
    createSlackConnection({
      variables: {
        input: {
          redirectUri: window.location.href,
        },
      },
    })
      .then(({ data }) => {
        if (data?.createSlackConnection?.errors?.length > 0) {
          notification.error(data.createSlackConnection.errors[0].message);
        } else {
          window.location.replace(data?.createSlackConnection?.redirectUrl);
        }
      })
      .catch((e) => notification.error(e.message));
  };

  return (
    <VSCodeButton onClick={addSlackConnection}>
      <div className="h-5 space-x-1 flex-row-centered">
        <div>Add a Slack</div>
      </div>
    </VSCodeButton>
  );
};

const SlackSetupButton = ({ text = "Setup" }) => {
  return (
    <a href="https://app.stateful.com/manage/settings/connections" target="_blank" rel="noreferrer">
      <VSCodeButton>{text}</VSCodeButton>
    </a>
  );
};

const SlackEntry = ({ slack }) => {
  const { addDialog } = useDialog();

  const hooks = slack?.hooks?.data;
  const dndData = hooks.find((hook) => hook.__typename === "HookSlackDnd") ?? {
    disabled: true,
  };
  const statusData = useMemo(() => {
    return (
      hooks.find((hook) => hook.__typename === "HookSlackStatus") ?? {
        disabled: true,
        statusChange: {},
      }
    );
  }, [hooks]);

  const fieldArr = useMemo(() => {
    return Object.entries(omit(statusData.statusChange, "__typename"))
      .filter(([, value]) => value)
      .map(([key]) => key);
  }, [statusData]);

  return (
    <div className="flex flex-col w-full border rounded bg-background">
      <div className="flex flex-col px-4 py-2 space-y-1">
        <div className="justify-between flex-row-centered">
          <div className="py-1 space-x-2 flex-row-centered">
            <div>
              {slack.disabled ? (
                <StatusOfflineIcon className="w-5 text-description" />
              ) : (
                <StatusOnlineIcon className="w-5 text-covey-500" />
              )}
            </div>
            <div className="font-medium truncate">{slack.name}</div>
          </div>
          <div className="text-xs truncate">
            {moment(slack.updateTime).local().format("LL - h:mm a")}
          </div>
        </div>
        <div className="flex flex-row space-x-2">
          <div className="font-medium">Do not disturb: </div>
          <div>{dndData.disabled ? `off` : `on`}</div>
        </div>
        <div className="flex flex-row space-x-2">
          <div className="font-medium">Status: </div>
          <div>{statusData.disabled ? `off` : `on`}</div>
        </div>
        {fieldArr.length > 0 && (
          <div className="flex flex-row space-x-2">
            <div className="font-medium">Sharing:</div>
            <div className="truncate">{fieldArr.join(", ")}</div>
          </div>
        )}
        <div>
          <hr />
        </div>
        <div className="px-4 py-2">
          <div className="justify-end w-auto space-x-3 flex-row-centered">
            <VSCodeButton
              onClick={() => {
                addDialog({
                  Component: (
                    <ModalSmall>
                      <SlackEditModal slack={slack} />
                    </ModalSmall>
                  ),
                });
              }}
            >
              Edit
            </VSCodeButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const SlackListContent = () => {
  const { slacks } = useContext(HookContext);
  const { browser } = useContext(AppContext);

  const activeSlacks = useMemo(() => {
    return slacks.filter((slack) => !slack.disabled);
  }, [slacks]);

  if (activeSlacks.length === 0) {
    return (
      <div className="w-full h-24">
        <div className="flex items-center justify-center w-full h-full">
          No Slacks registered: &nbsp;
          {browser ? <SlackAddButton /> : <SlackSetupButton text="Add a Slack" />}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-2 space-y-2">
      {activeSlacks.map((slack) => {
        return <SlackEntry slack={slack} key={slack.id} />;
      })}
    </div>
  );
};

const SlackContainer = () => {
  const { browser } = useContext(AppContext);
  const { slacksLoading, slacksError } = useContext(HookContext);

  if (slacksLoading) {
    return (
      <div className="p-4">
        <Loading />
      </div>
    );
  }
  if (slacksError) {
    return <ErrorGrow message={slacksError.message} />;
  }

  return (
    <div className="flex flex-col w-full space-y-3">
      <div className="text-description">
        Auto set `Do not disturb` or a real-time coding status in Slack. (New connections must be
        added in the browser).
      </div>

      <div className="justify-between space-x-2 flex-row-centered">
        <div className="py-1 font-medium">Connections:</div>
        <div>{browser ? <SlackAddButton /> : <SlackSetupButton text="Add a Slack" />}</div>
      </div>
      <div className="w-full h-full">
        <SuspenseContainer>
          <SlackListContent />
        </SuspenseContainer>
      </div>
    </div>
  );
};

export default SlackContainer;
