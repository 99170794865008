import "@/styles/edit.css";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  CollectionIcon,
  DocumentTextIcon,
} from "@heroicons/react/outline";
import EasyEdit, { Types } from "react-easy-edit";
import {
  VSCodeButton,
  VSCodeCheckbox,
  VSCodeDropdown,
  VSCodeOption,
  VSCodeTextField,
} from "@vscode/webview-ui-toolkit/react";
import { memo, useCallback, useContext, useMemo, useRef, useState } from "react";

import AppContext from "@/contexts/AppContext";
import Loading from "@/components/lib/Loading";
import NoteContext from "@/contexts/NoteContext";
import NoteCustomDisplay from "@/components/NoteCustomDisplay";
import NoteCustomInput from "@/components/NoteCustomInput";
import NoteGlobalPop from "@/components/pops/NoteGlobalPop";
import NoteSelectPop from "@/components/pops/NoteSelectPop";
import clsx from "clsx";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { useNotification } from "@/contexts/NotificationContext";

const ExpandButton = ({ open, setOpen, label = null, iconClasses = "w-4", classes = "" }) => {
  const ChevronClasses = `icon ${iconClasses}`;

  const handleOpen = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return (
    <div
      onClick={handleOpen}
      className={clsx(classes, "cursor-pointer group  text-foreground", {
        "flex flex-row space-x-1 items-center": label !== null,
      })}
    >
      {label !== null && <div className="hidden vmd:inline">{label}</div>}
      <ChevronUpIcon
        className={clsx(ChevronClasses, {
          hidden: !open,
          block: open,
        })}
      />
      <ChevronDownIcon
        className={clsx(ChevronClasses, {
          hidden: open,
          block: !open,
        })}
      />
    </div>
  );
};

const CheckboxWrapper = ({ checked, onChange }) => {
  return <VSCodeCheckbox checked={checked} onChange={onChange} />;
};

const Note = ({ note }) => {
  const [open, setOpen] = useState(false);
  const { updateNote, refetchNotes, bulk, selectedNotes, selectNote, unselectNote } =
    useContext(NoteContext);
  const notification = useNotification();

  const selected = useMemo(() => {
    return selectedNotes.indexOf(note.id) !== -1;
  }, [selectedNotes, note]);

  const doUpdateNote = useCallback(
    (noteObj) => {
      const variables = { input: noteObj };
      updateNote({
        variables: variables,
      })
        .then(() => {
          notification.info("Note updated");
        })
        .catch(() => {
          notification.error("Error updating note");
        });
    },
    [updateNote, notification]
  );

  const safeNoteUpdate = useCallback(
    (e) => {
      if (note.completed !== e.target.checked) {
        doUpdateNote({ id: note.id, complete: e.target.checked });
      }
    },
    [note.id, note.completed, doUpdateNote]
  );

  const saveEditedContent = (value) => {
    if (value !== note.content) {
      doUpdateNote({ id: note.id, content: value });
    }
  };

  const handleBulkChange = useCallback(
    (e) => {
      if (selected) {
        unselectNote(note.id);
      } else {
        selectNote(note.id);
      }
    },
    [selected, note, selectNote, unselectNote]
  );

  return (
    <div className="flex flex-col">
      <div className="flex-row-centered justify-between py-0.5 ">
        <div className="w-full overflow-hidden cursor-pointer flex-row-centered">
          <div className="flex items-center">
            {note.type === "TODO" ? (
              <CheckboxWrapper checked={note.completed} onChange={safeNoteUpdate} />
            ) : (
              <DocumentTextIcon className="w-5 icon" />
            )}
          </div>

          <div
            className="w-full px-1 truncate whitespace-nowrap"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {note.content}
          </div>
        </div>
        <div className="flex flex-row items-center">
          <ExpandButton open={open} setOpen={setOpen} />

          <div className="hidden px-2 whitespace-nowrap vsm:block">
            {moment(note.createTime).format("MMM D")}
          </div>
          <NoteSelectPop note={note} doUpdateNote={doUpdateNote} refetchNotes={refetchNotes} />

          <div className="px-1">
            {bulk && <CheckboxWrapper checked={selected} onChange={handleBulkChange} />}
          </div>
        </div>
      </div>
      <div
        className={clsx("p-2 border rounded m-2 bg-backgroundSecondary flex flex-col", {
          hidden: !open,
          block: open,
        })}
      >
        <div>{moment(note.createTime).local().format("LL, h:mm a")}</div>
        <div>
          {note.project?.displayName && `${note.project?.displayName}`}
          {note.gitBranch?.name && `#${note.gitBranch?.name}`}
          {note.file?.path && `: ${note.file?.path}`}
        </div>
        <div>&nbsp;</div>
        <div>
          <EasyEdit
            type={Types.TEXTAREA}
            value={note.content}
            onSave={saveEditedContent}
            saveOnBlur
            saveButtonLabel="✅"
            cancelButtonLabel="❌"
            editComponent={<NoteCustomInput />}
            displayComponent={<NoteCustomDisplay />}
            attributes={{
              name: "note-edit-input",
              className: "border-0",
              style: { border: "none", background: "none", outline: "none" },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const NoteBaseGroup = () => {
  const { notes } = useContext(NoteContext);
  return (
    <div className="flex flex-col divide-y">
      {notes.map((note) => {
        return <Note key={note.id} note={note} />;
      })}
    </div>
  );
};

const NoteGroupRows = ({ notes, name }) => {
  const [open, setOpen] = useState(false);

  return (
    <div key={name} className="flex flex-col pl-4 divide-y cursor-pointer">
      <div
        className="justify-between flex-row-centered"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="py-2 space-x-2 flex-row-centered">
          <div>
            <CollectionIcon className="w-5" />
          </div>
          <div className="font-semibold ">
            {name === "undefined" ? <>Unassigned</> : <>{name}</>}
          </div>
          <div>({notes.length})</div>
        </div>
        <div className="px-4">
          <ExpandButton open={open} setOpen={setOpen} />
        </div>
      </div>
      {open && (
        <>
          {notes.map((note) => {
            return <Note key={note.id} note={note} />;
          })}
        </>
      )}
    </div>
  );
};

const NoteProjectGroup = () => {
  const { notes } = useContext(NoteContext);

  const notesByProject = useMemo(() => {
    return groupBy(notes, "project.name");
  }, [notes]);

  const projectNames = useMemo(() => {
    return Object.keys(notesByProject);
  }, [notesByProject]);

  return (
    <div className="flex flex-col divide-y">
      {projectNames.map((name) => {
        const noteGroup = notesByProject[name];
        return <NoteGroupRows key={name} notes={noteGroup} name={name} />;
      })}
    </div>
  );
};

const NoteBranchGroup = () => {
  const { notes } = useContext(NoteContext);

  const notesByBranch = useMemo(() => {
    return groupBy(notes, "gitBranch.name");
  }, [notes]);

  const branchNames = useMemo(() => {
    return Object.keys(notesByBranch);
  }, [notesByBranch]);

  return (
    <div className="flex flex-col divide-y">
      {branchNames.map((name) => {
        const noteGroup = notesByBranch[name];
        return <NoteGroupRows key={name} notes={noteGroup} name={name} />;
      })}
    </div>
  );
};

const NoteList = () => {
  const { notesLoading, notes, notesGroupBy } = useContext(NoteContext);

  if (notesLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full h-full px-4 py-1 overflow-auto">
      {notes.length === 0 && (
        <div className="h-full" style={{ minHeight: "140px" }}>
          <div className="flex items-center justify-center w-full h-full">
            <div className="flex flex-col space-y-2">
              <div>
                <DocumentTextIcon className="w-9 text-focus" />
              </div>
              <div>No notes yet, create one above.</div>
              <div>Or..</div>
              <div>
                Highlight some text in VS Code, right click then <pre>'Add to Stateful'</pre>
              </div>
            </div>
          </div>
        </div>
      )}
      {!notesGroupBy || (notesGroupBy === "none" && <NoteBaseGroup />)}
      {notesGroupBy === "project" && <NoteProjectGroup />}
      {notesGroupBy === "branch" && <NoteBranchGroup />}
    </div>
  );
};

const NoteNavigation = () => {
  const { projectEnv } = useContext(AppContext);

  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const notification = useNotification();
  const noteInputRef = useRef(null);

  const {
    noteQuery,
    setNoteQuery,
    showNotesArchived,
    setNotesShowArchived,
    notesGroupBy,
    setNotesGroupBy,
    showNotesStatus,
    setNotesShowStatus,
    noteOrder,
    setNoteOrder,
    bulk,
    setBulk,
    selectAllNotes,
    unselectAllNotes,
    deleteSelectedNotes,
    selectedNotes,
    updateSelectedNotes,
    createNote,
  } = useContext(NoteContext);

  const addNote = useCallback(() => {
    const noteObject = {
      date: moment().format("YYYY-MM-DD"),
      content: noteContent,
      type: "NOTE",
    };

    if (noteContent) {
      if (projectEnv !== null) {
        Object.assign(noteObject, {
          gitUrl: projectEnv?.gitUrl,
          gitBranchName: projectEnv?.gitBranchName,
          gitCommit: projectEnv?.gitCommit,
        });
      }

      createNote({
        variables: {
          input: noteObject,
        },
      }).then((resp) => {
        setNoteContent("");
        notification.info("Note added.");
      });
    } else {
      noteInputRef.current.focus();
    }
  }, [noteContent, notification, createNote, projectEnv]);

  return (
    <>
      <div className="flex flex-row justify-between px-4 py-2 space-x-3">
        <div className="w-full flex-row-centered">
          <VSCodeTextField
            type="text"
            className="w-full"
            value={noteContent}
            placeholder="My new note..."
            onInput={(e) => {
              setNoteContent(e.target.value);
            }}
            ref={noteInputRef}
          />

          <VSCodeButton onClick={addNote}>Add</VSCodeButton>
        </div>
        <div className="items-center justify-end hidden space-x-2 vsm:flex">
          <ExpandButton
            open={advancedOpen}
            setOpen={setAdvancedOpen}
            label="Filter"
            classes={clsx({ "w-full font-medium": noteQuery !== "" })}
          />
          <NoteGlobalPop>
            <div className="flex flex-col w-full">
              <div
                className="border-b popDownItem"
                onClick={() => {
                  setBulk(!bulk);
                }}
              >
                Bulk operations ({bulk ? `on` : `off`})
              </div>
              {bulk && (
                <>
                  <div
                    className="popDownItem"
                    onClick={() => {
                      selectAllNotes();
                    }}
                  >
                    Select all
                  </div>
                  <div
                    className="popDownItem"
                    onClick={() => {
                      unselectAllNotes();
                    }}
                  >
                    Select none
                  </div>
                  {selectedNotes.length > 0 && (
                    <>
                      <div>
                        <hr />
                      </div>
                      <div
                        className="popDownItem"
                        onClick={() => {
                          updateSelectedNotes({
                            complete: true,
                          });
                        }}
                      >
                        Mark complete
                      </div>
                      <div
                        className="popDownItem"
                        onClick={() => {
                          updateSelectedNotes({
                            complete: false,
                          });
                        }}
                      >
                        Mark incomplete
                      </div>
                      <div>
                        <hr />
                      </div>
                      <div
                        className="popDownItem"
                        onClick={() => {
                          updateSelectedNotes({
                            hide: true,
                          });
                        }}
                      >
                        Hide
                      </div>
                      <div
                        className=" popDownItem"
                        onClick={() => {
                          deleteSelectedNotes();
                        }}
                      >
                        Delete forever ⚠️
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </NoteGlobalPop>
        </div>
      </div>

      {advancedOpen && (
        <div className="w-full p-2">
          <div className="flex flex-col p-1 border rounded-sm bg-sideBarBackground">
            <div className="p-1">
              <VSCodeTextField
                className="w-full"
                value={noteQuery}
                placeholder="Filter..."
                onInput={(e) => {
                  setNoteQuery(e.target.value);
                }}
              />
            </div>
            <div className="flex-wrap p-1 space-x-2 space-y-1 flex-row-centered align-center">
              <div className="flex-row-centered flex-nowrap">
                <div>Status:</div>
                <VSCodeDropdown
                  value={showNotesStatus || "all"}
                  onChange={(e) => {
                    setNotesShowStatus(e.target.value);
                  }}
                >
                  <VSCodeOption value="all">All</VSCodeOption>
                  <VSCodeOption value="complete">Complete</VSCodeOption>
                  <VSCodeOption value="incomplete">Incomplete</VSCodeOption>
                </VSCodeDropdown>
              </div>

              <div className="flex-row-centered flex-nowrap">
                <div>GroupBy:</div>
                <div>
                  <VSCodeDropdown
                    value={notesGroupBy || "none"}
                    className="text-sm"
                    onChange={(e) => {
                      setNotesGroupBy(e.target.value);
                    }}
                  >
                    <VSCodeOption value="none">None</VSCodeOption>
                    <VSCodeOption value="project">Project</VSCodeOption>
                    <VSCodeOption value="branch">Branch</VSCodeOption>
                  </VSCodeDropdown>
                </div>
              </div>
              <div className="flex-row-centered flex-nowrap">
                <div>Date:</div>
                <VSCodeDropdown
                  value={noteOrder || "DESC"}
                  className="text-sm"
                  onChange={(e) => {
                    setNoteOrder(e.target.value);
                  }}
                >
                  <VSCodeOption value="DESC">DESC</VSCodeOption>
                  <VSCodeOption value="ASC">ASC</VSCodeOption>
                </VSCodeDropdown>
              </div>
              <div className="flex-row-centered flex-nowrap">
                <div>Archived:</div>

                <VSCodeDropdown
                  value={showNotesArchived || "hide"}
                  className="text-sm"
                  onChange={(e) => {
                    setNotesShowArchived(e.target.value);
                  }}
                >
                  <VSCodeOption value="hide">Hide</VSCodeOption>
                  <VSCodeOption value="show">Show</VSCodeOption>
                  <VSCodeOption value="mix">Mix</VSCodeOption>
                </VSCodeDropdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const NotesContainer = () => {
  return (
    <div className="w-full h-full overflow-hidden">
      <div className="flex flex-col w-full h-full overflow-hidden">
        <NoteNavigation />
        <div className="w-full h-full overflow-hidden">
          <NoteList />
        </div>
      </div>
    </div>
  );
};

export default memo(NotesContainer);
