import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";
import { memo, useMemo, useState } from "react";

import { CalendarIcon } from "@heroicons/react/outline";
import ErrorGrow from "@/components/lib/ErrorGrow";
import LanguagePie from "@/components/graphs/LanguagePie";
import Loading from "@/components/lib/Loading";
import clsx from "clsx";
import moment from "moment";
import { secondsToHuman } from "@/utils/convert";

const GET_USER_SUMMARY = gql`
  query GetUserSummary($startDate: Date!) {
    dayRangeSummary(startDate: $startDate) {
      dayCount
      endDate
      startDate
      codingDuration {
        totalSeconds
        readingSeconds
        writingSeconds
      }
      languages {
        data {
          codingDuration {
            totalSeconds
          }
          displayName
          id
        }
      }
      sessions {
        count
      }
      score {
        mean
      }
    }
  }
`;

const SummaryCardContent = () => {
  const [open, setOpen] = useState(false);

  const {
    data: summaryData,
    loading: summaryLoading,
    error: summaryError,
  } = useQuery(GET_USER_SUMMARY, {
    variables: { startDate: moment().subtract(1, "year").format("YYYY-MM-DD") },
  });

  const summaryObj = summaryData?.dayRangeSummary;
  const dayCount = summaryObj?.dayCount;
  const totalSeconds = summaryObj?.codingDuration?.totalSeconds;
  const startDate = summaryObj?.startDate;
  const scoreAvg = summaryObj?.score?.mean;
  const sessionCount = summaryObj?.sessions?.count;
  const readingSeconds = summaryObj?.codingDuration?.readingSeconds;
  const writingSeconds = summaryObj?.codingDuration?.writingSeconds;
  const readingPercent = (readingSeconds / totalSeconds) * 100;
  const writingPercent = (writingSeconds / totalSeconds) * 100;

  const languageData = useMemo(() => {
    if (summaryObj) {
      return summaryObj?.languages?.data.map((lang) => {
        return {
          ...lang,
          totalSeconds: lang.codingDuration.totalSeconds,
        };
      });
    }
  }, [summaryObj]);

  if (summaryLoading) {
    return <Loading />;
  }
  if (summaryError) {
    return <ErrorGrow error={summaryError} />;
  }

  return (
    <div className="flex flex-col py-2">
      <div className="justify-between px-2 flex-row-centered">
        <div className="w-full space-x-2 truncate flex-row-centered">
          <CalendarIcon className="w-6 icon-fg" />

          <div className="truncate whitespace-nowrap">
            <span className="font-medium">{dayCount}</span>&nbsp;coding days since&nbsp;
            <span className="font-medium">{moment(startDate).format("MMMM DD, YYYY")}</span>
          </div>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? <ChevronUpIcon className="h-5 icon" /> : <ChevronDownIcon className="h-5 icon" />}
        </div>
      </div>
      <div
        className={clsx({
          hidden: !open,
          block: open,
        })}
      >
        <div className="p-2 pt-3 space-x-2 flex-row-centered">
          <div className="hidden w-1/2 h-32 vsm:block">
            <LanguagePie data={languageData} />
          </div>
          <div className="flex flex-col space-y-0.5">
            <div>
              <span className="font-medium">{secondsToHuman(totalSeconds)}</span> coding
            </div>
            <div>
              <span className="font-medium">{readingPercent?.toFixed(0)}%</span> read /{" "}
              <span className="font-medium">{writingPercent?.toFixed(0)}%</span> write
            </div>
            <div>
              <span className="font-medium">{languageData?.length}</span> languages
            </div>
            <div>
              <span className="font-medium">{sessionCount?.toLocaleString("en-US")}</span> sessions
            </div>
            <div>
              Score average of <span className="font-medium">{scoreAvg}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SummaryCard = ({ panel = false }) => {
  return (
    <div className={clsx("w-full h-full px-4", { "pt-2": !panel })}>
      <div className="border rounded-sm">
        <SummaryCardContent />
      </div>
    </div>
  );
};

export default memo(SummaryCard);
