import { ClipboardCheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { cloneElement, useMemo, useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { InformationCircleIcon } from "@heroicons/react/outline";
import SimplePop from "@/components/pops/SimplePop";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";

const ArrowLink = ({ Big, Small }) => {
  return (
    <>
      <div className="hidden vsm:block">{cloneElement(Big, {})}</div>
      <div className="block vsm:hidden">{cloneElement(Small, {})}</div>
    </>
  );
};

const AddPop = () => {
  return (
    <SimplePop trigger={<InformationCircleIcon className="w-4 text-yellow-500 icon" />}>
      <div className="p-2">
        Google's <i>"click to add calendar"</i> feature only accepts{" "}
        <a
          href="https://stackoverflow.com/questions/12563178/link-to-add-ics-to-google-calendar-stopped-working"
          className="link"
        >
          calendar URLs passed via HTTP (instead of HTTPS!)
        </a>
        . We suggest copying the link below and following the{" "}
        <a href="https://www.stateful.com/calendar" className="link">
          step-by-step instructions
        </a>{" "}
        if you value maximum encryption over convenience.
      </div>
    </SimplePop>
  );
};

const CopyLink = ({ text, calendar }) => {
  const [statusCopied, setStatusCopied] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="w-full space-x-2 flex-row-centered">
        <CopyToClipboard onCopy={() => setStatusCopied(true)} text={calendar.calendarUrl}>
          {statusCopied ? (
            <div className="flex-row-centered">
              <VSCodeLink>{text}</VSCodeLink> <ClipboardCheckIcon className="w-4 " />
            </div>
          ) : (
            <div className="flex-row-centered">
              <VSCodeLink>{text}</VSCodeLink> <ClipboardIcon className="w-4" />
            </div>
          )}
        </CopyToClipboard>
      </div>
      {statusCopied && (
        <div className="text-xs link">
          <VSCodeLink href="https://www.stateful.com/calendar#instructions">
            Add calendar step-by-step instructions
          </VSCodeLink>
        </div>
      )}
    </div>
  );
};

const AddLink = ({ text, calendar }) => {
  const gcalUrl = useMemo(() => {
    const httpUrl = calendar.calendarUrl.replace("https://", "http://");
    return `https://calendar.google.com/calendar/r?cid=${httpUrl}`;
  }, [calendar]);

  return (
    <div className="space-x-2 flex-row-centered">
      <VSCodeLink href={gcalUrl} target="_blank" rel="noreferrer">
        {text}
      </VSCodeLink>
      <AddPop />
    </div>
  );
};

const CalendarLinks = ({ calendar }) => {
  return (
    <div className="flex flex-col space-y-1 ">
      <ArrowLink
        Small={<AddLink text="Add to GCal →" calendar={calendar} />}
        Big={<AddLink text="Add to your Google Calendar →" calendar={calendar} />}
      />
      <ArrowLink
        Small={<CopyLink text="Copy link →" calendar={calendar} />}
        Big={<CopyLink text="Copy the link to your clipboard →" calendar={calendar} />}
      />
    </div>
  );
};

export default CalendarLinks;
