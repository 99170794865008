import "@/styles/react-big-calendar.css";
import "moment-timezone";

import * as ical from "ical.js";

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import {
  CalendarIcon,
  ClipboardCheckIcon,
  ClipboardIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { VSCodeButton, VSCodeDropdown, VSCodeOption } from "@vscode/webview-ui-toolkit/react";
import { gql, useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ErrorGrow from "@/components/lib/ErrorGrow";
import ExpandCenter from "@/components/lib/ExpandCenter";
import Loading from "@/components/lib/Loading";
import SuspenseContainer from "@/components/SuspenseContainer";
import icaljs from "ical.js";
import moment from "moment";
import { msToHuman } from "@/utils/convert";
import useSWR from "swr";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const localizer = momentLocalizer(moment);

const GET_CALENDARS = gql`
  query getCalendars {
    user {
      id
      calendars {
        data {
          createTime
          id
          public
          secret
          sinceWeeks
          type
          updateTime
          origin
          name
          description
          calendarUrl
        }
      }
    }
  }
`;

const GET_DATA_SUMMARY = gql`
  query getDataSummary {
    user {
      id
      dataSummary {
        activityDurationMs
      }
    }
  }
`;

const RECURRING_EVENTS = 12;

const CustomToolbar = (props) => {
  let { onNavigate, label } = props;

  return (
    <div className="rbc-toolbar">
      <span className="text-sm rbc-btn-group">
        <VSCodeButton
          appearance="secondary"
          onClick={(e) => {
            onNavigate("PREV");
          }}
        >
          Prev
        </VSCodeButton>
      </span>
      <span className="text-sm rbc-toolbar-label">{label}</span>
      <span className="text-sm rbc-btn-group">
        <VSCodeButton
          appearance="secondary"
          onClick={(e) => {
            onNavigate("NEXT");
          }}
        >
          Next
        </VSCodeButton>
      </span>
    </div>
  );
};

const CalendarContent = ({ calendarUrl, view, customToolbar }) => {
  const fetcher = (...args) => fetch(...args).then((res) => res.text());
  const { data, error } = useSWR(calendarUrl, fetcher);

  const eventsObj = useMemo(() => {
    if (data) {
      var jcal = icaljs.parse(data);
      var vcal = new icaljs.Component(jcal);
      return vcal.getAllSubcomponents("vevent");
    }
  }, [data]);

  const anchorDays = useMemo(() => {
    if (eventsObj?.length > 0) {
      return parseInt(
        moment
          .duration(
            moment(eventsObj[0].getFirstPropertyValue("dtstart").toJSDate()).diff(
              moment(moment.now()).subtract(2, "weeks")
            )
          )
          .asDays(),
        10
      );
    }
    return 0;
  }, [eventsObj]);

  const anchorShift = useMemo(() => {
    // shifts happen for a week
    return parseInt(anchorDays / 7, 10);
  }, [anchorDays]);

  const events = useMemo(() => {
    if (eventsObj) {
      return eventsObj.map((vevent) => {
        const startDate = new Date(vevent.getFirstPropertyValue("dtstart"));
        const endDate = new Date(vevent.getFirstPropertyValue("dtend"));
        var duration = moment.duration(moment(endDate).diff(moment(startDate))).asMilliseconds();
        const title = vevent.getFirstPropertyValue("summary");
        const tooltip = vevent.getFirstPropertyValue("description");

        const anchorTime = ical.Time.fromJSDate(
          moment(startDate).subtract(anchorShift, "weeks").toDate()
        );

        var expand = new icaljs.RecurExpansion({
          component: vevent,
          dtstart: anchorTime,
        });

        let eventsArr = [];
        var next;
        var i = 0;
        while (i < RECURRING_EVENTS && (next = expand.next())) {
          const day = moment(next.toJSDate());
          const start = day.toDate();
          const end = day.add(duration, "milliseconds").toDate();

          eventsArr.push({
            start,
            end,
            title,
            tooltip,
          });
          i++;
        }
        return eventsArr;
      });
    }
  }, [eventsObj, anchorShift]);

  const customToolbarComponent = useMemo(() => {
    if (customToolbar) {
      return { toolbar: CustomToolbar };
    }
    return {};
  }, [customToolbar]);

  if (!data) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error</div>;
  }

  return (
    <BigCalendar
      localizer={localizer}
      events={events.flat(2)}
      startAccessor="start"
      endAccessor="end"
      titleAccessor="title"
      tooltipAccessor="tooltip"
      defaultView={view}
      views={["week", "day", "agenda"]}
      components={customToolbarComponent}
    />
  );
};

const CalendarSelector = (props) => {
  const { selected, setSelected, calendars, calendarUrl } = props;
  const [statusCopied, setStatusCopied] = useState(false);

  const gcalUrl = useMemo(() => {
    if (calendarUrl) {
      const httpUrl = calendarUrl.replace("https://", "http://");
      return `https://calendar.google.com/calendar/r?cid=${httpUrl}`;
    }
  }, [calendarUrl]);

  return (
    <div className="flex flex-col pt-2 space-y-1">
      {/* <div className="font-medium">Available calendars:</div> */}
      <div className="flex-row-centered">
        <div>
          <VSCodeDropdown
            className="inputBase"
            value={selected || "none"}
            onChange={(e) => {
              setSelected(e.target.value);
            }}
          >
            {calendars.map((calendar) => {
              return (
                <VSCodeOption key={calendar.id} value={calendar.id}>
                  {calendar.name || calendar.id}
                </VSCodeOption>
              );
            })}
          </VSCodeDropdown>
        </div>
        <div className="cursor-pointer text-covey-500">
          <CopyToClipboard onCopy={() => setStatusCopied(true)} text={calendarUrl}>
            {statusCopied ? (
              <ClipboardCheckIcon className="w-5 link" />
            ) : (
              <ClipboardIcon className="w-5 link" />
            )}
          </CopyToClipboard>
        </div>
        {gcalUrl && (
          <div>
            <a className="link" href={gcalUrl} target="_blank" rel="noreferrer">
              <PlusCircleIcon className="w-5" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const CalendarContainer = (props) => {
  const { pollInterval } = useContext(AppContext);
  const { view = "week", customToolbar = false } = props;
  const {
    loading: calLoading,
    error: calError,
    data: calData,
    refetch: calRefetch,
  } = useQuery(GET_CALENDARS, {
    pollInterval: pollInterval,
  });

  const {
    loading: summaryLoading,
    error: summaryError,
    data: summaryData,
    refetch: summaryRefetch,
  } = useQuery(GET_DATA_SUMMARY, {
    pollInterval: pollInterval,
  });

  const [selected, setSelected] = useState(null);
  const [selectedCal, setSelectedCal] = useState(null);

  useWindowFocus(0, () => {
    calRefetch();
    summaryRefetch();
  });

  const summary = useMemo(() => {
    return summaryData?.user?.dataSummary;
  }, [summaryData]);

  const calendars = useMemo(() => {
    const _cals = Array.from(calData?.user?.calendars?.data ?? []);
    // show user-gen cals last
    _cals.sort((a) => (a?.origin === "FEEDER" ? -1 : 1));
    return _cals;
  }, [calData]);

  const calendarMap = useMemo(() => {
    if (calendars) {
      return calendars.reduce(function (accum, currentVal) {
        accum[currentVal.id] = currentVal;
        return accum;
      }, {});
    }
  }, [calendars]);

  useEffect(() => {
    if (calendars && calendars.length > 0 && !selected) {
      setSelected(calendars[0].id);
    }
  }, [calendars, selected]);

  useEffect(() => {
    if (selected && calendarMap) {
      setSelectedCal(calendarMap[selected]);
    }
  }, [selected, calendarMap]);

  const calendarUrl = useMemo(() => {
    if (selectedCal) {
      return selectedCal.calendarUrl;
    }
  }, [selectedCal]);

  if (summaryLoading || calLoading || !calendars) return <Loading />;
  if (calError) return <ErrorGrow message={calError.message} />;
  if (summaryError) return <ErrorGrow message={summaryError.message} />;

  // could add something about the amount of data required
  // and an imagine of what it looks like when ready
  // link to web landing page?
  if (calendars.length === 0) {
    // no data
    if (summary && !summary?.activityDurationMs) {
      return (
        <ExpandCenter>
          <div className="flex flex-col w-3/4 px-4 space-y-2">
            <div>Make sure the VS Code extension is running, we aren't seeing any data yet.</div>
            <a
              href="https://marketplace.visualstudio.com/items?itemName=stateful.stable"
              target="_blank"
              rel="noreferrer"
            >
              <VSCodeButton>Install Stateful for VS Code</VSCodeButton>
            </a>
          </div>
        </ExpandCenter>
      );
    }

    // Not enough data
    return (
      <ExpandCenter>
        <div className="flex flex-col w-3/4 px-4 space-y-2">
          <div>
            <CalendarIcon className="text-blue-500 w-14" />
          </div>
          <div>
            We don't have enough data to generate your predictive calendar (
            <span className="font-medium">{msToHuman(summary?.activityDurationMs)}</span>), keep
            your eyes on the feed to tell you when it's ready.
          </div>
        </div>
      </ExpandCenter>
    );
  }

  return (
    <div className="h-full px-4 py-2">
      <div className="flex flex-col h-full px-2 space-y-4">
        {calendars.length > 0 && view === "week" && (
          <CalendarSelector
            calendars={calendars}
            selected={selected}
            setSelected={setSelected}
            calendarUrl={calendarUrl}
          />
        )}

        <div className="h-full overflow-auto">
          {selectedCal && (
            <SuspenseContainer>
              <CalendarContent
                calendarUrl={calendarUrl}
                view={view}
                customToolbar={customToolbar}
              />
            </SuspenseContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarContainer;
