import { PersonRow, RELATIONSHIP_FRAGMENT } from "@/components/connections/PeopleWrappers";
import { gql, useQuery } from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo } from "react";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_FOLLOWERS = gql`
  ${RELATIONSHIP_FRAGMENT}
  query GetFollowers {
    user {
      id
      following: relationships(showFollowers: true) {
        ...ProfileFields
      }
    }
  }
`;

const GET_FOLLOWING = gql`
  ${RELATIONSHIP_FRAGMENT}
  query GetFollowing {
    user {
      id
      following: relationships(showFollowing: true) {
        ...ProfileFields
      }
    }
  }
`;

const GET_FRIENDS = gql`
  ${RELATIONSHIP_FRAGMENT}
  query GetFriends {
    user {
      id
      following: relationships(showFollowers: true, showFollowing: true) {
        ...ProfileFields
      }
    }
  }
`;

const FollowersContainer = () => {
  const { pollInterval } = useContext(AppContext);

  const {
    loading: followersLoading,
    error: followersError,
    data: followersData,
    refetch: followersRefetch,
  } = useQuery(GET_FOLLOWERS, { pollInterval: pollInterval });

  const {
    loading: followingLoading,
    error: followingError,
    data: followingData,
    refetch: followingRefetch,
  } = useQuery(GET_FOLLOWING, { pollInterval: pollInterval });

  const {
    loading: friendsLoading,
    error: friendsError,
    data: friendsData,
    refetch: friendsRefetch,
  } = useQuery(GET_FRIENDS, { pollInterval: pollInterval });

  const refetchAll = useCallback(() => {
    followingRefetch();
    followersRefetch();
    friendsRefetch();
  }, [followingRefetch, followersRefetch, friendsRefetch]);

  useWindowFocus(0, () => {
    refetchAll();
  });

  useEffect(() => {
    refetchAll();
  }, [refetchAll]);

  const followers = useMemo(() => {
    return followersData?.user?.following?.data;
  }, [followersData]);

  const following = useMemo(() => {
    return followingData?.user?.following?.data;
  }, [followingData]);

  const friends = useMemo(() => {
    return friendsData?.user?.following?.data;
  }, [friendsData]);

  if (followersLoading || followingLoading || friendsLoading) return <Loading />;
  if (followersError) return <ErrorGrow message={followersError.message} />;
  if (followingError) return <ErrorGrow message={followingError.message} />;
  if (friendsError) return <ErrorGrow message={friendsError.message} />;

  if (
    friends &&
    friends.length === 0 &&
    following &&
    following.legth === 0 &&
    followers &&
    followers.length === 0
  ) {
    return (
      <div className="w-full py-6 text-center">
        No connections yet, goto the `discover` tab and make some!
      </div>
    );
  }

  return (
    <div className="flex flex-col px-4">
      <div className="flex flex-col pb-2">
        <div className="py-1 font-medium">Friends</div>
        <div className="divide-y">
          {friends &&
            friends.length > 0 &&
            friends.map((user) => {
              return <PersonRow user={user} key={user.id} refetch={refetchAll} />;
            })}
        </div>
      </div>

      <div className="flex flex-col pb-2">
        <div className="py-1 font-medium">Following</div>
        <div className="divide-y">
          {following &&
            following.length > 0 &&
            following.map((user) => {
              return <PersonRow user={user} key={user.id} refetch={refetchAll} />;
            })}
        </div>
      </div>

      <div className="flex flex-col pb-2">
        <div className="py-1 font-medium">Followers</div>
        <div className="divide-y">
          {followers &&
            followers.length > 0 &&
            followers.map((user) => {
              return <PersonRow user={user} key={user.id} refetch={refetchAll} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default FollowersContainer;
