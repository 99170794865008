import { useContext, useMemo } from "react";

import AppContext from "@/contexts/AppContext";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import { useNavigate } from "react-router-dom";

const PanelLink = ({ linkText, uriState, browserUrl = null, homeUrl = null }) => {
  const navigate = useNavigate();
  const { browser, vscodeVersion } = useContext(AppContext);

  const vscodeScheme = useMemo(() => {
    return (vscodeVersion || "").indexOf("-insider") > -1 ? "vscode-insiders" : "vscode";
  }, [vscodeVersion]);

  const statePayload = useMemo(() => {
    return btoa(JSON.stringify(uriState));
  }, [uriState]);

  if (browser && browserUrl) {
    return (
      <VSCodeLink
        onClick={() => {
          navigate(browserUrl);
        }}
      >
        {linkText}
      </VSCodeLink>
    );
  }
  if (typeof vscodeVersion === "undefined" && homeUrl) {
    return (
      <VSCodeLink
        onClick={() => {
          navigate(homeUrl);
        }}
      >
        {linkText}
      </VSCodeLink>
    );
  } else if (uriState && statePayload) {
    return (
      <VSCodeLink href={`${vscodeScheme}://stateful.stable?state=${statePayload}`}>
        {linkText}
      </VSCodeLink>
    );
  }

  return <></>;
};

export default PanelLink;
