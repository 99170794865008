import { useContext, useMemo } from "react";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import UserContext from "@/contexts/UserContext";
import clsx from "clsx";
import { getScheduleMessage } from "@/utils/getSchedule";
import { getStatusTuples } from "@/utils/getStatus";
import moment from "moment";

const MagicalStatus = () => {
  const {
    user,
    statusLoading,
    statusError,
    hasStatus,
    status,
    day,
    sharingSettingsLoading,
    sharingSettingsError,
    sharingSettingsData,
  } = useContext(UserContext);

  const permissions = useMemo(() => {
    return sharingSettingsData?.user?.settings?.sharing;
  }, [sharingSettingsData]);

  const permArr = useMemo(() => {
    return permissions ? Object.keys(permissions) : [];
  }, [permissions]);

  const permMap = useMemo(() => {
    return permArr.reduce(function (accum, currentVal) {
      if (currentVal !== "__typename") {
        accum[currentVal] = permissions[currentVal] === "EVERYONE";
      }
      return accum;
    }, {});
  }, [permArr, permissions]);

  const userDnD = useMemo(() => {
    if (permMap.dnd !== "ONLY_ME") {
      return user?.user?.dnd;
    }
  }, [user, permMap]);

  const tuples = useMemo(() => {
    return getStatusTuples(status, userDnD);
  }, [status, userDnD]);

  const workDayMessage = useMemo(() => {
    return getScheduleMessage({
      startTime: moment(day?.workStartTime, "HH:mm:ss").toDate(),
      endTime: moment(day?.workEndTime, "HH:mm:ss").toDate(),
    });
  }, [day]);

  if (sharingSettingsLoading || statusLoading) {
    return <Loading />;
  }

  if (statusError) return <ErrorGrow message={statusError.message} />;
  if (sharingSettingsError) return <ErrorGrow message={sharingSettingsError.message} />;

  if (!hasStatus) {
    if (workDayMessage) {
      return (
        <div className="flex flex-shrink">
          <div
            className={clsx("text-left break-words ", {
              "line-through decoration-covey-500/50 decoration-2": !permMap["schedule"],
            })}
          >
            {workDayMessage}
          </div>
        </div>
      );
    }
    return <div className="text-left">Chillin (no status available) 🤗</div>;
  }

  return (
    <div className="flex flex-row flex-wrap">
      {tuples.map(([field, str], i) => {
        const permActive = field !== null && !permMap[field];

        return (
          <span
            key={i}
            className={clsx(
              "whitespace-nowrap flex-nowrap truncate",
              { "line-through decoration-covey-500/50 decoration-2": permActive },
              { "pl-1": field !== "gitBranch" && str !== "#" }
            )}
          >
            {str}
          </span>
        );
      })}
    </div>
  );
};

export default MagicalStatus;
