import { PencilIcon, SwitchVerticalIcon, TrashIcon } from "@heroicons/react/outline";
import { memo, useCallback } from "react";

import ModalTiny from "@/components/modals/ModalTiny";
import NoteEditModal from "@/components/modals/NoteEditModal";
import NoteOptionsPop from "@/components/pops/NoteOptionsPop";
import { useDialog } from "@/contexts/DialogContext";

const NoteSelectPop = ({ note, doUpdateNote, refetchNotes }) => {
  const { addDialog } = useDialog();

  const handleArchiveNote = useCallback(() => {
    doUpdateNote({ id: note.id, hide: !note.hidden });
  }, [note, doUpdateNote]);

  const handleConvertNote = useCallback(() => {
    if (note.type === "TODO") {
      doUpdateNote({ id: note.id, type: "NOTE" });
    } else {
      doUpdateNote({ id: note.id, type: "TODO" });
    }
  }, [note, doUpdateNote]);

  const openNoteEditModal = useCallback(() => {
    addDialog({
      Component: (
        <ModalTiny>
          <NoteEditModal note={note} refetchData={refetchNotes} />
        </ModalTiny>
      ),
    });
  }, [refetchNotes, note, addDialog]);

  return (
    <NoteOptionsPop>
      <div className="flex flex-col p-1 divide-y">
        <div className={"popDownItem"} onClick={openNoteEditModal}>
          <PencilIcon className="h-4 icon" />
          <div>Edit</div>
        </div>
        <div className={"popDownItem"} onClick={handleConvertNote}>
          <SwitchVerticalIcon className="h-4 icon" />
          <div>Convert to {note.type === "TODO" ? "Note" : "Todo"}</div>
        </div>
        <div className={"popDownItem"} onClick={handleArchiveNote}>
          <TrashIcon className="h-4 icon" />
          <div>{note.hidden ? "Restore" : "Archive"}</div>
        </div>
      </div>
    </NoteOptionsPop>
  );
};

export default memo(NoteSelectPop);
