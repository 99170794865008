import * as appConfig from "@/config/appConfig";

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";

import firebase from "firebase/compat/app";
import { onError } from "@apollo/client/link/error";
import possibleTypes from "../possibleTypes.json";
import { setContext } from "@apollo/client/link/context";
import { useMemo } from "react";
import { useNotification } from "@/contexts/NotificationContext";

const httpLink = createHttpLink({
  uri: `${appConfig.API_ENDPOINT}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  let authedUser = firebase.auth().currentUser;
  const token = await authedUser.getIdToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const ClientProvider = ({ children }) => {
  const notification = useNotification();

  const client = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          onError(({ networkError, graphQLErrors }) => {
            if (networkError) {
              notification.error(networkError.message);
            }

            if (graphQLErrors && graphQLErrors.length > 0) {
              console.error(graphQLErrors);
              notification.error("Something went wrong.");
            }
          }),
          authLink,
          httpLink,
        ]),
        cache: new InMemoryCache({
          possibleTypes,
          typePolicies: {
            Day: {
              keyFields: ["date"],
              fields: {
                codingDuration: {
                  merge(existing, incoming) {
                    return { ...existing, ...incoming };
                  },
                },
              },
            },
            DayLanguage: { keyFields: false },
            DayProject: { keyFields: false },
            DayRangeSummary: { keyFields: ["startDate", "endDate"] },
            Standup: { keyFields: ["date"] },
            UserSettings: { keyFields: [] },
            UserDaySettings: { keyFields: ["dayOfWeek"] },
            UserAnnotationProjectFile: { keyFields: false },
            CodingDuration: { keyFields: false },
            Feed: { keyFields: [] },
            UserSharingSettings: { keyFields: [] },
            UserStatus: { keyFields: false },
          },
        }),
      }),
    [notification]
  );
  return <ApolloProvider client={client} children={children} />;
};

export default ClientProvider;
