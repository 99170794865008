import { CardContent, CardFooter, CardTitle, CardWrapper } from "@/components/cards/CardWrappers";
import { gql, useQuery } from "@apollo/client";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import { ReactionDisplay } from "@/components/Reaction";
import ReactionPop from "@/components/pops/ReactionPop";
import ScoreGraph from "@/components/graphs/ScoreGraph";
import UserAvatarStatus from "@/components/UserAvatarStatus";

var converter = require("number-to-words");

const GET_RECENT_SCORES = gql`
  query GetRecentScores {
    days {
      data {
        score
        date
      }
    }
  }
`;

const StreakScoreitem = (props) => {
  const { item } = props;
  const { data: scoreData, loading: scoreLoading, error: scoreError } = useQuery(GET_RECENT_SCORES);

  const scores = scoreData?.days?.data.filter((day) => day.score).reverse();

  if (scoreLoading) {
    return <Loading />;
  }
  if (scoreError) {
    return <ErrorGrow error={scoreError} />;
  }

  const messageYou = `You've achieved a coding score over 80 for the ${converter.toOrdinal(
    item.count
  )} day in a row, keep up the good work! 🎊`;

  const messageOther = `${
    item?.profile?.github?.username
  } had a score of 80+ for the ${converter.toOrdinal(
    item?.count
  )} day in a row, give them some kudos! 🎊`;

  const contentText = item?.owner ? messageYou : messageOther;

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        date
        title={
          <div className="flex-row-centered space-x-2">
            <UserAvatarStatus face={item?.profile} classes="w-5" />
            <div>Score streak (day {item?.count})</div>
          </div>
        }
      ></CardTitle>

      <CardContent>
        <div className="flex flex-col h-full">
          <div>{contentText}</div>
          {item.owner && (
            <>
              <div className="h-24">
                <ScoreGraph data={scores} />
              </div>
              <div className="flex justify-end text-xs text-description">
                (Graph shown only to you.)
              </div>
            </>
          )}
        </div>
      </CardContent>
      <CardFooter>
        <div className="flex-row-centered">
          <ReactionDisplay item={item} />
          <ReactionPop item={item} />
        </div>
      </CardFooter>
    </CardWrapper>
  );
};

export default StreakScoreitem;
