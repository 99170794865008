import { StatusOfflineIcon, StatusOnlineIcon } from "@heroicons/react/solid";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext, useMemo } from "react";

import ErrorGrow from "@/components/lib/ErrorGrow";
import { HookContext } from "@/contexts/HookContext";
import Loading from "@/components/lib/Loading";
import ModalSmall from "@/components/modals/ModalSmall";
import SuspenseContainer from "@/components/SuspenseContainer";
import { TrashIcon } from "@heroicons/react/outline";
import { VSCodeButton } from "@vscode/webview-ui-toolkit/react";
import WebhookAddModal from "@/components/modals/WebhookAddModal";
import WebhookEditModal from "@/components/modals/WebhookEditModal";
import moment from "moment";
import omit from "lodash.omit";
import { useDialog } from "@/contexts/DialogContext";
import { useNotification } from "@/contexts/NotificationContext";

const DELETE_WEBHOOK = gql`
  mutation DeleteHook($input: DeleteHookInput!) {
    deleteHook(input: $input) {
      errors {
        message
      }
    }
  }
`;

const TEST_WEBHOOK = gql`
  mutation TriggerEvent {
    triggerEvent(input: { type: STATUS_CHANGE }) {
      errors {
        message
      }
    }
  }
`;

const WebhookAddButton = ({ text = "Add a Webhook" }) => {
  const { hooksRefetch } = useContext(HookContext);
  const { addDialog } = useDialog();

  return (
    <VSCodeButton
      onClick={() => {
        addDialog({
          Component: (
            <ModalSmall>
              <WebhookAddModal refetch={hooksRefetch} />
            </ModalSmall>
          ),
        });
      }}
    >
      <div className="h-5 space-x-1 flex-row-centered">
        <div>{text}</div>
      </div>
    </VSCodeButton>
  );
};

const HookEntry = ({ webhook }) => {
  const { hooksRefetch } = useContext(HookContext);

  const notification = useNotification();
  const { addDialog } = useDialog();

  const fieldArr = useMemo(() => {
    return Object.entries(omit(webhook.statusChange, "__typename"))
      .filter(([, value]) => value)
      .map(([key]) => key);
  }, [webhook]);

  const headerArr = useMemo(() => {
    if (webhook) {
      return webhook?.headers?.map((header) => header.name).filter((name) => name !== "");
    }
  }, [webhook]);

  const [deleteWebhook] = useMutation(DELETE_WEBHOOK);
  const [testWebhook] = useMutation(TEST_WEBHOOK);

  const doDeleteWebhook = useCallback(() => {
    deleteWebhook({
      variables: {
        input: {
          id: webhook.id,
        },
      },
    }).then(({ data }) => {
      if (data.deleteHook.errors.length > 0) {
        notification.error(data.deleteHook.errors[0].message);
      } else {
        notification.info(`Webhook ${webhook.name} deleted.`);
        hooksRefetch();
      }
    });
  }, [webhook, deleteWebhook, hooksRefetch, notification]);

  const doTestWebhook = useCallback(() => {
    testWebhook().then(({ data }) => {
      if (data?.triggerEvent?.errors?.length > 0) {
        notification.error(data.triggerEvent.errors[0].message);
      } else {
        notification.info(`Webhook test sent.`);
      }
    });
  }, [notification, testWebhook]);

  return (
    <div className="flex flex-col w-full border rounded bg-background">
      <div className="flex flex-col px-4 py-2 space-y-1">
        <div className="justify-between flex-row-centered">
          <div className="py-1 space-x-2 flex-row-centered">
            <div>
              {webhook.disabled ? (
                <StatusOfflineIcon className="w-5 text-description" />
              ) : (
                <StatusOnlineIcon className="w-5 text-covey-500" />
              )}
            </div>
            <div className="font-medium truncate">{webhook.name}</div>
          </div>
          <div className="text-xs truncate">
            {moment(webhook.updateTime).local().format("LL - h:mm a")}
          </div>
        </div>
        <div className="flex flex-row space-x-2">
          <div className="font-medium">Url:</div>
          <div className="truncate">{webhook.url}</div>
        </div>
        {fieldArr.length > 0 && (
          <div className="flex flex-row space-x-2">
            <div className="font-medium">Sharing:</div>
            <div className="truncate">{fieldArr.join(", ")}</div>
          </div>
        )}

        {headerArr.length > 0 && (
          <div className="flex flex-row space-x-2">
            <div className="font-medium">Headers:</div>
            <div className="truncate">{headerArr.join(", ")}</div>
          </div>
        )}
      </div>
      <div>
        <hr />
      </div>
      <div className="px-4 py-2">
        <div className="justify-end w-auto space-x-3 flex-row-centered">
          <VSCodeButton
            appearance="secondary"
            onClick={() => {
              doTestWebhook();
            }}
          >
            Test
          </VSCodeButton>
          <VSCodeButton
            onClick={() => {
              addDialog({
                Component: (
                  <ModalSmall>
                    <WebhookEditModal webhook={webhook} />
                  </ModalSmall>
                ),
              });
            }}
          >
            Edit
          </VSCodeButton>
          <div
            className="cursor-pointer"
            onClick={() => {
              doDeleteWebhook();
            }}
          >
            <TrashIcon className="h-5 icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

const WebhookListContent = () => {
  const { webhooks } = useContext(HookContext);

  if (webhooks.length === 0) {
    return (
      <div className="w-full h-24">
        <div className="flex items-center justify-center w-full h-full">
          No webhooks registered: &nbsp; <WebhookAddButton text="Add" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-2 space-y-2">
      {webhooks.map((webhook) => {
        return <HookEntry webhook={webhook} key={webhook.id} />;
      })}
    </div>
  );
};

const WebhookContainer = () => {
  const { hooksLoading, hooksError } = useContext(HookContext);

  if (hooksLoading) {
    return (
      <div className="p-4">
        <Loading />
      </div>
    );
  }
  if (hooksError) {
    return <ErrorGrow message={hooksError.message} />;
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-description">
        Push your configured coding status to any endpoint you want!
      </div>

      <div className="justify-between space-x-2 flex-row-centered">
        <div className="py-1 font-medium">Connections:</div>
        <div className="flex justify-end">
          <WebhookAddButton />
        </div>
      </div>

      <div className="w-full h-full">
        <SuspenseContainer>
          <WebhookListContent />
        </SuspenseContainer>
      </div>
    </div>
  );
};

export default WebhookContainer;
