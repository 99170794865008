import "@/styles/markdown.css";

import { VSCodeButton, VSCodeCheckbox } from "@vscode/webview-ui-toolkit/react";
import { memo, useCallback, useContext, useState } from "react";

import MilestoneContext from "@/contexts/MilestoneContext";
import ReactMarkdown from "react-markdown";
import ToS from "@/md/tos.md";

const ModalContent = ({ title, buttons, children }) => {
  return (
    <div className="flex flex-col w-full h-full focus:outline-none">
      <div className="h-16 border-b ">
        <div className="justify-between h-full px-4 flex-row-centered">
          <div>
            <p className="text-lg font-medium truncate text-foreground">{title}</p>
          </div>
        </div>
      </div>

      <div className="h-full overflow-auto text-foreground">{children}</div>

      <div className="h-16 border-t ">
        <div className="justify-end h-full px-4 space-x-2 flex-row-centered">{buttons}</div>
      </div>
    </div>
  );
};

const TermsModal = ({ _close }) => {
  const { completeMilestones, milestonesRefetch } = useContext(MilestoneContext);
  const [accepted, setAccepted] = useState(false);

  const accept = useCallback(() => {
    if (accepted) {
      completeMilestones("agree_tos", () => {
        milestonesRefetch();
        _close();
      });
    }
  }, [accepted, _close, completeMilestones, milestonesRefetch]);

  return (
    <ModalContent
      title="Terms of service"
      close={_close}
      buttons={
        <div className="justify-between w-full flex-row-centered text-foreground">
          <div className="flex-row-centered">
            <VSCodeCheckbox
              checked={accepted}
              onChange={() => {
                setAccepted(!accepted);
              }}
            />
            <label
              htmlFor="accepted_tos"
              className="block ml-2 cursor-pointer"
              onClick={() => {
                setAccepted(!accepted);
              }}
            >
              I accept the Terms of Service
            </label>
          </div>
          <VSCodeButton
            appearance="primary"
            disabled={!accepted}
            onClick={() => {
              accept();
            }}
          >
            Proceed
          </VSCodeButton>
        </div>
      }
    >
      <div className="p-4 m-2">
        <div className="markdown-body">
          <ReactMarkdown children={ToS} />
        </div>
      </div>
    </ModalContent>
  );
};

export default memo(TermsModal);
