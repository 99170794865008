import { Cell, Pie, PieChart, Tooltip } from "recharts";

import ContainerDimensions from "react-container-dimensions";
import { GraphReTooltip } from "@/components/graphs/ChartWrappers";
import { getColors } from "@/utils/colors";
import { secondsToHuman } from "@/utils/convert";
import { useMemo } from "react";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    const obj = payload[0].payload;

    return (
      <GraphReTooltip>
        <div className="text-xs">
          <div>
            {obj.displayName}:{" "}
            <span style={{ color: obj.fill, fontWeight: "bold" }}>
              {secondsToHuman(obj.totalSeconds)}
            </span>
          </div>
        </div>
      </GraphReTooltip>
    );
  }

  return null;
};

const LanguagePie = ({ data }) => {
  const colors = useMemo(() => {
    return getColors(data.length);
  }, [data]);

  return (
    <div className="w-full h-full">
      <ContainerDimensions>
        {({ width, height }) => {
          return (
            <PieChart height={height} width={width}>
              <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip />} />
              <Pie
                isAnimationActive={false}
                nameKey="displayName"
                data={data}
                innerRadius="20%"
                outerRadius="100%"
                paddingAngle={4}
                minAngle={6}
                dataKey="totalSeconds"
                stroke="var(--vscode-editorGroup-border)"
              >
                {data.map((entry, index) => {
                  return <Cell key={`cell-${index}`} fill={colors[index]} />;
                })}
              </Pie>
            </PieChart>
          );
        }}
      </ContainerDimensions>
    </div>
  );
};

export default LanguagePie;
