const WORKING_HOURS_START = "09:00:00";
const WORKING_HOURS_END = "18:00:00";

const TOTAL_WORKING_SECONDS = 18000;
const PLANNED_BREAK_OPTIONS = [
  3600, 5400, 7200, 9000, 10800, 12600, 14400, 16200, 18000, 19800, 21600, 23400, 25200, 27000,
  28800, 30600, 32400,
];

const WEEK_DAYS = [
  { id: "MONDAY" },
  { id: "TUESDAY" },
  { id: "WEDNESDAY" },
  { id: "THURSDAY" },
  { id: "FRIDAY" },
  { id: "SATURDAY" },
  { id: "SUNDAY" },
];
const SELECT_ONE_DAY_WARNING = "You must have at least one day selected.";

const RATE_HOUR = "16:00:00";
const STANDUP_HOUR = "10:00:00";

export {
  WORKING_HOURS_END,
  WORKING_HOURS_START,
  TOTAL_WORKING_SECONDS,
  PLANNED_BREAK_OPTIONS,
  WEEK_DAYS,
  SELECT_ONE_DAY_WARNING,
  RATE_HOUR,
  STANDUP_HOUR,
};
