/* eslint-disable no-unused-vars */
// import { CardContent, CardFooter, CardTitle, CardWrapper } from "@/components/cards/CardWrappers";
// import { CardHideLink, CardSnoozeLink } from "@/components/cards/CardWrappers";
import { useContext, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";

import AppContext from "@/contexts/AppContext";
import BadgeRecommendationFeedItem from "@/components/cards/BadgeRecommendationFeedItem";
import CliRecommendationFeedItem from "@/components/cards/CliRecommendationFeedItem";
import { ExclamationIcon } from "@heroicons/react/outline";
import DayNoteFeedItem from "@/components/cards/DayNoteFeedItem";
import DefaultCalendarFeedItem from "@/components/cards/DefaultCalendarFeedItem";
import ErrorGrow from "@/components/lib/ErrorGrow";
import FacepileFeedItem from "@/components/cards/FacepileFeedItem";
import FeedRecommendationFeedItem from "@/components/cards/FeedRecommendationFeedItem";
import GitBranchRecommendationFeedItem from "@/components/cards/GitBranchRecommendationFeedItem";
import InviteFriendFeedItem from "@/components/cards/InviteFriendFeedItem";
import Loading from "@/components/lib/Loading";
import PanelLink from "@/components/PanelLink";
import PerfectScoreDayFeedItem from "@/components/cards/PerfectScoreDayFeedItem";
import PersonalizeSettingsFeedItem from "@/components/cards/PersonalizeSettingsFeedItem";
import RateMyDayFeedItem from "@/components/cards/RateMyDayFeedItem";
import SharingRecommendationFeedItem from "@/components/cards/SharingRecommendationFeedItem";
import SlackRecommendationFeedItem from "@/components/cards/SlackRecommendationFeedItem";
import StreakCodingDurationFeedItem from "@/components/cards/StreakCodingDurationFeedItem";
import StreakLanguageFeedItem from "@/components/cards/StreakLanguageFeedItem";
import StreakRatingFeedItem from "@/components/cards/StreakRatingFeedItem";
import StreakScoreFeedItem from "@/components/cards/StreakScoreFeedItem";
import SummaryDailyFeedItem from "@/components/cards/SummaryDailyFeedItem";
import SummaryMonthlyFeedItem from "@/components/cards/SummaryMonthlyFeedItem";
import SummaryWeeklyFeedItem from "@/components/cards/SummaryWeeklyFeedItem";
import SuspenseContainer from "./SuspenseContainer";
import UserFeedbackFeedItem from "@/components/cards/UserFeedbackFeedItem";
import UserRecommendationFeedItem from "@/components/cards/UserRecommendationFeedItem";
import WebhookRecommendationFeedItem from "@/components/cards/WebhookRecommendationFeedItem";
import useLocalStorageState from "use-local-storage-state";
import useWindowFocus from "@/components/hooks/useWindowFocus";

// IMPORTANT: when new card types are added, we need to add the apollo types
// SF_API_TOKEN=<staging-token> node scripts/possible-types.js

const GET_FEED = gql`
  query Feed {
    feed {
      items(showDismissed: false) {
        data {
          ... on SummaryDailyFeedItem {
            day
          }
          ... on SummaryWeeklyFeedItem {
            days
          }
          ... on SummaryMonthlyFeedItem {
            days
          }
          ... on PerfectScoreDayFeedItem {
            count
          }
          ... on StreakScoreFeedItem {
            days
            count
          }
          ... on StreakCodingDurationFeedItem {
            days
            count
          }
          ... on StreakRatingFeedItem {
            days
            count
          }
          ... on StreakLanguageFeedItem {
            days
            count
            language
          }
          ... on DayNoteFeedItem {
            note {
              type
              id
              content
            }
          }
          ... on FeedItemAbstract {
            id
            time
            profile {
              id
              name
              bio
              displayName
              photoUrl
              follower
              github {
                id
                username
              }
            }
            snoozable
            owner
            shareable
            type {
              displayName
              id
              name
              category {
                id
                name
                displayName
              }
              category {
                name
                id
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

//For development purposes
// const DefaultCard = (props) => {
//   return (
//     <CardWrapper>
//       <CardTitle {...props} />
//       <CardContent>Not yet implemented</CardContent>
//       <CardFooter>
//         <CardHideLink {...props} text="Hide" />
//         <CardSnoozeLink {...props} text="Snooze" />
//       </CardFooter>
//     </CardWrapper>
//   );
// };

const cardMap = {
  perfect_score_day: <PerfectScoreDayFeedItem />,
  webhook_recommendation: <WebhookRecommendationFeedItem />,
  cli_recommendation: <CliRecommendationFeedItem />,
  slack_recommendation: <SlackRecommendationFeedItem />,
  invite_friend: <InviteFriendFeedItem />,
  facepile: <FacepileFeedItem />,
  git_branch_recommendation: <GitBranchRecommendationFeedItem />,
  sharing_recommendation: <SharingRecommendationFeedItem />,
  personalize_settings: <PersonalizeSettingsFeedItem />,
  badge_recommendation: <BadgeRecommendationFeedItem />,
  feed_recommendation: <FeedRecommendationFeedItem />,
  user_recommendation: <UserRecommendationFeedItem />,
  default_calendar: <DefaultCalendarFeedItem />,
  rate_my_day: <RateMyDayFeedItem />,
  streak_coding_duration: <StreakCodingDurationFeedItem />,
  streak_rating: <StreakRatingFeedItem />,
  streak_score: <StreakScoreFeedItem />,
  streak_language: <StreakLanguageFeedItem />,
  user_feedback: <UserFeedbackFeedItem />,
  summary_daily: <SummaryDailyFeedItem />,
  summary_weekly: <SummaryWeeklyFeedItem />,
  summary_monthly: <SummaryMonthlyFeedItem />,
  day_note: <DayNoteFeedItem />,
};

const Cards = ({ panel = false }) => {
  const { pollInterval } = useContext(AppContext);
  const [hiddenCats, setHiddenCats] = useLocalStorageState("feed-category-config", {
    defaultValue: {},
  });

  const {
    loading: feedLoading,
    error: feedError,
    data: feedData,
    refetch: feedRefetch,
  } = useQuery(GET_FEED, {
    pollInterval: pollInterval,
  });

  useWindowFocus(0, () => {
    feedRefetch();
  });

  const cardData = useMemo(() => {
    return feedData?.feed?.items?.data;
  }, [feedData]);

  const cardsFiltered = useMemo(() => {
    if (cardData) {
      return cardData.filter((card) => {
        return !hiddenCats[card?.type?.category?.name];
      });
    }
  }, [cardData, hiddenCats]);

  // console.log(cardData);

  if (feedLoading) return <Loading />;
  if (feedError) return <ErrorGrow message={feedError.message} />;

  return (
    <div className="flex flex-col w-full h-full px-4 pb-4">
      <SuspenseContainer>
        {/* <div className="justify-between pt-2 space-x-4 flex-row-centered flex-nowrap">
          {panel ? (
            <div className="flex-row-centered space-x-0.5">
              <SettingsPop>
                <CogIcon className="w-4 icon" />
              </SettingsPop>
              <RatingPop simple={true} classes="w-4 icon" />
            </div>
          ) : (
            <div />
          )}

          <FeedCategorySelect
            cardData={cardData}
            hiddenCats={hiddenCats}
            setHiddenCats={setHiddenCats}
          />
        </div> */}

        <div className="w-full border border-warning-border bg-warning-bg my-2 rounded-sm p-1 flex flex-row items-top space-x-2 text-input-fg">
          <ExclamationIcon className="w-6 h-6 min-w-min" />{" "}
          <div className="text-xs">
            <span className="font-semibold">Important:</span> Coding metrics stopped collecting data
            as of on October 30, 2023. Contact us on{" "}
            <a className="link" href="https://discord.gg/stateful" target="_blank" rel="noreferrer">
              Discord
            </a>{" "}
            if you have any questions. We apologize for any inconvenience. Historical metrics and
            notes in the VS Code UX will continue to be available for the time being.
            <br />
            <br />
            Please visit{" "}
            <a className="underline font-semibold" href={"https://runme.dev"} alt={"runme.dev"}>
              https://runme.dev
            </a>{" "}
            to learn how the team is making{" "}
            <a className="underline font-semibold" href={"https://github.com/stateful/runme"}>
              Runme (Open Source)
            </a>{" "}
            run your Markdown docs.
          </div>
        </div>

        {panel && (
          <div className="pt-3">
            <PanelLink
              linkText="📈 Looking for your metrics? →"
              uriState={{ onView: { panel: "stateful-metrics", name: "week" } }}
              browserUrl="/app/metrics"
            />
          </div>
        )}

        {/* <div className="w-full h-full">
          {cardsFiltered && cardsFiltered.length === 0 && (
            <div className="w-full p-4 text-center">You're all caught up! 👍</div>
          )}
          <div className="gap-3 md:columns-2 columns-1 2xl:columns-3 3xl:columns-4">
            {cardsFiltered &&
              cardsFiltered.map((item) => {
                if (cardMap[item?.type?.name]) {
                  return (
                    <Fragment key={item.id}>
                      {cloneElement(cardMap[item.type.name], {
                        item: item,
                        feedRefetch: feedRefetch,
                      })}
                    </Fragment>
                  );
                }
                return false;
                // else { // for debuggin
                //   return (
                //     <span key={item.id}>
                //       <DefaultCard item={item} feedRefetch={feedRefetch} />
                //     </span>
                //   );
                // }
              })}
          </div>
        </div> */}
      </SuspenseContainer>
    </div>
  );
};

export default Cards;
