import MediumSwitch from "@/components/MediumSwitch";
import { memo } from "react";

const LabelToggle = ({ label, enabled, setEnabled }) => {
  return (
    <div className="flex items-center space-x-2">
      <MediumSwitch enabled={enabled} setEnabled={setEnabled} />
      <div className="text-xs">{label}</div>
    </div>
  );
};

export default memo(LabelToggle);
