import { cloneElement, memo, useState } from "react";

import PopWrapper from "@/components/pops/PopWrapper";

const PopGeneric = ({ children, target }) => {
  const [open, setOpen] = useState(false);

  return (
    <PopWrapper open={open} setOpen={setOpen} contentChildren={children}>
      <div
        className="h-full cursor-pointer"
        onMouseOver={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
      >
        {cloneElement(target, {})}
      </div>
    </PopWrapper>
  );
};

export default memo(PopGeneric);
