import moment from "moment";
const humanizeDuration = require("humanize-duration");

const humanOpts = {
  round: true,
  spacer: "",
  delimiter: " ",
  language: "shortEn",
  units: ["y", "mo", "d", "h", "m"],
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
};

let msToMin = (ms) => {
  if (!ms) {
    return 0;
  } else {
    return moment.duration(ms.toFixed(2)).asMinutes();
  }
};

let secondsToMinutes = (seconds) => {
  if (!seconds) {
    return 0;
  } else {
    return moment.duration(seconds.toFixed(2)).asMinutes();
  }
};

let minToHuman = (minutes) => {
  return humanizeDuration(moment.duration(minutes, "minutes").asMilliseconds(), humanOpts);
};

let hrsToHuman = (hours) => {
  return humanizeDuration(moment.duration(hours, "hours").asMilliseconds(), humanOpts);
};

let msToHuman = (ms) => {
  return humanizeDuration(ms, humanOpts);
};

let secondsToHuman = (seconds) => {
  return humanizeDuration(moment.duration(seconds, "seconds").asMilliseconds(), humanOpts);
};

export { msToMin, minToHuman, msToHuman, hrsToHuman, secondsToHuman, secondsToMinutes };
