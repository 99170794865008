import { ClockIcon, FireIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";

import DaysGraph from "@/components/graphs/DaysGraph";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import ModalBig from "@/components/modals/ModalBig";
import { StarIcon } from "@heroicons/react/solid";
import SummaryReportRangeModal from "@/components/modals/SummaryReportRangeModal";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import flatten from "lodash/flatten";
import { secondsToHuman } from "@/utils/convert";
import { useDialog } from "@/contexts/DialogContext";
import { useMemo } from "react";

const GET_DAY_DURATIONS = gql`
  query getDayDurations($startDate: Date!, $endDate: Date!) {
    days(startDate: $startDate, endDate: $endDate, pageSize: 30) {
      data {
        codingDuration {
          totalSeconds
        }
        date
      }
    }
  }
`;

const GET_DAY_RANGE_SUMMARY = gql`
  query dayRangeSummary($startDate: Date!, $endDate: Date!) {
    dayRangeSummary(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      codingDuration {
        totalSeconds
      }
      dayCount
      languages {
        data {
          codingDuration {
            totalSeconds
          }
          displayName
          id
        }
      }
      projects {
        data {
          codingDuration {
            totalSeconds
          }
          displayName
          files {
            data {
              codingDuration {
                totalSeconds
              }
              name
              path
            }
          }
          gitBranches {
            data {
              codingDuration {
                totalSeconds
              }
              name
            }
          }
          name
          shortName
          id
        }
      }
      rating {
        mean
      }
      score {
        mean
      }
    }
  }
`;

const ReportEntry = ({ title, description, children }) => {
  return (
    <div className="flex flex-col px-2 py-1 my-1 space-y-1 border rounded max-w-1/2 bg-sideBarBackground min-w-1/3">
      <div className="flex flex-row justify-between space-x-1 text-xs">
        <div className="font-medium truncate">{title}</div>
        {description && <div className="text-descriptions">{description}</div>}
      </div>
      {children}
    </div>
  );
};

const DayRangeSummary = ({ startDate, endDate }) => {
  const { addDialog } = useDialog();

  const {
    loading: durationsLoading,
    error: durationsError,
    data: durationsData,
  } = useQuery(GET_DAY_DURATIONS, {
    skip: !startDate || !endDate,
    variables: { startDate: startDate, endDate: endDate },
  });

  const {
    loading: rangeLoading,
    error: rangeError,
    data: rangeData,
  } = useQuery(GET_DAY_RANGE_SUMMARY, {
    skip: !startDate || !endDate,
    variables: { startDate: startDate, endDate: endDate },
  });

  const dayRangeSummary = rangeData?.dayRangeSummary;
  const activity = dayRangeSummary?.codingDuration?.totalSeconds || null;
  const score = dayRangeSummary?.score?.mean || null;
  const rating = dayRangeSummary?.rating?.mean || null;

  const languages = useMemo(() => {
    const langData = dayRangeSummary?.languages?.data;
    if (langData) {
      const langArr = langData.map((lang) => {
        return [lang?.displayName, lang?.codingDuration?.totalSeconds];
      });

      return langArr.sort((a, b) => b[1] - a[1]);
    }
  }, [dayRangeSummary]);

  const projects = useMemo(() => {
    const projectData = dayRangeSummary?.projects?.data;
    if (projectData) {
      const projectArr = projectData.map((project) => {
        return [project?.displayName, project?.codingDuration?.totalSeconds];
      });

      return projectArr.sort((a, b) => b[1] - a[1]);
    }
  }, [dayRangeSummary]);

  const branches = useMemo(() => {
    const arr = dayRangeSummary?.projects?.data;
    if (arr) {
      const branchData = flatten(
        arr.map((project) => {
          return project?.gitBranches?.data;
        })
      );
      const branchArr = branchData.map((branch) => {
        return [branch?.name, branch?.codingDuration?.totalSeconds];
      });
      return branchArr.sort((a, b) => b[1] - a[1]);
    }
  }, [dayRangeSummary]);

  const files = useMemo(() => {
    const arr = dayRangeSummary?.projects?.data;
    if (arr) {
      const fileData = flatten(
        arr.map((project) => {
          return project.files.data;
        })
      );
      const filesArr = fileData.map((file) => {
        return [file?.name, file?.codingDuration?.totalSeconds];
      });
      return filesArr.sort((a, b) => b[1] - a[1]);
    }
  }, [dayRangeSummary]);

  const durations = useMemo(() => {
    const arr = durationsData?.days?.data;
    if (arr) {
      const dayArr = arr.map((day) => {
        return [day.date, day?.codingDuration?.totalSeconds || 0];
      });
      return dayArr.sort((a, b) => b[1] - a[1]);
    }
  }, [durationsData]);

  const durationsObj = useMemo(() => {
    if (durations) {
      return durations
        .map((entry) => {
          return {
            day: entry[0],
            duration: entry[1],
          };
        })
        .sort(function (a, b) {
          return new Date(b.day) - new Date(a.day);
        })
        .reverse();
    }
  }, [durations]);

  if (rangeLoading) return <Loading />;
  if (rangeError) return <ErrorGrow message={rangeError.message} />;
  if (durationsLoading) return <Loading />;
  if (durationsError) return <ErrorGrow message={durationsError.message} />;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap space-x-1 overflow-auto">
        {activity && (
          <ReportEntry title="Activity">
            <div className="flex-row-centered">
              <div>{secondsToHuman(activity)}</div>
              <div>
                <ClockIcon className="w-4" />
              </div>
            </div>
          </ReportEntry>
        )}

        {score && (
          <ReportEntry title="Score">
            <div className="flex-row-centered">
              <div>{score}</div>
              <div>
                <FireIcon className="w-4 " />
              </div>
            </div>
          </ReportEntry>
        )}
        {rating && (
          <ReportEntry title="Rating">
            <div>
              <div className="flex-row-centered">
                <div>{rating}/5</div>
                <div>
                  <StarIcon className="w-4 text-yellow-500" />
                </div>
              </div>
            </div>
          </ReportEntry>
        )}
        {languages && (
          <ReportEntry title="Language" description={`1 of ${languages.length}`}>
            <div className="break-all">
              {languages[0][0]} ({secondsToHuman(languages[0][1])})
            </div>
          </ReportEntry>
        )}
        {projects && (
          <ReportEntry title="Project" description={`1 of ${projects.length}`}>
            <div className="break-all">
              {projects[0][0]} ({secondsToHuman(projects[0][1])})
            </div>
          </ReportEntry>
        )}
        {branches && (
          <ReportEntry title="Branch" description={`1 of ${branches.length}`}>
            <div className="break-all">
              {branches[0][0]} ({secondsToHuman(branches[0][1])})
            </div>
          </ReportEntry>
        )}
        {files && (
          <ReportEntry title="File" description={`1 of ${files.length}`}>
            <div className="break-all">
              {files[0][0]} ({secondsToHuman(files[0][1])})
            </div>
          </ReportEntry>
        )}
        {durations && durationsObj && (
          <ReportEntry title="Active days" description={durations.length}>
            <div className="h-14" style={{ minWidth: "200px" }}>
              <DaysGraph data={durationsObj} />
            </div>
          </ReportEntry>
        )}
      </div>

      <div className="pt-3">
        <VSCodeLink
          onClick={() => {
            addDialog({
              Component: (
                <ModalBig>
                  <SummaryReportRangeModal startDate={startDate} endDate={endDate} />
                </ModalBig>
              ),
            });
          }}
        >
          View the full report →
        </VSCodeLink>
      </div>
    </div>
  );
};

export default DayRangeSummary;
