import "moment-timezone";

import moment from "moment";

export function isWorking(day) {
  if (!day.hasOwnProperty("workStartTime") || !day.hasOwnProperty("workEndTime")) {
    return false;
  }
  return day.workStartTime !== null && day.workEndTime !== null;
}

export function localizeDays(days, tz) {
  return days?.map((day) => {
    if (day.standupTimezone && day.standupTime && tz) {
      day.standupTime = moment
        .tz(day.standupTime, "HH:mm:ss", day.standupTimezone)
        .tz(tz)
        .format("HH:mm:ss");
      day.standupTimezone = tz;
    }
    return day;
  });
}

export function localizeStandupTime(day, standupTz) {
  //if the day is toggled off, standupTime becomes
  //null and fails to convert
  return day.standupTime
    ? moment.tz(day.standupTime, "HH:mm:ss", day.standupTimezone).tz(standupTz).format("HH:mm:ss")
    : null;
}

export function parseDayTime(time) {
  return moment(time, "HH:mm:ss").format("h:mm a");
}

export function parseTotalWorkingHours(seconds) {
  const duration = moment.duration(seconds, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();

  let retVal = "";
  if (hours > 0) {
    retVal += hours === 1 ? `${hours} hour` : `${hours} hours`;
  }
  if (minutes > 0) {
    retVal += retVal.length ? ` & ${minutes} minutes` : `${minutes} minutes`;
  }

  return retVal;
}

export function hasSelectedDays(days) {
  return Object.entries(days).some(([_, enabled]) => enabled);
}

export function getAvailableHoursCoding(daySettings) {
  const { targetCodingDurationSeconds, targetRestCount, targetRestDurationSeconds } = daySettings;
  const breakTotalSeconds = targetRestCount * targetRestDurationSeconds;

  return parseTotalWorkingHours(targetCodingDurationSeconds - breakTotalSeconds);
}

export function parsePlannedBreaksDuration(seconds) {
  const duration = moment.duration(seconds, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();

  let retVal = `${hours}`;
  if (minutes < 10) {
    retVal += `:0${minutes}`;
  } else {
    retVal += `:${minutes}`;
  }

  // if (hours === 1 && minutes === 0) {
  //   retVal += ` hour`;
  // } else {
  //   retVal += ` hours`;
  // }

  return retVal;
}

export function getWeekDayLabel(id) {
  switch (id) {
    case "MONDAY":
      return "Mon";
    case "TUESDAY":
      return "Tue";
    case "WEDNESDAY":
      return "Wed";
    case "THURSDAY":
      return "Thu";
    case "FRIDAY":
      return "Fri";
    case "SATURDAY":
      return "Sat";
    default:
      return "Sun";
  }
}
