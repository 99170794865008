import { Arrow, useLayer } from "react-laag";
import { cloneElement, memo, useContext, useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import AppContext from "@/contexts/AppContext";
import BranchScoreContributor from "@/components/score/BranchScoreContributor";
import BreaksScoreContributor from "@/components/score/BreaksScoreContributor";
import DefaultScoreContributor from "@/components/score/DefaultScoreContributor";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import TargetActivityScoreContributor from "@/components/score/TargetActivityScoreContributor";
import WorkingHoursScoreContributor from "@/components/score/WorkingHoursScoreContributor";
import moment from "moment-timezone";

const GET_TODAY_SCORE = gql`
  query GetDayScore($date: Date!) {
    day(date: $date) {
      date
      score
      scoreBreakdown {
        points
        contributors {
          name
          displayName
          points
          possiblePoints
          hints {
            name
            unit
            targetValue
            currentValue
          }
        }
      }
    }
  }
`;

const contribMap = {
  target_activity_duration: <TargetActivityScoreContributor />,
  target_work_hours: <WorkingHoursScoreContributor />,
  target_rest: <BreaksScoreContributor />,
  penalize_default_branch_usage: <BranchScoreContributor />,
};

export const ScoreBreakdown = ({ date }) => {
  const { pollInterval } = useContext(AppContext);

  const {
    refetch: scoreRefetch,
    loading: scoreLoading,
    error: scoreError,
    data: scoreData,
  } = useQuery(GET_TODAY_SCORE, {
    pollInterval: pollInterval,
    variables: { date: date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD") },
  });

  useEffect(() => {
    scoreRefetch();
  }, [scoreRefetch]);

  const contributors = useMemo(() => {
    return scoreData?.day?.scoreBreakdown?.contributors;
  }, [scoreData]);

  if (scoreLoading) {
    return <Loading />;
  }

  if (scoreError) return <ErrorGrow message={scoreError.message} />;

  // const contributors = SCORE_DUMP_OBJ.data.day?.scoreBreakdown?.contributors;

  return (
    <div className="flex flex-col w-full h-full divide-y">
      {!contributors && <div>No details available.</div>}

      {contributors &&
        contributors.map((contributor) => {
          if (contribMap[contributor.name]) {
            return (
              <div className="flex flex-col py-2" key={contributor.name}>
                {cloneElement(contribMap[contributor.name], {
                  contributor: contributor,
                  scoreRefetch: scoreRefetch,
                })}
              </div>
            );
          } else {
            return (
              <div className="flex flex-col py-2" key={contributor.name}>
                <DefaultScoreContributor contributor={contributor} />
              </div>
            );
          }
        })}
    </div>
  );
};

const ScorePop = ({ children, date }) => {
  const [isOpen, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    auto: true,
    triggerOffset: 20,
    onOutsideClick: () => setOpen(false),
  });

  return (
    <>
      <div
        {...triggerProps}
        className="cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </div>

      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{ ...layerProps.style, zIndex: 100 }}
            className="max-w-sm px-2 py-1 mx-1 mt-2 text-sm bg-opacity-0 border rounded-md shadow-lg bg-background text-foreground"
          >
            <ScoreBreakdown date={date} />
            <Arrow
              {...arrowProps}
              size={10}
              roundness={0}
              borderWidth={1}
              borderColor="var(--vscode-panel-border)"
              backgroundColor="var(--vscode-editor-background)"
            />
          </div>
        )}
    </>
  );
};

export default memo(ScorePop);
