import { ClipboardCheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Loading from "@/components/lib/Loading";
import UserContext from "@/contexts/UserContext";
import { VSCodeTextField } from "@vscode/webview-ui-toolkit/react";

const BadgeContainer = () => {
  const { user, userIsLoading } = useContext(UserContext);
  const username = user?.user?.github?.username;
  const [statusCopied, setStatusCopied] = useState(false);
  const [focusCopied, setFocusCopied] = useState(false);

  const label = `Status for @${username}`;
  const url = `https://app.stateful.com/@${username}`;
  const statusBadgeUrl = `https://badge.stateful.com/${username}/status.svg`;
  const focusBadgeUrl = `https://badge.stateful.com/${username}/dnd.svg`;

  const statusEmbed = `[![${label}](${statusBadgeUrl})](${url})`;
  const focusEmbed = `[![${label}](${focusBadgeUrl})](${url})`;

  if (userIsLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="text-description">
        SVG URL's to embed your coding or focus status on Github or anywhere else you want. It uses
        your coding status configuration from above.
      </div>
      <div>
        <hr />
      </div>
      <div className="flex flex-col space-y-2">
        <div className="font-medium">Status</div>
        <div>
          <img src={statusBadgeUrl} alt={label} />
        </div>
        <div className="w-full flex-row-centered">
          <VSCodeTextField type="text" value={statusEmbed} className="w-full" readonly />
          <div className="px-2 cursor-pointer text-covey-500">
            <CopyToClipboard onCopy={() => setStatusCopied(true)} text={statusEmbed}>
              {statusCopied ? (
                <ClipboardCheckIcon className="flex-shrink-0 w-6" />
              ) : (
                <ClipboardIcon className="flex-shrink-0 w-6" />
              )}
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <div>
        <hr />
      </div>
      <div className="flex flex-col space-y-2">
        <div className="font-medium">Focus</div>
        <div>
          <img src={focusBadgeUrl} alt={label} />
        </div>
        <div className="w-full flex-row-centered">
          <VSCodeTextField type="text" value={focusEmbed} className="w-full" readonly />
          <div className="px-2 cursor-pointer text-covey-500">
            <CopyToClipboard onCopy={() => setFocusCopied(true)} text={focusEmbed}>
              {focusCopied ? (
                <ClipboardCheckIcon className="flex-shrink-0 w-6" />
              ) : (
                <ClipboardIcon className="flex-shrink-0 w-6" />
              )}
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgeContainer;
