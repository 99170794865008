import "moment-timezone";

import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import DailyStandupStartTimePicker from "@/components/DailyStandupStartTimePicker";
import Dots from "@/components/lib/Dots";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import MilestoneContext from "@/contexts/MilestoneContext";
import SettingsContext from "@/contexts/SettingsContext";
import TimePicker from "@/components/TimePicker";
import TimezoneSelector from "@/components/TimezoneSelector";
import UserContext from "@/contexts/UserContext";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import { isWorking } from "@/utils/personalize";
import { localizeStandupTime } from "@/utils/personalize";

const TimezoneWrapper = ({ containerRef, selectedTz, onStandupTzChange }) => {
  return (
    <TimezoneSelector
      selectedTimezone={selectedTz}
      setSelectedTimezone={onStandupTzChange}
      containerRef={containerRef}
    />
  );
};

const TimezoneContainer = ({ standupTz, onStandupTzChange }) => {
  const { dayData, dayLoading, dayError } = useContext(SettingsContext);
  const containerRef = useRef();

  const userTz = useMemo(() => dayData?.user?.timezone, [dayData]);

  if (dayLoading) return <Loading />;
  if (dayError) return <ErrorGrow message={dayError.message} />;

  return (
    <div ref={containerRef}>
      <TimezoneWrapper
        selectedTz={standupTz || userTz}
        containerRef={containerRef}
        onStandupTzChange={onStandupTzChange}
      />
    </div>
  );
};

const SimplePickerContainer = ({ standupTz }) => {
  const { completeMilestones } = useContext(MilestoneContext);
  const {
    dayLoading,
    dayError,
    daySettings,
    daysSettings,
    updateSettingsLoading,
    saveUserDayWorkingSettings,
  } = useContext(SettingsContext);

  const workingDays = useMemo(() => {
    if (daysSettings) {
      return daysSettings?.filter((day) => isWorking(day));
    }
  }, [daysSettings]);

  const standupTime = useMemo(() => {
    if (daySettings && daySettings.standupTime) {
      if (daySettings.standupTimezone) {
        return localizeStandupTime(daySettings, standupTz);
      }
      // convert using the users timezone
      // which has been set in standupTz
      const patchedDay = { ...daySettings, standupTimezone: standupTz };
      return localizeStandupTime(patchedDay, standupTz);
    }
    // if this day is globally disabled
    // find and show standup time
    // from a non disabled day
    return localizeStandupTime(workingDays?.[0], standupTz);
  }, [daySettings, standupTz, workingDays]);

  const onChange = (time) => {
    saveUserDayWorkingSettings({ standupTime: time, standupTimezone: standupTz });
    completeMilestones("configure_standup_times");
  };

  if (dayLoading) {
    return <Loading />;
  }
  if (dayError) {
    return <ErrorGrow message={dayError.message} />;
  }

  return (
    <div className="flex-row-centered">
      <div>
        <TimePicker value={standupTime} onChange={onChange} />
      </div>
      <div>{updateSettingsLoading && <Dots height={6} />}</div>
    </div>
  );
};

const CustomPickerContainer = ({ standupTz }) => {
  const { completeMilestones } = useContext(MilestoneContext);

  const { daysLoading, daysError, daysSettings, saveUserDayWorkingSettings } =
    useContext(SettingsContext);

  const onChange = (day) => {
    saveUserDayWorkingSettings({
      dayOfWeeks: [day.dayOfWeek],
      standupTime: localizeStandupTime(day, standupTz),
      standupTimezone: standupTz,
    });
    completeMilestones("configure_standup_times");
  };

  if (daysLoading) {
    return <Loading />;
  }
  if (daysError) {
    return <ErrorGrow message={daysError.message} />;
  }

  return (
    <div>
      <DailyStandupStartTimePicker
        days={daysSettings}
        standupTimezone={standupTz}
        onChange={onChange}
      />
    </div>
  );
};

const TeamStandUpTime = () => {
  const {
    updateSettingsLoading,
    daySettings,
    dayLoading,
    dayError,
    daysSettings,
    daysLoading,
    daysError,
    saveUserDayWorkingSettings,
  } = useContext(SettingsContext);
  const { user, userIsLoading, userError } = useContext(UserContext);

  const [isCustomStandup, setIsCustomStandup] = useState(null);
  const [standupTz, setStandupTz] = useState(null);
  const userTz = user?.user?.timezone;

  const workingDays = useMemo(() => {
    if (daysSettings) {
      return daysSettings?.filter((day) => isWorking(day));
    }
  }, [daysSettings]);

  //set the simple or custom ux
  useEffect(() => {
    if (daysSettings && isCustomStandup === null) {
      const standupTimes = daysSettings
        .map((day) => day.standupTime)
        .filter((time) => time !== null);

      //extract the days that are disabled in the
      //standup picker, but not globally disabled
      const disabledDays = daysSettings.filter(
        (day) => day.standupTime === null && day.workStartTime !== null
      );

      const same = standupTimes.every((val, i, arr) => val === arr?.[0]);

      //if all the days have the same time
      //and none are disabled at the standup level
      if (same && disabledDays.length === 0) {
        setIsCustomStandup(false);
      } else {
        setIsCustomStandup(true);
      }
    }
  }, [daysSettings, isCustomStandup]);

  //set standupTimezone
  //if the standupTz hasn't been set
  //otherwise set userTz as backup
  useEffect(() => {
    if (daySettings && !standupTz) {
      setStandupTz(daySettings?.standupTimezone || workingDays?.[0]?.standupTimezone || userTz);
    }
  }, [userTz, daySettings, standupTz, workingDays]);

  const onStandupTzChange = useCallback(
    (tz) => {
      setStandupTz(tz.value);
    },
    [setStandupTz]
  );

  //if tz is changed, iterate all days, convert and save
  useEffect(() => {
    if (daysSettings && standupTz) {
      daysSettings.forEach((day) => {
        if (isWorking(day)) {
          const standupTime = day.standupTimezone
            ? localizeStandupTime(day, standupTz)
            : localizeStandupTime({ ...day, standupTimezone: standupTz }, standupTz);

          saveUserDayWorkingSettings({
            dayOfWeeks: [day.dayOfWeek],
            standupTimezone: standupTz,
            standupTime: standupTime,
          });
        }
      });
    }
  }, [standupTz, daysSettings, saveUserDayWorkingSettings]);

  //when we switch from custom back to simple,
  //set all the working days details to today
  const setTodayForAllWorking = useCallback(() => {
    if (daySettings && daysSettings) {
      // if today is a disabled day, grab a non disabled day
      const todayStandupTime = daySettings.standupTime || workingDays?.[0].standupTime;
      const todayStandupTimezone = daySettings.standupTimezone || workingDays?.[0].standupTimezone;
      daysSettings.forEach((day) => {
        if (isWorking(day)) {
          saveUserDayWorkingSettings({
            dayOfWeeks: [day.dayOfWeek],
            standupTimezone: todayStandupTimezone,
            standupTime: todayStandupTime,
          });
        }
      });
    }
  }, [daySettings, daysSettings, saveUserDayWorkingSettings, workingDays]);

  if (userIsLoading || dayLoading || daysLoading || !standupTz) {
    return <Loading />;
  }

  if (userError) {
    return <ErrorGrow message={userError.message} />;
  }
  if (dayError) {
    return <ErrorGrow message={dayError.message} />;
  }
  if (daysError) {
    return <ErrorGrow message={daysError.message} />;
  }

  return (
    <div className="flex flex-col w-full space-y-3">
      <div className="text-description">
        Configure your daily standup time so that we can generate your reports at the ideal time to
        be helpful.
      </div>

      <div className="flex flex-row space-x-0.5 justify-between items-center">
        <div className="space-x-2 flex-row-centered">
          <div>Timezone</div>
          <div>{updateSettingsLoading && <Dots height={6} />}</div>
        </div>
        <div className="text-sm truncate text-description">
          {Intl.DateTimeFormat().resolvedOptions().timeZone} (current)
        </div>
      </div>

      <TimezoneContainer standupTz={standupTz} onStandupTzChange={onStandupTzChange} />

      <div className="flex-row-centered">
        <div>Daily time</div>

        <div>{updateSettingsLoading && <Dots height={6} />}</div>
      </div>

      {isCustomStandup ? (
        <div className="flex-row-centered">
          <CustomPickerContainer standupTz={standupTz} />
        </div>
      ) : (
        <div className="flex-row-centered">
          <SimplePickerContainer standupTz={standupTz} />
        </div>
      )}
      <div>
        <VSCodeLink
          onClick={() => {
            //going from custom to non custom
            if (isCustomStandup) {
              //set all days to todays standupTime and standupTimezone
              setTodayForAllWorking();
            }
            setIsCustomStandup(!isCustomStandup);
          }}
        >
          {isCustomStandup ? <>Simple schedule →</> : <>Custom days →</>}
        </VSCodeLink>
      </div>
    </div>
  );
};

export default memo(TeamStandUpTime);
