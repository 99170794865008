import {
  BeakerIcon,
  CalendarIcon,
  ClockIcon,
  CollectionIcon,
  DocumentTextIcon,
  FireIcon,
  FolderIcon,
  LightBulbIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { cloneElement, memo, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import DateSelector from "@/components/pops/DateSelector";
import ErrorGrow from "@/components/lib/ErrorGrow";
import HoursDetailedGraph from "@/components/graphs/HoursDetailedGraph";
import LanguagePie from "@/components/graphs/LanguagePie";
import Loading from "@/components/lib/Loading";
import ModalTitle from "@/components/modals/ModalTitle";
import NoDataError from "@/components/NoDataError";
import RatingPop from "@/components/pops/RatingPop";
import ScorePop from "@/components/pops/ScorePop";
import clsx from "clsx";
import flatten from "lodash/flatten";
import moment from "moment";
import { secondsToHuman } from "@/utils/convert";
import { useMemo } from "react";

const GET_SUMMARY_FOR_DAY = gql`
  query getSummaryForDay($date: Date!) {
    day(date: $date) {
      timezone
      date
      score
      codingDuration {
        totalSeconds
      }
      rating
      languages {
        data {
          codingDuration {
            totalSeconds
          }
          displayName
          id
        }
      }
      projects {
        data {
          codingDuration {
            totalSeconds
          }
          displayName
          name
          id
          shortName
          gitBranches {
            data {
              name
              codingDuration {
                totalSeconds
              }
              files {
                data {
                  codingDuration {
                    totalSeconds
                  }
                  name
                  path
                }
              }
            }
          }
          files {
            data {
              codingDuration {
                totalSeconds
              }
              name
              path
            }
          }
        }
      }
      hours {
        data {
          time
          codingDuration {
            totalSeconds
          }
        }
      }
    }
  }
`;

const ExpandSection = ({ children, title, count, icon, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className="border rounded-sm">
      <div className="flex flex-col">
        <div
          className={clsx(
            { "border-b": open },
            "flex-row-centered justify-between p-2 cursor-pointer"
          )}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="flex-row-centered">
            {icon && <div>{cloneElement(icon, { className: "w-5 text-description" })}</div>}
            <div className="font-medium">{title}</div>
            <div>({count})</div>
          </div>
          <div>
            {open ? (
              <ChevronUpIcon className="w-5 icon" />
            ) : (
              <ChevronDownIcon className="w-5 icon" />
            )}
          </div>
        </div>
        <div
          className={clsx({
            hidden: !open,
            block: open,
          })}
        >
          <div className="w-full p-2 pt-3 space-x-2 flex-row-centered">{children}</div>
        </div>
      </div>
    </div>
  );
};

const ReportEntry = ({ title, description, children }) => {
  return (
    <div className="flex flex-col px-2 py-1 my-1 space-y-1 border rounded max-w-1/2 bg-background min-w-1/3">
      <div className="flex flex-row justify-between space-x-1 text-xs">
        <div className="font-medium truncate">{title}</div>
        {description && <div className="text-descriptions">{description}</div>}
      </div>
      {children}
    </div>
  );
};

const FileTree = ({ files }) => {
  return (
    <div className="flex flex-col pl-4">
      {files &&
        files.map((file) => {
          return (
            <div className="flex flex-col" key={file.path}>
              <div className="flex flex-row justify-between w-full space-x-2">
                <div className="hidden overflow-x-auto vmd:block">{file.path}</div>
                <div className="block overflow-x-auto vmd:hidden">{file.name}</div>
                <div>{secondsToHuman(file.codingDuration.totalSeconds)}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const BranchTree = ({ gitBranches }) => {
  return (
    <div className="flex flex-col pl-4">
      {gitBranches &&
        gitBranches.map((branch) => {
          return (
            <div className="flex flex-col py-2" key={branch.name}>
              <div className="flex flex-row justify-between space-x-2 font-medium border-b">
                <div className="overflow-x-auto">{branch.name}</div>
                <div>{secondsToHuman(branch.codingDuration.totalSeconds)}</div>
              </div>
              <div>
                <FileTree files={branch.files.data} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

const ProjectTree = ({ projects }) => {
  return (
    <div className="flex flex-col w-full">
      {projects &&
        projects.map((project) => {
          return (
            <div className="flex flex-col py-2" key={project.name}>
              <div className="flex flex-row justify-between space-x-2 font-semibold">
                <div className="overflow-x-auto">{project.name}</div>
                <div>{secondsToHuman(project.codingDuration.totalSeconds)}</div>
              </div>
              <div>
                <BranchTree gitBranches={project.gitBranches.data} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

const SummaryContent = ({ date }) => {
  const {
    data: summaryData,
    loading: summaryLoading,
    error: summaryError,
  } = useQuery(GET_SUMMARY_FOR_DAY, {
    variables: { date: moment(date).format("YYYY-MM-DD") },
  });

  const activity = summaryData?.day?.codingDuration?.totalSeconds || null;
  const score = summaryData?.day?.score || null;
  const rating = summaryData?.day?.rating || null;
  const timezone = summaryData?.day?.timezone || null;
  const projectTree = summaryData?.day?.projects.data;

  const languages = useMemo(() => {
    const arr = summaryData?.day?.languages?.data;
    if (arr) {
      const languageArray = arr.map((language) => {
        return [language.displayName, language?.codingDuration?.totalSeconds || 0];
      });
      return languageArray.sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const languageData = useMemo(() => {
    const arr = summaryData?.day?.languages?.data;

    if (arr) {
      return arr.map((lang) => {
        return {
          ...lang,
          totalSeconds: lang.codingDuration.totalSeconds,
        };
      });
    }
  }, [summaryData]);

  const projects = useMemo(() => {
    const arr = summaryData?.day?.projects?.data;
    if (arr) {
      const projectArray = arr.map((project) => {
        return [project.displayName, project?.codingDuration?.totalSeconds || 0];
      });
      return projectArray.sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const branches = useMemo(() => {
    const arr = summaryData?.day?.projects?.data;
    if (arr) {
      return flatten(
        arr.map((project) => {
          return project.gitBranches.data.map((branch) => {
            return [branch.name, branch.codingDuration.totalSeconds || 0];
          });
        })
      ).sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const files = useMemo(() => {
    const projects = summaryData?.day?.projects?.data;
    if (projects) {
      const files = projects.map((project) => {
        return flatten(project.files.data);
      });
      const fileObjArr = flatten(files);
      const fileArray = fileObjArr.map((file) => {
        return [file.name, file?.codingDuration?.totalSeconds || 0];
      });

      return fileArray.sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const hours = useMemo(() => {
    const arr = summaryData?.day?.hours?.data;
    if (arr) {
      const hoursArray = arr.map((hour) => {
        return [
          moment(hour.time).local().format("HH"),
          hour?.codingDuration?.totalSeconds || 0,
          hour.time,
        ];
      });
      return hoursArray.sort((a, b) => b[0] - a[0]).reverse();
    }
  }, [summaryData]);

  const hoursObj = useMemo(() => {
    if (hours) {
      return hours.map((hour) => {
        return {
          time: hour[2],
          minutes: moment.duration(hour[1], "seconds").asMinutes(),
        };
      });
    }
  }, [hours]);

  if (summaryLoading) {
    return <Loading />;
  }
  if (summaryError) {
    return <ErrorGrow error={summaryError} />;
  }

  if (!summaryData || !summaryData.day) {
    return <NoDataError />;
  }

  return (
    <div className="flex flex-col pb-2 space-y-1">
      <div className="flex flex-row flex-wrap space-x-1 overflow-auto">
        {timezone && (
          <ReportEntry title="Timezone">
            <div className="flex-row-centered">
              <div>{timezone}</div>
              <div>
                <ClockIcon className="w-4" />
              </div>
            </div>
          </ReportEntry>
        )}
        {activity && (
          <ReportEntry title="Activity">
            <div className="flex-row-centered">
              <div>{secondsToHuman(activity)}</div>
              <div>
                <ClockIcon className="w-4" />
              </div>
            </div>
          </ReportEntry>
        )}

        {score && (
          <ReportEntry title="Score">
            <div className="flex-row-centered">
              <div>
                <ScorePop date={date}>{score}</ScorePop>
              </div>
              <div>
                <FireIcon className="w-4 " />
              </div>
            </div>
          </ReportEntry>
        )}

        <ReportEntry title="Rating">
          <div>
            {rating ? (
              <div className="flex-row-centered">
                <div>{rating}/5</div>
                <div>
                  <RatingPop
                    simple={true}
                    startDate={moment(date).format("YYYY-MM-DD")}
                    classes="w-4"
                  />
                </div>
              </div>
            ) : (
              <div className="flex-row-centered">
                <div>😝</div>
                <RatingPop
                  simple={true}
                  startDate={moment(date).format("YYYY-MM-DD")}
                  classes="w-4"
                />
              </div>
            )}
          </div>
        </ReportEntry>
      </div>

      <ExpandSection
        title="Active hours"
        defaultOpen={true}
        count={hours.length}
        icon={<LightBulbIcon />}
      >
        {hours && hoursObj && (
          <div className="w-full h-48">
            <HoursDetailedGraph data={hoursObj} />
          </div>
        )}
      </ExpandSection>

      <div className="flex flex-col w-full space-y-2">
        {languageData && languages && (
          <ExpandSection title="Languages" count={languages.length} icon={<BeakerIcon />}>
            <div className="flex flex-row w-full h-full">
              <div className="w-1/2 h-32">
                <LanguagePie data={languageData} />
              </div>
              <div className="w-1/2 flex flex-col space-y-0.5 justify-center">
                {languages.map((language) => {
                  return (
                    <div key={language[0]}>
                      {language[0]} ({secondsToHuman(language[1])})
                    </div>
                  );
                })}
              </div>
            </div>
          </ExpandSection>
        )}

        <ExpandSection title="Projects" count={projects.length} icon={<FolderIcon />}>
          <div className="flex flex-col w-full space-y-1">
            {projects.map((project) => {
              return (
                <div className="flex flex-row justify-between w-full" key={project[0]}>
                  <div>{project[0]}</div> <div>{secondsToHuman(project[1])}</div>
                </div>
              );
            })}
          </div>
        </ExpandSection>

        <ExpandSection title="Branches" count={branches.length} icon={<CollectionIcon />}>
          <div className="flex flex-col w-full space-y-1">
            {branches.map((branch) => {
              return (
                <div className="flex flex-row justify-between w-full" key={branch[0]}>
                  <div>{branch[0]}</div> <div>{secondsToHuman(branch[1])}</div>
                </div>
              );
            })}
          </div>
        </ExpandSection>

        <ExpandSection
          title="File tree"
          count={`${projects.length}p, ${branches.length}b, ${files.length}f`}
          icon={<DocumentTextIcon />}
        >
          <ProjectTree projects={projectTree} />
        </ExpandSection>
      </div>
    </div>
  );
};

const ModalContent = ({ title, description, buttons, children, close }) => {
  return (
    <div className="flex flex-col w-full h-full focus:outline-none text-foreground bg-background">
      <ModalTitle close={close} title={title} description={description} />
      <div className="h-full px-6 pt-4 overflow-auto">{children}</div>
    </div>
  );
};

const SummaryReportDayModal = ({ _close, date }) => {
  const [selectedDate, setSelectedDate] = useState(moment(date).toDate());

  return (
    <ModalContent
      title={
        <div className="flex-row-centered">
          <div className="text-lg">Summary report</div>
        </div>
      }
      description={
        <div className="flex">
          <DateSelector
            icon={
              <div className="shrink">
                <div className="space-x-2 flex-row-centered">
                  <div className="hidden hover:text-covey-500 hover:underline vsm:block">
                    {moment(selectedDate).format("ll (ddd)")}
                  </div>
                  <CalendarIcon className="h-4 icon" />
                </div>
              </div>
            }
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>
      }
      close={_close}
    >
      <SummaryContent date={selectedDate} />
    </ModalContent>
  );
};

export default memo(SummaryReportDayModal);
