const ShadowWrapper = ({ children }) => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-center w-full min-h-screen px-4 lg:shadow-2xl md:px-0">
        {children}
      </div>
    </div>
  );
};

export default ShadowWrapper;
