import { InformationCircleIcon } from "@heroicons/react/outline";
import ProgressBar from "@/components/ProgressBar";
import SimpleTooltip from "@/components/pops/SimpleTooltip";
import { contribLabelMap } from "@/components/score/contribLabelMap";
import { useMemo } from "react";

const DefaultContributor = ({ contributor }) => {
  const percentage = useMemo(() => {
    return Math.round((contributor.points / contributor.possiblePoints) * 100);
  }, [contributor]);

  const backgroundColor = useMemo(() => {
    if (percentage >= 75) {
      return "bg-green-300";
    } else if (percentage >= 50) {
      return "bg-yellow-300";
    } else {
      return "bg-red-300";
    }
  }, [percentage]);

  return (
    <div className="flex-row-centered space-x-2">
      <div className="flex justify-start flex-grow">
        <div className="font-medium whitespace-nowrap">
          {contribLabelMap[contributor.name] || contributor.displayName}:
        </div>
      </div>
      <ProgressBar percentage={percentage} backgroundColor={backgroundColor} />
      <SimpleTooltip trigger={<InformationCircleIcon className="w-5 cursor-pointer icon" />}>
        <div className="flex flex-col">
          <div className="whitespace-nowrap">
            <span className="font-medium">Points:</span> {contributor.points} of{" "}
            {contributor.possiblePoints}
            {contributor.points === contributor.possiblePoints && <> 👍</>}
          </div>
        </div>
      </SimpleTooltip>
    </div>
  );
};

export default DefaultContributor;
