import PopWrapper from "@/components/pops/PopWrapper";
import { useState } from "react";

const DndPop = ({ children, dnd = false }) => {
  const [open, setOpen] = useState(false);

  return (
    <PopWrapper
      open={open}
      setOpen={setOpen}
      contentChildren={
        <div className="p-2">{dnd ? <>Currently focused 🔥</> : <>Not currently focused 🎧</>}</div>
      }
    >
      <div
        className="cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </div>
    </PopWrapper>
  );
};

export default DndPop;
