import * as appConfig from "@/config/appConfig";

import { AppProvider } from "@/contexts/AppContext";
import ClientProvider from "@/contexts/ClientProvider";
import { DateProvider } from "@/contexts/DateContext";
import { DialogProvider } from "@/contexts/DialogContext";
import { HookProvider } from "@/contexts/HookContext";
import { IntercomProvider } from "react-use-intercom";
import { MilestoneProvider } from "@/contexts/MilestoneContext";
import { NoteProvider } from "@/contexts/NoteContext";
import { NotificationProvider } from "@/contexts/NotificationContext";
import { SWRConfig } from "swr";
import { SettingsProvider } from "@/contexts/SettingsContext";
import { UserAgentProvider } from "@quentin-sommer/react-useragent";
import { UserProvider } from "@/contexts/UserContext";
import fetcher from "@/utils/fetcher";
import { useNotification } from "@/contexts/NotificationContext";

const INTERCOM_APP_ID =
  appConfig.API_ENDPOINT === "https://api.stateful.com" ? "i3cov2gj" : "x3hvygb6";

const ProviderWrapped = ({ children, browser }) => {
  const notification = useNotification();

  return (
    <SWRConfig
      value={{
        refreshInterval: 12 * 1000,
        dedupingInterval: 12 * 1000,
        revalidateOnMount: true,
        // initialData: { results: [] },
        suspense: true,
        fetcher: fetcher,
        onError: (error, key) => {
          if (error.status !== 403 && error.status !== 404) {
            notification.info("Error.");
          }
        },
      }}
    >
      <ClientProvider>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <AppProvider browser={browser}>
            <HookProvider>
              <UserProvider>
                <MilestoneProvider>
                  <DateProvider>
                    <NoteProvider>
                      <SettingsProvider>
                        <UserAgentProvider ua={window.navigator.userAgent}>
                          <DialogProvider>{children}</DialogProvider>
                        </UserAgentProvider>
                      </SettingsProvider>
                    </NoteProvider>
                  </DateProvider>
                </MilestoneProvider>
              </UserProvider>
            </HookProvider>
          </AppProvider>
        </IntercomProvider>
      </ClientProvider>
    </SWRConfig>
  );
};

let Providers = ({ children, browser = false }) => {
  return (
    <NotificationProvider>
      <ProviderWrapped browser={browser}>{children}</ProviderWrapped>
    </NotificationProvider>
  );
};

export default Providers;
