import { delayMS } from "@/utils/tools";
import { memo, useCallback, useEffect, useRef, useState } from "react";

const NoteCustomInput = ({ value, onBlur, setParentValue }) => {
  const inputRef = useRef(null);
  const [text, setText] = useState(value);

  const handleChange = useCallback((event) => setText(event.target.innerText), []);
  const handleBlur = useCallback(() => {
    if (value !== text) {
      setParentValue(text);
      // hack to wait for easy edit to set state for text value
      delayMS(100).then(() => onBlur());
      return;
    }
    onBlur();
  }, [setParentValue, text, value, onBlur]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <span
      className="block w-full overflow-hidden text-lg whitespace-pre-wrap outline-none resize-y min-h-fit"
      role="textbox"
      contentEditable
      suppressContentEditableWarning={true}
      onInput={handleChange}
      onBlur={handleBlur}
      ref={inputRef}
    >
      {value}
    </span>
  );
};

export default memo(NoteCustomInput);
