import moment from "moment";

// workDay: null -> workday sharing is off
// workDay: {startTime: "2022-02-25T15:00:00+00:00", endTime: "2022-02-25T23:00:00+00:00"} -> Sharing and prefs are set for today
// workDay: {startTime: null, endTime: null} -> Sharing but no prefs for today
const getScheduleObject = (workDay) => {
  if (workDay && workDay?.startTime && workDay?.endTime) {
    const nowDateTime = moment();
    const startDateTime = moment(workDay.startTime);
    const startTimePlusHour = moment(workDay.startTime).add(1, "hour");
    const endDateTime = moment(workDay.endTime);
    const endDateTimeMinusHour = moment(workDay.endTime).subtract(1, "hour");

    const hasStarted = nowDateTime.isSameOrAfter(startDateTime);
    const hasEnded = nowDateTime.isSameOrAfter(endDateTime);
    const isGettingStarted = nowDateTime.isSameOrBefore(startTimePlusHour);
    const isWrappingUp = nowDateTime.isSameOrAfter(endDateTimeMinusHour);

    return {
      hasStarted,
      hasEnded,
      isWrappingUp,
      isGettingStarted,
      startTime: workDay.startTime,
      endTime: workDay.endTime,
    };
  } else {
    if (workDay?.hasOwnProperty("startTime") && workDay?.hasOwnProperty("endTime")) {
      return { outToday: true };
    }

    return null;
  }
};

const getScheduleEmoji = (workDay) => {
  const scheduleObj = getScheduleObject(workDay);

  if (scheduleObj) {
    if (scheduleObj.isGettingStarted) {
      return `🌄`;
    }
    if (scheduleObj.isWrappingUp) {
      return `🌆`;
    }
    if (scheduleObj.outToday) {
      return "🏖️";
    }
    if (!scheduleObj.hasStarted || scheduleObj.hasEnded) {
      return `🌃`;
    }
  }
  return "";
};

const getScheduleMessage = (workDay) => {
  const scheduleObj = getScheduleObject(workDay);

  if (scheduleObj) {
    if (scheduleObj.isGettingStarted && scheduleObj.hasStarted) {
      return `Booting up for a new day! 🌄`;
    }
    if (scheduleObj.isWrappingUp && !scheduleObj.hasEnded) {
      return `Wrapping up a great day. 🌆`;
    }
    if (scheduleObj.outToday) {
      return "Trying to be AFK today! 🏖️";
    }
    if (!scheduleObj.hasStarted || scheduleObj.hasEnded) {
      return `Outside my working hours. 🌃`;
    }
  }
  return "";
};

export { getScheduleEmoji, getScheduleMessage, getScheduleObject };
