import clsx from "clsx";
import { memo } from "react";

const MediumSwitch = ({ enabled, setEnabled }) => {
  return (
    <button
      type="button"
      onClick={() => {
        setEnabled(!enabled);
      }}
      className={clsx(
        "relative inline-flex flex-shrink-0 h-5 w-10 border rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none items-center",
        { "bg-button-bg": enabled },
        { "bg-button-secondary-bg": !enabled }
      )}
    >
      <span
        className={clsx(
          "pointer-events-none inline-block h-4 w-4 rounded-full bg-button-fg shadow transform ring-0 transition ease-in-out duration-200",
          { "translate-x-5": enabled },
          { "translate-x-0": !enabled }
        )}
      />
    </button>
  );
};

export default memo(MediumSwitch);
