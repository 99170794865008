import { gql, useMutation, useQuery } from "@apollo/client";
import { memo, useCallback, useContext, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import Dots from "@/components/lib/Dots";
import { StarIcon as EmptyStarIcon } from "@heroicons/react/outline";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import PopWrapper from "@/components/pops/PopWrapper";
import RatingsWidget from "@/components/RatingsWidget";
import { StarIcon } from "@heroicons/react/solid";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import clsx from "clsx";
import moment from "moment";
import { useNotification } from "@/contexts/NotificationContext";

const UPDATE_RATING = gql`
  mutation updateRating($input: SetUserDayRatingInput!) {
    setUserDayRating(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const GET_DAY_RATING = gql`
  query getDayRating($date: Date!) {
    day(date: $date) {
      rating
      date
      ratingNote
    }
  }
`;

const RatingPop = ({ simple = false, classes = "w-5", startDate }) => {
  const { pollInterval } = useContext(AppContext);
  const [date, setDate] = useState(startDate || moment().format("YYYY-MM-DD"));
  const notification = useNotification();
  const [setUserDayRating, { loading: updateRatingLoading }] = useMutation(UPDATE_RATING, {
    refetchQueries: [GET_DAY_RATING, "getDayRating", { variables: { date: date } }],
  });

  const {
    loading: dayRatingLoading,
    data: dayRatingData,
    error: dayRatingError,
  } = useQuery(GET_DAY_RATING, { pollInterval: pollInterval, variables: { date: date } });

  const [open, setOpen] = useState(false);

  const displayRating = useMemo(() => {
    return dayRatingData?.day?.rating || 0;
  }, [dayRatingData]);

  const rateable = useMemo(() => {
    if (dayRatingData && dayRatingData.day === null) {
      return false;
    }
    return true;
  }, [dayRatingData]);

  const note = useMemo(() => {
    return dayRatingData?.day?.ratingNote || "";
  }, [dayRatingData]);

  const displayDate = useMemo(() => {
    return moment(date).calendar(null, {
      lastDay: "[yesterday]",
      sameDay: "[today]",
      nextDay: "[tomorrow]",
      lastWeek: "[last] dddd",
      nextWeek: "dddd",
      sameElse: "MMMM Do",
    });
  }, [date]);

  const goPrevious = useCallback(() => {
    setDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"));
  }, [date]);

  const goNext = useCallback(() => {
    if (moment(date).isBefore(moment().subtract(1, "days"))) {
      setDate(moment(date).add(1, "days").format("YYYY-MM-DD"));
    }
  }, [date]);

  const save = useCallback(
    (rating) => {
      setUserDayRating({
        variables: { input: { rating: rating, date: date } },
      })
        .then(() => {
          notification.info("Rating saved");
        })
        .catch(() => {
          setOpen(false);
        });
    },
    [date, notification, setUserDayRating]
  );

  return (
    <PopWrapper
      open={open}
      setOpen={setOpen}
      contentChildren={
        <div className="flex flex-col p-2" style={{ minWidth: "320px" }}>
          <div className="flex flex-row justify-between px-1 pb-2">
            <VSCodeLink
              onClick={() => {
                goPrevious();
              }}
            >
              <div>← Previous</div>
            </VSCodeLink>
            <div className="pt-2">{updateRatingLoading && <Dots height={6} />}</div>
            <VSCodeLink
              className={clsx({
                hidden: displayDate === "today",
              })}
              onClick={() => {
                goNext();
              }}
            >
              Next →
            </VSCodeLink>
          </div>
          <div className="p-1 font-medium">Rate your happiness ({displayDate}):</div>

          <div className="flex justify-center w-full p-2">
            {dayRatingLoading && <Loading />}
            {dayRatingError && <ErrorGrow message={dayRatingError.message} />}
            {!rateable && <div>This day can't be rated.</div>}
            {!dayRatingLoading && !dayRatingError && rateable && (
              <RatingsWidget size={"30px"} rating={displayRating} setRating={save} />
            )}
          </div>
          <div className="px-2 my-2 overflow-auto text-sm max-h-20 text-description">{note}</div>
        </div>
      }
    >
      <div
        className="h-full cursor-pointer flex-row-centered"
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className="flex items-center justify-center h-full cursor-pointer">
          <div
            className={clsx("rounded hover:bg-backgroundSecondary p-0.5", { " border": !simple })}
          >
            {(!displayRating || !rateable) && (
              <EmptyStarIcon className={["icon", classes].join(" ")} />
            )}
            {displayRating !== 0 && <StarIcon className={["text-yellow-400", classes].join(" ")} />}
          </div>
        </div>
      </div>
    </PopWrapper>
  );
};

export default memo(RatingPop);
