import ModalContent from "@/components/modals/ModalContent";
import { VSCodeButton } from "@vscode/webview-ui-toolkit/react";
import { memo } from "react";

const FriendlyPrivacyModal = ({ _close }) => {
  return (
    <ModalContent
      title={"Your data belongs to you."}
      close={_close}
      buttons={
        <VSCodeButton
          appearance="secondary"
          onClick={() => {
            _close();
          }}
        >
          Close
        </VSCodeButton>
      }
    >
      <div className="p-4 m-2">
        <div className="flex justify-center pb-12">
          <img src="/assets/shield.png" className="h-24" alt="Privacy" />
        </div>
        <ul className="pl-6 space-y-1 list-disc">
          <li>
            We are <span className="font-semibold">not a management tool</span>. What you share, and
            with whom, is your decision.
          </li>
          <li>
            We take our privacy policy very seriously,{" "}
            <a href="https://stateful.com/privacy" className="link">
              👀 read it if you're interested.
            </a>
          </li>
          <li>
            To get a personalized status or insights, you need to{" "}
            <span className="font-semibold">install the VS Code extension</span>.
          </li>
          <li>
            Once the extension is installed, we collect metadata about how you code, but{" "}
            <span className="font-semibold">not the code</span> you work on.
          </li>
          <li>
            If you have further security concerns, we are happy (and interested) in discussing ways
            to make you comfortable. 😊
          </li>
        </ul>
      </div>
    </ModalContent>
  );
};

export default memo(FriendlyPrivacyModal);
