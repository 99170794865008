import ModalBig from "@/components/modals/ModalBig";
import SettingsModal from "@/components/modals/SettingsModal";
import { useDialog } from "@/contexts/DialogContext";

const SettingsPop = ({ children }) => {
  const { addDialog } = useDialog();

  return (
    <div className="flex-row-centered space-x-2">
      <div
        onClick={() => {
          addDialog({
            Component: (
              <ModalBig>
                <SettingsModal />
              </ModalBig>
            ),
          });
        }}
        className="cursor-pointer hover:text-covey-500"
      >
        {children}
      </div>
    </div>
  );
};

export default SettingsPop;
