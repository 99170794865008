import { gql, useQuery } from "@apollo/client";
import { memo, useContext, useMemo } from "react";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import maxBy from "lodash/maxBy";
import moment from "moment";
import { msToHuman } from "@/utils/convert";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_DAYS = gql`
  query getDays {
    days(pageSize: 30) {
      data {
        date
        score
        codingDuration {
          totalMs
        }
      }
    }
  }
`;

const ScoreRecord = ({ children, contentChildren }) => {
  const { pollInterval } = useContext(AppContext);

  const {
    error: daysError,
    refetch: refetchDays,
    loading: daysLoading,
    data: daysData,
  } = useQuery(GET_DAYS, { pollInterval: pollInterval });

  const recordDay = useMemo(() => {
    return maxBy(daysData?.days?.data, (o) => {
      return o?.codingDuration?.totalMs || 0;
    });
  }, [daysData]);

  useWindowFocus(0, () => {
    refetchDays();
  });

  const record = recordDay?.codingDuration?.totalMs;

  if (daysLoading) {
    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }

  if (daysError) return <ErrorGrow message={daysError.message} />;

  return (
    <div>
      Your most active day (in the last 30) was {moment(recordDay?.date).format("LL")} - focused
      activity for {msToHuman(record)}.🥇
    </div>
  );
};

export default memo(ScoreRecord);
