import { CardContent, CardFooter, CardTitle, CardWrapper } from "@/components/cards/CardWrappers";
import { gql, useQuery } from "@apollo/client";

import ErrorGrow from "@/components/lib/ErrorGrow";
import LanguageOnlyGraph from "@/components/graphs/LanguageOnlyGraph";
import Loading from "@/components/lib/Loading";
import { ReactionDisplay } from "@/components/Reaction";
import ReactionPop from "@/components/pops/ReactionPop";
import UserAvatarStatus from "@/components/UserAvatarStatus";
import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import { upperCaseFirst } from "@/utils/tools";
import { useMemo } from "react";

var converter = require("number-to-words");

const GET_LANGUAGES_FOR_DAYS = gql`
  query getLanuagesForDays($startDate: Date!, $endDate: Date!) {
    days(startDate: $startDate, endDate: $endDate) {
      data {
        date
        languages {
          data {
            codingDuration {
              totalMs
            }
            displayName
            id
          }
        }
      }
    }
  }
`;

const StreakLanguageitem = (props) => {
  const { item } = props;

  const startDate = useMemo(() => {
    if (item) {
      return item.days[0];
    }
  }, [item]);

  const endDate = useMemo(() => {
    if (item) {
      return item.days[item.days.length - 1];
    }
  }, [item]);

  const {
    loading: daysLoading,
    error: daysError,
    data: daysData,
  } = useQuery(GET_LANGUAGES_FOR_DAYS, {
    skip: !startDate || !endDate,
    variables: { startDate: startDate, endDate: endDate },
  });

  const data = useMemo(() => {
    const arr = daysData?.days?.data;
    if (arr) {
      const dayArr = arr.map((day) => {
        const langObj = day?.languages?.data.map((lang) => {
          return {
            language: lang?.id,
            duration: lang?.codingDuration?.totalMs,
            date: day?.date,
          };
        });

        const byDate = groupBy(langObj, (o) => {
          return o.date;
        });

        return Object.keys(byDate).map((date) => {
          let dayEntry = {
            date: date,
          };
          byDate[date].forEach((lang) => {
            dayEntry[lang.language] = lang.duration;
          });
          return dayEntry;
        });
      });

      return flatten(dayArr).reverse();
    }
  }, [daysData]);

  const uniqLangs = useMemo(() => {
    const arr = daysData?.days?.data;
    if (arr) {
      const langArr = arr.map((entry) => {
        return entry?.languages?.data.map((lang) => lang.id);
      });
      return uniq(flatten(langArr));
    }
  }, [daysData]);

  const langNameMap = useMemo(() => {
    const arr = daysData?.days?.data;
    if (arr) {
      const langArr = arr.map((entry) => {
        return entry?.languages?.data.map((lang) => {
          return [lang.id, lang.displayName];
        });
      });

      const nameMap = {};
      flatten(langArr).forEach((entry) => {
        nameMap[entry[0]] = entry[1];
      });
      return nameMap;
    }
  }, [daysData]);

  const messageYou = `Your on a the ${converter.toOrdinal(item?.count)} day of a ${upperCaseFirst(
    item.language
  )}, coding tear -- keep going! 🎊`;

  const messageOther = `${item?.profile?.github?.username} is on the ${converter.toOrdinal(
    item.count
  )} day of a ${item.language} coding tear - give them some kudos to keep going! 🎊`;

  const contentText = item.owner ? messageYou : messageOther;

  if (daysLoading) return <Loading />;
  if (daysError) return <ErrorGrow message={daysError.message} />;

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        date
        title={
          <div className="flex-row-centered space-x-2">
            <UserAvatarStatus face={item?.profile} classes="w-5" />
            <div>
              {upperCaseFirst(item?.language)} streak (day {item?.count})
            </div>
          </div>
        }
      />

      <CardContent>
        <div className="flex flex-col h-full">
          <div>{contentText}</div>
          {item.owner && (
            <>
              <div className="h-24">
                <LanguageOnlyGraph data={data} uniqLangs={uniqLangs} langNameMap={langNameMap} />
              </div>
              <div className="flex justify-end text-xs text-description">
                (Graph shown only to you.)
              </div>
            </>
          )}
        </div>
      </CardContent>
      <CardFooter>
        <div className="flex-row-centered">
          <ReactionDisplay item={item} />
          <ReactionPop item={item} />
        </div>
      </CardFooter>
    </CardWrapper>
  );
};

export default StreakLanguageitem;
