import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "@/styles/datepicker.css";

import { BrowserView, MobileView } from "react-device-detect";
import { cloneElement, memo, useState } from "react";

import { CalendarIcon } from "@heroicons/react/outline";
import { DateRange } from "react-date-range";
import PopGeneric from "@/components/pops/PopGeneric";
import { Popover } from "react-tiny-popover";
import moment from "moment";
import { useDebounce } from "use-debounce";
import useEffectExceptMount from "use-effect-except-mount";

const DateRangeSelector = ({ icon = null, selectionRange, setSelectionRange, onOpen }) => {
  // const { minDate } = useContext(AppContext);
  const [open, setOpen] = useState(false);

  const [selection, setSelection] = useState({
    startDate: selectionRange.startDate,
    endDate: selectionRange.endDate,
    key: "selection",
  });

  const [bounced] = useDebounce(selection, 1000);

  useEffectExceptMount(() => {
    setSelectionRange(selection);
  }, [bounced]);

  const handleSelect = (ranges) => {
    setSelection(ranges["selection"]);
  };

  return (
    <>
      <Popover
        isOpen={open}
        positions={["bottom", "left", "right", "top"]}
        onClickOutside={() => setOpen(false)}
        containerClassName="z-50"
        content={
          <div className="z-20 mt-2 overflow-hidden border rounded-md shadow-lg text-foreground bg-background">
            <BrowserView>
              <DateRange
                className=""
                weekStartsOn={1}
                shownDate={selection.endDate}
                // disabledDay={(date) => {
                //   if (moment(date).isBefore(minDate)) {
                //     return true;
                //   } else {
                //     return false;
                //   }
                // }}
                maxDate={moment().toDate()}
                // scroll={{ enabled: true }}
                // showMonthArrow={false}
                // showDateDisplay={false}
                ranges={[selection]}
                onChange={handleSelect}
                rangeColors={["var(--vscode-button-secondaryBackground)"]}
              />
            </BrowserView>
            <MobileView>
              <DateRange
                weekStartsOn={1}
                shownDate={selection.endDate}
                // disabledDay={(date) => {
                //   if (moment(date).isBefore(minDate)) {
                //     return true;
                //   } else {
                //     return false;
                //   }
                // }}
                maxDate={moment().toDate()}
                ranges={[selection]}
                onChange={handleSelect}
                rangeColors={["var(--vscode-button-secondaryBackground)"]}
              />
            </MobileView>
          </div>
        }
      >
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpen(!open);
            if (onOpen) {
              onOpen();
            }
          }}
        >
          {icon ? (
            cloneElement(icon, {})
          ) : (
            <PopGeneric arrow={false} target={<CalendarIcon className="h-4 icon" />}>
              <div className="p-2">Date range.</div>
            </PopGeneric>
          )}
        </div>
      </Popover>
    </>
  );
};

export default memo(DateRangeSelector);
