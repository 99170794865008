import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useMemo, useRef, useState } from "react";

import EmailPrompt from "@/components/settings/EmailPrompt";
import ErrorGrow from "@/components/lib/ErrorGrow";
import ExpandSection from "@/components/settings/ExpandSection";
import Loading from "@/components/lib/Loading";
import ProfileForm from "@/components/settings/ProfileForm";
import TimezoneSelector from "@/components/TimezoneSelector";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_TIMEZONE = gql`
  query getTimezone {
    user {
      id
      timezone
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const TimezoneContent = ({ tz, containerRef }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(tz || {});
  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    updateUser({
      variables: {
        input: { timezone: selectedTimezone.value },
      },
    });
  }, [selectedTimezone, updateUser]);

  return (
    <div className="flex-col w-full space-y-3">
      <div>
        You appear to currently be in{" "}
        <span className="font-medium">{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>.
      </div>
      <div className="flex flex-col space-y-1">
        <div className="flex flex-row justify-between">
          <div>Your timezone is set to:</div>
          <VSCodeLink
            onClick={() => {
              setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
            }}
          >
            Use my current timezone
          </VSCodeLink>
        </div>
        <div>
          <TimezoneSelector
            selectedTimezone={selectedTimezone}
            setSelectedTimezone={setSelectedTimezone}
            containerRef={containerRef}
          />
        </div>
      </div>
    </div>
  );
};

const TimezoneSection = ({ containerRef }) => {
  const { loading, error, data, refetch } = useQuery(GET_TIMEZONE);

  useWindowFocus(0, () => {
    refetch();
  });

  const tz = useMemo(() => {
    return data?.user?.timezone;
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <ErrorGrow message={error.message} />;

  return <TimezoneContent tz={tz} containerRef={containerRef} />;
};

const SettingsGeneralTab = () => {
  const containerRef = useRef();

  return (
    <div className="flex flex-col space-y-2" ref={containerRef}>
      <div>
        <EmailPrompt />
      </div>
      <ExpandSection title="Timezone" defaultOpen={false}>
        <TimezoneSection containerRef={containerRef} />
      </ExpandSection>

      <ExpandSection title="Profile" defaultOpen={true}>
        <ProfileForm />
      </ExpandSection>
    </div>
  );
};
export default SettingsGeneralTab;
