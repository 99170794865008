import { ProgressBar } from "react-loader-spinner";

const Dots = (props) => {
  return (
    <ProgressBar
      height="20"
      width="50"
      borderColor="rgba(0, 0, 0, 0)"
      barColor="var(--vscode-progressBar-background)"
    />
  );
};

export default Dots;
