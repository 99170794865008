import "@/styles/simpletooltip.css";

import { usePopperTooltip } from "react-popper-tooltip";

const SimpleTooltip = ({
  trigger,
  children,
  opts = {
    interactive: true,
    delayHide: 200,
    offset: [10, 10],
    closeOnOutsideClick: true,
  },
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip(opts);

  return (
    <div>
      <div ref={setTriggerRef}>{trigger}</div>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {children}
        </div>
      )}
    </div>
  );
};

export default SimpleTooltip;
