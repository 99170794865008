import {
  CardContent,
  CardTitle,
  CardTitleIcon,
  CardWrapper,
} from "@/components/cards/CardWrappers";
import { VSCodeButton, VSCodeTextArea } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo, useRef, useState } from "react";

import Dots from "@/components/lib/Dots";
import ReactMarkdown from "react-markdown";
import { TruckIcon } from "@heroicons/react/outline";
import flatten from "lodash/flatten";
import moment from "moment";
import { useNotification } from "@/contexts/NotificationContext";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateUserDayNoteFeedItemInput!) {
    updateUserDayNoteFeedItem(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const GET_SUMMARY_FOR_DAY = gql`
  query GetSummaryForDay($date: Date!) {
    day(date: $date) {
      projects {
        data {
          files {
            data {
              codingDuration {
                totalSeconds
              }
              name
            }
          }
          name
          id
          gitBranches {
            data {
              name
              codingDuration {
                totalSeconds
              }
            }
          }
          codingDuration {
            totalSeconds
          }
          displayName
        }
      }
      date
      userAnnotations {
        data {
          content
          date
        }
      }
      workStartTime
    }
  }
`;

const displayDate = (date) => {
  return moment(date).calendar(null, {
    lastDay: "[yesterday]",
    sameDay: "[today]",
    nextDay: "[tomorrow]",
    lastWeek: "[last] dddd",
    nextWeek: "dddd",
    sameElse: "MMMM Do",
  });
};

const DayNoteFeedItem = (props) => {
  const notification = useNotification();
  const { item, feedRefetch } = props;
  const [updateNote, { loading: updateNoteLoading }] = useMutation(UPDATE_NOTE);
  const [content, setContent] = useState(item?.note?.content || "");
  const itemDate = moment(item.time).local().format("YYYY-MM-DD");
  const {
    data: summaryData,
    refetch: summaryRefetch,
    loading: summaryLoading,
  } = useQuery(GET_SUMMARY_FOR_DAY, {
    variables: { date: itemDate },
  });
  const noteRef = useRef(null);
  const [editing, setEditing] = useState(!content ? true : false);

  useWindowFocus(0, () => {
    feedRefetch();
    summaryRefetch();
  });

  const projects = useMemo(() => {
    const arr = summaryData?.day?.projects?.data;
    if (arr) {
      const projectArray = arr.map((project) => {
        return [project.displayName, project?.codingDuration?.totalSeconds || 0];
      });
      return projectArray.sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const branches = useMemo(() => {
    const arr = summaryData?.day?.projects?.data;
    if (arr) {
      return flatten(
        arr.map((project) => {
          return project.gitBranches.data.map((branch) => {
            return [branch.name, branch?.codingDuration?.totalSeconds || 0];
          });
        })
      ).sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const files = useMemo(() => {
    const projects = summaryData?.day?.projects?.data;
    if (projects) {
      const files = projects.map((project) => {
        return flatten(project.files.data);
      });
      const fileObjArr = flatten(files);
      const fileArray = fileObjArr.map((file) => {
        return [file.name, file?.codingDuration?.totalSeconds || 0];
      });

      return fileArray.sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const workStartTime = summaryData?.day?.workStartTime;

  const itemIsToday = itemDate === moment().format("YYYY-MM-DD");

  const hintString = useMemo(() => {
    let hs = ``;
    if (workStartTime) {
      hs += `You started coding at ${moment(workStartTime).local().format("H:mm a")}. `;
    }
    if (projects && !branches) {
      hs += `Focus was in ${projects?.[0]?.[0]}. `;
    }
    if (projects && branches) {
      hs += `Focus was in ${projects?.[0]?.[0]}#${branches?.[0]?.[0]}. `;
    }
    if (files) {
      hs += `Lots in ${files?.[0]?.[0]}.`;
    }
    return hs;
  }, [branches, files, projects, workStartTime]);

  const onSubmit = useCallback(() => {
    if (content !== "") {
      const noteObj = { feedItemId: item?.id, content: content };
      updateNote({
        variables: { input: noteObj },
      })
        .then(() => {
          setEditing(false);
          feedRefetch();
          notification.info("Saved!");
        })
        .catch(() => {
          notification.error("Error updating the reminder.");
        });
    } else {
      noteRef.current.focus();
    }
  }, [notification, content, feedRefetch, item, updateNote]);

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        date
        snooze={false}
        title={
          <div className="space-x-2 flex-row-centered">
            <CardTitleIcon {...props}>
              <TruckIcon />
            </CardTitleIcon>
            <div>Rolling start</div>
            <div className="text-description">({displayDate(item?.time)})</div>
          </div>
        }
      />

      <CardContent>
        <div className="flex flex-col space-y-2">
          <div>Leave yourself a note to start your next coding session.</div>
          <div className="text-xs text-description">
            {hintString && itemIsToday && `Hint: ` + hintString}
            {summaryLoading && <Dots height={10} />}
          </div>
          <div>
            <hr className="w-full" />
          </div>
          <div>
            {editing ? (
              <>
                <VSCodeTextArea
                  className="w-full"
                  id="note"
                  name="note"
                  ref={noteRef}
                  rows={2}
                  placeholder="What context will help you dive back in?"
                  value={content}
                  resize="both"
                  onInput={(e) => {
                    setContent(e.target.value);
                  }}
                />
                {/* <textarea
                  id="note"
                  name="note"
                  ref={noteRef}
                  rows={2}
                  className="w-full text-sm inputBase"
                  placeholder="What context will help you dive back in?"
                  value={content}
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                /> */}
                <div className="flex items-center justify-between w-full">
                  <div className="text-xs text-description">(Supports Markdown)</div>
                  <div className="flex items-center justify-end space-x-2">
                    <div>{updateNoteLoading && <Dots height={6} />}</div>
                    <VSCodeButton
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      Save
                    </VSCodeButton>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className="markdown-body">
                    <ReactMarkdown children={content} />
                  </div>
                </div>
                <div className="flex justify-end pt-2">
                  <VSCodeButton
                    onClick={() => {
                      setEditing(!editing);
                    }}
                  >
                    Edit
                  </VSCodeButton>
                </div>
              </>
            )}
          </div>
        </div>
      </CardContent>
    </CardWrapper>
  );
};

export default DayNoteFeedItem;
