const titleFull = "Hmm, something went wrong.";
const titleShort = "Uh oh...";

const ErrorGrow = ({ message }) => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex-row hidden w-full h-2/3 vsm:flex">
        <div className="flex flex-col items-center justify-center w-full h-full ">
          <div className="font-semibold">😔 {titleFull}</div>
          <div>{message}</div>
        </div>
      </div>
      <div className="flex-row-centered w-full space-x-2 vsm:hidden">
        <div className="flex flex-col space-y-1">
          <div className="font-medium">😔 {titleShort}</div>
          <div>{message}</div>
        </div>
      </div>
    </div>
  );
};

export default ErrorGrow;
