import AuthButton from "@/components/status/AuthButton";
// import FacesUnauthed from "@/components/status/FacesUnauthed";
import PrivacyLink from "@/components/status/PrivacyLink";
import SectionDiv from "@/components/status/SectionDiv";
import ShadowWrapper from "@/components/status/ShadowWrapper";
import StatusBox from "@/components/status/StatusBox";
import StatusContainer from "@/components/status/StatusContainer";
import StatusLandingPage from "@/components/status/StatusLandingPage";
import SuspenseContainer from "@/components/SuspenseContainer";
import Title from "@/components/Title";
import { useParams } from "react-router-dom";

const StatusUnauthedContent = ({ username }) => {
  return (
    <>
      <Title>{username ? `${username}` : "Status!"}</Title>

      <StatusContainer>
        <SectionDiv stay={true}>
          <div className="hidden py-8 md:block">
            <a href="/">
              <img src="/assets/stateful_icon.svg" className="h-20" alt="Stateful" />
            </a>
          </div>
        </SectionDiv>
        <SectionDiv stay={true}>
          <SuspenseContainer>
            <StatusBox username={username} />
          </SuspenseContainer>
        </SectionDiv>

        {/* <SectionDiv>
          <hr className="w-full" />
        </SectionDiv> */}
        {/* <SectionDiv stay={true}>
          <div className="w-full text-center">
            <SuspenseContainer>
              <FacesUnauthed animate={true} />
            </SuspenseContainer>
          </div>
        </SectionDiv> */}

        <SectionDiv stay={true}>
          <div className="flex flex-col py-6 mb-8 space-y-2">
            <AuthButton caption="Join the community" />
            <PrivacyLink />
          </div>
        </SectionDiv>
      </StatusContainer>
    </>
  );
};

const StatusUnauthed = () => {
  const { username } = useParams();
  return (
    <>
      <Title>Sign up!</Title>

      <ShadowWrapper>
        {username && <StatusUnauthedContent username={username} />}
        {!username && <StatusLandingPage />}
      </ShadowWrapper>
    </>
  );
};

export default StatusUnauthed;
