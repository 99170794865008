import { memo, useContext, useEffect, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import { BallTriangle } from "react-loader-spinner";
import Title from "@/components/Title";
import VSEmailPrompt from "@/components/vs/VSEmailPrompt";
import moment from "moment";

const percentage = (partialValue, totalValue) => {
  return (Math.min(partialValue, totalValue) / totalValue) * 100;
};

const VSWaitingView = () => {
  const { tangle } = useContext(AppContext);
  const [throughputSMA, setThroughputSMA] = useState(0);
  const [durationMs, setDurationMs] = useState(0);

  useEffect(() => {
    tangle?.on("status", (status) => {
      if (status?.delivery?.throughput?.sma && status?.userDataSummary) {
        setThroughputSMA(status.delivery?.throughput?.sma?.value || 0);
        setDurationMs(status.userDataSummary.activityDurationMs);
      }
    });
    return () => {
      tangle?.off("status");
    };
  }, [tangle]);

  const minDuration = useMemo(() => {
    return moment.duration(durationMs, "ms").asMinutes();
  }, [durationMs]);

  const percent = useMemo(() => {
    const total = percentage(durationMs, 900000);
    return total.toFixed(1);
  }, [durationMs]);

  return (
    <div
      className="flex flex-col items-center justify-center w-3/4 h-full mx-auto space-y-2"
      style={{ minHeight: "260px" }}
    >
      <Title>Waiting</Title>

      <div className="text-lg">You're online!</div>
      <div className="text-xs font-medium">
        You have {minDuration.toFixed(1)}m / 15m of activity.
      </div>

      <div className="space-x-2 flex-row-centered">
        <div className="py-2 text-xs text-description">
          Generating {throughputSMA.toFixed(2)} events / s
        </div>

        <BallTriangle
          type="BallTriangle"
          color="var(--vscode-descriptionForeground)"
          timeout={600000}
          height={22}
          width={22}
        />
      </div>
      <div className="w-1/2 my-6 border rounded-sm border-border">
        <div
          className="bg-button-bg text-xs font-medium text-button-fg text-center p-0.5 leading-none rounded-sm"
          style={{ width: `${percent}%` }}
        >
          {percent}%
        </div>
      </div>

      <div className="w-full py-6">
        <hr className="w-full text-border" />
      </div>
      <VSEmailPrompt />
    </div>
  );
};

export default memo(VSWaitingView);
