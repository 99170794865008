import "@/styles/hide-scroll.css";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useContext, useEffect, useState } from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";

import Title from "@/components/Title";
import clsx from "clsx";
import { upperCaseFirst } from "@/utils/tools";

const ArrowBase = ({ children, disabled, onClick, classes }) => {
  return (
    <button
      classes={classes}
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: disabled ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "center",
        // right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
      }}
    >
      {children}
    </button>
  );
};

const TabButton = ({ title, url }) => {
  const navigate = useNavigate();

  const pathObj = useResolvedPath(":page/*");
  const match = useMatch({ path: pathObj.pathname, end: true });
  const page = match?.params?.page;

  return (
    <>
      <Title>{`${upperCaseFirst(url)}`}</Title>
      <button
        className={clsx(
          "mx-2 pb-0.5 hover:text-tab-active-fg border-b border-transparent",
          { "text-tab-inactive-fg": page !== url },
          {
            "text-tab-active-fg border-tab-active-border": page === url,
          }
        )}
        onClick={() => {
          navigate(`${url}`);
        }}
      >
        {title}
      </button>
    </>
  );
};

const TabEntry = ({ onClick, title, url }) => {
  const visibility = useContext(VisibilityContext);

  return <TabButton onClick={() => onClick(visibility)} tabIndex={0} title={title} url={url} />;
};

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators, initComplete } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible));
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ArrowBase disabled={disabled} onClick={() => scrollPrev()}>
      <ChevronLeftIcon className="w-4 text-tab-active-fg" />
    </ArrowBase>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ArrowBase disabled={disabled} onClick={() => scrollNext()} classes="pl-2">
      <ChevronRightIcon className="w-4 text-tab-active-fg" />
    </ArrowBase>
  );
};

const RouterTabsScroll = ({ TABS }) => {
  const [selected, setSelected] = useState([]);
  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected ? currentSelected.filter((el) => el !== id) : currentSelected.concat(id)
      );
    };

  return (
    <div className="w-full pl-2 pr-2 overflow-hidden">
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {TABS.map((tab) => (
          <TabEntry
            itemId={tab.value} // NOTE: itemId is required for track items
            title={tab.title}
            key={tab.value}
            url={tab.url}
            onClick={handleClick(tab.value)}
            selected={isItemSelected(tab.value)}
          />
        ))}
      </ScrollMenu>
    </div>
  );
};

export default RouterTabsScroll;
