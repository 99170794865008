import { VSCodeDropdown, VSCodeOption } from "@vscode/webview-ui-toolkit/react";
import { gql, useQuery } from "@apollo/client";
import { memo, useContext, useEffect, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import { PROFILE_FRAGMENT } from "@/components/connections/PeopleWrappers";
import SimpleTooltip from "@/components/pops/SimpleTooltip";
import clsx from "clsx";
import getStatus from "@/utils/getStatus";
import useLocalStorageState from "use-local-storage-state";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_PROFILES = gql`
  ${PROFILE_FRAGMENT}

  query GetProfiles {
    profiles(includeUser: true) {
      data {
        ...UserProfileFields
      }
    }
  }
`;

const Face = memo(({ face, setUserSelected, selected }) => {
  const isSelected = useMemo(() => {
    return selected?.id === face?.id;
  }, [face, selected]);

  const isDnd = useMemo(() => {
    return face?.dnd?.active;
  }, [face]);

  const status = useMemo(() => {
    const workDay = face?.workDay;
    return getStatus(face.status, face?.dnd, workDay);
  }, [face]);

  return (
    <div className="relative py-0.5 max-w-min">
      <SimpleTooltip
        opts={{
          interactive: true,
          delayHide: 100,
          placement: "bottom",
          offset: [10, 10],
          closeOnOutsideClick: true,
        }}
        trigger={
          <img
            onMouseOver={() => {
              setUserSelected(face);
            }}
            src={face.photoUrl || "/assets/empty-avatar.png"}
            className={clsx(
              "w-8 h-8 max-w-min rounded cursor-pointer overflow-hidden  border-gray-500 hover:border-blue-400 border",
              { "border-blue-400": isSelected },
              { "border-covey-400": isDnd }
            )}
            alt="face"
          />
        }
      >
        <div
          className="flex flex-col flex-grow space-y-1 text-center rounded-xl"
          style={{ minWidth: "200px" }}
        >
          <div className="font-medium">{face.github.username}</div>
          <div className="">{status}</div>
        </div>
      </SimpleTooltip>
    </div>
  );
});

const FacePile = ({ faces }) => {
  const [selected, setSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);

  useEffect(() => {
    if (userSelected) {
      setSelected(userSelected);
    }
  }, [userSelected]);

  if (faces.length === 0) {
    return <div>Looks like nobody's online, check back later. 🪐</div>;
  }

  return (
    <div
      className="flex flex-col"
      onMouseLeave={() => {
        setSelected(null);
      }}
      onMouseEnter={() => {
        if (faces.length > 1 && !userSelected) {
          const index = faces.findIndex((face) => face.id === selected?.id);
          if (index < faces.length - 1) {
            setSelected(faces[index + 1]);
          } else if (faces.length > 3) {
            const middle = faces.length / 2;
            setSelected(faces[Math.floor(middle)]);
          } else {
            setSelected(faces[0]);
          }
        }
      }}
    >
      <div className="flex flex-row flex-wrap items-start justify-start space-x-1">
        {faces.map((face) => {
          return (
            <Face key={face.id} face={face} setUserSelected={setUserSelected} selected={selected} />
          );
        })}
      </div>
    </div>
  );
};

const OnlineContainer = () => {
  const { pollInterval } = useContext(AppContext);

  const [group, setGroup] = useLocalStorageState("network-online-group", {
    defaultValue: "everyone",
  });

  const {
    data: everyoneData,
    loading: everyoneLoading,
    error: everyoneError,
    refetch: everyoneRefetch,
  } = useQuery(GET_PROFILES, {
    pollInterval: pollInterval,
  });

  useWindowFocus(0, () => {
    everyoneRefetch();
  });

  const allProfiles = useMemo(() => {
    return everyoneData?.profiles?.data;
  }, [everyoneData]);

  const connectionProfiles = useMemo(() => {
    return everyoneData?.profiles?.data.filter((profile) => profile.following || profile.follower);
  }, [everyoneData]);

  if (everyoneLoading) {
    return <Loading />;
  }
  if (everyoneError) return <ErrorGrow message={everyoneError.message} />;

  return (
    <div className="flex flex-col px-4 divide-y">
      <div className="py-2">
        <VSCodeDropdown
          value={group}
          onChange={(e) => {
            setGroup(e.target.value);
          }}
        >
          <VSCodeOption value="everyone">Everyone</VSCodeOption>
          <VSCodeOption value="connections">My network</VSCodeOption>
        </VSCodeDropdown>
      </div>
      <div className="py-2">
        <FacePile faces={group === "everyone" ? allProfiles : connectionProfiles} animate={false} />
      </div>
    </div>
  );
};
export default OnlineContainer;
