import "@/styles/hide-scroll.css";

import * as appConfig from "@/config/appConfig";

import { Navigate, Route, Routes, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { memo, useContext, useEffect, useMemo } from "react";

import AppContext from "@/contexts/AppContext";
import Cards from "@/components/Cards";
import { CogIcon } from "@heroicons/react/outline";
import DndCard from "@/components/cards/DndCard";
import MetricsContainer from "@/components/MetricsContainer";
import NotesContainer from "@/components/NotesContainer";
import PeopleContainer from "@/components/PeopleContainer";
import RatingPop from "@/components/pops/RatingPop";
import RouterTabsScroll from "@/components/RouterTabsScroll";
import ScoreBox from "@/components/ScoreBox";
import SettingsPop from "@/components/pops/SettingsPop";
import StandupContainer from "@/components/StandupContainer";
import { StandupProvider } from "@/contexts/StandupContext";
import SummaryCard from "@/components/cards/SummaryCard";
import SuspenseContainer from "@/components/SuspenseContainer";
import Tos from "@/components/Tos";
import { logEvent } from "firebase/analytics";
import { useIntercom } from "react-use-intercom";

const FeedContainer = ({ path }) => {
  return (
    <div className="w-full h-full overflow-auto">
      <div>
        <SuspenseContainer>
          <SummaryCard />
        </SuspenseContainer>
      </div>
      <div className="flex items-center py-2">
        <SuspenseContainer>
          <ScoreBox path={path} />
        </SuspenseContainer>
      </div>
      <div className="pb-4">
        <DndCard />
      </div>

      <div className="pb-4">
        <Cards />
      </div>
    </div>
  );
};

const StandupWrapper = () => {
  return (
    <div className="w-full h-full">
      <StandupProvider>
        <StandupContainer />
      </StandupProvider>
    </div>
  );
};

const TABS = [
  { title: "Feed", value: "feed", url: "feed", Component: FeedContainer },
  { title: "Metrics", value: "metrics", url: "metrics", Component: MetricsContainer },
  { title: "Notes", value: "notes", url: "notes", Component: NotesContainer },

  { title: "Standup", value: "standup", url: "standup", Component: StandupWrapper },
  {
    title: "Network",
    value: "network",
    url: "network",
    Component: PeopleContainer,
  },
];

const VSHomeView = () => {
  const { tangle, analytics } = useContext(AppContext);
  const pathObj = useResolvedPath(":page/*");
  const match = useMatch({ path: pathObj.pathname, end: true });
  const page = match?.params?.page;
  const page_path = match?.pathname;

  const onView = window.APP_STATE?.syncPayload?.onView;

  const navigate = useNavigate();
  const { trackEvent } = useIntercom();

  const viewName = useMemo(() => {
    return onView?.name;
  }, [onView]);

  useEffect(() => {
    if (page_path) {
      const page_location = `${appConfig.API_ENDPOINT}${page_path}`;
      logEvent(analytics, "page_view", {
        page_title: document.title,
        page_location,
        page_path,
        vscode: true,
      });
    }
    trackEvent("tab", {
      name: page || "unknown",
    });
  }, [trackEvent, page_path, page, analytics]);

  useEffect(() => {
    tangle?.on("onView", (view) => {
      if (view !== null) {
        navigate(view.name);
      }
    });
  }, [navigate, tangle]);

  useEffect(() => {
    if (viewName && viewName !== page) {
      // reset once used
      window.APP_STATE.syncPayload = undefined;
      navigate(viewName);
    }
  }, [navigate, viewName, page]);

  return (
    <div className="flex flex-col w-full h-full overflow-auto">
      <Tos />

      <div className="justify-between py-2 pr-2 shadow flex-row-centered">
        <RouterTabsScroll TABS={TABS} />

        <div className="flex-row-centered space-x-0.5">
          <RatingPop simple={true} classes="w-5" />
          <SettingsPop>
            <CogIcon className="w-5 icon" />
          </SettingsPop>
        </div>
      </div>
      <div className="flex flex-col w-full h-full overflow-auto">
        <Routes className="switch-wrapper">
          {TABS.map((tab) => (
            <Route
              key={tab.value}
              path={`${tab.url}/*`}
              element={<tab.Component path={tab.url} />}
            />
          ))}

          <Route index element={<Navigate to="feed" />} />
        </Routes>
      </div>
    </div>
  );
};

export default memo(VSHomeView);
