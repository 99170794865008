import { useContext, useEffect, useMemo } from "react";

import FacesAuthed from "@/components/status/FacesAuthed";
import InstallExtensionButton from "@/components/status/InstallExtensionButton";
import InstallLandingPage from "@/components/status/InstallLandingPage";
import Loading from "@/components/lib/Loading";
import MilestoneContext from "@/contexts/MilestoneContext";
import PrivacyLink from "@/components/status/PrivacyLink";
import SectionDiv from "@/components/status/SectionDiv";
import ShadowWrapper from "@/components/status/ShadowWrapper";
import StatusBox from "@/components/status/StatusBox";
import StatusContainer from "@/components/status/StatusContainer";
import SuspenseContainer from "@/components/SuspenseContainer";
import Title from "@/components/Title";
import UserContext from "@/contexts/UserContext";
import { useParams } from "react-router-dom";

const StatusAuthedContent = ({ username, extensionInstalled }) => {
  return (
    <>
      <Title>{username ? `${username}` : "Status!"}</Title>
      <StatusContainer>
        <SectionDiv stay={true}>
          <div className="py-8">
            <a href="/">
              <img src="/assets/stateful_icon.svg" className="h-20" alt="Stateful" />
            </a>
          </div>
        </SectionDiv>
        <SectionDiv stay={true}>
          <SuspenseContainer>
            <StatusBox username={username} />
          </SuspenseContainer>
        </SectionDiv>

        <SectionDiv>
          <hr className="w-full" />
        </SectionDiv>
        <SectionDiv stay={true}>
          <FacesAuthed />
        </SectionDiv>

        {!extensionInstalled && (
          <>
            <SectionDiv stay={true}>
              <div className="flex flex-col py-6 mb-8 space-y-2">
                <InstallExtensionButton />
                <PrivacyLink />
              </div>
            </SectionDiv>
          </>
        )}
      </StatusContainer>
    </>
  );
};

const StatusAuthed = () => {
  const { username } = useParams();

  const { userIsLoading } = useContext(UserContext);
  const { milestoneData, milestonesLoading, startPollingMilestones, stopPollingMilestones } =
    useContext(MilestoneContext);
  const milestoneMap = useMemo(() => {
    return milestoneData?.user?.milestones.reduce(function (accum, currentVal) {
      accum[currentVal.name] = currentVal;
      return accum;
    }, {});
  }, [milestoneData]);

  const extensionInstalled = useMemo(() => {
    return milestoneMap && milestoneMap["setup_vscode_extension"]?.status === "COMPLETED";
  }, [milestoneMap]);

  //poll until the milestone is set
  useEffect(() => {
    if (!extensionInstalled) {
      startPollingMilestones(6000);
    } else {
      stopPollingMilestones();
    }
  }, [extensionInstalled, startPollingMilestones, stopPollingMilestones]);

  if (userIsLoading || milestonesLoading) {
    return <Loading />;
  }

  if (username) {
    return (
      <ShadowWrapper>
        <StatusAuthedContent extensionInstalled={extensionInstalled} username={username} />
      </ShadowWrapper>
    );
  }

  return <InstallLandingPage />;
};

export default StatusAuthed;
