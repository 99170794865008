import { VSCodeDropdown, VSCodeOption } from "@vscode/webview-ui-toolkit/react";
import { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";

import Loading from "@/components/lib/Loading";
import SettingsContext from "@/contexts/SettingsContext";
import moment from "moment";

const TOTAL_BREAK_OPTIONS = [0, 1, 2, 3, 4, 5];
const DURATION_OPTION_COUNT = 12;
const DURATION_INCREMENTS = 300;

const PlannedBreaks = () => {
  const { saveUserDaySettings, daySettings } = useContext(SettingsContext);
  const [total, setTotal] = useState(null);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    if (!duration && daySettings) {
      if (daySettings.targetRestDurationSeconds === null) {
        setDuration(0);
      }
      setDuration(daySettings.targetRestDurationSeconds);
    }
    if (!total && daySettings) {
      if (daySettings.targetRestCount === null) {
        setTotal(0);
      }
      setTotal(daySettings.targetRestCount);
    }
  }, [daySettings, total, duration]);

  useEffect(() => {
    if (total !== null && daySettings) {
      if (daySettings.targetRestCount !== parseInt(total)) {
        saveUserDaySettings({
          targetRestCount: parseInt(total),
        });
      }
    }
    if (duration !== null && daySettings) {
      if (daySettings.targetRestDurationSeconds !== parseInt(duration)) {
        saveUserDaySettings({
          targetRestDurationSeconds: parseInt(duration),
        });
      }
    }
  }, [total, duration, saveUserDaySettings, daySettings]);

  const durationOptionsArr = useMemo(() => {
    const durationsArr = [0];
    for (var i = 1; i <= DURATION_OPTION_COUNT; i++) {
      durationsArr.push(i * DURATION_INCREMENTS);
    }
    return durationsArr;
  }, []);

  const onChangeDuration = useCallback((e) => {
    setDuration(e.target.value);
  }, []);

  const onChangeTotal = useCallback((e) => {
    setTotal(e.target.value);
  }, []);

  if (!total || !duration) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex flex-col space-y-2">
        <div className="text-description">
          Plan some breaks from the screen for your eyes and your brain!
        </div>
        <div className="flex items-center py-1 space-x-1 background">
          <VSCodeDropdown
            value={total.toString()}
            title="total"
            name="total"
            onChange={onChangeTotal}
          >
            {TOTAL_BREAK_OPTIONS.map((optionValue, index) => {
              return (
                <VSCodeOption
                  name={optionValue}
                  key={`${optionValue}-${index}`}
                  value={optionValue.toString()}
                >{`${optionValue}`}</VSCodeOption>
              );
            })}
          </VSCodeDropdown>
          <div>for</div>
          <VSCodeDropdown
            disabled={total === 0}
            value={duration.toString()}
            name="duration"
            title="duration"
            onChange={onChangeDuration}
          >
            {durationOptionsArr.map((seconds, index) => {
              const label = `${moment.duration(seconds, "seconds").asMinutes()}`;

              return (
                <VSCodeOption key={`${seconds}-${index}`} value={seconds.toString()} name={label}>
                  {label} minutes
                </VSCodeOption>
              );
            })}
          </VSCodeDropdown>
        </div>
      </div>
    </>
  );
};

export default memo(PlannedBreaks);
