import {
  PROFILE_FRAGMENT,
  PersonRow,
  RELATIONSHIP_FRAGMENT,
} from "@/components/connections/PeopleWrappers";
import { gql, useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import InviteTeamForm from "@/components/InviteTeamForm";
import Loading from "@/components/lib/Loading";
import { VSCodeTextField } from "@vscode/webview-ui-toolkit/react";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_RECOMMENDED_FOLLOWS = gql`
  ${RELATIONSHIP_FRAGMENT}
  query Relationships {
    user {
      id
      peopleToFollow: relationships(showFollowing: false) {
        ...ProfileFields
      }
    }
  }
`;

const GET_PROFILES_ALL = gql`
  ${PROFILE_FRAGMENT}

  query getProfilesAll {
    profilesAll(pageSize: 40, sharingFields: { feed: FOLLOWERS }) {
      data {
        ...UserProfileFields
      }
    }
  }
`;

const InviteTeamContainer = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full py-8">
      <div className="flex flex-col w-full px-4 space-y-2 vsm:w-3/4">
        <div className="font-medium text-covey-500">We don't have any new recommendations...</div>
        <div className="font-medium">
          Invite your teammates and start the trend! Stateful is a lot more fun with collaborators.
          👋
        </div>
        <div className="w-full pt-4">
          <InviteTeamForm />
        </div>
      </div>
    </div>
  );
};

const filterProfiles = (list, filter) => {
  return list.filter((user) => {
    const name = user?.github?.username || user?.displayName || user?.name;
    const nameMatch = name.toLowerCase().indexOf(filter) !== -1;
    const bioMatch = user?.bio && user?.bio?.indexOf(filter) !== -1;
    return nameMatch || bioMatch;
  });
};

const DiscoveryContainer = () => {
  const { pollInterval } = useContext(AppContext);
  const [filter, setFilter] = useState("");

  const {
    loading: recsLoading,
    error: recsError,
    data: recsData,
    refetch: recsRefetch,
  } = useQuery(GET_RECOMMENDED_FOLLOWS, { pollInterval: pollInterval });

  const {
    loading: profilesLoading,
    error: profilesError,
    data: profilesData,
    refetch: profilesRefetch,
  } = useQuery(GET_PROFILES_ALL, { pollInterval: pollInterval });

  useWindowFocus(0, () => {
    recsRefetch();
    profilesRefetch();
  });

  useEffect(() => {
    recsRefetch();
    profilesRefetch();
  }, [recsRefetch, profilesRefetch]);

  const recList = useMemo(() => {
    const userList = recsData?.user?.peopleToFollow?.data;
    if (!filter) {
      return userList;
    }
    return filterProfiles(userList, filter);
  }, [recsData, filter]);

  const profileList = useMemo(() => {
    if (profilesData) {
      const validUsers = profilesData?.profilesAll?.data
        ?.filter((user) => !user.following)
        .filter((user) => user?.github?.username || user?.displayName || user.name);

      if (!filter) {
        return validUsers;
      }

      return filterProfiles(validUsers, filter);
    }
  }, [profilesData, filter]);

  if (recsLoading || profilesLoading) return <Loading />;
  if (recsError) return <ErrorGrow message={recsError.message} />;
  if (profilesError) return <ErrorGrow message={profilesError.message} />;

  if (recList && recList.length === 0 && profileList && profileList.length === 0) {
    return <InviteTeamContainer />;
  }

  return (
    <div className="flex flex-col overflow-hidden divide-y">
      {recList && recList.length !== 0 && profileList && profileList.length !== 0}
      <div className="px-4 py-2">
        <VSCodeTextField
          type="text"
          className="w-full"
          value={filter}
          placeholder="Filter..."
          onInput={(e) => {
            setFilter(e.target.value);
          }}
        />
      </div>
      <div className="w-full h-full px-4 py-2 overflow-auto">
        <div>
          {recList && recList.length > 0 && (
            <>
              <div className="py-1 font-medium">Collaborators</div>
              {recList.map((rec) => {
                return <PersonRow user={rec} key={rec.id} refetch={recsRefetch} />;
              })}
            </>
          )}
          {profileList && profileList.length > 0 && (
            <>
              <div className="py-1 font-medium">Community</div>
              {profileList.map((profile) => {
                return <PersonRow user={profile} key={profile.id} refetch={profilesRefetch} />;
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscoveryContainer;
