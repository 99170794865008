import { SnackbarProvider, useSnackbar } from "notistack";
import { createRef, useMemo } from "react";

import { XCircleIcon } from "@heroicons/react/outline";
import Zoom from "@material-ui/core/Zoom";
import clsx from "clsx";

const Notification = ({ children, isError, onDismiss }) => {
  return (
    <div
      className={clsx(
        "flex-row-centered justify-between px-2 py-1 mx-4 space-x-4 border rounded shadow-md text-notif-fg bg-notif-bg",
        isError ? "text-notif-error-fg" : "text-notif-fg"
      )}
    >
      <div>{children}</div>
      <button onClick={() => onDismiss()}>
        <XCircleIcon
          className={clsx("h-5 text-notif-info-fg", { "text-notif-error-fg": isError })}
        />
      </button>
    </div>
  );
};

export const useNotification = (autoHideDuration = 3000) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMemo(
    () => ({
      error: (message) => {
        enqueueSnackbar(message, {
          autoHideDuration,
          content: (key, message) => (
            <div key={key}>
              <Notification isError onDismiss={onClickDismiss(key)}>
                {message}
              </Notification>
            </div>
          ),
        });
      },
      info: (message) => {
        enqueueSnackbar(message, {
          autoHideDuration,
          content: (key, message) => (
            <div key={key}>
              <Notification onDismiss={onClickDismiss(key)}>{message}</Notification>
            </div>
          ),
        });
      },
    }),
    [autoHideDuration, enqueueSnackbar]
  );
};

const notistackRef = createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

export const NotificationProvider = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={1}
      preventDuplicate
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      TransitionComponent={Zoom}
      dense
      ref={notistackRef}
      children={children}
      // classes={{
      //   containerRoot: { root: "bg-transparent" },
      // }}
    />
  );
};
