import PrivacyLink from "@/components/status/PrivacyLink";
import ShadowWrapper from "@/components/status/ShadowWrapper";
import { ExclamationIcon } from "@heroicons/react/outline";

const Login = () => {
  return (
    <ShadowWrapper>
      <div className="h-full p-8 border rounded shadow-md">
        <div className="flex flex-col justify-center w-full h-full max-w-3xl p-8 mx-auto space-y-8 text-center md:justify-between md:w-full">
          <img src="/assets/stateful_icon.svg" className="h-16" alt="Stateful" />
          <div>
            <hr />
          </div>
          <div className="text-lg">
            We translate your coding meta-data into daily summaries and cool graphs.
          </div>
          <div className="w-full border border-warning-border bg-warning-bg my-2 rounded-sm p-1 flex flex-row items-top space-x-2 text-input-fg">
            <ExclamationIcon className="w-6 h-6 min-w-min" />
            <div>
              <span className="font-semibold">Important:</span> Coding metrics stopped collecting
              data as of on October 30, 2023. Contact us on{" "}
              <a
                className="link"
                href="https://discord.gg/stateful"
                target="_blank"
                rel="noreferrer"
              >
                Discord
              </a>{" "}
              if you have any questions. We apologize for any inconvenience. Historical metrics and
              notes in the VS Code UX will continue to be available for the time being.
              <br />
              <br />
              Please visit{" "}
              <a className="underline font-semibold" href={"https://runme.dev"} alt={"runme.dev"}>
                https://runme.dev
              </a>{" "}
              to learn how the team is making{" "}
              <a className="underline font-semibold" href={"https://github.com/stateful/runme"}>
                Runme (Open Source)
              </a>{" "}
              run your Markdown docs.
            </div>
          </div>
          <div className="flex flex-col items-center justify-center py-4 space-y-0">
            {/* <div>
              <AuthButton caption={"Sign in with Github"} />
            </div> */}
            <PrivacyLink />
          </div>
        </div>
      </div>
    </ShadowWrapper>
  );
};

export default Login;
