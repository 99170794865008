import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip } from "recharts";
import {
  GraphReTooltip,
  LegendWrapper,
  LineWrap,
  XAxisWrap,
  YAxisWrap,
} from "@/components/graphs/ChartWrappers";

import { getColors } from "@/utils/colors";
import moment from "moment";
import { secondsToHuman } from "@/utils/convert";

const CustomTooltip = ({ active, payload, label, formatValue = (entry) => entry.value }) => {
  if (active && payload) {
    return (
      <GraphReTooltip>
        <div className="pb-2">{label}</div>
        <div>
          {payload.map((entry) => (
            <div key={entry.name} style={{ color: entry.color }}>
              {entry.name}: {formatValue(entry)}
            </div>
          ))}
        </div>
      </GraphReTooltip>
    );
  }

  return <></>;
};

const FlowAndScoreGraph = ({ data }) => {
  const colors = getColors(2);
  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 0,
          bottom: 10,
        }}
      >
        <CartesianGrid stroke="var(--vscode-panel-border)" />

        {XAxisWrap({ dataKey: "date", dy: 10 })}
        {YAxisWrap({
          yAxisId: "totalSeconds",
          orientation: "left",
          tickFormatter: (num) => {
            return moment.duration(num, "seconds").asHours().toFixed(0);
          },
          label: {
            value: "Hours",
            angle: -90,
            dx: -5,
          },
        })}
        {YAxisWrap({
          yAxisId: "score",
          orientation: "right",
          label: {
            value: "Score",
            angle: 90,
            dx: 10,
          },
        })}

        <Bar
          yAxisId="score"
          dataKey="score"
          name="Score"
          fill={colors[1]}
          fillOpacity="0.7"
          isAnimationActive={false}
        />
        {LineWrap({ name: "Activity", id: "totalSeconds", color: colors[0], strokeWidth: 4 })}
        {/* {LineWrap({ name: "Score", id: "score", color: colors[1] })} */}
        <Tooltip
          content={
            <CustomTooltip
              formatValue={(entry) =>
                entry.id === "totalSeconds" ? secondsToHuman(entry.value) : entry.value
              }
            />
          }
        />
        {LegendWrapper()}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default FlowAndScoreGraph;
