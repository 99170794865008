import { gql, useQuery } from "@apollo/client";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import SettingsContainer from "@/components/settings/SettingsContainer";
import Title from "@/components/Title";
import { upperCaseFirst } from "@/utils/tools";
import { useParams } from "react-router-dom";

const GET_USER = gql`
  query GetUser {
    user {
      company
      bio
      linkedin
      twitter
      siteUrl
      id
      name
      displayName
      photoUrl
      github {
        username
      }
    }
  }
`;

const Settings = () => {
  const { page } = useParams();
  const { loading: userLoading, error } = useQuery(GET_USER);

  if (userLoading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorGrow message={error.message} />;
  }
  return (
    <>
      <Title>{`Settings • ${upperCaseFirst(page)}`}</Title>
      <div className="flex flex-col w-full h-full space-y-3">
        <SettingsContainer />
      </div>
    </>
  );
};

export default Settings;
