import {
  CardContent,
  CardTitle,
  CardTitleIcon,
  CardWrapper,
} from "@/components/cards/CardWrappers";
import { VSCodeButton, VSCodeCheckbox, VSCodeTextArea } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext, useRef, useState } from "react";

import Dots from "@/components/lib/Dots";
import { MilestoneContext } from "@/contexts/MilestoneContext";
import RatingsWidget from "@/components/RatingsWidget";
import { StarIcon } from "@heroicons/react/outline";
import moment from "moment";
import { useNotification } from "@/contexts/NotificationContext";

const UPDATE_RATING = gql`
  mutation updateRating($input: SetUserDayRatingInput!) {
    setUserDayRating(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const GET_TODAY_RATING = gql`
  query getTodayRating {
    day {
      rating
      date
      ratingNote
    }
  }
`;

const SET_CARD_VISIBILITY = gql`
  mutation updateCardVis($input: SetFeedItemsInput!) {
    setFeedItems(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const SET_RATING_PROMPT = gql`
  mutation setRatingPromptSettings($ratingPromptTime: Time) {
    setUserDaySettings(
      input: {
        dayOfWeeks: [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY]
        ratingPromptTime: $ratingPromptTime
      }
    ) {
      errors {
        message
        field
      }
    }
  }
`;

const displayDate = (date) => {
  return moment(date).calendar(null, {
    lastDay: "[yesterday]",
    sameDay: "[today]",
    nextDay: "[tomorrow]",
    lastWeek: "[last] dddd",
    nextWeek: "dddd",
    sameElse: "MMMM Do",
  });
};

const RateMyDayContent = (props) => {
  const { completeMilestones } = useContext(MilestoneContext);
  const [setUserDayRating, { loading: updateRatingLoading }] = useMutation(UPDATE_RATING, {
    refetchQueries: [{ query: GET_TODAY_RATING }],
  });
  const [note, setNote] = useState(null);
  const [rating, setRating] = useState(0);
  const inputRef = useRef(null);
  const [updateCardVis] = useMutation(SET_CARD_VISIBILITY);
  const notification = useNotification();
  const { item, feedRefetch } = props;

  const [setRatingPromptSettings, { loading: updatePromptLoading }] =
    useMutation(SET_RATING_PROMPT);

  const [dailyCheck, setDailyCheck] = useState(true);

  const saveDailyCheck = useCallback(
    (e) => {
      setDailyCheck(e.target.checked);
      if (e.target.checked) {
        setRatingPromptSettings({ variables: { ratingPromptTime: "16:00:00" } });
      } else {
        setRatingPromptSettings({ variables: { ratingPromptTime: null } });
      }
    },
    [setRatingPromptSettings]
  );

  const onSetRating = useCallback(
    (rating) => {
      setRating(rating);
      inputRef.current.focus();
    },
    [inputRef]
  );

  const dismissCard = useCallback(
    (item, message) => {
      updateCardVis({
        variables: {
          input: {
            dismissed: true,
            feedItemIds: [item.id],
          },
        },
      }).then(() => {
        notification.info(message);
        feedRefetch();
      });
    },
    [feedRefetch, notification, updateCardVis]
  );

  const saveRating = useCallback(() => {
    completeMilestones("rate_day");
    if (rating > 0) {
      const date = moment(item.time).format("YYYY-MM-DD");
      const ratingObj = { rating: rating, date: date };
      if (note) {
        ratingObj.note = note;
      }
      setUserDayRating({ variables: { input: ratingObj } }).then(() => {
        dismissCard(item, "Rating saved!");
      });
    } else {
      dismissCard(item, "Rating skipped.");
    }
  }, [rating, setUserDayRating, note, item, dismissCard, completeMilestones]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full space-y-1">
      <div className="py-2">
        <RatingsWidget rating={rating} setRating={onSetRating} size={"25px"} />
      </div>
      <div className="flex w-full py-2">
        <VSCodeTextArea
          className="w-full text-sm inputBase"
          ref={inputRef}
          name="note"
          rows={2}
          placeholder="Add some context for your future self."
          value={note || ""}
          onInput={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
      <div className="justify-between w-full flex-row-centered">
        <div className="justify-start space-x-2 flex-row-centered ">
          <VSCodeCheckbox checked={dailyCheck} onChange={saveDailyCheck} />
          <label htmlFor="show_checkin" className="block ml-2 ">
            Keep asking me.
          </label>
        </div>
        <div>{updateRatingLoading || (updatePromptLoading && <Dots height={6} />)}</div>

        <VSCodeButton onClick={saveRating}>{rating === 0 ? <>Skip</> : <>Save</>}</VSCodeButton>
      </div>
    </div>
  );
};

const RateMyDayFeedItem = (props) => {
  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        title={
          <div className="space-x-2 flex-row-centered">
            <CardTitleIcon {...props}>
              <StarIcon />
            </CardTitleIcon>
            <div>Rate my day</div>
            <div className="text-description">({displayDate(props.item.time)})</div>
          </div>
        }
      />
      <CardContent>
        <RateMyDayContent {...props} />
      </CardContent>
    </CardWrapper>
  );
};

export default RateMyDayFeedItem;
