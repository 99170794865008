import { CardContent, CardFooter, CardTitle, CardWrapper } from "@/components/cards/CardWrappers";
import { gql, useQuery } from "@apollo/client";

import DurationOnlyGraph from "@/components/graphs/DurationOnlyGraph";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import { ReactionDisplay } from "@/components/Reaction";
import ReactionPop from "@/components/pops/ReactionPop";
import UserAvatarStatus from "@/components/UserAvatarStatus";

var converter = require("number-to-words");

const GET_RECENT_CODINGS = gql`
  query GetRecentCodings {
    days {
      data {
        date
        codingDuration {
          totalMs
        }
      }
    }
  }
`;

const StreakCodingDurationFeedItem = (props) => {
  const { item } = props;

  const {
    data: codingData,
    loading: codingLoading,
    error: codingError,
  } = useQuery(GET_RECENT_CODINGS);

  const codings = codingData?.days.data
    .map((day) => {
      return { date: day.date, duration: day?.codingDuration?.totalMs };
    })
    .filter((day) => day.duration)
    .reverse();

  if (codingLoading) {
    return <Loading />;
  }
  if (codingError) {
    return <ErrorGrow error={codingError} />;
  }

  const messageYou = `You've hit your daily coding target for the ${converter.toOrdinal(
    item.count
  )} day in a row, keep up the good work! 🎊`;

  const messageOther = `${item?.profile?.github?.username} is on a ${converter.toOrdinal(
    item.count
  )} day coding tear, give them some kudos! 🎊`;

  const contentText = item.owner ? messageYou : messageOther;

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        date
        title={
          <div className="flex-row-centered space-x-2">
            <UserAvatarStatus face={item.profile} classes="w-5" />
            <div>Activity streak (day {item.count})</div>
          </div>
        }
      />
      <CardContent>
        <div className="flex flex-col h-full">
          <div>{contentText}</div>
          {item.owner && (
            <>
              <div className="h-24">
                <DurationOnlyGraph data={codings} />
              </div>
              <div className="flex justify-end text-xs text-description">
                (Graph shown only to you.)
              </div>
            </>
          )}
        </div>
      </CardContent>
      <CardFooter>
        <div className="flex-row-centered">
          <ReactionDisplay item={item} />
          <ReactionPop item={item} />
        </div>
      </CardFooter>
    </CardWrapper>
  );
};

export default StreakCodingDurationFeedItem;
