import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { cloneElement, useState } from "react";

import clsx from "clsx";

const ExpandSection = ({ children, title, icon, defaultOpen = false, overflow = true }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className="border rounded-sm">
      <div className="flex flex-col">
        <div
          className={clsx(
            { "border-b": open },
            "flex-row-centered justify-between p-2 cursor-pointer"
          )}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="space-x-2 flex-row-centered">
            {icon && <div>{cloneElement(icon, { className: "w-4 text-description" })}</div>}
            <div className="font-medium">{title}</div>
          </div>
          <div>
            {open ? (
              <ChevronUpIcon className="h-4 icon" />
            ) : (
              <ChevronDownIcon className="h-4 icon" />
            )}
          </div>
        </div>
        <div
          className={clsx({
            hidden: !open,
            block: open,
          })}
        >
          <div
            className={clsx(
              { "overflow-auto": overflow },
              "w-full p-4 space-x-2 flex-row-centered"
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandSection;
