import SettingsContainer from "@/components/settings/SettingsContainer";
import StyleWrapper from "@/components/StyleWrapper";
import { useState } from "react";

const SettingsManager = () => {
  const [dark, setDark] = useState(false);

  return (
    <StyleWrapper setThemeDark={setDark} classes="flex justify-center w-full h-full">
      <div className="flex flex-col w-full h-full max-w-6xl overflow-hidden">
        <div className="w-full py-4">
          {dark ? (
            <img className="w-auto h-7" src="/assets/stateful_logo_white.png" alt="Stateful" />
          ) : (
            <img className="w-auto h-7" src="/assets/stateful_logo.png" alt="Stateful" />
          )}{" "}
        </div>
        <div className="flex-grow overflow-auto border-t rounded-sm border-x">
          <SettingsContainer root="/manage" />
        </div>
      </div>
    </StyleWrapper>
  );
};

export default SettingsManager;
