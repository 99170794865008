import { CalendarIcon } from "@heroicons/react/outline";
import DateRangeSelector from "@/components/pops/DateRangeSelector";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import { memo } from "react";
import moment from "moment";

const CoolDateRangeSelector = memo(({ selectionRange, setSelectionRange }) => {
  return (
    <div className="flex items-center justify-center w-full">
      <DateRangeSelector
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
        icon={
          <div className="w-full flex-row-centered">
            <VSCodeLink className="hidden vsm:block">
              {selectionRange.startDate
                ? moment(selectionRange.startDate).format("ll")
                : moment().subtract(8, "day").format("ll")}
            </VSCodeLink>

            <div className="hidden vsm:block">-</div>
            <VSCodeLink className="hidden vsm:block">
              {selectionRange.endDate ? moment(selectionRange.endDate).format("ll") : "Now"}
            </VSCodeLink>

            <div>
              <CalendarIcon className="h-4 icon" />
            </div>
          </div>
        }
      />
    </div>
  );
});

export default CoolDateRangeSelector;
