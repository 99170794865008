import "@/styles/edit.css";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentReportIcon,
  DocumentTextIcon,
} from "@heroicons/react/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import EasyEdit, { Types } from "react-easy-edit";
import {
  VSCodeCheckbox,
  VSCodeDropdown,
  VSCodeLink,
  VSCodeOption,
} from "@vscode/webview-ui-toolkit/react";
import { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";

import Dots from "@/components/lib/Dots";
import ErrorGrow from "@/components/lib/ErrorGrow";
import ExpandCenter from "@/components/lib/ExpandCenter";
import Loading from "@/components/lib/Loading";
import ModalBig from "@/components/modals/ModalBig";
import NoteCustomDisplay from "@/components/NoteCustomDisplay";
import NoteCustomInput from "@/components/NoteCustomInput";
import NoteStandupSelectPop from "@/components/pops/NoteStandupSelectPop";
import RatingPop from "@/components/pops/RatingPop";
import SettingsModal from "@/components/modals/SettingsModal";
import StandupContext from "@/contexts/StandupContext";
import clsx from "clsx";
import moment from "moment";
import { msToHuman } from "@/utils/convert";
import { msToMin } from "@/utils/convert";
import { useDialog } from "@/contexts/DialogContext";
import { useNotification } from "@/contexts/NotificationContext";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const getDateDisplay = (date, markToday = true) => {
  const today = markToday ? "🎈" : "";
  return moment(date).calendar(null, {
    lastDay: "dddd",
    sameDay: `dddd ${today}`,
    nextDay: "[Upcoming]",
    lastWeek: "dddd",
    nextWeek: "[Upcoming]",
    sameElse: "MMMM Do",
  });
};

const DateLink = ({ day, selectedDate, setSelectedDate }) => {
  const displayDate = useMemo(() => {
    return getDateDisplay(day);
  }, [day]);

  const selected = useMemo(() => {
    return moment(selectedDate).isSame(day, "day");
  }, [day, selectedDate]);

  const handleSelectDate = useCallback(() => {
    setSelectedDate(moment(day).toDate());
  }, [day, setSelectedDate]);

  return (
    <div
      className={clsx(
        "whitespace-nowrap flex items-center cursor-pointer hover:text-tab-active-fg text-tab-inactive-fg mx-2 border-b border-transparent pb-0.5",
        {
          "text-tab-active-fg border-tab-active-border": selected,
        }
      )}
      onClick={handleSelectDate}
    >
      {displayDate}
    </div>
  );
};

const DateDropdown = ({ selectedDate, setSelectedDate, allDays }) => {
  return (
    <VSCodeDropdown
      className="w-full"
      value={moment(selectedDate).format("YYYY-MM-DD")}
      onChange={(e) => {
        setSelectedDate(e.target.value);
      }}
    >
      {allDays.map((day) => {
        return (
          <VSCodeOption value={day} key={day}>
            {getDateDisplay(day)}
          </VSCodeOption>
        );
      })}
    </VSCodeDropdown>
  );
};

const DayLinks = ({ allDays, selectedDate, setSelectedDate }) => {
  return (
    <div className="space-x-2 flex-row-centered">
      {allDays.slice(-3).map((day) => {
        return (
          <DateLink
            key={day}
            day={day}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        );
      })}
    </div>
  );
};

const DaySelector = memo((props) => {
  const { recents } = props;
  const [open, setOpen] = useState(false);

  const allDays = useMemo(() => {
    let dayArr = recents.map((day) => day.date);
    return dayArr.reverse();
  }, [recents]);

  return (
    <>
      <div className="flex-row items-center hidden px-2 py-1 vsm:flex">
        <div className="flex w-full h-6">
          {!open && <DayLinks allDays={allDays} {...props} />}
          {open && (
            <div className="flex-shrink pt-0.5">
              <DateDropdown {...props} allDays={allDays} />
            </div>
          )}
        </div>
        <div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <ChevronRightIcon className="h-4 text-tab-active-fg" />
            ) : (
              <ChevronLeftIcon className="h-4 text-tab-active-fg" />
            )}
          </div>
        </div>
      </div>
      <div className="block w-full vsm:hidden">
        <DateDropdown {...props} allDays={allDays} />
      </div>
    </>
  );
});

const TreeRow = ({ children, ...props }) => {
  const { duration } = props;

  const { openNoteAddModal } = useContext(StandupContext);

  return (
    <div className="flex flex-row justify-between group">
      <div className="flex-row-centered">
        {children}
        <VSCodeLink
          className="hidden pr-2 group-hover:block"
          onClick={() => {
            openNoteAddModal();
          }}
        >
          Add note
        </VSCodeLink>
      </div>
      <div className="flex flex-row space-x-2">
        <div className="group-hover:underline">{msToHuman(duration)}</div>
      </div>
    </div>
  );
};

const TreeFileSection = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const { files } = props;

  const fileArr = useMemo(() => {
    if (open) {
      return files;
    } else {
      return files.slice(0, 3);
    }
  }, [files, open]);

  const label = useMemo(() => {
    if (open) {
      return `Less files`;
    } else {
      return `More files (${files.length})`;
    }
  }, [open, files]);

  return (
    <div>
      {fileArr.map((file) => (
        <TreeRow
          {...props}
          key={file.path}
          title={file.name}
          filePath={file.path}
          duration={file.codingDuration.totalMs}
        >
          <div className="hidden pl-4 text-description vmd:block">{file.path}</div>
          <div className="block pl-4 text-description vmd:hidden">{file.name}</div>
        </TreeRow>
      ))}
      {files.length > 5 && (
        <div className="flex flex-shrink pt-1 pl-4">
          <ExpandButton open={open} setOpen={setOpen} label={label} classes="w-3" />
        </div>
      )}
    </div>
  );
};

const ProjectTree = ({ ...props }) => {
  const { projects } = props;
  return (
    <div className="flex flex-col px-2 space-y-2">
      {projects.map((project) => {
        const totalProjectMins = msToMin(project.codingDuration.totalMs);
        if (totalProjectMins > 1) {
          return (
            <div className="flex flex-col" key={project.id}>
              <TreeRow {...props} duration={project.codingDuration.totalMs}>
                <div className="font-semibold text-covey-500">{project.displayName}</div>
              </TreeRow>

              <div className="space-y-2">
                {project.gitBranches.data.map((branch) => {
                  const totalBranchMins = msToMin(branch.codingDuration.totalMs);
                  if (typeof branch.name === "string" && totalBranchMins > 1) {
                    const branchName = branch.name.length > 0 ? branch.name : "unknown";
                    return (
                      <div className="flex flex-col" key={project.id + "_" + branchName || "empty"}>
                        <TreeRow
                          title={branchName}
                          gitBranchName={branchName}
                          projectId={project.id}
                          duration={branch.codingDuration.totalMs}
                          {...props}
                        >
                          <div className="font-medium">{branchName || "empty"}</div>
                        </TreeRow>
                        <TreeFileSection
                          files={branch.files.data}
                          gitBranchName={branchName}
                          projectId={project.id}
                          {...props}
                        />
                      </div>
                    );
                  } else {
                    return true;
                  }
                })}
              </div>
            </div>
          );
        } else {
          return true;
        }
      })}
    </div>
  );
};

const Section = ({ children }) => {
  return <div className="w-full py-1.5">{children}</div>;
};

const SectionHeader = ({ children }) => {
  return <div className="py-1 ">{children}</div>;
};

const DataRow = ({ label, value }) => {
  return (
    <tr>
      <td className="pr-8 text-description">{label}</td>
      <td className="">{value}</td>
    </tr>
  );
};

const ExpandButton = ({ open, setOpen, label = null, classes = "w-4" }) => {
  const ChevronClasses = `group-hover:text-covey-500 icon ${classes}`;

  const handleOpen = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return (
    <div
      onClick={handleOpen}
      className={clsx("cursor-pointer group hover:text-covey-500 text-foreground", {
        "flex flex-row space-x-1 items-center": label !== null,
      })}
    >
      {label !== null && <div className="text-xs font-medium">{label}</div>}
      <ChevronUpIcon
        className={clsx(ChevronClasses, {
          hidden: !open,
          block: open,
        })}
      />
      <ChevronDownIcon
        className={clsx(ChevronClasses, {
          hidden: open,
          block: !open,
        })}
      />
    </div>
  );
};

const NoteRow = ({ note }) => {
  const [open, setOpen] = useState(false);
  const { updateNote, refetchStandupData } = useContext(StandupContext);
  const notification = useNotification();
  const [checked, setChecked] = useState(note.completed);

  const doUpdateNote = useCallback(
    (noteObj) => {
      updateNote({
        variables: { input: noteObj },
      })
        .then(() => {
          notification.info("Note updated");
        })
        .catch(() => {
          notification.error("Error updating note");
        });
    },
    [updateNote, notification]
  );

  useEffect(() => {
    if (note.completed !== checked) {
      doUpdateNote({ id: note.id, complete: checked });
    }
  }, [checked, doUpdateNote, note]);

  const saveEditedContent = (value) => {
    doUpdateNote({ id: note.id, content: value });
  };

  return (
    <div className="flex flex-col">
      <div className="flex-row-centered justify-between py-0.5 ">
        <div className="w-full space-x-2 overflow-hidden cursor-pointer flex-row-centered">
          <div className="flex items-center">
            {note.type === "TODO" ? (
              <VSCodeCheckbox
                className="cursor-pointer"
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              />
            ) : (
              <div>
                <DocumentTextIcon className="w-4 text-fg mr-0.5" />
              </div>
            )}
          </div>

          <div
            className="w-full px-2 truncate vmd:px-4 whitespace-nowrap"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {note.content}
          </div>
        </div>
        <div className="space-x-2 flex-row-centered">
          <div className="hidden px-4 whitespace-nowrap vsm:block">
            {moment(note.createTime).format("MMM D")}
          </div>
          <ExpandButton open={open} setOpen={setOpen} />
          <NoteStandupSelectPop
            note={note}
            doUpdateNote={doUpdateNote}
            refetchNotes={refetchStandupData}
          />
        </div>
      </div>
      <div
        className={clsx(
          "p-2 border rounded m-2 bg-backgroundSecondary flex flex-col overflow-auto",
          {
            hidden: !open,
            block: open,
          }
        )}
      >
        <div>{moment(note.createTime).local().format("LL, h:mm a")}</div>
        <div>
          {note.project?.displayName && `${note.project?.displayName}`}
          {note.gitBranch?.name && `#${note.gitBranch?.name}`}
          {note.file?.path && `: ${note.file?.path}`}
        </div>
        <div>&nbsp;</div>
        <div>
          <EasyEdit
            type={Types.TEXTAREA}
            value={note.content}
            onSave={saveEditedContent}
            saveOnBlur
            saveButtonLabel="✅"
            cancelButtonLabel="❌"
            editComponent={<NoteCustomInput />}
            displayComponent={<NoteCustomDisplay />}
            attributes={{
              rows: 2,
              name: "note-edit-input",
              style: {
                background: "none",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const NoteSection = ({ notes, type, label }) => {
  const { updateNoteLoading, openNoteAddModal } = useContext(StandupContext);
  const filtered = useMemo(() => {
    return notes && notes.filter((note) => note.type === type);
  }, [notes, type]);

  return (
    <Section>
      <div>
        <div className="justify-between flex-row-centered">
          <div className="flex-row-centered ">
            <div className="font-medium">{label}</div>
            <div>{updateNoteLoading && <Dots height={6} />}</div>
          </div>
          <div className="w-full px-2 overflow-hidden text-center">
            {filtered.length === 0 && (
              <div className="w-full text-sm font-normal truncate whitespace-nowrap text-description">
                (empty)
              </div>
            )}
          </div>

          <VSCodeLink
            onClick={() => {
              openNoteAddModal(type);
            }}
            className="whitespace-nowrap"
          >
            Add <span className="lowercase">{label}</span>
          </VSCodeLink>
        </div>
      </div>
      <div className="flex flex-col pt-1 divide-y divide-solid divide-border">
        {filtered.map((note) => (
          <NoteRow key={note.id} note={note} />
        ))}
      </div>
    </Section>
  );
};

const NoteContainer = (props) => {
  return (
    <>
      <NoteSection type="TODO" label="Todo" {...props} />
      <NoteSection type="NOTE" label="Note" {...props} />
    </>
  );
};

const DayDisplay = ({ day }) => {
  const activity = `${msToHuman(day.codingDuration?.totalMs)} ⏳`;
  return (
    <div className="flex flex-col space-y-1">
      <div className="flex flex-row space-x-2">
        <div className="w-full border-b">
          <span className="font-medium text-description">
            {moment(day.date).format("MMMM D, dddd")}
          </span>
        </div>
      </div>
      <div className="space-x-2 flex-row-centered">
        <div className="flex-row-centered flex-nowrap">
          {day.score > 50 ? <div>🔥</div> : <div>🎧</div>}
          <div className="font-medium text-covey-500">{day.score}</div>
        </div>
        <div>•</div>
        <div className="flex flex-row space-x-1">
          {day.rating && <div>{day.rating}/5</div>}
          <div>
            <RatingPop
              simple={true}
              startDate={moment(day.date).format("YYYY-MM-DD")}
              classes="w-4"
            />
          </div>
        </div>
        <div>•</div>
        <div>{activity}</div>
        <div className="hidden vmd:block">•</div>
        <div className="hidden vmd:block">
          {moment(day.startTime).local().format("ha")} - {moment(day.endTime).local().format("ha")}{" "}
          🕑
        </div>
      </div>
      {day.ratingNote && <div>{day.ratingNote}</div>}
    </div>
  );
};

const StandupContent = memo(() => {
  const { loadingStandup, errorStandup, dataStandup, refetchStandupData } =
    useContext(StandupContext);

  useWindowFocus(0, () => {
    refetchStandupData();
  });

  const days = dataStandup?.standup?.days?.data;
  const date = dataStandup?.standup?.date;
  const timezone = dataStandup?.standup?.timezone;
  const projects = dataStandup?.standup?.projects?.data;
  const dayCount = dataStandup?.standup?.dayCount;
  const codingDuration = dataStandup?.standup?.codingDuration;
  const userAnnotations = dataStandup?.standup?.userAnnotations?.data;
  const languages = dataStandup?.standup?.languages?.data;

  const counts = useMemo(() => {
    if (projects) {
      let fileCount = 0;
      let projectCount = 0;
      let branchCount = 0;
      projects.map((project) => {
        projectCount += 1;
        branchCount += project.gitBranches.data.length;

        const filesArr = project.gitBranches.data.map((branch) => {
          return branch?.files?.data.length;
        });
        fileCount += filesArr.reduce((partial, a) => partial + a, 0);
        return true;
      });
      return { files: fileCount, projects: projectCount, branches: branchCount };
    }
  }, [projects]);

  const daysIncludedMessage = useMemo(() => {
    if (!dayCount) {
      return null;
    } else if (dayCount === 1) {
      return "";
    } else {
      return `(includes ${dayCount} days)`;
    }
  }, [dayCount]);

  const totalMins = useMemo(() => {
    if (codingDuration?.totalMs) {
      return msToMin(codingDuration?.totalMs);
    }
  }, [codingDuration]);

  const dailyAverageMs = useMemo(() => {
    if (days && days.length > 0) {
      const durationArray = days.map((day) => day?.codingDuration?.totalMs);
      const durationTotal = durationArray.reduce((partial, a) => partial + a, 0);
      return durationTotal / dayCount;
    }
  }, [days, dayCount]);

  const languageCount = useMemo(() => {
    if (languages) {
      const languageArr = languages.map((data) => data.displayName);
      return languageArr.join(", ");
    }
  }, [languages]);

  const notes = useMemo(() => {
    if (userAnnotations) {
      return userAnnotations?.filter((note) => !note.hidden);
    }
  }, [userAnnotations]);

  if (loadingStandup) {
    return <Loading />;
  }
  if (errorStandup) return <ErrorGrow message={errorStandup.message} />;

  if (!dataStandup || dataStandup.standup === null || totalMins === undefined) {
    return (
      <ExpandCenter>
        <div className="flex-row-centered">
          <div>
            Preparing data for this standup, check back in <b>one</b> minute.
          </div>
          <Dots height={6} />
        </div>
      </ExpandCenter>
    );
  }

  return (
    <div className="w-full h-full px-4 pt-2 overflow-auto">
      <div className="flex flex-col">
        <div className="block font-semibold truncate vmd:hidden">
          {getDateDisplay(date, false)} Standup
        </div>
        <div className="hidden font-semibold truncate vmd:block">
          {getDateDisplay(date, false)} Standup
        </div>
        <div className="text-description">
          On {moment(date).format("MMMM D")} • {timezone}
        </div>
      </div>

      <Section>
        <SectionHeader>Summary {daysIncludedMessage}</SectionHeader>

        <table className="table-auto">
          <tbody>
            <DataRow
              label={"Activity:"}
              // value={`${msToHuman(codingDuration?.totalMs)} / (${msToHuman(
              //   codingDuration?.readingMs
              // )} read / ${msToHuman(codingDuration?.writingMs)} write) ⏳`}
              value={`${msToHuman(codingDuration?.totalMs)} ⏳`}
            />
            <DataRow label={"Daily Avg:"} value={`${msToHuman(dailyAverageMs)} 🕑`} />
            <DataRow label={"Tech:"} value={languageCount + " 🧰"} />
            <DataRow label={"Projects:"} value={counts?.projects} />
            <DataRow label={"Branches:"} value={counts?.branches} />
            <DataRow label={"Files:"} value={counts?.files} />
          </tbody>
        </table>
      </Section>

      <Section>
        <SectionHeader>Days</SectionHeader>
        <div className="flex flex-col space-y-3">
          {days.map((day) => {
            return <DayDisplay day={day} key={day.date} />;
          })}
        </div>
      </Section>

      <NoteContainer notes={notes} />

      <Section>
        <SectionHeader>Code</SectionHeader>
        <ProjectTree projects={projects} refetchData={refetchStandupData} date={date} />
      </Section>
    </div>
  );
});

const StandupContainer = () => {
  const { addDialog } = useDialog();

  const { loadingRecents, dataRecents, errorRecents, setSelectedDate, selectedDate } =
    useContext(StandupContext);

  const recents = useMemo(() => {
    if (dataRecents?.standups?.data) {
      return dataRecents?.standups?.data;
    }
  }, [dataRecents]);

  useEffect(() => {
    if (recents && recents.length > 0) {
      const hasTodayArr = recents.filter((day) => {
        return moment().isSame(day.date, "day");
      });
      if (hasTodayArr.length > 0) {
        setSelectedDate(moment(hasTodayArr[0].date).toDate());
      } else {
        setSelectedDate(moment(recents[0].date).toDate());
      }
    }
  }, [recents, setSelectedDate]);

  if (loadingRecents) {
    return <Loading />;
  }

  if (!recents || recents.length === 0) {
    return (
      <ExpandCenter>
        <div className="flex flex-col justify-center w-3/4 px-4 space-y-2">
          <div>
            <DocumentReportIcon className="w-12 text-indigo-500" />
          </div>
          <div>No standups found.</div>
          <div>
            <VSCodeLink
              onClick={() => {
                addDialog({
                  Component: (
                    <ModalBig>
                      <SettingsModal tab="schedule" />
                    </ModalBig>
                  ),
                });
              }}
            >
              Confirm your preferences, and check back tomorrow.
            </VSCodeLink>
          </div>
        </div>
      </ExpandCenter>
    );
  }

  if (errorRecents) return `Error! ${errorRecents.message}`;

  return (
    <div className="w-full h-full overflow-hidden">
      <div className="flex flex-col w-full h-full overflow-hidden">
        <DaySelector
          recents={recents}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />

        <div className="w-full h-full overflow-hidden">
          <StandupContent selectedDate={selectedDate} />
        </div>
      </div>
    </div>
  );
};

export default memo(StandupContainer);
