import DiscoveryContainer from "@/components/connections/DiscoveryContainer";
import ConnectionsContainer from "@/components/connections/ConnectionsContainer";
import OnlineContainer from "@/components/connections/OnlineContainer";
import RouteTabs from "@/components/RouteTabs";

const TABS = [
  {
    title: "Online",
    value: "online",
    url: "online",
    Component: <OnlineContainer />,
  },
  {
    title: "Connections",
    value: "connections",
    url: "connections",
    Component: <ConnectionsContainer />,
  },
  {
    title: "Discovery",
    value: "discovery",
    url: "discovery",
    Component: <DiscoveryContainer />,
  },
];

const DEFAULT_TAB = "online";

const PeopleContainer = () => {
  return <RouteTabs TABS={TABS} DEFAULT_TAB={DEFAULT_TAB} />;
};

export default PeopleContainer;
