import "@/styles/transition.css";

import AppContext from "@/contexts/AppContext";
import Title from "@/components/Title";
import { useContext } from "react";

const VSAuthView = () => {
  const { tangle } = useContext(AppContext);

  return (
    <div className="flex items-center justify-center h-screen px-4 text-center ">
      <Title>VS • Auth</Title>
      <div className="flex flex-col space-y-4">
        <div>We translate your coding meta-data into a daily summary and cool graphs.</div>
        <div className="text-description">Login with Github to get started.</div>

        <div className="flex items-center justify-center w-full">
          <button
            className="px-2 py-1 cursor-pointer bg-button-bg hover:bg-button-hover text-button-fg"
            onClick={() => {
              tangle?.emit("onCommand", "login");
            }}
          >
            Sign in with Github
          </button>
        </div>
      </div>
    </div>
  );
};

export default VSAuthView;
