import { useCallback, useState } from "react";

import LabelToggle from "@/components/LabelToggle";
import { STANDUP_HOUR } from "@/const/personalize";
import TimePicker from "@/components/TimePicker";
import { getWeekDayLabel } from "@/utils/personalize";
import { isWorking } from "@/utils/personalize";

const DailyStandupStartTimePickerRow = ({ day, standupTimezone, onChange }) => {
  const [enabled, setEnabled] = useState(day.standupTime !== null);
  const defaultTime = day.standupTime || STANDUP_HOUR;
  const label = getWeekDayLabel(day.dayOfWeek);
  const working = isWorking(day);

  const onToggle = useCallback(
    (value) => {
      setEnabled(value);
      const updatedDay = { ...day };

      updatedDay.standupTime = value ? defaultTime : null;
      updatedDay.standupTimezone = value ? standupTimezone : null;
      onChange(updatedDay);
    },
    [day, standupTimezone, defaultTime, setEnabled, onChange]
  );

  const onTimeChanged = useCallback(
    (time) => {
      const updatedDay = { ...day };
      updatedDay.standupTime = time;
      updatedDay.standupTimezone = standupTimezone;
      onChange(updatedDay);
    },
    [day, onChange, standupTimezone]
  );

  return (
    <div className="flex justify-start">
      <div className="flex items-center space-x-2">
        <div className="w-8 text-sm font-medium">{label}</div>
        <TimePicker disabled={!enabled || !working} value={defaultTime} onChange={onTimeChanged} />
        {working && <LabelToggle enabled={enabled} setEnabled={onToggle} label="" />}
      </div>
    </div>
  );
};

const DailyStandupStartTimePicker = (props) => {
  const { days, standupTimezone, onChange } = props;

  return (
    <div>
      {days.map((day) => {
        return (
          <DailyStandupStartTimePickerRow
            key={day.dayOfWeek}
            day={day}
            onChange={onChange}
            standupTimezone={standupTimezone}
          />
        );
      })}
    </div>
  );
};

export default DailyStandupStartTimePicker;
