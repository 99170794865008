import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import { useNotification } from "@/contexts/NotificationContext";

const GET_NOTES = gql`
  query getNotes(
    $query: String!
    $showHidden: Boolean
    $showCompleted: Boolean
    $order: SortOrder!
  ) {
    user {
      id
      annotations(
        pageSize: 100
        query: $query
        showHidden: $showHidden
        showCompleted: $showCompleted
        showDeleted: false
        sort: { field: CREATE_TIME, order: $order }
        types: [NOTE, TODO]
      ) {
        data {
          content
          date
          file {
            line
            name
            path
          }
          gitBranch {
            name
          }
          gitCommit
          id
          project {
            displayName
            id
            name
          }
          type
          completed
          hidden
          createTime
        }
      }
    }
  }
`;

const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateUserAnnotationInput!) {
    updateUserAnnotation(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const UPDATE_NOTES = gql`
  mutation updateNotes($input: UpdateUserAnnotationsInput!) {
    updateUserAnnotations(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const CREATE_NOTE = gql`
  mutation createNote($input: CreateUserAnnotationInput!) {
    createUserAnnotation(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const DELETE_NOTES = gql`
  mutation deleteNotes($input: DeleteUserAnnotationsInput!) {
    deleteUserAnnotations(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const NoteContext = createContext();

const NoteProvider = ({ children }) => {
  const { tangle } = useContext(AppContext);
  const { pollInterval } = useContext(AppContext);

  const [showNotesArchived, setNotesShowArchived] = useState(false);
  const [showNotesStatus, setNotesShowStatus] = useState(null);
  const [noteQuery, setNoteQuery] = useState("");
  const [notesGroupBy, setNotesGroupBy] = useState("none");
  const [noteOrder, setNoteOrder] = useState("DESC");
  const [bulk, setBulk] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const notification = useNotification();

  const showNotesCompleted = useMemo(() => {
    if (showNotesStatus === "incomplete") {
      return false;
    }
    if (showNotesStatus === "complete") {
      return true;
    }

    return null;
  }, [showNotesStatus]);

  const showNotesHidden = useMemo(() => {
    if (showNotesArchived === "show") {
      return true;
    }
    if (showNotesArchived === "mix") {
      return null;
    }
    return false;
  }, [showNotesArchived]);

  const refetchVariables = useMemo(() => {
    return {
      showHidden: showNotesHidden,
      showCompleted: showNotesCompleted,
      query: noteQuery,
      order: noteOrder,
    };
  }, [showNotesHidden, showNotesCompleted, noteQuery, noteOrder]);

  const refetchQueries = useMemo(() => {
    return [GET_NOTES, "getNotes", { variables: refetchVariables }];
  }, [refetchVariables]);

  const [updateNote, { loading: updateNoteLoading }] = useMutation(UPDATE_NOTE, {
    refetchQueries: refetchQueries,
  });

  const [updateNotes, { loading: updateNotesLoading }] = useMutation(UPDATE_NOTES, {
    refetchQueries: refetchQueries,
  });

  const [createNote, { loading: createNoteLoading }] = useMutation(CREATE_NOTE, {
    refetchQueries: refetchQueries,
  });

  const [deleteNotes, { loading: deleteNotesLoading }] = useMutation(DELETE_NOTES, {
    refetchQueries: refetchQueries,
  });

  //Polling needs to still exist
  //incase a note is created by vscode, or web
  const {
    loading: notesLoading,
    data: noteData,
    refetch: refetchNotes,
    error,
  } = useQuery(GET_NOTES, {
    variables: refetchVariables,
    pollInterval: pollInterval,
  });

  useEffect(() => {
    if (tangle && tangle.on) {
      tangle.on("onAnnotations", () => {
        // refresh note list if the note came from vscode
        refetchNotes();
      });
    }
  }, [tangle, refetchNotes]);

  const notes = useMemo(() => {
    return noteData?.user?.annotations?.data;
  }, [noteData]);

  const selectNote = useCallback(
    (id) => {
      setSelectedNotes([...selectedNotes, id]);
    },
    [selectedNotes]
  );

  const unselectNote = useCallback(
    (id) => {
      setSelectedNotes(selectedNotes.filter((noteId) => noteId !== id));
    },
    [selectedNotes]
  );

  const selectAllNotes = useCallback(() => {
    const noteIds = notes.map((note) => {
      return note.id;
    });
    setSelectedNotes(noteIds);
  }, [setSelectedNotes, notes]);

  const unselectAllNotes = useCallback(() => {
    setSelectedNotes([]);
  }, [setSelectedNotes]);

  const deleteSelectedNotes = useCallback(() => {
    const variables = {
      variables: {
        input: {
          ids: selectedNotes,
        },
      },
    };

    deleteNotes(variables).then((resp) => {
      notification.info("Notes deleted");
    });
  }, [selectedNotes, deleteNotes, notification]);

  const updateSelectedNotes = useCallback(
    (fieldsObj) => {
      const variables = {
        variables: {
          input: {
            ids: selectedNotes,
            ...fieldsObj,
          },
        },
      };

      updateNotes(variables).then((resp) => {
        notification.info("Notes updated");
      });
    },
    [selectedNotes, updateNotes, notification]
  );

  if (error) {
    return <ErrorGrow message={error.message} />;
  }

  return (
    <NoteContext.Provider
      value={{
        showNotesArchived,
        setNotesShowArchived,
        showNotesStatus,
        setNotesShowStatus,
        noteQuery,
        setNoteQuery,
        notesGroupBy,
        setNotesGroupBy,
        notes,
        notesLoading,
        noteData,
        refetchNotes,
        updateNote,
        updateNoteLoading,
        noteOrder,
        setNoteOrder,
        createNote,
        createNoteLoading,
        bulk,
        setBulk,
        selectedNotes,
        selectNote,
        unselectNote,
        selectAllNotes,
        unselectAllNotes,
        deleteSelectedNotes,
        updateSelectedNotes,
        deleteNotesLoading,
        updateNotesLoading,
      }}
    >
      {children}
    </NoteContext.Provider>
  );
};

export default NoteContext;
export { NoteProvider };
