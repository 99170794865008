import { Children, cloneElement, memo, useEffect } from "react";

import Modal from "react-modal";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { use100vh } from "react-div-100vh";

const ModalBig = ({ _close, children, dialog }) => {
  Modal.setAppElement("#root");
  const height = use100vh();

  useEffect(() => {
    disableBrowserBackButton();
  }, []);

  const childrenWithProps = Children.map(children, (child, index) => {
    return cloneElement(child, {
      dialog: dialog,
      _close: _close,
    });
  });

  return (
    <Modal
      onRequestClose={_close}
      isOpen={true}
      className="flex items-center justify-center w-full focus:outline-none"
      overlayClassName="inset-0 fixed bg-black bg-opacity-50 z-50"
      style={{ height: height }}
    >
      <div className="flex flex-col w-full focus:outline-none" style={{ height: height }}>
        <div className="w-full h-full max-w-3xl mx-auto">{childrenWithProps}</div>
      </div>
    </Modal>
  );
};

export default memo(ModalBig);
