import { createContext, useContext } from "react";
import { gql, useQuery } from "@apollo/client";

import AppContext from "@/contexts/AppContext";

const GET_WEB_HOOKS = gql`
  query GetWebHooks {
    user {
      id
      hooks(deliveryTypes: [WEB]) {
        data {
          ... on HookWeb {
            id
            name
            url
            disabled
            headers {
              name
              value
            }
            statusChange {
              default
              activity
              project
              language
              file
              gitBranch
            }
          }
        }
      }
    }
  }
`;

const GET_SLACK_CONNECTIONS = gql`
  query GetSlackConnections {
    user {
      id
      connections(types: [SLACK]) {
        data {
          id
          name
          disabled
          hooks {
            data {
              ... on HookSlackDnd {
                disabled
                deliveryType
              }
              ... on HookSlackStatus {
                disabled
                statusChange {
                  default
                  activity
                  project
                  language
                  file
                  gitBranch
                }
                deliveryType
              }
            }
          }
        }
      }
    }
  }
`;

export const HookContext = createContext();

const HookProvider = ({ children }) => {
  const { pollInterval } = useContext(AppContext);
  const {
    data: slacksData,
    loading: slacksLoading,
    error: slacksError,
    refetch: slacksRefetch,
  } = useQuery(GET_SLACK_CONNECTIONS, {
    pollInterval: pollInterval,
  });

  const {
    data: hooksData,
    loading: hooksLoading,
    error: hooksError,
    refetch: hooksRefetch,
  } = useQuery(GET_WEB_HOOKS, {
    pollInterval: pollInterval,
  });

  const webhooks = hooksData?.user?.hooks?.data;
  const slacks = slacksData?.user?.connections?.data;

  return (
    <HookContext.Provider
      value={{
        webhooks,
        hooksData,
        hooksLoading,
        hooksError,
        hooksRefetch,
        slacks,
        slacksData,
        slacksLoading,
        slacksError,
        slacksRefetch,
      }}
    >
      {children}
    </HookContext.Provider>
  );
};

const withHookContext = (Component) => {
  return function WrapperComponent(props) {
    return (
      <HookProvider>
        <Component {...props} />
      </HookProvider>
    );
  };
};

export default HookContext;
export { HookProvider, withHookContext };
