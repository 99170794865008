import { memo } from "react";

const VSLayout = ({ children }) => {
  return (
    <div className="h-full overflow-hidden bg-background text-foreground">
      <div className="flex flex-col w-full h-full">{children}</div>
    </div>
  );
};

export default memo(VSLayout);
