import AuthButton from "@/components/status/AuthButton";
import PrivacyLink from "@/components/status/PrivacyLink";
import SectionDiv from "@/components/status/SectionDiv";
import StatusContainer from "@/components/status/StatusContainer";
import { useMemo } from "react";

const EmptyStatusBox = ({ username, status = "Coding blabberscript on web in depot.ts" }) => {
  return (
    <div className="flex flex-row px-4 py-4 border rounded-full shadow-xl">
      <div className="flex items-center min-w-max">
        <div className="text-7xl">😀</div>
      </div>
      <div className="flex flex-col justify-center px-4 space-y-1 text-left text-start">
        <div className="text-3xl">{username}</div>
        <div className="text-lg">{`${status}`}</div>
      </div>
      <div className="items-center hidden md:flex text-7xl">🎧</div>
    </div>
  );
};

const StatusLandingPage = ({ sharing = true }) => {
  const headline = useMemo(() => {
    return sharing ? "Join the Stateful community 🚀" : "Welcome to Stateful";
  }, [sharing]);
  const caption = useMemo(() => {
    return sharing ? "Sign in with GitHub" : "Sign in with GitHub";
  }, [sharing]);

  return (
    <StatusContainer>
      <SectionDiv stay={true}>
        <div className="flex-row-centered space-x-4">
          <img src="/assets/stateful_icon.svg" className="h-16" alt="Stateful" />
          <div className="text-5xl">+</div>
          <img src="/assets/vscode.png" className="h-16" alt="VS Code" />
        </div>
      </SectionDiv>
      <SectionDiv stay={true}>
        <div className="text-3xl font-medium text-center">{headline}</div>
      </SectionDiv>
      {sharing && (
        <SectionDiv stay={true}>
          <EmptyStatusBox
            username="Your Github username"
            status="Coding awesome on my OSS project"
          />
        </SectionDiv>
      )}
      {/* <SectionDiv>
        <hr className="w-full" />
      </SectionDiv>
      <SectionDiv stay={true}>
        <div className="flex flex-col w-full text-center">
          <SuspenseContainer>
            <FacesUnauthed animate={true} />
          </SuspenseContainer>
        </div>
      </SectionDiv> */}
      {/* <SectionDiv>
        <hr className="w-full" />
      </SectionDiv> */}
      <SectionDiv stay={true}>
        <div className="flex flex-col py-6 mb-8 space-y-2">
          <AuthButton caption={caption} />
          <PrivacyLink />
        </div>
      </SectionDiv>
    </StatusContainer>
  );
};

export default StatusLandingPage;
