import { gql, useMutation, useQuery } from "@apollo/client";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import SimpleTooltip from "@/components/pops/SimpleTooltip";
import UserAvatarStatus from "@/components/UserAvatarStatus";
import { useCallback } from "react";
import { useMemo } from "react";
import { useNotification } from "@/contexts/NotificationContext";

const GET_FEED_ITEM_REACTIONS = gql`
  query getFeedItemReactions($id: UUID!) {
    feed {
      item(id: $id) {
        ... on FeedItemAbstract {
          id
          reactions {
            count
            me
            name
            recent {
              id
              name
              photoUrl
              github {
                id
                username
              }
            }
          }
        }
      }
    }
  }
`;

const ADD_REACTION = gql`
  mutation addReaction($input: SetFeedItemReactionInput!) {
    setFeedItemReaction(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const REMOVE_REACTION = gql`
  mutation removeReaction($input: UnsetFeedItemReactionInput!) {
    unsetFeedItemReaction(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const reactionMap = {
  CROSSED_FINGERS: "🤞",
  EYES: "👀",
  HEART: "❤️",
  LAUGHING: "🤣",
  ROCKET: "🚀",
  TADA: "🎉",
  THUMBSUP: "👍",
};

const reactionOrder = [
  "CROSSED_FINGERS",
  "EYES",
  "HEART",
  "LAUGHING",
  "ROCKET",
  "TADA",
  "THUMBSUP",
];

const ReactionButton = (props) => {
  const { item, reaction } = props;
  const notification = useNotification();
  const [removeReaction] = useMutation(REMOVE_REACTION, {
    refetchQueries: [
      GET_FEED_ITEM_REACTIONS,
      "getFeedItemReactions",
      { variables: { id: item.id } },
    ],
  });
  const [addReaction] = useMutation(ADD_REACTION, {
    refetchQueries: [
      GET_FEED_ITEM_REACTIONS,
      "getFeedItemReactions",
      { variables: { id: item.id } },
    ],
  });

  const doAddReaction = useCallback(
    (name) => {
      addReaction({
        variables: {
          input: {
            name: name,
            feedItemId: item.id,
          },
        },
      }).then((resp) => {
        notification.info("Got it!");
      });
    },
    [addReaction, item, notification]
  );

  const doRemoveReaction = useCallback(
    (name) => {
      removeReaction({
        variables: {
          input: {
            name: name,
            feedItemId: item.id,
          },
        },
      }).then((resp) => {
        notification.info("Removed it!");
      });
    },
    [removeReaction, item, notification]
  );

  if (!reaction) {
    return <></>;
  }

  return (
    <div
      className="px-1 space-x-1 rounded cursor-pointer flex-row-centered hover:bg-backgroundSecondary"
      onClick={() => {
        if (reaction.me) {
          doRemoveReaction(reaction.name);
        } else {
          doAddReaction(reaction.name);
        }
      }}
    >
      <div>{reactionMap[reaction.name]}</div>
      {reaction?.count > 1 && (
        <div className="text-xs font-medium text-description">{reaction.count}</div>
      )}
    </div>
  );
};

const ReactionEmoji = ({ reaction, item }) => {
  const [addReaction] = useMutation(ADD_REACTION, {
    refetchQueries: [
      GET_FEED_ITEM_REACTIONS,
      "getFeedItemReactions",
      { variables: { id: item.id } },
    ],
  });
  const notification = useNotification();

  const doAddReaction = useCallback(
    (name) => {
      addReaction({
        variables: {
          input: {
            name: name,
            feedItemId: item.id,
          },
        },
      }).then((resp) => {
        notification.info("Got it!");
      });
    },
    [addReaction, item, notification]
  );

  if (!reaction) {
    return <></>;
  }

  return (
    <div
      key={reaction}
      className="px-1 text-lg rounded cursor-pointer hover:bg-backgroundSecondary"
      onClick={() => {
        doAddReaction(reaction);
      }}
    >
      {reactionMap[reaction]}
    </div>
  );
};

export const Reactions = (props) => {
  const { label = false, item } = props;

  return (
    <div className="flex flex-col">
      {label && <div className="font-medium">Add some reactions:</div>}
      <div className="flex flex-row justify-center py-1 space-x-1 max-w-fit">
        {reactionOrder.map((reaction) => (
          <ReactionEmoji key={reaction} reaction={reaction} item={item} />
        ))}
      </div>
    </div>
  );
};

export const ReactionDisplay = (props) => {
  const { item } = props;

  const {
    loading: reactionLoading,
    error: reactionError,
    data: reactionData,
  } = useQuery(GET_FEED_ITEM_REACTIONS, { variables: { id: item.id } });

  const reactions = useMemo(() => {
    if (reactionData) {
      return reactionData?.feed?.item?.reactions;
    }
  }, [reactionData]);

  if (reactionLoading) return <Loading />;
  if (reactionError) return <ErrorGrow message={reactionError.message} />;

  return (
    <div className="relative flex flex-row space-x-1">
      {reactions &&
        reactions.map((reaction) => {
          if (reaction.me && reaction?.count === 1) {
            return <ReactionButton key={reaction.name} {...props} reaction={reaction} />;
          } else {
            return (
              <SimpleTooltip
                key={reaction?.name}
                trigger={<ReactionButton {...props} reaction={reaction} />}
              >
                <div className="relative flex flex-col space-y-2">
                  {reaction?.recent?.map((profile) => {
                    return (
                      <div key={profile.id}>
                        <UserAvatarStatus face={profile} classes="w-5" />
                      </div>
                    );
                  })}
                </div>
              </SimpleTooltip>
            );
          }
        })}
    </div>
  );
};
