import * as appConfig from "@/config/appConfig";

import firebase from "firebase/compat/app";

const baseUrl = appConfig.API_ENDPOINT;

const fetcher = async (url) => {
  const fullUrl = baseUrl + url;
  let authedUser = firebase.auth().currentUser;
  if (authedUser) {
    const token = await authedUser.getIdToken();
    const response = await fetch(fullUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  }
  const response = await fetch(fullUrl);
  return response.json();
};

export default fetcher;
