import { VSCodeButton, VSCodeCheckbox, VSCodeTextField } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext, useMemo, useState } from "react";

import { HookContext } from "@/contexts/HookContext";
import MediumSwitch from "@/components/MediumSwitch";
import ModalContent from "@/components/modals/ModalContent";
import { TrashIcon } from "@heroicons/react/outline";
import useDynamicRefs from "use-dynamic-refs";
import { useNotification } from "@/contexts/NotificationContext";

var parse = require("url-parse");

const UPDATE_WEB_HOOK = gql`
  mutation updateWebHook($input: UpdateWebHookInput!) {
    updateWebHook(input: $input) {
      errors {
        message
      }
    }
  }
`;

const sharingDisplay = {
  activity: "Activity",
  file: "File",
  gitBranch: "Branch",
  language: "Language",
  project: "Project",
};

const WebhookEditContent = ({
  headers,
  setHeaders,
  url,
  setUrl,
  name,
  setName,
  permissions,
  setPermissions,
  active,
  setActive,
}) => {
  const [getRef, setRef] = useDynamicRefs();

  const addHeader = () => setHeaders([{ name: "", value: "" }, ...headers]);

  const updateName = (idx, name) => {
    const updatedHeaders = () =>
      headers.map((header, index) => {
        if (index === idx) {
          return { ...header, name };
        }
        return header;
      });
    setHeaders(updatedHeaders);
  };

  const updateValue = (idx, value) => {
    const updatedHeaders = () =>
      headers.map((header, index) => {
        if (index === idx) {
          return { ...header, value };
        }
        return header;
      });
    setHeaders(updatedHeaders);
  };

  const removeHeader = (idx) => {
    const updatedHeaders = headers.filter((header, index) => index !== idx);
    setHeaders(updatedHeaders);
  };

  const permissionsArr = useMemo(() => {
    if (permissions) {
      return Object.entries(permissions)
        .map(([key]) => key)
        .filter((value) => value !== "default");
    }
  }, [permissions]);

  const onChangeCheckbox = useCallback(
    (field, value) => {
      setPermissions({ ...permissions, ...{ [field]: value } });
    },
    [setPermissions, permissions]
  );

  return (
    <>
      <div className="justify-end px-4 pt-3 space-x-2 flex-row-centered">
        <label htmlFor="url" className="block font-medium">
          Enabled
        </label>
        <MediumSwitch enabled={active} setEnabled={setActive} />
      </div>
      <div className="flex flex-col px-4 space-y-4">
        <div>
          <label htmlFor="name" className="font-medium">
            <div>Name</div>
          </label>
          <div className="mt-1">
            <VSCodeTextField
              value={name}
              placeholder="Custom webhook..."
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              className={"w-full"}
              onInput={(e) => {
                setName(e.target.value);
              }}
              autoFocus
            />
          </div>
        </div>
        <div>
          <label htmlFor="url" className="block font-medium">
            Endpoint URL
          </label>
          <div className="mt-1">
            <VSCodeTextField
              value={url}
              id="url"
              name="url"
              type="text"
              autoComplete="off"
              className={"w-full"}
              placeholder="https://"
              onInput={(e) => {
                setUrl(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <label htmlFor="sharing" className="block font-medium">
            Sharing
          </label>
          <div className="pl-4 mt-2">
            {permissionsArr.map((name) => {
              return (
                <VSCodeCheckbox
                  key={name}
                  checked={permissions[name]}
                  onChange={(e) => {
                    onChangeCheckbox(name, e.target.checked);
                  }}
                >
                  {sharingDisplay[name]}
                </VSCodeCheckbox>
              );
            })}
          </div>
        </div>

        <div>
          <div className="justify-between flex-row-centered">
            <div>
              <label htmlFor="headers" className="block font-medium">
                Headers
              </label>
            </div>
            <div>
              <VSCodeButton
                appearance="secondary"
                onClick={() => {
                  addHeader();
                  const input = getRef(`0_key`)?.current;
                  if (input) {
                    input.focus();
                  }
                }}
              >
                <div className="flex-row-centered">
                  <div>Add header</div>
                </div>
              </VSCodeButton>
            </div>
          </div>
          <div className="p-2 mt-1">
            <div className="flex flex-col space-y-2">
              {headers.length === 0 && (
                <div className="w-full">
                  <div className="flex items-center justify-center w-full h-full">
                    No headers defined.
                  </div>
                </div>
              )}
              {headers.map((entry, idx) => {
                return (
                  <div key={idx} className="space-x-2 flex-row-centered">
                    <VSCodeTextField
                      type="text"
                      placeholder="key"
                      ref={setRef(idx + "_key")}
                      className={"w-1/2"}
                      value={entry.name}
                      onInput={(e) => {
                        updateName(idx, e.target.value);
                      }}
                    />
                    <div>:</div>
                    <VSCodeTextField
                      type="text"
                      placeholder="value"
                      ref={setRef(idx + "_value")}
                      className={"w-1/2"}
                      value={entry.value}
                      onInput={(e) => {
                        updateValue(idx, e.target.value);
                      }}
                    />
                    <div className="px-2 cursor-pointer">
                      <TrashIcon
                        className="h-5 icon"
                        onClick={() => {
                          removeHeader(idx);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const WebhookEditModal = ({ _close, webhook }) => {
  const { hooksRefetch } = useContext(HookContext);

  const [headers, setHeaders] = useState(
    webhook.headers.map(({ name, value }) => ({ name, value }))
  );
  const [url, setUrl] = useState(webhook.url);
  const [name, setName] = useState(webhook.name);
  const [permissions, setPermissions] = useState({
    default: webhook.statusChange.default,
    activity: webhook.statusChange.activity,
    project: webhook.statusChange.project,
    language: webhook.statusChange.language,
    file: webhook.statusChange.file,
    gitBranch: webhook.statusChange.gitBranch,
  });
  const [active, setActive] = useState(!webhook.disabled);
  const notification = useNotification();

  const [saveEndpoint] = useMutation(UPDATE_WEB_HOOK);

  const updateEndpoint = () => {
    var urlObj = parse(url, {});
    if (urlObj.protocol === "") {
      urlObj.set("protocol", "https");
    }

    const headersFiltered = headers.filter((header) => {
      return header.name && header.value;
    });

    saveEndpoint({
      variables: {
        input: {
          id: webhook.id,
          name: name,
          url: urlObj.toString(),
          statusChange: permissions,
          headers: headersFiltered,
          disabled: !active,
        },
      },
    }).then(({ data }) => {
      if (data?.updateWebHook?.errors?.length > 0) {
        notification.error(data?.updateWebHook?.errors[0]?.message);
      } else {
        hooksRefetch();
        notification.info("Updated.");
        _close();
      }
    });
  };

  return (
    <ModalContent
      title={`Edit webhook`}
      close={_close}
      buttons={<VSCodeButton onClick={updateEndpoint}>Save</VSCodeButton>}
    >
      {webhook.id && (
        <WebhookEditContent
          headers={headers}
          setHeaders={setHeaders}
          url={url}
          setUrl={setUrl}
          name={name}
          setName={setName}
          permissions={permissions}
          setPermissions={setPermissions}
          active={active}
          setActive={setActive}
        />
      )}
    </ModalContent>
  );
};

export default WebhookEditModal;
