import {
  CardContent,
  CardTitle,
  CardTitleIcon,
  CardWrapper,
} from "@/components/cards/CardWrappers";
import { ClockIcon, FireIcon, PresentationChartBarIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";

import ErrorGrow from "@/components/lib/ErrorGrow";
import HoursGraph from "@/components/graphs/HoursGraph";
import Loading from "@/components/lib/Loading";
import ModalBig from "@/components/modals/ModalBig";
import RatingPop from "@/components/pops/RatingPop";
import ScorePop from "@/components/pops/ScorePop";
import SummaryReportDayModal from "@/components/modals/SummaryReportDayModal";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import flatten from "lodash/flatten";
import moment from "moment";
import { msToHuman } from "@/utils/convert";
import { useDialog } from "@/contexts/DialogContext";
import { useMemo } from "react";

const GET_SUMMARY_FOR_DAY = gql`
  query getSummaryForDay($date: Date!) {
    day(date: $date) {
      date
      score
      codingDuration {
        totalMs
      }
      rating
      languages {
        data {
          codingDuration {
            totalMs
          }
          displayName
          id
        }
      }
      projects {
        data {
          codingDuration {
            totalMs
          }
          displayName
          name
          id
          shortName
          gitBranches {
            data {
              name
              codingDuration {
                totalMs
              }
            }
          }
          files {
            data {
              codingDuration {
                totalMs
              }
              name
              path
            }
          }
        }
      }
      hours {
        data {
          time
          codingDuration {
            totalMs
          }
        }
      }
    }
  }
`;

const ReportEntry = ({ title, description, children }) => {
  return (
    <div className="flex flex-col px-2 py-1 my-1 space-y-1 border rounded max-w-1/2 bg-background min-w-1/3">
      <div className="flex flex-row justify-between space-x-1 text-xs">
        <div className="font-medium truncate">{title}</div>
        {description && <div className="text-descriptions">{description}</div>}
      </div>
      {children}
    </div>
  );
};

const SummaryDailyFeedItem = (props) => {
  const { item } = props;
  const { addDialog } = useDialog();

  const date = useMemo(() => {
    return moment(item?.day).toDate();
  }, [item]);

  const {
    data: summaryData,
    loading: summaryLoading,
    error: summaryError,
  } = useQuery(GET_SUMMARY_FOR_DAY, {
    variables: { date: moment(date).format("YYYY-MM-DD") },
  });

  const activity = summaryData?.day?.codingDuration?.totalMs || null;
  const score = summaryData?.day?.score || null;
  const rating = summaryData?.day?.rating || null;

  const languages = useMemo(() => {
    const arr = summaryData?.day?.languages?.data;
    if (arr) {
      const languageArray = arr.map((language) => {
        return [language.displayName, language?.codingDuration?.totalMs || 0];
      });
      return languageArray.sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const projects = useMemo(() => {
    const arr = summaryData?.day?.projects?.data;
    if (arr) {
      const projectArray = arr.map((project) => {
        return [project.displayName, project?.codingDuration?.totalMs || 0];
      });
      return projectArray.sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const branches = useMemo(() => {
    const arr = summaryData?.day?.projects?.data;
    if (arr) {
      return flatten(
        arr.map((project) => {
          return project.gitBranches.data.map((branch) => {
            return [branch.name, branch.codingDuration.totalMs || 0];
          });
        })
      ).sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const files = useMemo(() => {
    const projects = summaryData?.day?.projects?.data;
    if (projects) {
      const files = projects.map((project) => {
        return flatten(project.files.data);
      });
      const fileObjArr = flatten(files);
      const fileArray = fileObjArr.map((file) => {
        return [file.name, file?.codingDuration?.totalMs || 0];
      });

      return fileArray.sort((a, b) => b[1] - a[1]);
    }
  }, [summaryData]);

  const hours = useMemo(() => {
    const arr = summaryData?.day?.hours?.data;
    if (arr) {
      const hoursArray = arr.map((hour) => {
        return [moment(hour.time).local().format("HH"), hour?.codingDuration?.totalMs || 0];
      });
      return hoursArray.sort((a, b) => b[0] - a[0]).reverse();
    }
  }, [summaryData]);

  const hoursObj = useMemo(() => {
    if (hours) {
      return hours.map((hour) => {
        return {
          time: hour[0],
          duration: hour[1],
        };
      });
    }
  }, [hours]);

  if (summaryLoading) {
    return <Loading />;
  }
  if (summaryError) {
    return <ErrorGrow error={summaryError} />;
  }

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        date
        title={
          <div className="space-x-2 flex-row-centered">
            <CardTitleIcon {...props}>
              <PresentationChartBarIcon />
            </CardTitleIcon>
            <div>Summary for {moment(date).format("MMMM Do")}</div>
          </div>
        }
      />
      <CardContent>
        <div className="flex flex-row flex-wrap space-x-1 overflow-auto">
          {activity && (
            <ReportEntry title="Activity">
              <div className="flex-row-centered">
                <div>{msToHuman(activity)}</div>
                <div>
                  <ClockIcon className="w-4" />
                </div>
              </div>
            </ReportEntry>
          )}

          {score && (
            <ReportEntry title="Score">
              <div className="flex-row-centered">
                <div>
                  <ScorePop date={date}>{score}</ScorePop>
                </div>
                <div>
                  <FireIcon className="w-4 " />
                </div>
              </div>
            </ReportEntry>
          )}

          <ReportEntry title="Rating">
            <div>
              {rating ? (
                <div className="flex-row-centered">
                  <div>{rating}/5</div>
                  <div>
                    <RatingPop
                      simple={true}
                      startDate={moment(date).format("YYYY-MM-DD")}
                      classes="w-4"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-row-centered">
                  <div>😝</div>
                  <RatingPop
                    simple={true}
                    startDate={moment(date).format("YYYY-MM-DD")}
                    classes="w-4"
                  />
                </div>
              )}
            </div>
          </ReportEntry>

          {languages && languages[0] && (
            <ReportEntry title="Language" description={`1 of ${languages.length}`}>
              <div className="break-all">
                {languages[0][0]} ({msToHuman(languages[0][1])})
              </div>
            </ReportEntry>
          )}

          {projects && projects[0] && (
            <ReportEntry title="Project" description={`1 of ${projects.length}`}>
              <div className="break-all">
                {projects[0][0]} ({msToHuman(projects[0][1])})
              </div>
            </ReportEntry>
          )}

          {branches && branches[0] && (
            <ReportEntry title="Branch" description={`1 of ${branches.length}`}>
              <div className="break-all">
                {branches[0][0]} ({msToHuman(branches[0][1])})
              </div>
            </ReportEntry>
          )}

          {files && files[0] && (
            <ReportEntry title="File" description={`1 of ${files.length}`}>
              <div className="break-all">
                {files[0][0]} ({msToHuman(files[0][1])})
              </div>
            </ReportEntry>
          )}

          {hours && hoursObj && (
            <ReportEntry title="Active hours" description={hours.length}>
              <div className="h-14" style={{ minWidth: "200px" }}>
                <HoursGraph data={hoursObj} />
              </div>
            </ReportEntry>
          )}
        </div>

        <div className="pt-2">
          <VSCodeLink
            onClick={() => {
              addDialog({
                Component: (
                  <ModalBig>
                    <SummaryReportDayModal date={date} />
                  </ModalBig>
                ),
              });
            }}
          >
            View the full report →
          </VSCodeLink>
        </div>
      </CardContent>
    </CardWrapper>
  );
};

export default SummaryDailyFeedItem;
