import {
  CardContent,
  CardTitle,
  CardTitleIcon,
  CardWrapper,
} from "@/components/cards/CardWrappers";
import { VSCodeButton, VSCodeTextArea } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useRef, useState } from "react";

import { SpeakerphoneIcon } from "@heroicons/react/solid";
import { useNotification } from "@/contexts/NotificationContext";

const SUBMIT_FEEDBACK = gql`
  mutation submitFeedback($input: CreateUserFeedbackInput!) {
    createUserFeedback(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const UserFeedbackFeedItem = (props) => {
  const notification = useNotification();
  const [submitFeedback] = useMutation(SUBMIT_FEEDBACK);
  const [comment, setComment] = useState("");
  const feedbackRef = useRef(null);

  const onSubmit = useCallback(() => {
    if (comment !== "") {
      submitFeedback({
        variables: {
          input: {
            comment: comment,
          },
        },
      })
        .then((resp) => {
          const err = resp.data?.createUserFeedback?.errors;
          if (err.length !== 0) {
            notification.error(err[0].message);
          } else {
            setComment("");
            notification.info("Feedback sent, thanks!");
            feedbackRef.current.focus();
          }
        })
        .catch(() => {
          notification.error("Something went wrong.");
        });
    } else {
      feedbackRef.current.focus();
    }
  }, [notification, comment, submitFeedback]);

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        title={
          <div className="space-x-2 flex-row-centered">
            <CardTitleIcon {...props}>
              <SpeakerphoneIcon />
            </CardTitleIcon>
            <div>Give us feedback</div>
          </div>
        }
      />
      <CardContent>
        <div className="flex flex-col space-y-2">
          <div>We need your help to make Stateful awesome.</div>
          <div>
            <VSCodeTextArea
              id="feedback"
              name="feedback"
              ref={feedbackRef}
              rows={2}
              className="w-full"
              placeholder="Type your feedback and ideas, then send!"
              value={comment}
              onInput={(e) => {
                setComment(e.target.value);
              }}
            />
          </div>
          <div className="flex justify-end flex-shrink">
            <VSCodeButton
              onClick={() => {
                onSubmit();
              }}
            >
              Send
            </VSCodeButton>
          </div>
        </div>
      </CardContent>
    </CardWrapper>
  );
};

export default UserFeedbackFeedItem;
