import { useCallback, useContext, useMemo, useRef, useState } from "react";

import Chips from "react-chips";
import Dots from "@/components/lib/Dots";
import ErrorGrow from "@/components/lib/ErrorGrow";
import ExpandSection from "@/components/settings/ExpandSection";
import Loading from "@/components/lib/Loading";
import MilestoneContext from "@/contexts/MilestoneContext";
import SettingsContext from "@/contexts/SettingsContext";
import { VSCodeButton } from "@vscode/webview-ui-toolkit/react";
import { useNotification } from "@/contexts/NotificationContext";
import useWindowFocus from "@/components/hooks/useWindowFocus";
import isEqual from "lodash/isEqual";

const theme = {
  chipsContainer: {
    display: "flex",
    position: "relative",
    border: "1px solid var(--vscode-editorGroup-border)",
    font: "13.33333px Arial",
    minHeight: 24,
    alignItems: "center",
    flexWrap: "wrap",
    borderRadius: 3,
    padding: "2.5px",
    ":focus": {
      border: "1px solid #aaa",
    },
  },
  container: {
    flex: 1,
  },
  containerOpen: {},
  input: {
    border: "none",
    outline: "none",
    boxSizing: "border-box",
    width: "100%",
    padding: 5,
    margin: 2.5,
    background: "1px solid var(--vscode-editor-background)",
  },
  suggestionsContainer: {},
  suggestionsList: {
    position: "absolute",
    border: "1px solid var(--vscode-editorGroup-border)",
    zIndex: 10,
    left: 0,
    top: "100%",
    width: "100%",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  suggestion: {
    padding: "5px 15px",
  },
  sectionContainer: {},
  sectionTitle: {},
};

const BranchChip = ({ children }) => {
  return <div className="px-2 py-1 mx-0.5 border rounded-sm">{children}</div>;
};

const ChipsContainer = ({ chips, setChips }) => {
  const chipsRef = useRef();

  return (
    <div
      onBlur={() => {
        chipsRef.current.addChip(chipsRef.current.state.value);
      }}
    >
      <Chips
        theme={theme}
        value={chips}
        onChange={(updatedChips) => {
          setChips(updatedChips.filter((chip) => chip !== ""));
        }}
        placeholder="branch..."
        suggestions={["main", "master"]}
        renderChip={(value) => <BranchChip>{value}</BranchChip>}
        createChipKeys={[13]}
        ref={chipsRef}
      />
    </div>
  );
};

const BranchContribContent = () => {
  const {
    saveUserDaySettings,
    daySettings,
    dayRefetch,
    dayLoading,
    dayError,
    updateSettingsLoading,
  } = useContext(SettingsContext);

  const [chips, setChips] = useState(["main", "master"]);
  const notification = useNotification();
  const { completeMilestones } = useContext(MilestoneContext);

  useWindowFocus(0, () => {
    dayRefetch();
  });

  const penalizedBranchesArr = useMemo(() => {
    return daySettings?.penalizeGitBranchUsage || null;
  }, [daySettings]);

  const updateBranches = useCallback(() => {
    const chipsArr = chips.filter((chip) => chip !== "");
    let newChips = chipsArr;
    if (newChips.length === 0) {
      newChips = null;
    }
    if (!isEqual(penalizedBranchesArr, newChips)) {
      saveUserDaySettings(
        {
          penalizeGitBranchUsage: newChips,
        },
        () => {
          notification.info("Branches updated.");
          completeMilestones("configure_penalize_git_branch_usage");
        }
      );
    }
  }, [completeMilestones, saveUserDaySettings, chips, notification, penalizedBranchesArr]);

  const clearBranches = useCallback(() => {
    saveUserDaySettings(
      {
        penalizeGitBranchUsage: null,
      },
      () => {
        setChips([]);
        notification.info("All branches cleared.");
      }
    );
  }, [saveUserDaySettings, notification]);

  if (dayLoading || !chips) {
    return <Loading />;
  }
  if (dayError) return <ErrorGrow message={dayError.message} />;

  return (
    <div className="flex flex-col w-full space-y-3">
      <div className="text-description">
        {!penalizedBranchesArr ? (
          <>
            Earn points in your score for adhering to the best practice of working with Git
            branches.
          </>
        ) : (
          <> You score currently includes branch usage points, great work!</>
        )}
      </div>
      <div className="font-medium">The branches you want to avoid:</div>
      <ChipsContainer chips={chips} setChips={setChips} />

      <div className="justify-end flex-row-centered">
        {updateSettingsLoading && <Dots height={6} />}

        <VSCodeButton appearance="secondary" onClick={clearBranches}>
          Disable
        </VSCodeButton>

        <VSCodeButton onClick={updateBranches}>Save branches</VSCodeButton>
      </div>
    </div>
  );
};

const SettingsScoreTab = () => {
  return (
    <div className="flex flex-col space-y-2">
      <ExpandSection title="Branch usage" defaultOpen={true}>
        <BranchContribContent />
      </ExpandSection>
    </div>
  );
};

export default SettingsScoreTab;
