import { useContext, useEffect, useState } from "react";

import AppContext from "@/contexts/AppContext";

const StyleWrapper = ({ children, setThemeDark = null, classes = "" }) => {
  const { browser } = useContext(AppContext);

  const [dark, setDark] = useState(false);

  useEffect(() => {
    if (setThemeDark) {
      setThemeDark(dark);
    }
  }, [dark, setThemeDark]);

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.documentElement.classList.add("dark");
      if (browser) {
        document.body.classList.add("dark");
      }
      setDark(true);
    } else {
      document.documentElement.classList.remove("dark");
      if (browser) {
        document.body.classList.remove("dark");
      }
      setDark(false);
    }
  }, [browser]);

  return <div className={["text-foreground bg-background", classes].join(" ")}>{children}</div>;
};

export default StyleWrapper;
