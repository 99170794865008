const titleTdClasses = "pr-2 align-text-top font-medium";

const ShareOptionTable = () => {
  return (
    <table className="text-xs table-auto">
      <tbody>
        <tr>
          <td className={titleTdClasses}>Nobody:</td>
          <td>Don't share with anyone.</td>
        </tr>
        <tr>
          <td className={titleTdClasses}>Friends:</td>
          <td>Share with people you follow, who also follow you.</td>
        </tr>
        <tr>
          <td className={titleTdClasses}>Followers:</td>
          <td>Share with anyone who follows you.</td>
        </tr>
        <tr>
          <td className={titleTdClasses}>Everyone:</td>
          <td>Share with anyone logged into Stateful.</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ShareOptionTable;
