import SimpleTooltip from "@/components/pops/SimpleTooltip";
import clsx from "clsx";
import getStatus from "@/utils/getStatus";
import { useMemo } from "react";

//should come from AppContext
const initialStatusDefault = "Chillin (no status available) 🤗";

const UserAvatarStatus = ({ face, classes = "w-8" }) => {
  const statusMessage = useMemo(() => {
    const workDay = face?.workDay;
    return getStatus(face?.status, face?.dnd, workDay);
  }, [face]);

  return (
    <div className="relative">
      <SimpleTooltip
        opts={{
          interactive: true,
          delayHide: 100,
          placement: "bottom",
          offset: [10, 10],
          closeOnOutsideClick: true,
        }}
        trigger={
          <span className="relative flex">
            <img
              className={clsx(
                "max-w-min rounded cursor-pointer overflow-hidden border  border-gray-500",
                classes
              )}
              src={face?.photoUrl || "/assets/empty-avatar.png"}
              alt={face?.github?.username}
            />
            {statusMessage !== initialStatusDefault && (
              <span className="absolute block transform translate-x-1/2 translate-y-1/2 rounded-full bottom-1 right-0.5 border-2 border-background bg-background">
                <span className="block h-1.5 w-1.5 rounded-full bg-green-300" />
              </span>
            )}
          </span>
        }
      >
        <div
          className="flex flex-col flex-grow space-y-1 text-center rounded-xl"
          style={{ minWidth: "200px" }}
        >
          <div className="font-medium">{face?.github?.username}</div>
          <div className="">{statusMessage}</div>
        </div>
      </SimpleTooltip>
    </div>
  );
};

export default UserAvatarStatus;
