import "@/styles/hide-scroll.css";

import { LeftArrow, RightArrow, TabEntry } from "@/components/settings/SettingsTabs";
import { useMemo, useState } from "react";

import ModalTitle from "@/components/modals/ModalTitle";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import SettingsConnectionsTab from "@/components/settings/SettingsConnectionsTab";
import SettingsGeneralTab from "@/components/settings/SettingsGeneralTab";
import SettingsScheduleTab from "@/components/settings/SettingsScheduleTab";
import SettingsScoreTab from "@/components/settings/SettingsScoreTab";
import SettingsSharingTab from "@/components/settings/SettingsSharingTab";

const ModalContent = ({ title, description, children, close }) => {
  return (
    <div className="flex flex-col w-full h-full focus:outline-none text-foreground bg-background">
      <ModalTitle close={close} title={title} description={description} />
      <div className="h-full overflow-hidden">{children}</div>
    </div>
  );
};

const TABS = [
  { title: "General", id: "general", Component: SettingsGeneralTab },
  { title: "Schedule", id: "schedule", Component: SettingsScheduleTab },
  { title: "Sharing", id: "sharing", Component: SettingsSharingTab },
  { title: "Score", id: "Score", Component: SettingsScoreTab },
  { title: "Connections", id: "connections", Component: SettingsConnectionsTab },
];

const SettingsContent = ({ tab }) => {
  const [activeTab, setActiveTab] = useState(tab);

  const Content = useMemo(() => {
    const selectedArr = TABS.filter((tab) => tab.id === activeTab);
    return selectedArr[0].Component;
  }, [activeTab]);

  return (
    <div className="flex flex-col w-full h-full space-y-2 overflow-hidden">
      <div className="w-full px-4 pb-2 overflow-hidden">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {TABS.map(({ id, title }) => (
            <TabEntry
              itemId={id}
              title={title}
              key={id}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          ))}
        </ScrollMenu>
      </div>
      <div className="w-full h-full px-6 pb-4 overflow-auto">
        <Content />
      </div>
    </div>
  );
};

const SettingsModal = ({ _close, tab = "general" }) => {
  return (
    <ModalContent title="Settings" close={_close}>
      <SettingsContent tab={tab} />
    </ModalContent>
  );
};

export default SettingsModal;
