import { cloneElement, useContext, useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";

import AppContext from "@/contexts/AppContext";
import BranchScoreContributor from "@/components/score/BranchScoreContributor";
import BreaksScoreContributor from "@/components/score/BreaksScoreContributor";
import DefaultScoreContributor from "@/components/score/DefaultScoreContributor";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import TargetActivityScoreContributor from "@/components/score/TargetActivityScoreContributor";
import WorkingHoursScoreContributor from "@/components/score/WorkingHoursScoreContributor";

const GET_TODAY_SCORE = gql`
  query GetTodayScore {
    day {
      date
      score
      scoreBreakdown {
        points
        contributors {
          name
          displayName
          points
          possiblePoints
          hints {
            name
            unit
            targetValue
            currentValue
          }
        }
      }
    }
  }
`;

const contribMap = {
  target_activity_duration: <TargetActivityScoreContributor />,
  target_work_hours: <WorkingHoursScoreContributor />,
  target_rest: <BreaksScoreContributor />,
  penalize_default_branch_usage: <BranchScoreContributor />,
};

const ScoreBreakdown = () => {
  const { pollInterval } = useContext(AppContext);

  const {
    refetch: scoreRefetch,
    loading: scoreLoading,
    error: scoreError,
    data: scoreData,
  } = useQuery(GET_TODAY_SCORE, { pollInterval: pollInterval });

  useEffect(() => {
    scoreRefetch();
  }, [scoreRefetch]);

  const contributors = useMemo(() => {
    return scoreData?.day?.scoreBreakdown?.contributors;
  }, [scoreData]);

  if (scoreLoading) {
    return <Loading />;
  }

  if (scoreError) return <ErrorGrow message={scoreError.message} />;

  // const contributors = SCORE_DUMP_OBJ.data.day?.scoreBreakdown?.contributors;

  return (
    <div className="flex flex-col w-full h-full ">
      {!contributors && <div>No details available.</div>}

      {contributors &&
        contributors.map((contributor) => {
          if (contribMap[contributor.name]) {
            return (
              <div className="flex flex-col py-0.5" key={contributor.name}>
                {cloneElement(contribMap[contributor.name], {
                  contributor: contributor,
                  scoreRefetch: scoreRefetch,
                })}
              </div>
            );
          } else {
            return (
              <div className="flex flex-col" key={contributor.name}>
                <DefaultScoreContributor contributor={contributor} />
              </div>
            );
          }
        })}
    </div>
  );
};

export default ScoreBreakdown;
