import { VSCodeButton, VSCodeCheckbox } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { HookContext } from "@/contexts/HookContext";
import Loading from "@/components/lib/Loading";
import MediumSwitch from "@/components/MediumSwitch";
import ModalContent from "@/components/modals/ModalContent";
import SuspenseContainer from "@/components/SuspenseContainer";
import omit from "lodash.omit";
import { useNotification } from "@/contexts/NotificationContext";

const SET_SLACK_STATUS_HOOK = gql`
  mutation SetSlackStatusHook($input: SetSlackStatusHookInput!) {
    setSlackStatusHook(input: $input) {
      errors {
        message
      }
    }
  }
`;

const SET_SLACK_DND_HOOK = gql`
  mutation SetSlackDndHook($input: SetSlackDndHookInput!) {
    setSlackDndHook(input: $input) {
      errors {
        message
      }
    }
  }
`;

const sharingDisplay = {
  activity: "Activity",
  file: "File",
  gitBranch: "Branch",
  language: "Language",
  project: "Project",
};

const PermissionCheckbox = ({ display, name, checkedState, onChangeCheckbox }) => {
  const [checked, setChecked] = useState(checkedState);

  useEffect(() => {
    if (checkedState !== checked) {
      onChangeCheckbox(name, checked);
    }
  }, [checked, onChangeCheckbox, name, checkedState]);

  return (
    <VSCodeCheckbox
      key={name}
      checked={checked}
      onChange={(e) => {
        setChecked(e.target.checked);
      }}
    >
      {display}
    </VSCodeCheckbox>
  );
};

const SlackEditContent = ({ id }) => {
  const { slacksRefetch, slacks } = useContext(HookContext);
  const notification = useNotification();

  const slack = useMemo(() => {
    if (slacks) {
      return slacks.find((slack) => {
        return slack.id === id;
      });
    }
  }, [slacks, id]);

  const dnd = useMemo(() => {
    if (slack && slack?.hooks?.data.length > 0) {
      const dndHook = slack?.hooks?.data?.find((hook) => {
        return hook.deliveryType === "SLACK_DND";
      });
      if (dndHook) {
        return dndHook;
      }
    }
    return { disabled: true };
  }, [slack]);

  const statusData = useMemo(() => {
    if (slack && slack?.hooks?.data.length > 0) {
      const statusHook = slack?.hooks?.data?.find((hook) => {
        return hook.deliveryType === "SLACK_STATUS";
      });
      if (statusHook) {
        return statusHook;
      }
    }
    return { disabled: true };
  }, [slack]);

  const permissions = useMemo(() => {
    if (statusData && statusData?.statusChange) {
      return omit(statusData?.statusChange, ["__typename"]);
    }
  }, [statusData]);

  const permissionsArr = useMemo(() => {
    if (permissions) {
      return Object.entries(permissions)
        .map(([key]) => key)
        .filter((value) => value !== "default");
    }
  }, [permissions]);

  const permissionsValues = useMemo(() => {
    if (permissions) {
      return Object.entries(permissions)
        .filter(([, value]) => value)
        .map(([key]) => key);
    }
  }, [permissions]);

  const doUpdateEnabled = (disabled) => {
    setSlackStatusHook({
      variables: {
        input: {
          connectionId: slack.id,
          disabled: !disabled,
          statusChange: { default: false, ...permissions },
        },
      },
    }).then(({ data }) => {
      const errors = data?.setSlackStatusHook?.errors;
      if (errors?.length > 0) {
        notification.error(errors[0]?.message);
      } else {
        slacksRefetch();
        notification.info("Updated.");
      }
    });
  };

  const doUpdateDnd = (disabled) => {
    setSlackDndHook({
      variables: {
        input: {
          connectionId: slack.id,
          disabled: !disabled,
        },
      },
    }).then(({ data }) => {
      const errors = data?.setSlackDndHook?.errors;
      if (errors?.length > 0) {
        notification.error(errors[0]?.message);
      } else {
        slacksRefetch();
        notification.info("Updated.");
      }
    });
  };

  const [setSlackStatusHook] = useMutation(SET_SLACK_STATUS_HOOK);
  const [setSlackDndHook] = useMutation(SET_SLACK_DND_HOOK);

  const onChangeCheckbox = useCallback(
    (field, value) => {
      const newPermissions = { ...permissions, ...{ [field]: value } };

      setSlackStatusHook({
        variables: {
          input: {
            connectionId: slack.id,
            disabled: statusData.disabled,
            statusChange: { default: false, ...newPermissions },
          },
        },
      }).then(({ data }) => {
        const errors = data?.setSlackStatusHook?.errors;
        if (errors.length > 0) {
          notification.error(errors[0].message);
        } else {
          slacksRefetch();
          notification.info("Updated.");
        }
      });
    },
    [setSlackStatusHook, permissions, slack, slacksRefetch, statusData, notification]
  );

  if (!statusData || !dnd) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col p-2 space-y-2 divide-y">
      <div className="justify-between p-2 flex-row-centered">
        <div className="flex flex-col">
          <label htmlFor="url" className="block font-medium">
            Do not disturb
          </label>
          <div className="text-sm text-description">Auto set DnD when you're focused.</div>
        </div>
        <div>
          <MediumSwitch enabled={!dnd?.disabled || false} setEnabled={doUpdateDnd} />
        </div>
      </div>
      <div className="justify-between p-2 flex-row-centered">
        <div>
          <label htmlFor="url" className="block font-medium">
            Status
          </label>
          <div className="text-sm text-description">Update your real-time coding status.</div>
        </div>
        <div>
          <MediumSwitch enabled={!statusData?.disabled || false} setEnabled={doUpdateEnabled} />
        </div>
      </div>
      {statusData && !statusData?.disabled && permissionsValues && (
        <div className="flex flex-col p-2">
          <label htmlFor="url" className="block font-medium">
            Sharing
          </label>
          <div className="text-sm text-description">
            Customize which fields should be included in your status.
          </div>
          <div className="py-4">
            {permissionsArr.map((name) => {
              return (
                <PermissionCheckbox
                  display={sharingDisplay[name]}
                  name={name}
                  checkedState={permissions[name]}
                  onChangeCheckbox={onChangeCheckbox}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const SlackEditModal = ({ _close, slack }) => {
  return (
    <ModalContent
      title={`Edit Slack`}
      close={_close}
      buttons={
        <VSCodeButton
          appearance="secondary"
          onClick={() => {
            _close();
          }}
        >
          Close
        </VSCodeButton>
      }
    >
      <SuspenseContainer>
        <SlackEditContent id={slack.id} />
      </SuspenseContainer>
    </ModalContent>
  );
};

export default SlackEditModal;
