import { Navigate, Route, Routes, useMatch, useResolvedPath } from "react-router-dom";

import RouterTabsScroll from "@/components/RouterTabsScroll";
import Title from "@/components/Title";
import { cloneElement } from "react";
import { upperCaseFirst } from "@/utils/tools";

const RouteTabs = (props) => {
  const { TABS, DEFAULT_TAB } = props;

  const pathObj = useResolvedPath(":page/*");
  const match = useMatch({ path: pathObj.pathname, end: true });
  const page = match?.params?.page;

  return (
    <div className="flex flex-col w-full h-full overflow-auto">
      <Title>{`${upperCaseFirst(page)}`}</Title>

      <div className="justify-between py-1 flex-row-centered">
        <RouterTabsScroll TABS={TABS} />
      </div>

      <div className="flex flex-col w-full h-full overflow-auto">
        <Routes>
          {TABS.map((tab) => (
            <Route
              key={tab.value}
              path={`${tab.url}/*`}
              element={cloneElement(tab.Component, { path: tab.url })}
            />
          ))}
          <Route index element={<Navigate to={`${DEFAULT_TAB}`} />} />
        </Routes>
      </div>
    </div>
  );
};

export default RouteTabs;
