import { CardContent, CardFooter, CardTitle, CardWrapper } from "@/components/cards/CardWrappers";

import { ReactionDisplay } from "@/components/Reaction";
import ReactionPop from "@/components/pops/ReactionPop";
import UserAvatarStatus from "@/components/UserAvatarStatus";
import moment from "moment";

var converter = require("number-to-words");

const PerfectScoreDayFeedItem = (props) => {
  const { item } = props;

  const day = moment(item.time).format("dddd");
  const messageYou = `You rocked it on ${day}. Achieving perfection isn't easy - congratulations to you! 🎊`;

  const messageOther = `${item?.profile?.github?.username} rocked it on ${day}. Achieving a perfect score isn't easy - congratulate them on a job well done! 🎊`;

  // const personText = perfectScoreItem.owner ? "you" : perfectScoreItem.profile.github.username;

  const contentText = item.owner ? messageYou : messageOther;

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        date
        title={
          <div className="flex-row-centered space-x-2">
            <UserAvatarStatus face={item.profile} classes="w-5" />
            <div>{converter.toOrdinal(item.count)} perfect score! 💯</div>
          </div>
        }
      />

      <CardContent>
        <div className="flex flex-col">
          <div>{contentText}</div>
          {item.owner && (
            <div className="flex items-center justify-center py-2 text-3xl">
              <div>🏆</div>
            </div>
          )}
        </div>
      </CardContent>
      <CardFooter>
        <div className="flex-row-centered">
          <ReactionDisplay item={item} />
          <ReactionPop item={item} />
        </div>
      </CardFooter>
    </CardWrapper>
  );
};

export default PerfectScoreDayFeedItem;
