import { useContext, useMemo } from "react";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import MilestoneContext from "@/contexts/MilestoneContext";
import ModalBig from "@/components/modals/ModalBig";
import ScoreCard from "@/components/cards/ScoreCard";
import SettingsModal from "@/components/modals/SettingsModal";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import { useDialog } from "@/contexts/DialogContext";

const ACCEPTED_MILESTONES = [
  "configure_standup_times",
  "configure_target_durations",
  "configure_work_hours",
  "view_personalization",
];

const ScoreNux = () => {
  const { addDialog } = useDialog();

  return (
    <div className="p-4 space-x-3 flex-row-centered">
      <div className="hidden w-1/3 vsm:block">
        <img
          style={{ maxHeight: "160px" }}
          className="object-scale-down"
          src="/assets/ScoreCardPrefCharacter.png"
          alt="Preferences"
        />
      </div>
      <div className="flex flex-col space-y-1">
        <div>Tell us about your ideal coding week, so we can help you get there.</div>
        <div>
          <VSCodeLink
            onClick={() => {
              addDialog({
                Component: (
                  <ModalBig>
                    <SettingsModal tab="schedule" />
                  </ModalBig>
                ),
              });
            }}
          >
            Customize my schedule →
          </VSCodeLink>
        </div>
      </div>
    </div>
  );
};

const ScoreBox = ({ path }) => {
  const { milestonesLoading, milestonesError, milestonesCompleted } = useContext(MilestoneContext);

  const personalizeMilestonesHit = useMemo(() => {
    if (milestonesCompleted && milestonesCompleted.length > 0) {
      const names = milestonesCompleted.map((m) => m.name);
      const search = names.filter((name) => ACCEPTED_MILESTONES.includes(name));
      if (search.length > 0) {
        return true;
      }
      return false;
    }
  }, [milestonesCompleted]);

  if (milestonesLoading)
    return (
      <div className="w-full h-full">
        <Loading />
      </div>
    );

  if (milestonesError) return <ErrorGrow message={milestonesError.message} />;

  return (
    <div className="w-full h-full px-4">
      <div className="border rounded-sm">
        {personalizeMilestonesHit ? <ScoreCard /> : <ScoreNux />}
      </div>
    </div>
  );
};

export default ScoreBox;
