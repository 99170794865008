import { gql, useQuery } from "@apollo/client";

import BadgeContainer from "@/components/settings/BadgeContainer";
import CalendarSettings from "@/components/CalendarSettings";
import ErrorGrow from "@/components/lib/ErrorGrow";
import ExpandSection from "@/components/settings/ExpandSection";
import FeedSharingSelect from "@/components/FeedSharingSelect";
import Loading from "@/components/lib/Loading";
import MagicalStatus from "@/components/status/MagicalStatus";
import ProfileSwitch from "@/components/settings/ProfileSwitch";
import ScheduleSwitch from "@/components/settings/ScheduleSwitch";
import ShareOptionTable from "@/components/settings/ShareOptionTable";
import StatusFieldConfiguration from "@/components/settings/StatusFieldConfiguration";
import StatusSwitch from "@/components/settings/StatusSwitch";

const PROFILE_SHARING_SETTING = gql`
  query getProfileSharingSetting {
    user {
      settings {
        sharing {
          profile
          status
        }
      }
    }
  }
`;

const SettingsSharingTab = () => {
  const { data, error, loading } = useQuery(PROFILE_SHARING_SETTING);

  const profile = data?.user?.settings?.sharing?.profile;
  const status = data?.user?.settings?.sharing?.status;

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorGrow message={error.message} />;
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex-row-centered p-2 mb-2 space-x-4 border rounded-sm shadow-md">
        <div className="font-medium">Sharing</div>
        <ProfileSwitch />
        <div className=" text-description">Global override for all sharing.</div>
      </div>
      {profile === "EVERYONE" && (
        <>
          <ExpandSection title="Status">
            <div className="flex flex-col space-y-2 overflow-hidden">
              <div className="flex-row-centered space-x-4 ">
                <div className="font-medium">{status === "EVERYONE" ? `On` : `Off`}</div>
                <StatusSwitch />
                <div className="text-description ">Override for status sharing.</div>
              </div>
              {status === "EVERYONE" && (
                <>
                  <div>
                    <hr />
                  </div>
                  <div className="font-medium">Configuration</div>
                  <div className="p-4 border rounded-xl">
                    <MagicalStatus />
                  </div>
                  <StatusFieldConfiguration />

                  <div>
                    <hr />
                  </div>
                  <div className="flex flex-col">
                    <div className="font-medium">Schedule</div>
                    <div className="flex-row-centered w-full px-2 space-x-4 ">
                      <ScheduleSwitch />
                      <div className="text-description">
                        Integrate your schedule preferences into your status. It'll say things like
                        "Trying to be AFK today! 🏖️"
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </ExpandSection>

          <ExpandSection title="Feed">
            <div className="flex flex-col space-y-2">
              <div className="text-description">
                When you earn achievements, you can share them with teammates through their feed and
                get kudos! 🚀👍🎉❤️
              </div>
              <div>
                <hr />
              </div>
              <div className="flex-row-centered space-x-2">
                <div className="font-medium">Share with:</div>

                <FeedSharingSelect />
              </div>
              <div>
                <hr />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="font-medium">Designations</div>
                <div>
                  <ShareOptionTable />
                </div>
              </div>
            </div>
          </ExpandSection>
          {status === "EVERYONE" && (
            <ExpandSection title="Badges">
              <BadgeContainer />
            </ExpandSection>
          )}
        </>
      )}

      <ExpandSection title="Calendar">
        <CalendarSettings />
      </ExpandSection>
    </div>
  );
};

export default SettingsSharingTab;
