import { ClockIcon } from "@heroicons/react/outline";
import ExpandCenter from "@/components/lib/ExpandCenter";

const NotEnoughData = () => {
  return (
    <ExpandCenter>
      <div className="flex flex-col space-y-2">
        <div>
          <ClockIcon className="w-12 text-description" />
        </div>
        <div>Not enough data yet.</div>
      </div>
    </ExpandCenter>
  );
};
export default NotEnoughData;
