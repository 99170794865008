import { ArcElement, Chart as ChartOg, Title, Tooltip } from "chart.js";
import { Doughnut, getDatasetAtEvent } from "react-chartjs-2";
import { gql, useQuery } from "@apollo/client";
import { memo, useContext, useEffect, useMemo, useRef } from "react";

import AppContext from "@/contexts/AppContext";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ContainerDimensions from "react-container-dimensions";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import { contribLabelMap } from "@/components/score/contribLabelMap";
import { getColors } from "@/utils/colors";
import useWindowFocus from "./hooks/useWindowFocus";

ChartOg.register([ArcElement, Tooltip, Title, ChartDataLabels]); // we need to set this in order to get the other functionality working - tooltips, datalabels, arc

const GET_TODAY_SCORE = gql`
  query GetTodayScore {
    day {
      date
      score
      scoreBreakdown {
        points
        contributors {
          name
          displayName
          points
          possiblePoints
          hints {
            name
            unit
            targetValue
            currentValue
          }
        }
      }
    }
  }
`;

//get the completion percentage
const percentage = (partialValue, totalValue) => {
  return (Math.min(partialValue, totalValue) / totalValue) * 100;
};

const getDatasetsForDonut = (contributors, borderColor, backgroundColor) => {
  const colors = getColors(contributors.length || 0);

  const data = contributors.map((contributor, idx) => {
    return {
      label: contributor.displayName,
      data: [contributor.points, contributor.possiblePoints - contributor.points],

      backgroundColor: [colors[idx], backgroundColor],
      borderColor: [borderColor],
    };
  });
  return data;
};

const DohnutChart = ({ data, contributors, setRingDetails, mouseOut, foregroundColor }) => {
  const chartRef = useRef();

  const dataLabelFormatter = (_, context) => {
    const completionPercent = percentage(
      context.dataset.data[0],
      context.dataset.data[0] + context.dataset.data[1]
    );
    if (context.dataIndex === 1) {
      return ``;
    }
    return `${Math.round(completionPercent)}%`;
  };

  const plugins = {
    tooltip: {
      enabled: true,
      callbacks: {
        label: (tooltipItem, data) => {
          const current = contributors[tooltipItem.datasetIndex];
          return ` ${contribLabelMap[current.name]}`;
        },
      },
    },
    datalabels: {
      color: foregroundColor,
      clamp: true,
      font: {
        size: 10,
        weight: "bold",
      },
      formatter: dataLabelFormatter,
    },
  };

  const options = {
    autoPadding: true,
    maintainAspectRatio: true,
    aspectRatio: 1.8,
    circumference: 180,
    rotation: 270,
    cutout: "10%",
    plugins: plugins,
    animation: {
      duration: 0,
    },
  };

  const onMouseMove = (event) => {
    const dataSetEvent = getDatasetAtEvent(chartRef.current, event);
    if (dataSetEvent && dataSetEvent[0]) {
      const current = contributors[dataSetEvent[0].datasetIndex];
      const percentString = percentage(current.points, current.possiblePoints);
      const contributorDetails = {
        points: current.points,
        possiblePoints: current.possiblePoints,
        value: percentString,
        name: current.name,
      };
      setRingDetails(contributorDetails);
    }
  };

  return (
    <ContainerDimensions>
      {({ width }) => {
        return (
          <div style={{ width: width * 0.8 }}>
            <Doughnut
              data={{
                datasets: data,
              }}
              options={options}
              ref={chartRef}
              onMouseMove={onMouseMove}
              onMouseOut={mouseOut}
            />
          </div>
        );
      }}
    </ContainerDimensions>
  );
};

const ScoreRings = ({ setRingDetails, mouseOut }) => {
  const { pollInterval } = useContext(AppContext);

  const {
    refetch: scoreRefetch,
    loading: scoreLoading,
    error: scoreError,
    data: scoreData,
  } = useQuery(GET_TODAY_SCORE, { pollInterval: pollInterval });

  useWindowFocus(0, () => {
    scoreRefetch();
  });

  const borderColor = useMemo(() => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-editorGroup-border")
      .trim();
  }, []);
  const backgroundColor = useMemo(() => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-sideBar-background")
      .trim();
  }, []);
  const foregroundColor = useMemo(() => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue("--vscode-foreground")
      .trim();
  }, []);

  useEffect(() => {
    scoreRefetch();
  }, [scoreRefetch]);

  const contributors = useMemo(() => {
    return scoreData?.day?.scoreBreakdown?.contributors;
  }, [scoreData]);

  const data = useMemo(() => {
    if (contributors && contributors.length > 0) {
      return getDatasetsForDonut(contributors, borderColor, backgroundColor);
    }
  }, [contributors, borderColor, backgroundColor]);

  if (scoreLoading) {
    return <Loading />;
  }
  if (scoreError) return <ErrorGrow message={scoreError.message} />;

  return (
    <DohnutChart
      data={data}
      contributors={contributors}
      setRingDetails={setRingDetails}
      mouseOut={mouseOut}
      foregroundColor={foregroundColor}
    />
  );
};
export default memo(ScoreRings);
