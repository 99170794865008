import "@/styles/app.css";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";

import AppContext from "@/contexts/AppContext";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { CogIcon } from "@heroicons/react/outline";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import RatingPop from "@/components/pops/RatingPop";
import SettingsPop from "@/components/pops/SettingsPop";
import StatusPreviewVs from "@/components/pops/StatusPreviewVs";
import StyleWrapper from "@/components/StyleWrapper";
import UserContext from "@/contexts/UserContext";
import clsx from "clsx";
import firebase from "firebase/compat/app";
import getStatus from "@/utils/getStatus";

const navigation = [
  { name: "Feed", href: "/app/feed" },
  { name: "Metrics", href: "/app/metrics" },
  { name: "Standup", href: "/app/standup" },
  { name: "Notes", href: "/app/notes" },
  { name: "Calendar", href: "/app/calendar" },
  { name: "Network", href: "/app/network" },
  { name: "Sharing", href: "/app/sharing" },
  { name: "Settings", href: "/app/settings" },
];

const TabLink = ({ href, label }) => {
  const location = useLocation();
  const isSelected = location.pathname.startsWith(href);

  return (
    <Link
      to={href}
      className={clsx(
        isSelected
          ? " bg-sideBarBackground text-covey-600 font-medium"
          : " hover:bg-sideBarBackground text-foreground hover:text-covey-600 hover:font-medium",
        "group rounded-md py-2 px-2 flex items-center text-base"
      )}
    >
      <div>{label}</div>
    </Link>
  );
};

const Avatar = ({ user, statusMessage, initialStatusDefault }) => {
  return (
    <span className="relative flex">
      <img className="rounded-md w-7" src={user.user.photoUrl} alt={user.user.github.username} />
      {statusMessage !== initialStatusDefault && (
        <span className="absolute block transform translate-x-1/2 translate-y-1/2 rounded-full bottom-1 right-0.5 border-2 border-background bg-background">
          <span className="block h-1.5 w-1.5 rounded-full bg-green-300" />
        </span>
      )}
    </span>
  );
};

const Header = () => {
  const { user, userIsLoading, statusLoading, statusError, status } = useContext(UserContext);
  const { initialStatusDefault } = useContext(AppContext);

  const statusMessage = useMemo(() => {
    return getStatus(status);
  }, [status]);

  if (userIsLoading || statusLoading) {
    return <Loading />;
  }
  if (statusError) return <ErrorGrow message={statusError.message} />;

  return (
    <div className="justify-between w-full h-full px-4 flex-row-centered bg-background text-foreground">
      <div className="w-full space-x-3 overflow-hidden flex-row-centered">
        <div className="min-w-max">
          <Avatar
            user={user}
            statusMessage={statusMessage}
            initialStatusDefault={initialStatusDefault}
          />
        </div>
        <div className="truncate whitespace-nowrap">
          <StatusPreviewVs
            contentChildren={<div className="p-2 text-sm font-medium">{statusMessage}</div>}
          >
            <ChevronDownIcon className="w-4 icon" />
          </StatusPreviewVs>
        </div>
      </div>
      <div className="space-x-2 flex-row-centered">
        <RatingPop simple={true} classes="w-6" />
        <SettingsPop>
          <CogIcon className="w-6 icon" />
        </SettingsPop>
      </div>
    </div>
  );
};

const SignOutLink = () => {
  return (
    <div
      className="cursor-pointer hover:text-covey-500"
      onClick={() => {
        firebase.app().auth().signOut();
      }}
    >
      Sign out
    </div>
  );
};

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dark, setDark] = useState(false);

  return (
    <StyleWrapper setThemeDark={setDark} classes="lg:border-r">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-75 bg-background" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 border-r">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    type="button"
                    className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex items-center flex-shrink-0 px-4">
                {dark ? (
                  <img
                    className="w-auto h-7"
                    src="/assets/stateful_logo_white.png"
                    alt="Stateful"
                  />
                ) : (
                  <img className="w-auto h-7" src="/assets/stateful_logo.png" alt="Stateful" />
                )}
              </div>
              <div className="flex-1 h-0 mt-5 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <TabLink key={item.href} href={item.href} label={item.name} />
                  ))}
                </nav>
              </div>
              <div className="flex items-center flex-shrink-0 p-4 border-t ">
                <SignOutLink />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 lg:border-l">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow pt-3 overflow-y-auto border-r ">
          <div className="flex items-center flex-shrink-0 px-4">
            <Link to="/app">
              {dark ? (
                <img className="w-auto h-7" src="/assets/stateful_logo_white.png" alt="Stateful" />
              ) : (
                <img className="w-auto h-7" src="/assets/stateful_logo.png" alt="Stateful" />
              )}
            </Link>
          </div>
          <div className="flex flex-col flex-grow mt-5">
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navigation.map((item) => (
                <TabLink key={item.href} href={item.href} label={item.name} />
              ))}
            </nav>
          </div>
          <div className="flex flex-shrink-0 p-4 border-t ">
            <SignOutLink />
          </div>
        </div>
      </div>

      <div className="w-full h-full md:pl-64">
        <div className="flex flex-col h-full overflow-hidden ">
          <div className="sticky top-0 z-10 flex flex-shrink-0 border-b h-14">
            <button
              type="button"
              className="px-4 text-gray-500 border-r focus:outline-none focus:0 focus:ring-inset focus:ring-transparent md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
            </button>
            <div className="flex justify-between flex-1 overflow-hidden md:px-0">
              <div className="flex items-center flex-1 w-full">
                <Header />
              </div>
            </div>
          </div>

          <main className="h-full overflow-hidden">{children}</main>
        </div>
      </div>
    </StyleWrapper>
  );
};

export default Layout;
