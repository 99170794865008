import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import AppContext from "@/contexts/AppContext";
import ModalTiny from "@/components/modals/ModalTiny";
import NoteAddModal from "@/components/modals/NoteAddModal";
import moment from "moment";
import { useDialog } from "@/contexts/DialogContext";

const GET_RECENT_STANDUPS = gql`
  query getRecentStandups {
    standups(includeUpcoming: true, pageSize: 30) {
      data {
        date
      }
    }
  }
`;

const GET_STANDUP_FOR_DAY = gql`
  query getStandupForDay($date: Date!) {
    standup(includeUpcoming: true, date: $date) {
      codingDuration {
        readingMs
        totalMs
        writingMs
      }
      date
      languages {
        data {
          id
          displayName
        }
      }
      projects {
        data {
          displayName
          id
          codingDuration {
            totalMs
          }
          gitBranches {
            data {
              name
              codingDuration {
                totalMs
              }
              files {
                data {
                  codingDuration {
                    totalMs
                  }
                  name
                  path
                }
              }
            }
          }
          name
          shortName
          files {
            data {
              name
            }
          }
        }
      }
      rating {
        count
        mean
      }
      score {
        count
        mean
      }
      timezone
      dayCount
      days {
        data {
          date
          score
          rating
          ratingNote
          endTime
          startTime
          codingDuration {
            totalMs
          }
        }
      }
      userAnnotations(sort: { field: CREATE_TIME, order: DESC }, pageSize: 100) {
        data {
          content
          gitBranch {
            name
          }
          file {
            path
          }
          id
          project {
            id
            displayName
          }
          type
          completed
          date
          dates
          createTime
          hidden
        }
      }
    }
  }
`;

const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateUserAnnotationInput!) {
    updateUserAnnotation(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const NOTE_ADD_DATES = gql`
  mutation noteAddDates($input: UpdateUserAnnotationInput!) {
    updateUserAnnotation(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

const StandupContext = createContext();

const StandupProvider = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState();
  const { tangle } = useContext(AppContext);
  const { addDialog } = useDialog();
  const { pollInterval } = useContext(AppContext);

  const {
    loading: loadingRecents,
    error: errorRecents,
    data: dataRecents,
  } = useQuery(GET_RECENT_STANDUPS, { pollInterval: pollInterval });

  const {
    loading: loadingStandup,
    error: errorStandup,
    data: dataStandup,
    refetch: refetchStandupData,
  } = useQuery(GET_STANDUP_FOR_DAY, {
    variables: { date: moment(selectedDate).format("YYYY-MM-DD") },
  });

  const [updateNote, { loading: updateNoteLoading }] = useMutation(UPDATE_NOTE, {
    refetchQueries: [
      {
        query: GET_STANDUP_FOR_DAY,
        variables: { date: moment(selectedDate).format("YYYY-MM-DD") },
      },
    ],
  });

  const nextStandupDate = useMemo(() => {
    return dataRecents?.standups?.data[0]?.date || null;
  }, [dataRecents]);

  const [noteAddDates, { loading: noteAddDatesLoading }] = useMutation(NOTE_ADD_DATES, {
    refetchQueries: [
      {
        query: GET_STANDUP_FOR_DAY,
        variables: { date: nextStandupDate },
      },
    ],
  });

  useEffect(() => {
    if (tangle && tangle.on) {
      tangle.on("onAnnotations", () => {
        // refresh note list if the note came from vscode
        refetchStandupData();
      });
    }
  }, [tangle, refetchStandupData]);

  const openNoteAddModal = useCallback(
    (typeDefault) => {
      addDialog({
        Component: (
          <ModalTiny>
            <NoteAddModal
              date={moment(dataStandup?.standup?.date).subtract(1, "day").format("YYYY-MM-DD")}
              refetchData={refetchStandupData}
              typeDefault={typeDefault}
            />
          </ModalTiny>
        ),
      });
    },
    [refetchStandupData, addDialog, dataStandup]
  );

  return (
    <StandupContext.Provider
      value={{
        loadingRecents,
        errorRecents,
        dataRecents,
        loadingStandup,
        errorStandup,
        dataStandup,
        refetchStandupData,
        selectedDate,
        setSelectedDate,
        updateNote,
        updateNoteLoading,
        nextStandupDate,
        noteAddDates,
        noteAddDatesLoading,
        openNoteAddModal,
      }}
    >
      {children}
    </StandupContext.Provider>
  );
};

export default StandupContext;
export { StandupProvider };
