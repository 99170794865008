const StatusContainer = ({ children }) => {
  return (
    <div className="w-full h-full">
      <div className="flex flex-col justify-center w-full h-full max-w-2xl pb-8 mx-auto space-y-6 text-center md:justify-between md:w-3/4">
        {children}
      </div>
    </div>
  );
};
export default StatusContainer;
