import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { VSCodeButton, VSCodeTextField } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import Loading from "@/components/lib/Loading";
import MilestoneContext from "@/contexts/MilestoneContext";
import UserContext from "@/contexts/UserContext";
import clsx from "clsx";
import isEmail from "validator/lib/isEmail";
import { useNotification } from "@/contexts/NotificationContext";

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const EmailBox = ({ message = "Where should we send you updates?" }) => {
  const { completeMilestones, milestonesRefetch } = useContext(MilestoneContext);
  const { user, refetchUser } = useContext(UserContext);
  const notification = useNotification();

  const [updateUser, { loading: updateUserLoading, error: updateUserError }] =
    useMutation(UPDATE_USER);

  const emailRef = useRef(null);
  const [email, setEmail] = useState(user?.user?.email || "");
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (email) {
      setValid(isEmail(email));
    }
  }, [email]);

  useEffect(() => {
    emailRef?.current?.focus();
  }, []);

  const create = useCallback(() => {
    if (valid) {
      updateUser({
        variables: {
          input: { email: email },
        },
      })
        .then((response) => {
          completeMilestones("configure_email", () => {
            milestonesRefetch();
          });
          refetchUser();
        })
        .catch((error) => {
          setValid(false);
        });
    }
  }, [email, updateUser, refetchUser, valid, completeMilestones, milestonesRefetch]);

  if (updateUserError) {
    notification.error("There was an error saving your email.");
  }

  return (
    <div className="flex flex-col px-4 py-2 space-y-2 border rounded bg-backgroundSecondary ">
      <div className="text-sm font-medium">{message}</div>
      <div className="space-x-2 flex-row-centered">
        <VSCodeTextField
          placeholder="you@email.com"
          className={clsx("w-full outline-none", {
            "outline-input-error-border": !valid && email !== "",
          })}
          autoComplete="off"
          name="email"
          type="email"
          value={email}
          ref={emailRef}
          onInput={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div>
          {updateUserLoading ? <Loading /> : <VSCodeButton onClick={create}>Save</VSCodeButton>}
        </div>
      </div>
      <div className="text-sm text-description">Important stuff only.</div>
    </div>
  );
};

const EmailBoxWrapper = ({ children }) => {
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  return (
    <div className="py-5">
      <div
        className="space-x-2 cursor-pointer flex-row-centered group max-w-min whitespace-nowrap"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="text-sm truncate">
          You are receiving updates at <span className="font-medium">{user?.user?.email}</span>.
        </div>
        <div className="cursor-pointer ">
          {open ? <ChevronUpIcon className="h-4 icon" /> : <ChevronDownIcon className="h-4 icon" />}
        </div>
      </div>
      <div
        className={clsx({
          hidden: !open,
          block: open,
        })}
      >
        <div className="pt-3">{children}</div>
      </div>
    </div>
  );
};

const EmailPrompt = () => {
  const { user } = useContext(UserContext);

  if (user?.user?.email) {
    return (
      <EmailBoxWrapper>
        <EmailBox message="Contact email:" />
      </EmailBoxWrapper>
    );
  }

  return (
    <div className="pb-5">
      <EmailBox />
    </div>
  );
};

export default EmailPrompt;
