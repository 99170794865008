import "@/styles/index.css";
import "@/fonts/Montserrat/otf/Montserrat.css";
import "firebase/auth";

import * as Sentry from "@sentry/react";
import * as appConfig from "@/config/appConfig";

import AppContext, { withAppContext } from "@/contexts/AppContext";
import { BrowserAuthedRoutes, BrowserUnAuthedRoutes } from "@/components/BrowserRouter";
import { VSAuthedRouter, VSUnauthedRouter } from "@/components/VSRouter";
import { useContext, useEffect, useState } from "react";

import { BrowserTracing } from "@sentry/tracing";
import Error from "@/pages/Error";
import Loading from "@/components/lib/Loading";
import { createRoot } from "react-dom/client";

const environment =
  appConfig?.API_ENDPOINT === "https://api.stateful.com" ? "production" : "development";

Sentry.init({
  dsn: "https://81fb31e37ca44b93855b448436f029f9@o481102.ingest.sentry.io/6510289",
  integrations: [new BrowserTracing()],
  environment,
  tracesSampleRate: 0.1,
});

const Index = withAppContext(() => {
  const { authCustomToken, user, loading, error, tangle } = useContext(AppContext);
  const { authToken, defaultUx } = window.APP_STATE;
  const [authed, setAuthed] = useState(false);
  const [token, setToken] = useState(null);

  tangle?.on("onAuthToken", (_token) => {
    setToken(_token);
  });

  if (token === null && authToken) {
    setToken(authToken);
  }

  useEffect(() => {
    if (!authed && token) {
      authCustomToken(token).then(() => setAuthed(true));
    }
  }, [authCustomToken, authToken, authed, token]);

  if (token && !authed) {
    return <Loading />;
  }

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  // in vscode
  if (defaultUx !== undefined) {
    //insights backend is always default
    if (user) {
      //custom token auth completed
      return <VSAuthedRouter />;
    } else {
      //its the insights dash, but waiting for auth
      //this should catch all unauthed panels
      return <VSUnauthedRouter />;
    }
  } else {
    // in browser

    if (user) {
      //firebase authed
      return <BrowserAuthedRoutes />;
    } else {
      return <BrowserUnAuthedRoutes />;
    }
  }
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Index />);
