import { memo, useCallback, useContext, useEffect, useState } from "react";

import LabelToggle from "@/components/LabelToggle";
import { RATE_HOUR } from "@/const/personalize";
import SettingsContext from "@/contexts/SettingsContext";
import TimePicker from "@/components/TimePicker";
import { parseDayTime } from "@/utils/personalize";

const SettingTimePicker = ({ title = "", value = "", disabled = false, onSelect = () => null }) => {
  return (
    <div>
      <div className="text-sm font-medium">{title}</div>
      <TimePicker value={value} onChange={onSelect} disabled={disabled} />
    </div>
  );
};

const RateYourDaySchedule = () => {
  const { saveUserDaySettings, daySettings } = useContext(SettingsContext);
  const [rateTime, setRateTime] = useState({ label: parseDayTime(RATE_HOUR), data: RATE_HOUR });
  const [isRateTimeDisabled, setIsRateTimeDisabled] = useState(true);

  const handleRateTime = useCallback(
    (time) => {
      if (rateTime.data !== time) {
        setRateTime({ label: parseDayTime(time), data: time });
        saveUserDaySettings({ ratingPromptTime: time });
      }
    },
    [saveUserDaySettings, rateTime]
  );

  const handleRateTimeToggle = useCallback(() => {
    const ratingPromptTime = isRateTimeDisabled ? RATE_HOUR : null;

    setRateTime({ label: parseDayTime(RATE_HOUR), data: RATE_HOUR });
    setIsRateTimeDisabled(!isRateTimeDisabled);

    saveUserDaySettings({ ratingPromptTime });
  }, [isRateTimeDisabled, saveUserDaySettings]);

  useEffect(() => {
    if (daySettings) {
      setRateTime({
        label: parseDayTime(daySettings.ratingPromptTime),
        data: daySettings.ratingPromptTime,
      });
      setIsRateTimeDisabled(daySettings.ratingPromptTime === null);
    }
  }, [daySettings]);

  return (
    <div className="flex flex-col space-y-2">
      <LabelToggle
        enabled={!isRateTimeDisabled}
        setEnabled={handleRateTimeToggle}
        label={isRateTimeDisabled ? `Daily ratings are OFF` : `Daily ratings are ON`}
      />

      <div>
        <hr />
      </div>
      <div className="text-description">
        Contrasting how productive you felt with your metrics can provide useful insights into your
        flow.
      </div>

      <SettingTimePicker
        title="Prompt me at:"
        value={rateTime.data}
        onSelect={handleRateTime}
        disabled={isRateTimeDisabled}
      />
    </div>
  );
};

export default memo(RateYourDaySchedule);
