import { Navigate, Route, Routes } from "react-router-dom";

import CalendarContainer from "@/components/CalendarContainer";
import Cards from "@/components/Cards";
import DndCard from "@/components/cards/DndCard";
import Error from "@/pages/Error";
import MetricsContainer from "@/components/MetricsContainer";
import NotesContainer from "@/components/NotesContainer";
import PeopleContainer from "@/components/PeopleContainer";
import ScoreBox from "@/components/ScoreBox";
import SettingsContainer from "@/components/settings/SettingsContainer";
import SharingContainer from "@/components/SharingContainer";
import StandupContainer from "@/components/StandupContainer";
import { StandupProvider } from "@/contexts/StandupContext";
import SummaryCard from "@/components/cards/SummaryCard";
import SuspenseContainer from "@/components/SuspenseContainer";
import Title from "@/components/Title";
import Tos from "@/components/Tos";
import { memo } from "react";

const ScoreContainer = () => {
  return (
    <div className="flex flex-col h-full space-y-2 overflow-auto">
      <div>
        <SuspenseContainer>
          <SummaryCard />
        </SuspenseContainer>
      </div>
      <div>
        <SuspenseContainer>
          <ScoreBox />
        </SuspenseContainer>
      </div>

      <div>
        <DndCard />
      </div>

      <div>
        <Cards />
      </div>
    </div>
  );
};

const TabWrapper = ({ title, children }) => {
  return (
    <div className="w-full h-full">
      <Title>{title}</Title>
      {children}
    </div>
  );
};

const App = () => {
  return (
    <div className="w-full h-full">
      <Tos />

      <Routes>
        <Route
          path={"feed"}
          element={
            <TabWrapper title="Feed">
              <ScoreContainer />
            </TabWrapper>
          }
        />
        <Route
          path={"standup"}
          element={
            <TabWrapper title="Standup">
              <StandupProvider>
                <StandupContainer />
              </StandupProvider>
            </TabWrapper>
          }
        />
        <Route
          path={"notes"}
          element={
            <TabWrapper title="Notes">
              <NotesContainer />
            </TabWrapper>
          }
        />
        <Route
          path={"calendar"}
          element={
            <TabWrapper title="Calendar">
              <CalendarContainer />
            </TabWrapper>
          }
        />
        <Route
          path={"metrics/*"}
          element={
            <TabWrapper title="Metrics">
              <MetricsContainer />
            </TabWrapper>
          }
        />
        <Route
          path={"network/*"}
          element={
            <TabWrapper title="Network">
              <PeopleContainer />
            </TabWrapper>
          }
        />
        <Route
          path={"sharing/*"}
          element={
            <TabWrapper title="Sharing">
              <SharingContainer />
            </TabWrapper>
          }
        />
        <Route
          path={"settings/*"}
          element={
            <TabWrapper title="Settings">
              <SettingsContainer />
            </TabWrapper>
          }
        />

        <Route index element={<Navigate to="feed" />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
};

export default memo(App);
