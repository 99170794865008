import { Popover } from "react-tiny-popover";
import clsx from "clsx";
import { memo } from "react";

const PopWrapper = ({ contentChildren, children, open, setOpen, mt = "mt-2" }) => {
  return (
    <Popover
      isOpen={open}
      positions={["bottom", "left", "right", "top"]}
      onClickOutside={() => setOpen(false)}
      containerClassName="z-50"
      content={({ position, childRect, popoverRect }) => (
        <div
          className={clsx(
            "z-50 max-w-sm mx-1 mt-2 overflow-hidden text-sm bg-opacity-0 border rounded-md shadow-lg bg-background text-foreground"
          )}
        >
          {contentChildren}
        </div>
      )}
    >
      {children}
    </Popover>
  );
};

export default memo(PopWrapper);
