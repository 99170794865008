import {
  ChevronDownIcon,
  ChevronUpIcon,
  StatusOfflineIcon,
  StatusOnlineIcon,
} from "@heroicons/react/outline";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useContext, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import Dots from "@/components/lib/Dots";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import ModalBig from "@/components/modals/ModalBig";
import SettingsModal from "@/components/modals/SettingsModal";
import SmallSwitch from "@/components/SmallSwitch";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import clsx from "clsx";
import flatten from "lodash/flatten";
import moment from "moment";
import { useDialog } from "@/contexts/DialogContext";
import useWindowFocus from "@/components/hooks/useWindowFocus";

var converter = require("number-to-words");

const GET_USER_DND = gql`
  query GetUserDnd {
    user {
      dndEnabled
      dnd {
        active
        expireTime
        startTime
      }
      connections(types: SLACK) {
        data {
          hooks {
            data {
              ... on HookSlackDnd {
                id
                name
                disabled
                deliveryType
              }
            }
          }
        }
      }
      id
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const DndExpandSection = ({ dndEnabled, dnd, hookCount }) => {
  const [open, setOpen] = useState(false);
  const { addDialog } = useDialog();

  const [updateUser, { loading: updateUserLoading, error: updateUserError }] = useMutation(
    UPDATE_USER,
    { refetchQueries: [GET_USER_DND, "GetUserDnd"] }
  );

  const setEnabled = useCallback(
    (enabled) => {
      updateUser({
        variables: {
          input: { dndEnabled: enabled },
        },
      });
    },
    [updateUser]
  );

  return (
    <div className="flex flex-col py-2">
      <div className="justify-between px-2 flex-row-centered">
        <div className="w-full space-x-2 truncate flex-row-centered">
          <div>
            {dnd?.active ? (
              <StatusOnlineIcon className="w-5 text-covey-500" />
            ) : (
              <StatusOfflineIcon className="w-5 icon-fg" />
            )}
          </div>
          <div className="truncate whitespace-nowrap">
            <span className="font-medium">Do not disturb</span> is{" "}
            {dndEnabled ? `enabled` : `disabled`}.
          </div>

          <SmallSwitch enabled={dndEnabled} setEnabled={setEnabled} />
          {updateUserLoading && (
            <div>
              <Dots height={6} />
            </div>
          )}
          {updateUserError && `Something went wrong updating.`}
        </div>
        <div
          onClick={() => {
            setOpen(!open);
          }}
          className="pl-2"
        >
          {open ? (
            <ChevronUpIcon className="h-5 cursor-pointer icon" />
          ) : (
            <ChevronDownIcon className="h-5 cursor-pointer icon" />
          )}
        </div>
      </div>
      <div
        className={clsx({
          hidden: !open,
          block: open,
        })}
      >
        <div className="flex flex-col p-2 space-y-2">
          {dnd.active ? (
            <div>
              <div>
                You entered <span className="font-semibold">Focus Mode</span> at{" "}
                {moment.utc(dnd.startTime).local().format("h:mm a")} and are pushing to{" "}
                <span className="font-semibold">{converter.toWords(hookCount)}</span> Slack
                connection{hookCount === 1 ? `` : "s"}.
              </div>
              {/* <div>Started at: {moment.utc(dnd.startTime).local().format("h:mm a")}</div> */}
              {/* <div>Expires at: {moment.utc(dnd.expireTime).local().format("h:mm a")}</div> */}
            </div>
          ) : (
            <div>
              You're not currently in <span className="font-semibold">Focus Mode</span>, it will
              start automatically when enough coding activity is detected and push your DnD (if
              enabled).
            </div>
          )}
          <div>
            <hr className="w-full" />
          </div>
          <VSCodeLink
            onClick={() => {
              addDialog({
                Component: (
                  <ModalBig>
                    <SettingsModal tab="connections" />
                  </ModalBig>
                ),
              });
            }}
          >
            Configure your Slack connections →
          </VSCodeLink>
        </div>
      </div>
    </div>
  );
};

const DndCardContent = () => {
  const { pollInterval } = useContext(AppContext);

  const { loading, error, data, refetch } = useQuery(GET_USER_DND, { pollInterval: pollInterval });

  useWindowFocus(0, () => {
    refetch();
  });

  const dndEnabled = data?.user?.dndEnabled || false;
  const connections = data?.user?.connections?.data;

  const slackDndHooks = useMemo(() => {
    if (connections) {
      return flatten(
        connections.map((connection) => {
          return connection?.hooks?.data;
        })
      ).filter((hook) => hook.deliveryType === "SLACK_DND" && !hook.disabled);
    }
  }, [connections]);

  const hookCount = useMemo(() => {
    if (slackDndHooks) {
      return slackDndHooks.length;
    }
  }, [slackDndHooks]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorGrow message={error.message} />;
  }

  if (hookCount) {
    return (
      <div className="w-full h-full px-4">
        <div className="border rounded-sm">
          <div className="flex flex-col space-y-2">
            <DndExpandSection dnd={data?.user?.dnd} dndEnabled={dndEnabled} hookCount={hookCount} />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

const DndCard = () => {
  return <DndCardContent />;
};

export default DndCard;
