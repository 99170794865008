import DashContainer from "@/components/dash/DashContainer";
import StyleWrapper from "@/components/StyleWrapper";
import Title from "@/components/Title";

const Dash = () => {
  return (
    <>
      <Title>Dash</Title>
      <StyleWrapper classes="w-full h-full overflow-auto">
        <div className="flex flex-col py-4">
          <div className="flex flex-row items-center justify-center text-lg h-1/4">
            Recent Projects
          </div>
          <div className="w-1/2 mx-auto">
            <DashContainer />
          </div>
        </div>
      </StyleWrapper>
    </>
  );
};

export default Dash;
