import { useIntercom } from "react-use-intercom";
import { useEffect } from "react";

const IntercomUnauthedWrap = ({ children }) => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, [boot]);

  return <>{children}</>;
};

export default IntercomUnauthedWrap;
