import { VSCodeTextArea, VSCodeTextField } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import { useDebouncedCallback } from "use-debounce";

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const GET_USER = gql`
  query getUser {
    user {
      id
      company
      bio
      linkedin
      displayName
      twitter
      siteUrl
      photoUrl
    }
  }
`;

const BioSection = ({ value, save, label, placeholder, field }) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    save({
      [field]: val,
    });
  }, [val, field, save]);

  return (
    <div>
      <label htmlFor={field} className="block text-sm font-medium">
        {label}
      </label>
      <div className="mt-1">
        <VSCodeTextArea
          placeholder={placeholder}
          id={field}
          name={field}
          rows={2}
          className={"w-full"}
          value={val}
          onInput={(e) => {
            setVal(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

const InputSection = ({ value, save, label, placeholder, field }) => {
  const [val, setVal] = useState(value || "");

  useEffect(() => {
    save({
      [field]: val,
    });
  }, [val, field, save]);

  return (
    <div className="flex flex-col col-span-12 space-y-1">
      <div className="flex-row-centered">
        <label htmlFor={field} className="block text-sm font-medium">
          {label}
        </label>
      </div>

      <VSCodeTextField
        type="text"
        name={field}
        id={field}
        autoComplete={field}
        className={"w-full"}
        value={val}
        placeholder={placeholder}
        onInput={(e) => {
          setVal(e.target.value);
        }}
      />
    </div>
  );
};

const ProfileForm = () => {
  const { loading, error, data, refetch } = useQuery(GET_USER);

  // const [updateUser] = useMutation(UPDATE_USER, {
  //   refetchQueries: [GET_USER],
  // });

  const [updateUser] = useMutation(UPDATE_USER);

  const save = useDebouncedCallback((obj) => {
    updateUser({
      variables: {
        input: obj,
      },
    }).then(() => {
      refetch();
    });
  }, 1000);

  if (loading) return <Loading />;
  if (error) {
    return <ErrorGrow message={error.message} />;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex-grow space-y-4">
        <BioSection
          value={data.user?.bio ?? ""}
          save={save}
          label="Description"
          field="bio"
          placeholder="Tell us about yourself..."
        />
        <InputSection
          value={data.user?.displayName}
          save={save}
          label="Display name"
          field="displayName"
          placeholder="The name people see..."
        />
        <InputSection
          value={data.user?.company}
          save={save}
          label="Organization"
          field="company"
          placeholder="Any entity you want to associate with..."
        />
        <InputSection
          value={data.user?.twitter}
          save={save}
          label="Twitter"
          field="twitter"
          placeholder="Your twitter handle..."
        />
        <InputSection
          value={data.user?.linkedin}
          save={save}
          label="LinkedIn"
          field="linkedin"
          placeholder="Your LinkedIn username..."
        />
        <InputSection
          value={data.user?.siteUrl}
          save={save}
          label="Blog"
          field="siteUrl"
          placeholder="Have a blog?"
        />
      </div>
    </div>
  );
};

export default ProfileForm;
