import {
  CardContent,
  CardTitle,
  CardTitleIcon,
  CardWrapper,
} from "@/components/cards/CardWrappers";
import { gql, useQuery } from "@apollo/client";

import { CalendarIcon } from "@heroicons/react/outline";
import CalendarLinks from "@/components/CalendarLinks";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import PanelLink from "@/components/PanelLink";
import { useMemo } from "react";

const GET_DEFAULT_CALENDAR_ITEM = gql`
  query getDefaultCalendarFeedItem($id: UUID!) {
    feed {
      item(id: $id) {
        ... on DefaultCalendarFeedItem {
          id
          owner
          shareable
          snoozable
          time
          dismissed
          calendar {
            calendarUrl
            createTime
            description
            name
            id
            origin
            public
            secret
            type
            updateTime
          }
          type {
            displayName
            id
            name
          }
        }
      }
    }
  }
`;

const DefaultCalendarFeedItem = (props) => {
  const { item } = props;

  const {
    loading: cardLoading,
    error: cardError,
    data: cardData,
  } = useQuery(GET_DEFAULT_CALENDAR_ITEM, { variables: { id: item.id } });

  const cardItem = useMemo(() => {
    return cardData?.feed?.item;
  }, [cardData]);

  if (cardLoading || !cardData) return <Loading />;
  if (cardError) return <ErrorGrow message={cardError.message} />;

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        date
        title={
          <div className="space-x-2 flex-row-centered">
            <CardTitleIcon {...props}>
              <CalendarIcon />
            </CardTitleIcon>
            <div>Calendar</div>
          </div>
        }
      />

      <CardContent>
        <div className="flex flex-col space-y-2">
          <div>
            We generated a custom calendar to predict when you're going to be most productive. It's
            sharable, and can be easily added to Google Calendar.
          </div>
          <CalendarLinks calendar={cardItem.calendar} />

          <PanelLink
            linkText="Explore in calendar →"
            uriState={{ onView: { panel: "stateful-metrics", name: "forecast" } }}
            browserUrl="/app/calendar"
            homeUrl="/insights/metrics/forecast"
          />
        </div>
      </CardContent>
    </CardWrapper>
  );
};

export default DefaultCalendarFeedItem;
