import CalendarContainer from "@/components/CalendarContainer";

const Calendar = () => {
  return (
    <div className="w-full">
      <div className="flex min-h-screen px-4 lg:shadow-2xl">
        <div className="px-2 pt-2">
          <a href="/">
            <img src="/assets/stateful_icon.svg" className="h-20" alt="Stateful" />
          </a>
        </div>
        <div className="w-full">
          <CalendarContainer />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
