import { cloneElement, createContext, useContext, useEffect, useState } from "react";

import { Portal } from "react-portal";
import { uid } from "uid";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [dialogs, setDialogs] = useState([]);

  const removeDialog = (id) => {
    setDialogs(dialogs.filter((dialog) => dialog.id !== id));
  };

  const addDialog = (dialog) => {
    dialog.id = uid(16);
    setDialogs((prevDialogs) => [...prevDialogs, dialog]);
  };

  useEffect(() => {
    return () => {
      setDialogs([]);
    };
  }, []);

  return (
    <DialogContext.Provider value={{ addDialog }}>
      <Portal>
        {dialogs.map((dialog, index) => {
          let DialogComponent = dialog.Component;

          if (DialogComponent) {
            return cloneElement(DialogComponent, {
              _close: (e) => {
                removeDialog(dialog.id);
                if (e) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              },
              id: dialog.id,
              dialog: dialog,
              key: dialog.type + "_" + dialog.id,
              index: index,
            });
          } else {
            console.error(
              "Dialog layers was supplied a dialog without an associated component to clone. " +
                JSON.stringify(dialog)
            );
          }
          return true;
        })}
      </Portal>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  return useContext(DialogContext);
};
