import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import clsx from "clsx";

const ArrowBase = ({ children, disabled, onClick, classes }) => {
  return (
    <button
      classes={classes}
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: disabled ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "center",
        // right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
      }}
    >
      {children}
    </button>
  );
};

export const TabEntry = ({ title, itemId, setActiveTab, activeTab }) => {
  return (
    <div
      className={clsx("mx-2 cursor-pointer", {
        "font-medium pb-0.5 text-tab-active-fg border-b border-tab-active-border":
          activeTab === itemId,
      })}
      onClick={() => {
        setActiveTab(itemId);
      }}
      tabIndex={0}
    >
      <div className="card">
        <div>{title}</div>
      </div>
    </div>
  );
};

export const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators, initComplete } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible));
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ArrowBase disabled={disabled} onClick={() => scrollPrev()}>
      <ChevronLeftIcon className="w-4 icon" />
    </ArrowBase>
  );
};

export const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ArrowBase disabled={disabled} onClick={() => scrollNext()} classes="pl-2">
      <ChevronRightIcon className="w-4 icon" />
    </ArrowBase>
  );
};
