import { Children, cloneElement, memo, useEffect } from "react";

import Modal from "react-modal";
import disableBrowserBackButton from "disable-browser-back-navigation";

const ModalTiny = ({ _close, children, dialog }) => {
  Modal.setAppElement("#root");

  useEffect(() => {
    disableBrowserBackButton();
  }, []);

  const childrenWithProps = Children.map(children, (child, index) => {
    return cloneElement(child, {
      dialog: dialog,
      _close: _close,
    });
  });

  return (
    <Modal
      onRequestClose={_close}
      isOpen={true}
      className="flex items-center justify-center w-full h-full focus:outline-none"
      overlayClassName="inset-0 fixed bg-black bg-opacity-50 z-50"
    >
      <div className="flex flex-col w-full h-full overflow-hidden border vmd:mx-2 vmd:h-auto md:max-w-screen-md vmd:rounded bg-background">
        {childrenWithProps}
      </div>
    </Modal>
  );
};

export default memo(ModalTiny);
