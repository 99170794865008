import { CollectionIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { useCallback, useContext, useMemo } from "react";

import Loading from "@/components/lib/Loading";
import ProgressBar from "@/components/ProgressBar";
import SettingsContext from "@/contexts/SettingsContext";
import SimpleTooltip from "@/components/pops/SimpleTooltip";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import { contribLabelMap } from "@/components/score/contribLabelMap";

const BranchScoreContributor = ({ contributor, scoreRefetch }) => {
  const { saveUserDaySettings, updateSettingsLoading } = useContext(SettingsContext);

  const percentage = useMemo(() => {
    return Math.round((contributor.points / contributor.possiblePoints) * 100);
  }, [contributor]);

  const backgroundColor = useMemo(() => {
    if (percentage >= 85) {
      return "bg-green-300";
    } else if (percentage >= 50) {
      return "bg-yellow-300";
    } else {
      return "bg-red-300";
    }
  }, [percentage]);

  const removeBranchContributor = useCallback(() => {
    saveUserDaySettings({ penalizeGitBranchUsage: null }, () => {
      if (scoreRefetch) {
        scoreRefetch();
      }
    });
  }, [saveUserDaySettings, scoreRefetch]);

  return (
    <div className="space-x-2 flex-row-centered">
      <CollectionIcon className="w-5 text-covey-500" />

      <div className="justify-start flex-grow hidden sm:flex">
        <div className="font-medium whitespace-nowrap">{contribLabelMap[contributor.name]}:</div>
      </div>

      <ProgressBar percentage={percentage} backgroundColor={backgroundColor} />
      <SimpleTooltip trigger={<InformationCircleIcon className="w-4 cursor-pointer icon" />}>
        <div className="flex flex-col">
          <div className="pb-2 text-xs">{contribLabelMap[contributor.name]}</div>
          <div className="whitespace-nowrap">
            <span className="font-medium">Points:</span> {contributor.points} of{" "}
            {contributor.possiblePoints}
            {contributor.points === contributor.possiblePoints && <> 👍</>}
          </div>
          <div className="py-2">
            <hr />
          </div>
          <div>
            <VSCodeLink onClick={removeBranchContributor}>[Remove from Score]</VSCodeLink>
          </div>
          {updateSettingsLoading && (
            <div>
              <Loading />
            </div>
          )}
        </div>
      </SimpleTooltip>
    </div>
  );
};

export default BranchScoreContributor;
