import { memo, useEffect, useMemo, useState } from "react";

import { ChevronDownIcon } from "@heroicons/react/solid";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import clsx from "clsx";
import getStatus from "@/utils/getStatus";
import useInterval from "use-interval";

const Face = memo(({ face, setUserSelected, selected }) => {
  const isSelected = useMemo(() => {
    return selected?.id === face?.id;
  }, [face, selected]);

  const isDnd = useMemo(() => {
    return face?.dnd?.active;
  }, [face]);

  return (
    <div className="flex flex-col items-center justify-center text-blue-500 max-w-min">
      <img
        onMouseOver={() => {
          setUserSelected(face);
        }}
        src={face.photoUrl || "/assets/empty-avatar.png"}
        className={clsx(
          "w-8 h-8 mx-0.5 max-w-min rounded border cursor-pointer bg-white border-gray-500 hover:border-blue-400",
          { "border-blue-400": isSelected },
          { "border-covey-400": isDnd }
        )}
        alt="face"
      />
      {isSelected && <ChevronDownIcon className="h-3" />}
      {!isSelected && <div className="h-3"></div>}
    </div>
  );
});

const FacePile = ({ faces, animate = false }) => {
  const [selected, setSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);

  useEffect(() => {
    if (userSelected) {
      setSelected(userSelected);
    }
  }, [userSelected]);

  useEffect(() => {
    if (faces.length > 0 && !userSelected) {
      if (selected) {
        setSelected(selected);
      } else if (faces.length > 3) {
        const middle = faces.length / 2;
        setSelected(faces[Math.floor(middle)]);
      } else {
        setSelected(faces[0]);
      }
    }
  }, [faces, userSelected, selected]);

  const selectedStatus = useMemo(() => {
    const workDay = selected?.workDay;
    const status = selected?.status;
    return getStatus(status, selected?.dnd, workDay);
  }, [selected]);

  const selectedUsername = useMemo(() => {
    return selected?.github?.username || null;
  }, [selected]);

  const selectedDnd = useMemo(() => {
    return selected?.dnd?.active;
  }, [selected]);

  useInterval(() => {
    if (faces.length > 1 && animate && !userSelected) {
      const index = faces.findIndex((face) => face.id === selected.id);
      if (index < faces.length - 1) {
        setSelected(faces[index + 1]);
      } else if (faces.length > 3) {
        const middle = faces.length / 2;
        setSelected(faces[Math.floor(middle)]);
      } else {
        setSelected(faces[0]);
      }
    }
  }, 8000);

  if (faces.length === 0) {
    return <div>Looks like nobody's online, check back later. 🪐</div>;
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row flex-wrap items-start justify-center">
        {faces.map((face) => {
          return (
            <Face key={face.id} face={face} setUserSelected={setUserSelected} selected={selected} />
          );
        })}
      </div>
      <div className="flex flex-col justify-center w-full space-y-1 text-center">
        {selectedUsername ? (
          <div>
            <VSCodeLink href={`https://app.stateful.com/status/${selectedUsername || ""}`}>
              <span className="font-semibold">{selectedUsername}</span>
            </VSCodeLink>
          </div>
        ) : (
          <div className="font-semibold text-center">unknown</div>
        )}
        <div className="w-full px-2 py-2 text-center border shadow rounded-xl">
          {selectedStatus} {selectedDnd && <>(focused)</>}
        </div>
      </div>
    </div>
  );
};

export default memo(FacePile);
