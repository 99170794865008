import {
  ArrowSmRightIcon,
  DotsVerticalIcon,
  PencilIcon,
  SwitchVerticalIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { memo, useCallback, useContext, useMemo, useState } from "react";

import ModalTiny from "@/components/modals/ModalTiny";
import NoteEditModal from "@/components/modals/NoteEditModal";
import PopWrapper from "@/components/pops/PopWrapper";
import StandupContext from "@/contexts/StandupContext";
import moment from "moment";
import { useDialog } from "@/contexts/DialogContext";
import { useNotification } from "@/contexts/NotificationContext";

const NoteStandupSelectPop = ({ note, doUpdateNote, refetchNotes }) => {
  const { nextStandupDate, noteAddDates, selectedDate } = useContext(StandupContext);
  const { addDialog } = useDialog();
  const [open, setOpen] = useState(false);
  const notification = useNotification();

  const isNextStandup = useMemo(() => {
    const selectedDateMoment = moment(selectedDate);
    const nextStandupDateMoment = moment(nextStandupDate, "YYYY-MM-DD");
    return selectedDateMoment.isSame(nextStandupDateMoment, "day");
  }, [selectedDate, nextStandupDate]);

  const noteInNextStandup = useMemo(() => {
    const nextDay = moment(nextStandupDate, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD");
    return note?.dates?.includes(nextDay);
  }, [note, nextStandupDate]);

  const handleArchiveNote = useCallback(() => {
    doUpdateNote({ id: note.id, hide: !note.hidden });
  }, [note, doUpdateNote]);

  const handleConvertNote = useCallback(() => {
    if (note.type === "TODO") {
      doUpdateNote({ id: note.id, type: "NOTE" });
    } else {
      doUpdateNote({ id: note.id, type: "TODO" });
    }
  }, [note, doUpdateNote]);

  const openNoteEditModal = useCallback(() => {
    addDialog({
      Component: (
        <ModalTiny>
          <NoteEditModal note={note} refetchData={refetchNotes} />
        </ModalTiny>
      ),
    });
  }, [refetchNotes, note, addDialog]);

  const handlePuntNote = useCallback(() => {
    const nextStandupMinusOne = moment(nextStandupDate, "YYYY-MM-DD")
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    noteAddDates({ variables: { input: { id: note.id, addDates: [nextStandupMinusOne] } } }).then(
      () => {
        setOpen(false);
        notification.info("Note added to the upcoming standup.");
      }
    );
  }, [note, noteAddDates, nextStandupDate, notification]);

  return (
    <PopWrapper
      open={open}
      setOpen={setOpen}
      contentChildren={
        <div className="flex flex-col p-1 divide-y">
          <div className={"popDownItem"} onClick={openNoteEditModal}>
            <PencilIcon className="h-4 icon " />
            <div>Edit</div>
          </div>
          <div className={"popDownItem"} onClick={handleConvertNote}>
            <SwitchVerticalIcon className="h-4 cursor-pointer text-foreground " />
            <div>Convert to {note.type === "TODO" ? "Note" : "Todo"}</div>
          </div>
          {nextStandupDate !== null && !isNextStandup && noteInNextStandup !== true && (
            <div className={"popDownItem"} onClick={handlePuntNote}>
              <ArrowSmRightIcon className="h-4 cursor-pointer text-foreground " />
              <div>Add to upcoming standup</div>
            </div>
          )}
          <div className={"popDownItem"} onClick={handleArchiveNote}>
            <TrashIcon className="h-4 cursor-pointer " />
            <div>{note.hidden ? "Restore" : "Archive"}</div>
          </div>
        </div>
      }
    >
      <div
        className="cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DotsVerticalIcon className="h-5 icon" />
      </div>
    </PopWrapper>
  );
};

export default memo(NoteStandupSelectPop);
