import { InformationCircleIcon, PauseIcon } from "@heroicons/react/outline";

import ProgressBar from "@/components/ProgressBar";
import SimpleTooltip from "@/components/pops/SimpleTooltip";
import { contribLabelMap } from "@/components/score/contribLabelMap";
import { useMemo } from "react";

const BreaksScoreContributor = ({ contributor }) => {
  const hintArr = useMemo(() => {
    return contributor?.hints;
  }, [contributor]);

  const hintMap = useMemo(() => {
    return hintArr.reduce(function (accum, currentVal) {
      accum[currentVal.name] = currentVal;
      return accum;
    }, {});
  }, [hintArr]);

  const percentage = useMemo(() => {
    return Math.round((contributor.points / contributor.possiblePoints) * 100);
  }, [contributor]);

  const backgroundColor = useMemo(() => {
    if (percentage >= 85) {
      return "bg-green-300";
    } else if (percentage >= 50) {
      return "bg-yellow-300";
    } else {
      return "bg-red-300";
    }
  }, [percentage]);

  return (
    <div className="flex-row-centered space-x-2">
      <PauseIcon className="w-5 text-covey-500" />

      <div className="justify-start flex-grow hidden sm:flex">
        <div className="font-medium whitespace-nowrap">{contribLabelMap[contributor.name]}:</div>
      </div>
      <ProgressBar percentage={percentage} backgroundColor={backgroundColor} />
      <SimpleTooltip trigger={<InformationCircleIcon className="w-4 cursor-pointer icon" />}>
        <div className="flex flex-col">
          <div className="pb-2 text-xs">{contribLabelMap[contributor.name]}</div>
          <div className="whitespace-nowrap">
            <span className="font-medium">Points:</span> {contributor.points} of{" "}
            {contributor.possiblePoints}
            {contributor.points === contributor.possiblePoints && <> 👍</>}
          </div>
          {hintMap.count && (
            <div>
              <span className="font-medium">Count:</span> {hintMap.count.currentValue} of{" "}
              {hintMap.count.targetValue} goal{" "}
              {hintMap.count.currentValue >= hintMap.count.targetValue && "👍"}
            </div>
          )}
        </div>
      </SimpleTooltip>
    </div>
  );
};

export default BreaksScoreContributor;
