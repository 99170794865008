import clsx from "clsx";
import { memo } from "react";

const SectionDiv = ({ children, stay = false }) => {
  return (
    <div
      className={clsx("flex items-center justify-center md:flex-grow py-2 min-h-fit", {
        "hidden md:flex": !stay,
      })}
    >
      {children}
    </div>
  );
};

export default memo(SectionDiv);
