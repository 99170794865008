import {
  CardContent,
  CardTitle,
  CardTitleIcon,
  CardWrapper,
} from "@/components/cards/CardWrappers";
import { gql, useQuery } from "@apollo/client";
import { useContext, useMemo } from "react";

import AppContext from "@/contexts/AppContext";
import ArrowAct from "@/components/cards/ArrowAct";
import { CheckIcon } from "@heroicons/react/solid";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import { WebhooksIcon } from "@/components/lib/icons";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_HOOKS = gql`
  query Hooks {
    user {
      id
      hooks(deliveryTypes: [WEB]) {
        data {
          ... on HookWeb {
            id
            name
            url
            disabled
            statusChange {
              default
              file
              language
              project
              activity
            }
            updateTime
          }
        }
      }
    }
  }
`;

const WebhookRecommendationFeedItem = (props) => {
  const { pollInterval } = useContext(AppContext);

  const { browser = false } = props;
  const {
    loading: hooksLoading,
    error: hooksError,
    data: hooksData,
    refetch: hooksRefetch,
  } = useQuery(GET_HOOKS, { pollInterval: pollInterval });

  useWindowFocus(0, () => {
    hooksRefetch();
  });

  const hookArr = useMemo(() => {
    return hooksData?.user?.hooks?.data;
  }, [hooksData]);

  const hasHooks = useMemo(() => {
    if (hookArr && hookArr.length > 0) {
      const enabled = hookArr.filter((hook) => hook.disabled === false).length;

      if (Number.isInteger(enabled) && enabled > 0) {
        return true;
      }
    }

    return false;
  }, [hookArr]);

  const sharingStr = useMemo(() => {
    if (hasHooks) {
      const sharingArr = hookArr.map((hook) => {
        let fields = [];
        for (const [key, value] of Object.entries(hook.statusChange)) {
          if (value && key !== "__typename") {
            fields.push(key);
          }
        }
        return fields;
      });
      return [...new Set(sharingArr.flat())].join(", ");
    }
  }, [hookArr, hasHooks]);

  const description = useMemo(() => {
    if (hasHooks) {
      const enabledArr = hookArr.filter((hook) => hook.disabled === false);
      // const nameStr = enabledArr.map((hook) => hook.name).join(", ");
      return `Pushing status to ${enabledArr.length} webhook(s), including ${sharingStr}.`;
    }
    return "Customize and push your coding status anywhere you want, build something cool.";
  }, [hookArr, hasHooks, sharingStr]);

  if (hooksLoading) {
    return <Loading />;
  }

  if (hooksError) {
    return <ErrorGrow message={hooksError.message} />;
  }

  return (
    <CardWrapper {...props}>
      <CardTitle
        {...props}
        title={
          <div className="flex-row-centered space-x-2">
            <CardTitleIcon {...props}>
              <WebhooksIcon />
            </CardTitleIcon>
            <div>Webhooks</div>
            <div className="fill-covey-500">
              {hasHooks ? <CheckIcon className="w-5 text-covey-500" /> : <></>}
            </div>
          </div>
        }
      />
      <CardContent>
        <div className="flex flex-col space-y-2">
          <div>{description}</div>
          <div>
            <a
              rel="noreferrer"
              href={
                browser
                  ? "https://app.stateful.com/settings/webhooks"
                  : "https://app.stateful.com/manage/settings/webhooks"
              }
              className="w-full"
            >
              <ArrowAct>{hasHooks ? <>Make changes</> : <>Setup now</>}</ArrowAct>
            </a>
          </div>
        </div>
      </CardContent>
    </CardWrapper>
  );
};

export default WebhookRecommendationFeedItem;
