import { VSCodeButton, VSCodeTextField } from "@vscode/webview-ui-toolkit/react";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import Loading from "@/components/lib/Loading";
import MilestoneContext from "@/contexts/MilestoneContext";
import UserContext from "@/contexts/UserContext";
import clsx from "clsx";
import isEmail from "validator/lib/isEmail";
import { useNotification } from "@/contexts/NotificationContext";

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const VSEmailPrompt = () => {
  const { milestonesRefetch, completeMilestones } = useContext(MilestoneContext);

  const { user, refetchUser } = useContext(UserContext);
  const notification = useNotification();

  const [updateUser, { loading: updateUserLoading, error: updateUserError }] =
    useMutation(UPDATE_USER);

  const emailRef = useRef(null);
  const [email, setEmail] = useState(user?.user?.email || "");
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (email) {
      setValid(isEmail(email));
    }
  }, [email]);

  useEffect(() => {
    emailRef?.current?.focus();
  }, []);

  const create = useCallback(() => {
    if (valid) {
      updateUser({
        variables: {
          input: { email: email },
        },
      })
        .then((resp) => {
          completeMilestones("configure_email", () => {
            milestonesRefetch();
          });
          refetchUser();
        })
        .catch((error) => {
          setValid(false);
        });
    }
  }, [email, updateUser, refetchUser, valid, completeMilestones, milestonesRefetch]);

  if (updateUserError) {
    notification.error("There was an error saving your email.");
  }
  if (!user?.user?.email) {
    return (
      <div className="flex flex-col w-full p-4 space-y-1 border rounded bg-backgroundSecondary">
        <div className="font-medium ">Where should we send updates?</div>
        <div className="space-x-2 flex-row-centered">
          <VSCodeTextField
            placeholder="you@email.com"
            className={clsx("w-full outline-none", {
              "outline-input-error-border": !valid && email !== "",
            })}
            autoComplete="off"
            name="email"
            type="email"
            value={email}
            ref={emailRef}
            onInput={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div>
            {updateUserLoading ? <Loading /> : <VSCodeButton onClick={create}>Save</VSCodeButton>}
          </div>
        </div>
        <div className="text-xs text-description">Important stuff only.</div>
      </div>
    );
  }

  return (
    <div className="w-3/4 text-center text-description">
      Feel free to get back to coding, we will notify you when your dashboard is ready! 👍
    </div>
  );
};

export default VSEmailPrompt;
