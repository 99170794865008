import * as Sentry from "@sentry/browser";

import { Suspense, memo } from "react";

import { ErrorBoundary } from "react-error-boundary";
import Loading from "@/components/lib/Loading";

const Skel = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Loading />
    </div>
  );
};

const ErrorMessage = () => {
  return (
    <div className="flex items-center justify-center w-full h-full font-semibold text-error-fg">
      <div>It looks like something went wrong...</div>
    </div>
  );
};

const SuspenseContainer = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => {
        return <ErrorMessage />;
      }}
      onError={(error, info) => {
        console.log(error, info);
        Sentry.captureException(error);
      }}
    >
      <Suspense fallback={<Skel />}>{children}</Suspense>
    </ErrorBoundary>
  );
};

export default memo(SuspenseContainer);
