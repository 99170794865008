import { Switch } from "@headlessui/react";

const DayToggle = ({ label, checked, setChecked }) => {
  return (
    <Switch.Group>
      <div
        className={`border rounded-sm overflow-hidden mx-0.5  ${
          checked ? "border-link-fg" : "border-border"
        }`}
      >
        <Switch
          checked={checked}
          onChange={setChecked}
          className="w-full h-full px-4 py-2 bg-background"
        >
          <div className="flex items-center justify-center text-sm font-bold">{label}</div>
        </Switch>
      </div>
    </Switch.Group>
  );
};

export { DayToggle };
