import { VSCodeButton, VSCodeTextArea, VSCodeTextField } from "@vscode/webview-ui-toolkit/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import UserContext from "@/contexts/UserContext";
import clsx from "clsx";
import { gql } from "@apollo/client";
import isEmail from "validator/lib/isEmail";
import { useMutation } from "@apollo/client";
import { useNotification } from "@/contexts/NotificationContext";

export const SUBMIT_INVITATION = gql`
  mutation submitInvitation($input: CreateUserInvitationInput!) {
    createUserInvitation(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const InviteTeamForm = ({
  msg = "Join me on Stateful! Let's make coding a little more fun and rewarding together.",
}) => {
  const [submitInvitation] = useMutation(SUBMIT_INVITATION);
  const { user } = useContext(UserContext);
  const notification = useNotification();

  const [valid, setValid] = useState(true);
  const emailRef = useRef(null);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(msg);

  useEffect(() => {
    if (message === "") {
      setMessage(msg);
    }
  }, [message, msg]);

  useEffect(() => {
    if (email) {
      setValid(isEmail(email));
    }
  }, [email]);

  const setInvalid = () => {
    setValid(false);
    emailRef.current.focus();
  };

  const submit = useCallback(() => {
    if (valid) {
      submitInvitation({
        variables: {
          input: {
            recipientEmail: email,
            recipientDisplayName: "",
            senderDisplayName: user?.user?.name,
            message: message,
          },
        },
      })
        .then(({ data }) => {
          if (data?.createUserInvitation?.errors.length !== 0) {
            setInvalid();
            notification.error(data?.createUserInvitation?.errors[0].message);
          } else {
            setEmail("");
            setMessage("");
            setSent(true);
            emailRef.current.focus();
          }
        })
        .catch((error) => {
          setInvalid();
        });
    } else {
      setInvalid();
    }
  }, [email, message, notification, submitInvitation, valid, user]);
  return (
    <div className="flex flex-col space-y-2">
      <VSCodeTextField
        type="email"
        placeholder="teammate@email.com (required)"
        ref={emailRef}
        id="email"
        name="email"
        autoComplete="off"
        value={email}
        className={clsx("w-full outline-none", {
          "outline-input-error-border": !valid && email !== "",
        })}
        onInput={(e) => {
          setEmail(e.target.value);
        }}
      />

      <VSCodeTextArea
        autoComplete="off"
        placeholder="Optional message"
        id="message"
        name="message"
        value={message}
        onInput={(e) => {
          setMessage(e.target.value);
        }}
        rows={3}
        className="text-sm inputBase"
      />

      <div className="flex justify-between flex-shrink">
        <div className="font-medium text-covey-500"> {sent && <>Invite sent, keep going!</>}</div>
        <VSCodeButton onClick={submit}>Send</VSCodeButton>
      </div>
    </div>
  );
};

export default InviteTeamForm;
