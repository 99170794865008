// import { ProgressBar } from "react-loader-spinner";
import { VSCodeProgressRing } from "@vscode/webview-ui-toolkit/react";

const Loading = () => {
  return (
    <div className="flex items-center justify-center w-full h-full py-1">
      <VSCodeProgressRing />
      {/* <ProgressBar
        height="30"
        width="80"
        borderColor="rgba(0, 0, 0, 0)"
        barColor="var(--vscode-progressBar-background)"
      /> */}
    </div>
  );
};

export default Loading;
