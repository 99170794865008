import { PLANNED_BREAK_OPTIONS, TOTAL_WORKING_SECONDS } from "@/const/personalize";
import { VSCodeDropdown, VSCodeOption } from "@vscode/webview-ui-toolkit/react";
import { memo, useCallback, useContext, useMemo } from "react";

import MilestoneContext from "@/contexts/MilestoneContext";
import SettingsContext from "@/contexts/SettingsContext";
import { parsePlannedBreaksDuration } from "@/utils/personalize";

const PlannedDailyHoursCoding = ({ parentRef }) => {
  const { completeMilestones } = useContext(MilestoneContext);
  const { saveUserDaySettings, daySettings } = useContext(SettingsContext);

  const target = useMemo(() => {
    if (daySettings) {
      return daySettings.targetCodingDurationSeconds || TOTAL_WORKING_SECONDS;
    }
  }, [daySettings]);

  const onChange = useCallback(
    (e) => {
      completeMilestones("configure_target_durations");

      saveUserDaySettings({
        targetCodingDurationSeconds: parseInt(e.target.value, 10),
      });
    },
    [saveUserDaySettings, completeMilestones]
  );

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-description">
        How many hours of uninterrupted coding time do you want per day?
      </div>
      <div>
        <VSCodeDropdown value={target?.toString()} onChange={onChange}>
          {PLANNED_BREAK_OPTIONS.map((seconds, index) => {
            return (
              <VSCodeOption key={seconds + "_" + index} value={seconds.toString()}>
                {parsePlannedBreaksDuration(seconds)}
              </VSCodeOption>
            );
          })}
        </VSCodeDropdown>
      </div>
    </div>
  );
};

export default memo(PlannedDailyHoursCoding);
