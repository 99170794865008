import FriendlyPrivacyModal from "@/components/modals/FriendlyPrivacyModal";
import ModalBig from "@/components/modals/ModalBig";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import { useDialog } from "@/contexts/DialogContext";

const PrivacyLink = () => {
  const { addDialog } = useDialog();

  return (
    <VSCodeLink
      onClick={() => {
        addDialog({
          Component: (
            <ModalBig>
              <FriendlyPrivacyModal />
            </ModalBig>
          ),
        });
      }}
    >
      <span className="text-sm">Your data belongs to you.</span>
    </VSCodeLink>
  );
};

export default PrivacyLink;
