import { memo, useState } from "react";

import { DotsVerticalIcon } from "@heroicons/react/outline";
import PopWrapper from "@/components/pops/PopWrapper";

const NoteOptionsPop = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <PopWrapper open={open} setOpen={setOpen} contentChildren={children}>
      <div
        className="cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DotsVerticalIcon className="h-4 icon" />
      </div>
    </PopWrapper>
  );
};

export default memo(NoteOptionsPop);
