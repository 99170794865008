import groupBy from "lodash/groupBy";
import languages from "@/utils/languages.json";
import moment from "moment";

function sumPerKey(events, key, summand, label) {
  const groups = groupBy(events, key);
  return Object.entries(groups).reduce((accu, curr) => {
    const [k, v] = curr;
    const n = {};
    n[summand] = v.reduce((a, c) => a + c[summand], 0);
    n[label] = k;
    accu.push(n);
    return accu;
  }, []);
}

const formatLanguageId = (languageId) => {
  const formatted = languages[languageId];
  if (formatted) {
    return formatted;
  }
  return upperCaseFirst(languageId);
};

const isInZone = function (startedAt) {
  const startDate = moment.utc(startedAt).local();
  const zoneMs = moment(moment.now()).diff(startDate);
  const zoneMins = moment.duration(zoneMs).asMinutes();
  const zone = zoneMins > 15;
  return zone;
};

const getStatusString = (status, initialStatusDefault) => {
  if (status && status.started_at && moment(status.expires_at).isAfter()) {
    const emoji = isInZone(status.started_at) ? "🔥" : "🎧";
    const message = [emoji];
    if (status.language_id) {
      message.push(formatLanguageId(status.language_id));
    }
    if (status.activity) {
      message.push(status.activity);
    }
    if (status.project_name) {
      message.push(`in ${status.project_name}`);
    }
    if (status.file) {
      message.push(`on ${status.file}`);
    }
    return message.join(" ");
  } else {
    return initialStatusDefault;
  }
};

function mapSpans(span) {
  let { started_at, ended_at, data, ...other } = span;
  started_at = moment.utc(started_at);
  ended_at = moment.utc(ended_at);
  return {
    ...data,
    started_at,
    ended_at,
    duration: ended_at.diff(started_at),
    ...other,
  };
}

function toLocalDayDate(date) {
  let momentDate = date ? moment.utc(date).local() : moment();
  let day = moment(momentDate).minutes(0).seconds(0).milliseconds(0).toDate();
  return day;
}

function toLocalDayString(date) {
  let momentDate = date ? moment.utc(date).local() : moment();
  let day = moment(momentDate).minutes(0).seconds(0).milliseconds(0);
  return moment(day).format("YYYY/MM/DD");
}

function toLocalDayObject(date) {
  let momentDate = date ? moment.utc(date).local() : moment();
  let day = moment(momentDate).minutes(0).seconds(0).milliseconds(0);
  return day;
}

const upperCaseFirst = (str) => {
  if (str && str.charAt) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
};

const dateHourToStr = (date) => {
  const dateStr = toLocalDayObject(moment(date, "YYYY/MM/DD")).format("MM/DD");

  const dateArr = date.split("/");
  const hour = dateArr[dateArr.length - 1];

  const startHr = moment(hour, "hh").format("h");
  const endHr = moment(hour, "hh").add(1, "hour").format("h");
  const ap = moment(hour, "hh").add(1, "hour").format("a");

  return `${dateStr} ${startHr}-${endHr} ${ap}`;
};

const delayMS = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export {
  sumPerKey,
  toLocalDayString,
  toLocalDayObject,
  toLocalDayDate,
  upperCaseFirst,
  formatLanguageId,
  getStatusString,
  isInZone,
  mapSpans,
  dateHourToStr,
  delayMS,
};
