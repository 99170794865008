import { gql, useQuery } from "@apollo/client";

import CodingGraph from "@/components/graphs/CodingGraph";
import CoolDateRangeSelector from "@/components/CoolDateRangeSelector";
import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import NotEnoughData from "@/components/NotEnoughData";
import moment from "moment";
import { useMemo } from "react";
import useRangeSelection from "@/components/hooks/useRangeSelection";
import useWindowFocus from "@/components/hooks/useWindowFocus";

const GET_GRAPH_DATA = gql`
  query getDaysCoding($startDate: Date!, $endDate: Date!) {
    days(startDate: $startDate, endDate: $endDate, pageSize: 20) {
      data {
        date
        codingDuration {
          totalSeconds
          readingSeconds
          writingSeconds
        }
        restCount
        restDurationSeconds
      }
    }
  }
`;

const TimeContainerWrapper = ({ selectionRange }) => {
  const [startDate, endDate] = useMemo(() => {
    return [
      moment(selectionRange.startDate).startOf("day").format("YYYY-MM-DD"),
      moment(selectionRange.endDate).endOf("day").format("YYYY-MM-DD"),
    ];
  }, [selectionRange]);

  const { loading, error, data, refetch } = useQuery(GET_GRAPH_DATA, {
    skip: !startDate || !endDate,
    variables: { startDate: startDate, endDate: endDate },
  });

  const days = data?.days?.data;

  const graphData = useMemo(() => {
    if (days) {
      return days
        .map((day) => {
          return {
            readingSeconds: day?.codingDuration?.readingSeconds || 0,
            date: day?.date,
            writingSeconds: day?.codingDuration?.writingSeconds || 0,
            restCount: day?.restCount || 0,
            restDurationSeconds: day?.restDurationSeconds || 0,
          };
        })
        .sort(function (a, b) {
          return new Date(b.date) - new Date(a.Date);
        })
        .reverse();
    }
  }, [days]);

  useWindowFocus(0, () => {
    refetch();
  });

  if (loading) return <Loading />;
  if (error) return <ErrorGrow message={error.message} />;

  if (days.length === 0) {
    return <NotEnoughData />;
  }

  return (
    <div className="w-full h-full p-1">
      <CodingGraph data={graphData} />
    </div>
  );
};

const TimeContainer = () => {
  const [selectionRange, setSelectionRange] = useRangeSelection();

  return (
    <div className="flex flex-col w-full h-full pr-4">
      <CoolDateRangeSelector
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
      <TimeContainerWrapper selectionRange={selectionRange} />
    </div>
  );
};

export default TimeContainer;
