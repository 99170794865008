import { gql, useMutation } from "@apollo/client";

import UserAvatarStatus from "@/components/UserAvatarStatus";
import { VSCodeButton } from "@vscode/webview-ui-toolkit/react";
import { useCallback } from "react";
import { useNotification } from "@/contexts/NotificationContext";

const UNFOLLOW_USER = gql`
  mutation Unfollow($input: UnfollowUserInput!) {
    unfollowUser(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

const FOLLOW_USER = gql`
  mutation Follow($input: FollowUserInput!) {
    followUser(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;

export const RELATIONSHIP_FRAGMENT = `
  fragment ProfileFields on ProfileList {
    data {
    id
    name
    bio
    following
    follower
    photoUrl
    github {
      username
      id
    }
    status {
      activity {
        displayName
      }
      durationMs
      latestActivityTime
      project {
        displayName
        file {
          name
        }
        gitBranch {
          name
        }
        language {
          displayName
          id
        }
        name
        shortName
      }
    }
  }
}
`;

export const PROFILE_FRAGMENT = `
fragment UserProfileFields on Profile {
  id
  name
  bio
  following
  follower
  photoUrl
  github {
    username
    id
  }
  status {
    activity {
      displayName
    }
    durationMs
    latestActivityTime
    project {
      displayName
      file {
        name
      }
      gitBranch {
        name
      }
      language {
        displayName
        id
      }
      name
      shortName
    }
  }
  dnd {
    active
    expireTime
    startTime
  }
} `;

export const PersonRow = ({ user, refetch }) => {
  const [unfollowUser] = useMutation(UNFOLLOW_USER);
  const [followUser] = useMutation(FOLLOW_USER);

  const notification = useNotification();

  const doUnFollow = useCallback(() => {
    unfollowUser({
      variables: {
        input: {
          userId: user.id,
        },
      },
    }).then(() => {
      notification.info(`Unfollowed ${user.github.username}`);
      refetch();
    });
  }, [user, refetch, notification, unfollowUser]);

  const doFollow = useCallback(() => {
    followUser({
      variables: {
        input: {
          userId: user.id,
        },
      },
    }).then(() => {
      notification.info(`Followed ${user.github.username}`);
      refetch();
    });
  }, [user, refetch, notification, followUser]);

  return (
    <div className="w-full px-1 py-1 space-x-2 flex-row-centered">
      <div>
        <UserAvatarStatus face={user} />
      </div>
      <div className="flex flex-col flex-grow space-y-0.5 w-full truncate justify-center">
        <div className="font-medium whitespace-nowrap">
          {user?.github?.username || user?.displayName || user?.name}
        </div>

        {user.bio && (
          <div className="text-sm truncate whitespace-nowrap text-description">{user.bio}</div>
        )}
      </div>

      <div>
        {user.following ? (
          <VSCodeButton
            appearance="secondary"
            className=" whitespace-nowrap"
            onClick={() => {
              doUnFollow();
            }}
          >
            Un-follow
          </VSCodeButton>
        ) : (
          <VSCodeButton
            className=" whitespace-nowrap"
            onClick={() => {
              doFollow();
            }}
          >
            Follow
          </VSCodeButton>
        )}
      </div>
    </div>
  );
};
