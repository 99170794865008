import "firebase/compat/auth";

import { GithubIcon } from "@/components/lib/icons";
import firebase from "firebase/compat/app";
import { getFirebaseConfig } from "@/config/firebaseConfig";

firebase.initializeApp(getFirebaseConfig());

const authGithub = () => {
  var provider = new firebase.auth.GithubAuthProvider();
  firebase.auth().signInWithRedirect(provider);
};

const AuthButton = ({ caption }) => {
  return (
    <button
      type="button"
      className="flex-row-centered px-2 py-1 space-x-2 cursor-pointer bg-button-bg hover:bg-button-hover text-button-fg"
      onClick={() => {
        authGithub();
      }}
    >
      <div>
        <GithubIcon className="h-5" />
      </div>
      <div>{caption}</div>
    </button>
  );
};

export default AuthButton;
