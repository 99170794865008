/* eslint-disable no-unused-vars */
import * as appConfig from "@/config/appConfig";

import {
  ArrowSmRightIcon,
  BadgeCheckIcon,
  CalendarIcon,
  ShareIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { ClipboardCheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { cloneElement, useCallback, useContext, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import BadgeBox from "@/components/BadgeBox";
import CalendarBox from "@/components/CalendarBox";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DndInfoPop from "@/components/pops/DndInfoPop";
import ErrorGrow from "@/components/lib/ErrorGrow";
import { GithubIcon } from "@/components/lib/icons";
import InviteBox from "@/components/InviteBox";
import Loading from "@/components/lib/Loading";
import MagicalStatus from "./status/MagicalStatus";
import ModalContent from "@/components/modals/ModalContent";
import ModalTiny from "@/components/modals/ModalTiny";
import SectionDiv from "@/components/status/SectionDiv";
import { Share } from "react-twitter-widgets";
import StatusContainer from "@/components/status/StatusContainer";
import Title from "@/components/Title";
import UserContext from "@/contexts/UserContext";
import { VSCodeTextField } from "@vscode/webview-ui-toolkit/react";
import clsx from "clsx";
import { useDialog } from "@/contexts/DialogContext";
import { ExclamationIcon } from "@heroicons/react/outline";

const GET_CALENDARS = gql`
  query getCalendars {
    user {
      id
      calendars {
        data {
          createTime
          id
          public
          secret
          sinceWeeks
          type
          updateTime
          origin
          name
          description
          calendarUrl
        }
      }
    }
  }
`;

const EXT_IDENTIFIER =
  appConfig?.EXT_IDENTIFIER !== undefined ? appConfig.EXT_IDENTIFIER : "stateful.stable";

const VSCodeOpenButton = () => {
  return (
    <a
      className="link"
      href={`vscode://${EXT_IDENTIFIER}?state=eyJvblZpZXciOnsicGFuZWwiOiJzdGF0ZWZ1bC1tZXRyaWNzIiwibmFtZSI6IndlZWsifX0=`}
    >
      <div className="flex-row-centered flex-shrink px-2 py-0.5 space-x-2  font-semibold border rounded shadow cursor-pointer max-h-min text-vscode-light focus:outline-none whitespace-nowrap border-border hover:border-gray-300 hover:shadow">
        <div>
          <img src="/assets/vscode.png" className="h-4" alt="VS Code" />
        </div>
        <div>Open in VS Code</div>
      </div>
    </a>
  );
};

const UrlBox = ({ url }) => {
  const [copied, setCopied] = useState(false);
  const text = useMemo(() => {
    return `Check out ${url}`;
  }, [url]);
  return (
    <div className="w-full space-x-2 flex-row-centered">
      <VSCodeTextField value={text} className="w-full" readonly={true} />
      <div className="cursor-pointer t">
        <CopyToClipboard onCopy={() => setCopied(true)} text={text}>
          {copied ? (
            <ClipboardCheckIcon className="flex-shrink-0 w-5 icon" />
          ) : (
            <ClipboardIcon className="flex-shrink-0 w-5 icon" />
          )}
        </CopyToClipboard>
      </div>
      <Share
        url={url}
        options={{
          related: "statefulhq",
          text: "Follow my VS @Code coding status in real-time at",
          hashtags: "DEVCommunity",
        }}
      />{" "}
      &nbsp;
    </div>
  );
};

const StatusModal = ({ title, children, _close }) => {
  return (
    <ModalContent title={title} description="" close={_close}>
      {children}
    </ModalContent>
  );
};

const ShareButton = ({ icon, title, onClick }) => {
  return (
    <div
      className="w-full border rounded cursor-pointer hover:border-tab-active-border"
      onClick={onClick}
    >
      <div className="flex flex-col items-center p-3 space-y-1">
        <div>{cloneElement(icon, { className: "w-6 text-covey-500" })}</div>
        <div className="text-lg">{title}</div>
      </div>
    </div>
  );
};

const ShareButtons = ({ username }) => {
  const { addDialog } = useDialog();
  const {
    data: calData,
    loading: calLoading,
    error: calError,
  } = useQuery(GET_CALENDARS, { pollInterval: 60000 });

  const calendars = useMemo(() => {
    return calData?.user?.calendars?.data;
  }, [calData]);

  const _click = useCallback(
    (title, content) => {
      addDialog({
        Component: (
          <ModalTiny>
            <StatusModal title={title}>{cloneElement(content, {})}</StatusModal>
          </ModalTiny>
        ),
      });
    },
    [addDialog]
  );

  if (calLoading)
    return (
      <div className="pt-8">
        <Loading />
      </div>
    );
  if (calError) return <ErrorGrow message={calError.message} />;

  return (
    <div className="flex flex-col justify-center w-full space-x-0 space-y-2 md:space-x-2 md:flex-row md:space-y-0">
      {calendars && calendars.length > 0 && (
        <ShareButton
          icon={<CalendarIcon />}
          title="Calendar"
          onClick={() => {
            _click(
              "Your Calendar is ready!",
              <div className="flex flex-col space-y-4">
                <CalendarBox calendars={calendars} />
                <div className="space-x-2 flex-row-centered link">
                  <ArrowSmRightIcon className="w-5" />
                  <a href="/calendar">Or preview in the browser.</a>
                  <div>
                    <CalendarIcon className="w-5" />
                  </div>
                </div>
              </div>
            );
          }}
        />
      )}

      <ShareButton
        icon={<BadgeCheckIcon />}
        title="Badges"
        onClick={() => {
          _click("Status badges", <BadgeBox username={username} />);
        }}
      />
      <ShareButton
        icon={<ShareIcon />}
        title="Profile"
        onClick={() => {
          _click("Public profile", <UrlBox url={`https://app.stateful.com/status/${username}`} />);
        }}
      />
      <ShareButton
        icon={<UserGroupIcon />}
        title="Invite"
        onClick={() => {
          _click("Invite friends", <InviteBox />);
        }}
      />
    </div>
  );
};

const StatusBoxSharing = ({ user, message = null }) => {
  const [copied, setCopied] = useState(false);

  const username = useMemo(() => {
    return user?.github?.username;
  }, [user]);

  const isDnd = useMemo(() => {
    return user?.dnd?.active;
  }, [user]);

  const url = useMemo(() => {
    return `https://app.stateful.com/status/${username}`;
  }, [username]);

  return (
    <div className="w-full">
      <div className="flex flex-row py-4 border shadow-xl rounded-2xl">
        <div className="flex items-center justify-center w-1/6 px-2 min-w-max">
          <DndInfoPop dnd={isDnd}>
            <img
              src={user.photoUrl || "/assets/empty-avatar.png"}
              className={clsx("w-16 h-16 rounded-full max-w-min border border-transparent", {
                "border-blue-400": isDnd,
              })}
              alt="face"
            />
          </DndInfoPop>
        </div>
        <div className="flex-grow overflow-hidden text-left">
          <div className="flex flex-col justify-center h-full">
            <div className="pr-2 space-x-2 flex-row-centered">
              <div className="text-3xl truncate">{username}</div>
              <a href={`http://github.com/${username}`}>
                <GithubIcon className="w-5 icon" />
              </a>
              <div className="cursor-pointer text-covey-500">
                <CopyToClipboard onCopy={() => setCopied(true)} text={url}>
                  {copied ? (
                    <ClipboardCheckIcon className="flex-shrink-0 w-5" />
                  ) : (
                    <ClipboardIcon className="flex-shrink-0 w-5 " />
                  )}
                </CopyToClipboard>
              </div>
            </div>
            {message ? (
              <>{message}</>
            ) : (
              <>
                <MagicalStatus />
              </>
            )}
          </div>
        </div>
        <div className="items-center justify-center hidden w-1/6 px-2 md:flex">
          <div className="text-7xl">🎧</div>
        </div>
      </div>
    </div>
  );
};

const SharingContainer = () => {
  const { sharingSettingsLoading, sharingSettingsError, sharingSettingsMap, user, userIsLoading } =
    useContext(UserContext);

  const username = useMemo(() => {
    return user?.user?.github?.username;
  }, [user]);

  const message = useMemo(() => {
    if (!sharingSettingsMap["profile"]) {
      return "You are totally hidden.";
    }
    if (!sharingSettingsMap["status"]) {
      return "You are not sharing your status.";
    }
    return null;
  }, [sharingSettingsMap]);

  if (userIsLoading || sharingSettingsLoading) {
    return <Loading />;
  }

  if (sharingSettingsError) return <ErrorGrow message={sharingSettingsError.message} />;

  return (
    <>
      <Title>Sharing</Title>
      <StatusContainer>
        <SectionDiv stay={true}>
          <div className="justify-between w-full pt-8 pb-4 flex-row-centered">
            <div>
              <img src="/assets/stateful_icon.svg" className="h-20" alt="Stateful" />
            </div>
            <div>
              <VSCodeOpenButton />
            </div>
          </div>
        </SectionDiv>
        <SectionDiv>
          <div className="w-full border border-warning-border bg-warning-bg my-2 rounded-sm p-1 flex flex-row items-top space-x-2 text-input-fg">
            <ExclamationIcon className="w-6 h-6 min-w-min" />
            <div>
              <span className="font-semibold">Important:</span> Coding metrics stopped collecting
              data as of on October 30, 2023. Contact us on{" "}
              <a
                className="link"
                href="https://discord.gg/stateful"
                target="_blank"
                rel="noreferrer"
              >
                Discord
              </a>{" "}
              if you have any questions. We apologize for any inconvenience. Historical metrics and
              notes in the VS Code UX will continue to be available for the time being.
              <br />
              <br />
              Please visit{" "}
              <a className="underline font-semibold" href={"https://runme.dev"} alt={"runme.dev"}>
                https://runme.dev
              </a>{" "}
              to learn how the team is making{" "}
              <a className="underline font-semibold" href={"https://github.com/stateful/runme"}>
                Runme (Open Source)
              </a>{" "}
              run your Markdown docs.
            </div>
          </div>
        </SectionDiv>
        {/* <SectionDiv stay={true}>
          <div className="flex flex-col justify-center w-full px-4 space-y-5 overflow-hidden">
            <SuspenseContainer>
              <StatusBoxSharing user={user.user} message={message} />
            </SuspenseContainer>

            <StatusSharingExpand />
          </div>
        </SectionDiv>

        <SectionDiv stay={true}>
          <ShareButtons username={username} />
        </SectionDiv>

        <SectionDiv stay={true}>
          <FacesAuthed animate={true} />
        </SectionDiv> */}
      </StatusContainer>
    </>
  );
};

export default SharingContainer;
