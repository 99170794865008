import { cloneElement, useMemo } from "react";

import { CATEGORY_COLORS } from "@/components/cards/CardMaps";
import CardPop from "@/components/pops/CardPop";
import clsx from "clsx";
import moment from "moment";

// import { gql, useMutation } from "@apollo/client";

// import { useNotification } from "@/contexts/NotificationContext";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    ss: "%ss",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dh",
    d: "a day",
    dd: "%dd",
    M: "a month",
    MM: "%dM",
    y: "a year",
    yy: "%dY",
  },
});

// const SET_CARD_VISIBILITY = gql`
//   mutation updateCardVis($input: SetFeedItemsInput!) {
//     setFeedItems(input: $input) {
//       errors {
//         field
//         message
//       }
//     }
//   }
// `;

export const CardTitleIcon = ({ children, classes, item }) => {
  const category = CATEGORY_COLORS[item?.type?.category?.name] || "bg-indigo-700";

  return (
    <div className={clsx("rounded", `${classes} ${category}`)}>
      <div className="w-5 h-5 p-0.5 text-white">{children}</div>
    </div>
  );
};

export const CardTitle = (props) => {
  const { item, title, children, date = false } = props;

  const TitleNode = useMemo(() => {
    if (!title) {
      return <>{item?.type?.displayName}</>;
    } else if (typeof title === "string") {
      return <>{title}</>;
    } else {
      return title;
    }
  }, [title, item]);

  return (
    <div>
      <div className="justify-between px-1 py-2 flex-row-centered">
        <div className="flex-row-centered flex-nowrap">
          <div className="font-medium">{cloneElement(TitleNode, {})}</div>
          <div>{children}</div>
          {date && <div className="text-description">{moment(item.time).fromNow(true)}</div>}
        </div>

        <div className="px-2">
          <CardPop {...props} />
        </div>
      </div>
    </div>
  );
};

export const CardFooter = ({ children }) => {
  return <div className="flex w-full px-1 py-0.5">{children}</div>;
};

export const CardWrapper = ({ children }) => {
  return (
    <div className="py-2 break-inside-avoid">
      <div className="flex flex-col h-full">{children}</div>
    </div>
  );
};

export const CardContent = ({ children }) => {
  return (
    <div
      className="flex-shrink px-2 py-2 border rounded-sm border-panel-border"
      style={{ minHeight: "90px" }}
    >
      {children}
    </div>
  );
};

// export const CardHideLink = (props) => {
//   const { item, text, feedRefetch } = props;
//   const [updateCardVis] = useMutation(SET_CARD_VISIBILITY);
//   const notification = useNotification();

//   const doUpdateCardVis = useCallback(() => {
//     updateCardVis({
//       variables: {
//         input: {
//           dismissed: true,
//           feedItemIds: [item.id],
//         },
//       },
//     }).then((resp) => {
//       feedRefetch();
//       notification.info("Hidden!");
//     });
//   }, [updateCardVis, item, feedRefetch, notification]);

//   return (
//     <div
//       className="link"
//       onClick={() => {
//         doUpdateCardVis();
//       }}
//     >
//       {text}
//     </div>
//   );
// };

// export const CardSnoozeLink = (props) => {
//   const { item, text, feedRefetch } = props;
//   const [updateCardVis] = useMutation(SET_CARD_VISIBILITY);
//   const notification = useNotification();

//   const doUpdateCardVis = useCallback(() => {
//     updateCardVis({
//       variables: {
//         input: {
//           snoozeDays: 7,
//           feedItemIds: [item.id],
//         },
//       },
//     }).then((resp) => {
//       feedRefetch();
//       notification.info("Snoozed a week!");
//     });
//   }, [updateCardVis, item, feedRefetch, notification]);

//   return (
//     <div
//       className="link"
//       onClick={() => {
//         doUpdateCardVis();
//       }}
//     >
//       {text}
//     </div>
//   );
// };
