import { VSCodeDropdown, VSCodeOption } from "@vscode/webview-ui-toolkit/react";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";

const HOURS_OPTION_ITEM = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const MINUTES_OPTION_ITEM = [0, 10, 20, 30, 40, 50];

const formatCurrent = (hour, minute, meridiem) => {
  const h = parseInt(hour);
  const hours = meridiem === "pm" ? (h === 12 ? h : h + 12) : h === 12 ? 0 : h;
  const retVal = moment(`${hours}:${minute}`, "H:m").format("HH:mm:ss");

  return retVal;
};

const TimePicker = ({ value = "12:00:00", onChange = () => null, disabled = false }) => {
  const [currentTime, setCurrentTime] = useState({ hour: "12", minute: "00", meridiem: "pm" });

  useEffect(() => {
    const date = moment(value, "HH:mm:ss");
    const meridiem = date.hours() > 11 ? "pm" : "am";
    if (date.format("m") !== "Invalid date" || date.format("h") !== "Invalid date") {
      setCurrentTime({
        hour: date.format("h"),
        meridiem,
        minute: date.format("m"),
      });
    }
  }, [value]);

  const handleHourChange = useCallback(
    (event) => {
      const hour = event.target.value;
      setCurrentTime({ ...currentTime, hour });
      onChange(formatCurrent(hour, currentTime.minute, currentTime.meridiem));
    },
    [currentTime, onChange]
  );

  const handleMinuteChange = useCallback(
    (event) => {
      const minute = event.target.value;
      setCurrentTime({ ...currentTime, minute });
      onChange(formatCurrent(currentTime.hour, minute, currentTime.meridiem));
    },
    [currentTime, onChange]
  );

  const handleMeridiemChange = useCallback(
    (event) => {
      const meridiem = event.target.value;
      setCurrentTime({ ...currentTime, meridiem });
      onChange(formatCurrent(currentTime.hour, currentTime.minute, meridiem));
    },
    [currentTime, onChange]
  );

  const hoursOptionItems = useMemo(
    () =>
      HOURS_OPTION_ITEM.map((hour, index) => (
        <VSCodeOption key={`${hour}-${index}`} value={hour}>{`${hour}`}</VSCodeOption>
      )),
    []
  );

  const minutesOptionItems = useMemo(() => {
    return MINUTES_OPTION_ITEM.map((minute, index) => {
      const label = minute < 10 ? `0${minute}` : `${minute}`;
      return (
        <VSCodeOption key={`${minute}-${index}`} value={minute}>
          {label}
        </VSCodeOption>
      );
    });
  }, []);

  return (
    <div className="flex justify-start py-1">
      <div className="flex flex-col items-center justify-center space-x-1 vsm:flex-row">
        <VSCodeDropdown
          value={currentTime.hour}
          name="hours"
          onChange={handleHourChange}
          disabled={disabled}
        >
          {hoursOptionItems}
        </VSCodeDropdown>
        <div className="text-sm">:</div>
        <VSCodeDropdown
          value={currentTime.minute}
          name="minutes"
          onChange={handleMinuteChange}
          disabled={disabled}
        >
          {minutesOptionItems}
        </VSCodeDropdown>
        <VSCodeDropdown
          value={currentTime.meridiem}
          name="meridiem"
          onChange={handleMeridiemChange}
          disabled={disabled}
        >
          <VSCodeOption value="am">AM</VSCodeOption>
          <VSCodeOption value="pm">PM</VSCodeOption>
        </VSCodeDropdown>
      </div>
    </div>
  );
};

export default memo(TimePicker);
