import * as appConfig from "@/config/appConfig";

const EXT_IDENTIFIER =
  appConfig?.EXT_IDENTIFIER !== undefined ? appConfig.EXT_IDENTIFIER : "stateful.stable";

const InstallExtensionButton = () => {
  return (
    <button
      type="button"
      className="px-2 py-1 space-x-2 font-medium cursor-pointer bg-button-bg hover:bg-button-hover text-button-fg"
    >
      <a href={`vscode:extension/${EXT_IDENTIFIER}`}>Open VS Code and Install Stateful</a>
    </button>
  );
};

export default InstallExtensionButton;
