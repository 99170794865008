import { memo, useContext, useEffect, useMemo, useState } from "react";

import ErrorGrow from "@/components/lib/ErrorGrow";
import { MilestoneContext } from "@/contexts/MilestoneContext";
import ModalSmall from "@/components/modals/ModalSmall";
import TermsModal from "@/components/modals/TermsModal";
import { useDialog } from "@/contexts/DialogContext";

const Tos = () => {
  const { milestonesRefetch, milestonesLoading, milestonesError, milestonesCompleted } =
    useContext(MilestoneContext);
  const [tosChecked, setTosChecked] = useState(false);
  const { addDialog } = useDialog();

  // see if the agree_tos milestone exists in the completed_milestones query
  const acceptedTos = useMemo(() => {
    if (!milestonesLoading) {
      const search = milestonesCompleted?.filter((milestone) => milestone.name === "agree_tos");

      if (search && search.length > 0) {
        return true;
      }

      return false;
    }
  }, [milestonesCompleted, milestonesLoading]);

  useEffect(() => {
    if (!milestonesLoading)
      if (!acceptedTos && !tosChecked) {
        addDialog({
          Component: (
            <ModalSmall>
              {milestonesError ? (
                <ErrorGrow message={milestonesError.message} />
              ) : (
                <TermsModal title={"Terms of service"} refetch={milestonesRefetch} />
              )}
            </ModalSmall>
          ),
        });
        setTosChecked(true);
      }
  }, [addDialog, acceptedTos, tosChecked, milestonesLoading, milestonesRefetch, milestonesError]);

  return null;
};

export default memo(Tos);
