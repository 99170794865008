import { memo, useCallback, useContext, useEffect, useState } from "react";

import MilestoneContext from "@/contexts/MilestoneContext";
import SettingsContext from "@/contexts/SettingsContext";
import TimePicker from "@/components/TimePicker";
import { parseDayTime } from "@/utils/personalize";

const WorkingHours = () => {
  const { completeMilestones } = useContext(MilestoneContext);
  const { saveUserDayWorkingSettings, daySettings } = useContext(SettingsContext);
  const [workingHours, setWorkingHours] = useState({});

  useEffect(() => {
    if (daySettings) {
      setWorkingHours({
        start: {
          label: parseDayTime(daySettings.workStartTime),
          data: daySettings.workStartTime,
        },
        end: { label: parseDayTime(daySettings.workEndTime), data: daySettings.workEndTime },
      });
    }
  }, [daySettings]);

  const handleWorkingHoursStart = useCallback(
    (time) => {
      if (workingHours.start.data !== time) {
        setWorkingHours({ ...workingHours, start: { label: parseDayTime(time), data: time } });
        completeMilestones("configure_work_hours");
        saveUserDayWorkingSettings({ workStartTime: time, workEndTime: workingHours.end.data });
      }
    },
    [completeMilestones, workingHours, saveUserDayWorkingSettings]
  );
  const handleWorkingHoursEnd = useCallback(
    (time) => {
      if (workingHours.end.data !== time) {
        setWorkingHours({ ...workingHours, end: { label: parseDayTime(time), data: time } });
        completeMilestones("configure_work_hours");
        saveUserDayWorkingSettings({ workStartTime: workingHours.start.data, workEndTime: time });
      }
    },
    [completeMilestones, workingHours, saveUserDayWorkingSettings]
  );

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-description">The hours you want to be online and coding.</div>
      <div className="flex flex-col items-center vlg:space-x-2 vlg:flex-row">
        <TimePicker value={workingHours?.start?.data} onChange={handleWorkingHoursStart} />
        <div className="text-description vmd:block vxs:hidden">➔</div>
        <div className="text-description vmd:hidden vxs:block">↓</div>
        <TimePicker value={workingHours?.end?.data} onChange={handleWorkingHoursEnd} />
      </div>
    </div>
  );
};

export default memo(WorkingHours);
