// import { interpolateSpectral } from "d3-scale-chromatic";
// import { scaleDiverging } from "d3-scale";
// const colorGenerator = scaleDiverging(interpolateSpectral);
import { schemeSet2 } from "d3-scale-chromatic";
import { scaleOrdinal } from "d3-scale";
const colorGenerator = scaleOrdinal(schemeSet2);

// import { interpolateSpectral } from "d3-scale-chromatic";
// import { scaleOrdinal } from "d3-scale";
// const colorGenerator = scaleOrdinal(interpolateSpectral);

const simpleColorConverter = require("simple-color-converter");

// const colors = ["#ed6a5a", "#f4f1bb", "#9bc1bc", "#5ca4a9", "#e6ebe0"];
// const colors = ["#50514f", "#f25f5c", "#ffe066", "#247ba0", "#70c1b3"];
// const colors = ["#ef476f", "#ffd166", "#06d6a0", "#118ab2", "#073b4c"];
// const colors = ["#05668d", "#028090", "#00a896", "#02c39a", "#f0f3bd"];
// const colors = ["#ed6a5a", "#f4f1bb", "#9bc1bc", "#5ca4a9", "#e6ebe0"];
// const colors = ["#d62839", "#ba324f", "#175676", "#4ba3c3", "#cce6f4"];
// const colors = ["#533747", "#5f506b", "#6a6b83", "#76949f", "#86bbbd"];
// const colors = ["#d7263d", "#f46036", "#2e294e", "#1b998b", "#c5d86d"];
// const colors = ["#83b692", "#f9ada0", "#f9627d", "#c65b7c", "#5b3758"]; //closest
// const colors = ["#908e8b", "#88a1b4", "#9ac4b9", "#e09c93", "#ab807c"];
// const colors = ["#54c7b0", "#54c7b0", "#6d5cb4", "#5d92cf", "#d079a7"];
// const colors = ["#81b2c5", "#4d8ea8", "#bf2600", "#e76f51", "#f4a261"];
// const colors = ["#ff5b5c", "#00a1c5", "#0f5a6b", "#6efafa", "#238aa1"];
// const colors = ["#355070", "#6d597a", "#b56576", "#e56b6f", "#eaac8b"]; //seb
// const colors = ["#f72585", "#7209b7", "#3a0ca3", "#4361ee", "#4cc9f0"];
// const colors = ["#d81159", "#8f2d56", "#218380", "#62b6cb", "#5fa8d3"]; //admc
// const colors = ["#6345c4", "#05c793", "#f0be46", "#c3373c", "#00a8e8"];
// const colors = ["#05668d", "#028090", "#00a896", "#02c39a", "#8F2D56"];
// const colors = ["#AD6377", "#FAF6C0", "#FBA7BD", "##8EE0FA", "#6C9DAD"];
// const colors = ["#099ED9", "#36829F", "#05668D", "#4AB0D9", "#044159"];
// const colors = ["#1B3ABF", "#6B84F2", "#05668D", "#99562C", "#BF411B"];
// const colors = ["#5986BD", "#697788", "#355070", "#91A5BD", "#1D2B3D"];
// const colors = ["#F62459", "#75122B", "#F76F91", "#753545", "#C21D46"];

// let colors = [
//   "#9e0142",
//   "#f46d43",
//   "#fdae61",
//   "#fee08b",
//   "#e6f598",
//   "#abdda4",
//   "#66c2a5",
//   "#3288bd",
//   "#5e4fa2",
//   "#433d60",
//   "#e37374",
// ]; //nivo

let colors = [
  "#8dd3c7",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
];

const gradient = [
  "#fff4f7",
  "#fee9ee",
  "#fdc8d6",
  "#fba7bd",
  "#f9668b",
  "#f62459",
  "#dd2050",
  "#941635",
  "#6f1028",
  "#4a0b1b",
];

const randomColor = (max) => {
  return colors[Math.floor(Math.random() * colors.length)];
};

const getColors = (length) => {
  let count = 0;
  let arr = [];
  for (var i = 0; i < length; i++) {
    // let color = interpolateSpectral(i / 10);
    let color = colorGenerator(i);
    // console.log(color);

    if (count < colors.length - 1) {
      count++;
    } else {
      count = 0;
    }
    arr.push(color);
  }
  return arr;
};

const randomHsl = () => {
  let color = randomColor();
  let newColor = new simpleColorConverter({
    hex: color,
    to: "hsl",
  });
  let hslString = `hsl(${newColor.color.h} ${newColor.color.s}% ${newColor.color.l}%)`;
  return hslString;
};

export { colors, randomColor, getColors, gradient, randomHsl };
