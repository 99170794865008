import clsx from "clsx";

const ProgressBar = ({ percentage, backgroundColor }) => {
  return (
    <div className="border rounded-sm border-border" style={{ width: "100px" }}>
      <div
        className={clsx(
          "text-xs font-medium text-gray-700 text-center p-0.5 leading-none rounded-sm",
          backgroundColor
        )}
        style={{ width: `${percentage}%` }}
      >
        {percentage}%
      </div>
    </div>
  );
};

export default ProgressBar;
