import { ClipboardCheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { memo, useMemo, useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import DndInfoPop from "@/components/pops/DndInfoPop";
import { GithubIcon } from "@/components/lib/icons";
import clsx from "clsx";
import getStatus from "@/utils/getStatus";
import useSWR from "swr";

const UserUnknown = () => {
  return (
    <div className="w-full">
      <div className="flex flex-row px-4 py-4 border rounded-full shadow-xl">
        <div className="flex items-center min-w-max">
          <div className="text-7xl">😀</div>
        </div>
        <div className="flex flex-col items-start justify-center flex-grow px-4 space-y-1">
          <div className="flex-row-centered justify-start space-x-2">
            <div className="text-3xl">Looking for someone?</div>
          </div>
          <div className="text-lg">Unfortunately we don't know them!</div>
        </div>
        <div className="items-center hidden md:flex">
          <div className="text-7xl">🎧</div>
        </div>
      </div>
    </div>
  );
};

const StatusContent = ({ data }) => {
  return (
    <div className="pr-2 text-lg break-words">
      <span className="break-words">{getStatus(data.status, null, data?.workDay || null)}</span>
    </div>
  );
};

const StatusBox = ({ username, me = false }) => {
  const { data } = useSWR(`/profiles/${username}`, {
    refreshInterval: 30 * 1000,
    dedupingInterval: 30 * 1000,
  });

  const isDnd = useMemo(() => {
    return data?.dnd?.active;
  }, [data]);

  const [copied, setCopied] = useState(false);

  const url = useMemo(() => {
    return `https://app.stateful.com/status/${username}`;
  }, [username]);

  if (data.message === "Not found") {
    return <UserUnknown />;
  }

  return (
    <div className="w-full">
      <div className="flex flex-row py-4 border shadow-xl rounded-2xl">
        <div className="flex items-center justify-center w-1/6 px-2 min-w-max">
          <DndInfoPop dnd={isDnd}>
            <img
              src={data.photoUrl || "/assets/empty-avatar.png"}
              className={clsx("w-16 h-16 rounded-full max-w-min border border-transparent", {
                "border-blue-400": isDnd,
              })}
              alt="face"
            />
          </DndInfoPop>
        </div>
        <div className="flex-grow overflow-hidden text-left">
          <div className="flex flex-col justify-center h-full">
            <div className="flex-row-centered pr-2 space-x-2">
              <div className="text-3xl truncate">{username}</div>
              <a href={`http://github.com/${username}`}>
                <GithubIcon className="w-5 icon" />
              </a>
              <div className="cursor-pointer text-covey-500">
                <CopyToClipboard onCopy={() => setCopied(true)} text={url}>
                  {copied ? (
                    <ClipboardCheckIcon className="flex-shrink-0 w-5" />
                  ) : (
                    <ClipboardIcon className="flex-shrink-0 w-5 " />
                  )}
                </CopyToClipboard>
              </div>
            </div>
            <StatusContent data={data} />
          </div>
        </div>
        <div className="items-center justify-center hidden w-1/6 px-2 md:flex">
          <div className="text-7xl">🎧</div>
        </div>
      </div>
    </div>
  );
};

export default memo(StatusBox);
