import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import { VSCodeCheckbox } from "@vscode/webview-ui-toolkit/react";

const GET_STATUS_SHARING_SETTINGS = gql`
  query GetStatusSharingSettings {
    user {
      id
      settings {
        sharing {
          activity
          file
          gitBranch
          language
          profile
          project
          schedule
          dnd
        }
      }
    }
  }
`;

const UPDATE_SHARING_SETTING = gql`
  mutation UpdateSetting($input: SetUserSharingSettingsInput!) {
    setUserSharingSettings(input: $input) {
      errors {
        message
      }
    }
  }
`;

const sharingArr = ["activity", "file", "gitBranch", "language", "project", "dnd"];
const sharingDisplay = {
  activity: "Activity",
  file: "File",
  gitBranch: "Branch",
  language: "Language",
  project: "Project",
  dnd: "Focus",
};

const StatusEntry = ({ name, value }) => {
  const [UpdateSetting] = useMutation(UPDATE_SHARING_SETTING, {
    refetchQueries: [GET_STATUS_SHARING_SETTINGS, "GetStatusSharingSettings"],
  });

  const update = useCallback(
    (value) => {
      UpdateSetting({
        variables: {
          input: {
            [name]: value ? "EVERYONE" : "ONLY_ME",
          },
        },
      });
    },
    [name, UpdateSetting]
  );

  const checked = useMemo(() => {
    return value === "EVERYONE" ? true : false;
  }, [value]);

  return (
    <div
      className="py-1 space-x-1 cursor-pointer flex-row-centered"
      onClick={() => {
        update(!checked);
      }}
    >
      <VSCodeCheckbox checked={checked} onChange={(e) => update(e.target.checked)} />

      <div>{sharingDisplay[name]}</div>
    </div>
  );
};

const StatusFieldConfiguration = () => {
  const { data, error, loading } = useQuery(GET_STATUS_SHARING_SETTINGS);

  const sharingObj = data?.user?.settings?.sharing;

  if (loading || !sharingObj) return <Loading />;
  if (error) return <ErrorGrow message={error.message} />;

  return (
    <div className="flex flex-col flex-wrap space-x-0 vsm:space-y-0 vsm:space-x-2 vsm:flex-row">
      {sharingArr.map((name) => {
        return <StatusEntry name={name} key={name} value={sharingObj[name]} />;
      })}
    </div>
  );
};

export default StatusFieldConfiguration;
