import { gql, useQuery } from "@apollo/client";
import { useContext, useMemo } from "react";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import FacePile from "@/components/status/FacePile";
import Loading from "@/components/lib/Loading";
import useWindowFocus from "@/components/hooks/useWindowFocus";

//(includeUser: true)
const GET_PROFILES = gql`
  query GetProfiles {
    profiles {
      data {
        id
        displayName
        github {
          username
        }
        name
        photoUrl
        publicUrl
        status {
          project {
            file {
              name
            }
            displayName
            gitBranch {
              name
            }
            language {
              displayName
              id
            }
            name
            shortName
          }
          latestActivityTime
          activity {
            displayName
          }
        }
        dnd {
          active
          startTime
          expireTime
        }
      }
    }
  }
`;

const FacesAuthed = ({ animate = false }) => {
  const { pollInterval } = useContext(AppContext);

  const { data, loading, error, refetch } = useQuery(GET_PROFILES, { pollInterval: pollInterval });

  useWindowFocus(0, () => {
    refetch();
  });

  const faces = useMemo(() => {
    return data?.profiles?.data;
  }, [data]);

  if (loading) {
    return <Loading />;
  }
  if (error) return <ErrorGrow message={error.message} />;

  return (
    <div className="flex flex-col w-full space-y-4 rounded">
      <div className="text-xl">Online and sharing:</div>
      <FacePile faces={faces} animate={animate} />
    </div>
  );
};

export default FacesAuthed;
