import { cloneElement, memo } from "react";

import { XIcon } from "@heroicons/react/solid";

const ModalTitle = ({ title, description, close }) => {
  return (
    <div className="flex flex-col px-4 py-3">
      <div className="flex items-center justify-between ">
        <div>
          {typeof title === "string" ? (
            <div className="text-lg font-medium">{title}</div>
          ) : (
            cloneElement(title, {})
          )}
        </div>

        <XIcon
          className="h-4 icon"
          onClick={() => {
            close();
          }}
        />
      </div>
      <div className="text-description">{description}</div>
    </div>
  );
};

export default memo(ModalTitle);
