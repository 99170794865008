import { SELECT_ONE_DAY_WARNING, WEEK_DAYS } from "@/const/personalize";
import { getWeekDayLabel, hasSelectedDays } from "@/utils/personalize";
import { memo, useCallback, useContext, useMemo, useState } from "react";

import { DayToggle } from "@/components/DayToggle";
import SettingsContext from "@/contexts/SettingsContext";

const PlannedWorkDays = () => {
  const { saveUserDaySettings, daysSettings, plannedDays } = useContext(SettingsContext);
  const [oneDaySelectedWarning, setOneDaySelectedWarning] = useState(null);

  const handleDayToggle = useCallback(
    (day, checked) => {
      const templateDaySettings = daysSettings.filter((day) => day.workStartTime !== null)?.[0];

      if (templateDaySettings) {
        const emptyDay = {
          dayOfWeeks: [day],
          workStartTime: null,
          workEndTime: null,
          standupTime: null,
        };

        const activeDay = {
          dayOfWeeks: [day],
          workStartTime: templateDaySettings.workStartTime,
          workEndTime: templateDaySettings.workEndTime,
          standupTime: templateDaySettings.standupTime,
        };

        const variables = checked ? activeDay : emptyDay;
        saveUserDaySettings(variables);
      }
    },
    [saveUserDaySettings, daysSettings]
  );

  const toggleDays = useMemo(() => {
    return WEEK_DAYS.map((day) => (
      <DayToggle
        key={day.id}
        label={getWeekDayLabel(day.id)}
        checked={plannedDays?.[day.id]}
        setChecked={(checked) => {
          const days = { ...plannedDays, [day.id]: checked };

          if (!hasSelectedDays(days)) {
            setOneDaySelectedWarning(SELECT_ONE_DAY_WARNING);
          } else {
            handleDayToggle(day.id, checked);
            setOneDaySelectedWarning(null);
          }
        }}
      />
    ));
  }, [handleDayToggle, plannedDays]);

  return (
    <div>
      <div className="font-medium">Coding days:</div>
      <div className="flex gap-1 mt-2 vxs:flex-wrap vsm:flex-nowrap">{toggleDays}</div>
      {oneDaySelectedWarning && <div className="pt-2 text-error-fg">{oneDaySelectedWarning}</div>}
    </div>
  );
};

export default memo(PlannedWorkDays);
