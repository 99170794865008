import { createContext, useContext, useState } from "react";

import AppContext from "@/contexts/AppContext";
import moment from "moment";
import useInterval from "@use-it/interval";

const DateContext = createContext();

//check once a minute
const dateCheckInterval = 1000 * 10 * 6 * 5;

const getCurrentDateObject = () => {
  return {
    startDate: moment().subtract(6, "days").startOf("day").toDate(),
    endDate: moment().endOf("day").toDate(),
    key: "selection",
  };
};

const DateProvider = ({ children }) => {
  const { baseUrl } = useContext(AppContext);
  const [selectionRange, setSelectionRange] = useState(getCurrentDateObject());
  const [dateCustomized, setDateCustomized] = useState(false);

  // check every 5 minutes to see if we moved from the month date
  // when the app originally loaded, to a new day
  // if so, and the user hasn't customized the range
  // update to the new date range
  useInterval(() => {
    //if the user has a custom date range, don't muck with it
    if (dateCustomized) {
      return;
    }
    const today = moment().format("D");
    const pickerEndDay = moment(selectionRange.endDate).format("D");
    //if we passed the midnight threshold to the next day, update
    //the global picker to use the next day and day minus 6
    if (today !== pickerEndDay) {
      setSelectionRange(getCurrentDateObject());
    }
  }, dateCheckInterval);

  return (
    <DateContext.Provider
      value={{
        baseUrl,
        selectionRange,
        setSelectionRange,
        setDateCustomized,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};

export default DateContext;
export { DateProvider };
