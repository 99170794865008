import StretchCenter from "@/components/lib/StretchCenter";

export default function Error() {
  return (
    <StretchCenter>
      <center>
        <img alt="404" className="max-w-xs" src="/assets/robot.png" />
        <br />
        <h2 className="text-3xl leading-8 tracking-tight text-gray-900">404 - Page Not Found</h2>
      </center>
    </StretchCenter>
  );
}
