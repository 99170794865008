import TimezoneSelect, { allTimezones } from "react-timezone-select";

import SuspenseContainer from "@/components/SuspenseContainer";
import omit from "lodash.omit";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "var(--vscode-input-foreground)",
    };
  },
};

//some timezones don't exist in timezone-soft
//which breaks react-timezone-select
//https://github.com/ndom91/react-timezone-select/issues/76
const brokenTzArr = ["America/Ciudad_Juarez"];

const TimezoneSelector = ({ containerRef, setSelectedTimezone, selectedTimezone }) => {
  const timezones = Intl.supportedValuesOf("timeZone");
  const timezoneObj = timezones.reduce((a, v) => ({ ...a, [v]: v }), {});
  const tzObj = Object.values(omit(timezoneObj, brokenTzArr));
  const tzArr = tzObj.reduce((a, v) => ({ ...a, [v]: v }), {});

  return (
    <SuspenseContainer>
      <TimezoneSelect
        value={selectedTimezone}
        onChange={setSelectedTimezone}
        menuPortalTarget={containerRef?.current}
        styles={colourStyles}
        timezones={{
          ...allTimezones,
          ...tzArr,
        }}
        theme={(theme) => {
          return {
            ...theme,
            borderRadius: 2,
            border: 0,
            colors: {
              ...theme.colors,
              text: "var(--vscode-input-foreground)",
              color: "var(--vscode-input-background)",
              //selected item
              primary: "var(--vscode-inputOption-hoverBackground)",
              //hover
              primary25: "var(--vscode-inputOption-hoverBackground)",
              primary50: "var(--vscode-input-background)",
              primary75: "var(--vscode-input-background)",
              neutral0: "var(--vscode-input-background)",
              //seperator
              neutral20: "var(--vscode-editorGroup-border)",
              danger: "red",
              dangerLight: "orange",
              neutral5: "var(--vscode-inputOption-activeBorder)",
              neutral10: "var(--vscode-inputOption-activeBorder)",
              neutral30: "var(--vscode-editorGroup-border)",
              neutral40: "var(--vscode-inputOption-activeBorder)",
              neutral50: "var(--vscode-inputOption-activeBorder)",
              neutral60: "var(--vscode-editorGroup-border)",
              neutral70: "var(--vscode-input-foreground)",
              neutral80: "var(--vscode-input-foreground)",
              neutral90: "var(--vscode-input-background)",
            },
          };
        }}
      />
    </SuspenseContainer>
  );
};

export default TimezoneSelector;
