import { Area, CartesianGrid, ComposedChart, Tooltip } from "recharts";
import {
  GraphReTooltip,
  LegendWrapper,
  LineWrap,
  XAxisWrap,
  YAxisWrap,
} from "@/components/graphs/ChartWrappers";

import ContainerDimensions from "react-container-dimensions";
import { getColors } from "@/utils/colors";
import moment from "moment";
import { secondsToHuman } from "@/utils/convert";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <GraphReTooltip>
        <div className="pb-2">{label}</div>

        <div>
          {payload.map((entry, index) => {
            let value = secondsToHuman(entry.value);

            return (
              <div key={entry.name} style={{ color: entry.color }}>
                {entry.name}: {value}
              </div>
            );
          })}
        </div>
      </GraphReTooltip>
    );
  }

  return <></>;
};

const CodingGraph = ({ data }) => {
  const colors = getColors(2);

  return (
    <div className="w-full h-full">
      <ContainerDimensions>
        {({ width, height }) => {
          return (
            <ComposedChart
              data={data}
              height={height}
              width={width}
              margin={{
                top: 10,
                right: 20,
                left: 10,
                bottom: 10,
              }}
            >
              <CartesianGrid stroke="var(--vscode-panel-border)" />
              {XAxisWrap({ dataKey: "date", dy: 10 })}
              {YAxisWrap({
                tickFormatter: (num) => {
                  return moment.duration(num, "seconds").asMinutes().toFixed(0);
                },
                yAxisId: "seconds",
                orientation: "left",
                label: {
                  value: "Minutes",
                  angle: -90,
                  dx: -20,
                },
              })}

              {LineWrap({
                name: "Break time",
                id: "seconds",
                color: colors[0],
                dataKey: "restDurationSeconds",
              })}

              <Area
                dot
                name="Writing code"
                type="monotone"
                yAxisId="seconds"
                dataKey="writingSeconds"
                fill={colors[1]}
                stroke={colors[1]}
                isAnimationActive={false}
              />

              <Area
                dot
                name="Reading code"
                type="monotone"
                yAxisId="seconds"
                dataKey="readingSeconds"
                fill={colors[2]}
                stroke={colors[2]}
                isAnimationActive={false}
              />

              <Tooltip content={<CustomTooltip />} />
              {LegendWrapper()}
            </ComposedChart>
          );
        }}
      </ContainerDimensions>
    </div>
  );
};

export default CodingGraph;
