//Feed sharing preference order is type -> category -> user (user.sharing.feed)
// were just using the user level one for now, and can expand later

import { VSCodeDropdown, VSCodeOption } from "@vscode/webview-ui-toolkit/react";
import { useCallback, useContext, useMemo } from "react";

import ErrorGrow from "@/components/lib/ErrorGrow";
import Loading from "@/components/lib/Loading";
import UserContext from "@/contexts/UserContext";

const FeedSharingSelect = () => {
  const {
    setSharingSettings,
    sharingSettingsRefetch,
    sharingSettingsLoading,
    sharingSettingsError,
    sharingSettings,
  } = useContext(UserContext);

  const feedSharing = useMemo(() => {
    return sharingSettings?.feed;
  }, [sharingSettings]);

  const onChange = useCallback(
    (e) => {
      setSharingSettings({
        variables: {
          input: {
            feed: e.target.value,
          },
        },
      }).then((resp) => {
        sharingSettingsRefetch();
      });
    },
    [setSharingSettings, sharingSettingsRefetch]
  );

  if (sharingSettingsLoading) return <Loading />;

  if (sharingSettingsError) return <ErrorGrow message={sharingSettingsError.message} />;

  return (
    <VSCodeDropdown value={feedSharing} onChange={onChange}>
      <VSCodeOption value="ONLY_ME">Nobody</VSCodeOption>
      <VSCodeOption value="FRIENDS">Friends</VSCodeOption>
      <VSCodeOption value="FOLLOWERS">Followers</VSCodeOption>
      <VSCodeOption value="EVERYONE">Everyone</VSCodeOption>
    </VSCodeDropdown>
  );
};
export default FeedSharingSelect;
