import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip } from "recharts";
import {
  GraphReTooltip,
  LegendWrapper,
  LineWrap,
  XAxisWrap,
  YAxisWrap,
} from "@/components/graphs/ChartWrappers";

import { getColors } from "@/utils/colors";
import { memo } from "react";

const CustomTooltip = ({ active, payload, label, formatValue = (entry) => entry.value }) => {
  if (active && payload) {
    let ratingNote = "";
    return (
      <GraphReTooltip>
        <div className="pb-2">{label}</div>
        <div>
          {payload.map((entry) => {
            if (entry.payload.ratingNote) {
              ratingNote = entry.payload.ratingNote;
            }
            return (
              <div key={entry.name} style={{ color: entry.color }}>
                {entry.name}: {formatValue(entry)}
                {entry.ratingNote}
              </div>
            );
          })}
        </div>
        <div
          className="py-2 text-sm truncate max-h-20 text-description"
          style={{ maxWidth: "220px" }}
        >
          {ratingNote}
        </div>
      </GraphReTooltip>
    );
  }

  return <></>;
};

const RatingsGraph = ({ data }) => {
  const colors = getColors(3);

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        margin={{
          top: 10,
          right: 5,
          left: 0,
          bottom: 10,
        }}
      >
        <CartesianGrid stroke="var(--vscode-panel-border)" />
        {XAxisWrap({ dataKey: "date", dy: 10 })}
        {YAxisWrap({
          id: "sessions",
          name: "sessions",
          orientation: "right",
          yAxisId: "sessions",
          label: {
            value: "Sessions",
            angle: 90,
            dx: 10,
          },
        })}

        {YAxisWrap({
          id: "rating",
          name: "rating",
          yAxisId: "rating",
          orientation: "left",
          label: {
            value: "Ratings",
            angle: -90,
            dx: -5,
          },
        })}

        <Tooltip content={<CustomTooltip />} />

        <Bar
          yAxisId="sessions"
          dataKey="sessions"
          name="Sessions"
          fill={colors[0]}
          fillOpacity="0.7"
          isAnimationActive={false}
        />
        {LineWrap({
          datakey: "rating",
          name: "Rating",
          id: "rating",
          color: colors[1],
          strokeWidth: 4,
        })}

        {LegendWrapper()}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default memo(RatingsGraph);
