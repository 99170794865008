import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";
import { memo, useContext, useMemo, useState } from "react";

import AppContext from "@/contexts/AppContext";
import ErrorGrow from "@/components/lib/ErrorGrow";
import { FireIcon } from "@heroicons/react/outline";
import Loading from "@/components/lib/Loading";
import ModalBig from "@/components/modals/ModalBig";
import ScoreBreakdown from "@/components/ScoreBreakdown";
import ScoreRecord from "@/components/ScoreRecord";
import ScoreRings from "@/components/ScoreRings";
import SettingsContext from "@/contexts/SettingsContext";
import SettingsModal from "@/components/modals/SettingsModal";
import SuspenseContainer from "@/components/SuspenseContainer";
import UserContext from "@/contexts/UserContext";
import { VSCodeLink } from "@vscode/webview-ui-toolkit/react";
import clsx from "clsx";
import { contribLabelMap } from "@/components/score/contribLabelMap";
import moment from "moment";
import { secondsToHuman } from "@/utils/convert";
import { useDialog } from "@/contexts/DialogContext";
import useWindowFocus from "@/components/hooks/useWindowFocus";

export const GET_SCORE = gql`
  query getScore {
    day {
      score
      date
      codingDuration {
        totalSeconds
      }
    }
  }
`;

const ActivityTargetModal = () => {
  const { addDialog } = useDialog();

  return (
    <VSCodeLink
      onClick={() => {
        addDialog({
          Component: (
            <ModalBig>
              <SettingsModal tab="schedule" />
            </ModalBig>
          ),
        });
      }}
    >
      <span className="text-xs">Configure my activity target →</span>
    </VSCodeLink>
  );
};

const ProgressMessage = memo((props) => {
  const { remainingFlow, targetFlowSeconds, todayFlowSeconds } = props;
  const {
    daySettings,
    daysSettings,
    dayLoading,
    daysLoading,
    dayError,
    daysError,
    dayRefetch,
    daysRefetch,
  } = useContext(SettingsContext);

  useWindowFocus(0, () => {
    dayRefetch();
    daysRefetch();
  });

  const daySettingsMap = useMemo(() => {
    if (daysSettings) {
      return daysSettings?.data?.reduce(
        (obj, item) => ({ ...obj, [item.dayOfWeek]: item.workStartTime }),
        {}
      );
    }
  }, [daysSettings]);

  const today = useMemo(() => {
    return moment().format("dddd").toUpperCase();
  }, []);

  const workingLate = useMemo(() => {
    if (daySettings) {
      const nowTime = moment();
      const endTime = moment(daySettings?.workEndTime, "HH:mm:ss");
      return nowTime.isSameOrAfter(endTime);
    }
  }, [daySettings]);

  if (dayLoading || daysLoading) {
    return <Loading />;
  }
  if (dayError) return <ErrorGrow message={dayError.message} />;
  if (daysError) return <ErrorGrow message={daysError.message} />;

  // messages for day off
  if (daySettingsMap && daySettingsMap[today] === null) {
    if (moment.duration(todayFlowSeconds, "seconds").asMinutes() > 10) {
      return <>Sneaking in some coding on your day off? 🤔</>;
    }
    return <>We hope you're enjoying your day off! 🌴</>;
  }

  if (workingLate) {
    if (remainingFlow === 0) {
      return <>Your super productive day is ending, time to wrap it up? 🎇</>;
    } else {
      return <>You didn't quite hit your target, take a rest and hit it tomorrow. 😴</>;
    }
  }

  return (
    <div>
      {remainingFlow === targetFlowSeconds ? (
        <div className="flex flex-col">
          <div>
            <span className="font-semibold">No activity recorded today, you have a </span>
            target of <span className="font-semibold">{secondsToHuman(targetFlowSeconds)}.</span>
          </div>
          <ActivityTargetModal />
        </div>
      ) : remainingFlow === 0 ? (
        <>You've met your daily coding goal! 🎆</>
      ) : (
        <div className="flex flex-col">
          <div>
            <span className="font-semibold">{secondsToHuman(remainingFlow)}</span> more to reach
            your target of{" "}
            <span className="font-semibold">{secondsToHuman(targetFlowSeconds)}.</span>
          </div>
          <ActivityTargetModal />
        </div>
      )}
    </div>
  );
});

const RingDetails = ({ ringDetails }) => {
  const { name, points, possiblePoints, value } = ringDetails;

  if (ringDetails) {
    return (
      <div>
        <span className="font-medium">{contribLabelMap[name]}</span>: {points} / {possiblePoints}{" "}
        pts ({Number(value).toFixed(1)}%)
      </div>
    );
  }
  return <></>;
};

const NoScoreAvailable = () => {
  const { daysSettings, daysLoading, daysError, daysRefetch } = useContext(SettingsContext);

  useWindowFocus(0, () => {
    daysRefetch();
  });

  const days = daysSettings;

  const daySettingsMap = useMemo(() => {
    if (days) {
      return days?.data?.reduce(
        (obj, item) => ({ ...obj, [item.dayOfWeek]: item.workStartTime }),
        {}
      );
    }
  }, [days]);

  const today = useMemo(() => {
    return moment().format("dddd").toUpperCase();
  }, []);

  if (daysLoading) {
    return <Loading />;
  }

  if (daysError) return <ErrorGrow message={daysError.message} />;

  if (daySettingsMap && daySettingsMap[today] === null) {
    return (
      <div className="flex flex-row p-2 space-x-2">
        <div className="hidden w-1/3 vsm:block">
          <img
            className="object-scale-down"
            src="/assets/working_day_off.svg"
            alt="Day off!"
            style={{ maxHeight: "160px" }}
          />
        </div>
        <div className="flex flex-col justify-center w-full vsm:w-2/3">
          It's not a planned work day 👋, sneaking in some extra cycles?
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row p-2 space-x-2">
      <div className="hidden w-1/3 vsm:block">
        <img
          className="object-scale-down"
          src="/assets/booting_up.svg"
          alt="No score yet"
          style={{ maxHeight: "160px" }}
        />
      </div>
      <div className="flex flex-col justify-center w-full vsm:w-2/3">
        <div>We don't have any activity yet for today.</div>

        <ActivityTargetModal />
      </div>
    </div>
  );
};

const ScoreAvailable = (props) => {
  const { score, todayFlowSeconds, targetFlowSeconds, remainingFlow } = props;
  const [ringDetails, setRingDetails] = useState(null);
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="justify-between px-2 flex-row-centered">
        <div className="space-x-2 flex-row-centered">
          <div className="flex-row-centered">
            <div className="hidden vsm:block">
              <FireIcon className="w-8" />
            </div>
            <div className="text-5xl text-center text-covey-500">{score}</div>
          </div>

          <div className="px-2 py-2">
            {ringDetails ? (
              <RingDetails ringDetails={ringDetails} />
            ) : (
              <ProgressMessage
                remainingFlow={remainingFlow}
                targetFlowSeconds={targetFlowSeconds}
                todayFlowSeconds={todayFlowSeconds}
              />
            )}
          </div>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? <ChevronUpIcon className="h-5 icon" /> : <ChevronDownIcon className="h-5 icon" />}
        </div>
      </div>
      <div
        className={clsx({
          hidden: !open,
          block: open,
        })}
      >
        <div className="flex flex-col p-4 pt-3 space-y-4 overflow-auto">
          <div className="justify-center flex-row-centered">
            <div className="hidden w-1/2 vsm:block">
              <SuspenseContainer>
                <ScoreRings
                  setRingDetails={setRingDetails}
                  mouseOut={() => {
                    setRingDetails(null);
                  }}
                />
              </SuspenseContainer>
            </div>
            <div>
              <ScoreBreakdown />
            </div>
          </div>
          <div>
            <ScoreRecord />
          </div>
        </div>
      </div>
    </div>
  );
};

const ScoreCard = () => {
  const { user, userIsLoading } = useContext(UserContext);
  const { pollInterval } = useContext(AppContext);

  const {
    refetch: refetchScore,
    loading: scoreLoading,
    error: scoreError,
    data: scoreData,
  } = useQuery(GET_SCORE, { pollInterval: pollInterval });

  useWindowFocus(0, () => {
    refetchScore();
  });

  const todayFlowSeconds = scoreData?.day?.codingDuration?.totalSeconds || 0;
  const targetFlowSeconds = user?.user?.settings?.day?.targetCodingDurationSeconds || 0;

  const remainingFlow = useMemo(() => {
    const diff = targetFlowSeconds - todayFlowSeconds;
    if (diff <= 0) {
      return 0;
    }
    return diff;
  }, [todayFlowSeconds, targetFlowSeconds]);

  const score = useMemo(() => {
    if (scoreData?.day?.score) {
      return scoreData?.day?.score;
    }
  }, [scoreData]);

  if (scoreLoading || userIsLoading) {
    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }

  if (scoreError) return <ErrorGrow message={scoreError.message} />;

  if (score) {
    return (
      <ScoreAvailable
        score={score}
        remainingFlow={remainingFlow}
        targetFlowSeconds={targetFlowSeconds}
        todayFlowSeconds={todayFlowSeconds}
      />
    );
  }
  return <NoScoreAvailable />;
};

export default ScoreCard;
